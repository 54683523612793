import React from "react";

export default function AnalyticsCard({ label, img1, img2, count }) {
  return (
    <div className="w-full bg-white rounded-2xl flex flex-col p-6 gap-10">
      <p className="text-base font-bold">{label}</p>

      <div className="w-full flex justify-between items-center">
        <img src={img1} />

        <div className="flex items-center gap-4">
          <p className="flex text-3xl font-semibold">{count}</p>
          <img src={img2} />
        </div>
      </div>
    </div>
  );
}
