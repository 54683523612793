import React, { useEffect, useState } from "react";
import NavBar from "../../components/Universal/NavBar";
import Header from "../../components/Universal/Header";
import ActiveAndInActiveComp from "../../components/Common/ActiveAndInActiveComp";
import DynamicTable from "../../components/Universal/DynamicTable";
import CardSamplePic from "../../assets/images/CardSamplePic.png";
import DriversModal from "./Modals/DriversModal/DriversModal";
import { useDispatch } from "react-redux";
import {
  deactivateDriverAction,
  deleteDriverAction,
  readDriverAction,
} from "../../store/Service/ServiceAction";
import Toastify from "../../components/Universal/Toastify";
import ConfirmToast from "../../components/Common/ConfirmToast";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

const Drivers = ({ role, shortBar, setShortBar }) => {
  const [activeTab, setActiveTab] = useState(1);
  const [drivers, setDrivers] = useState(false);
  const [allDriverData, setAllDriverData] = useState([]);
  const [currentDriverId, setCurrentDriver] = useState("");

  const dispatch = useDispatch();

  const fetchDriversList = () => {
    dispatch(
      readDriverAction({
        callback: (res) => {
          if (res?.type === 1) {
            setAllDriverData(
              res?.data?.map((d) => ({ ...d, vehicleType: d?.vehicleDetails?.[0]?.vehicleType }))
            );
          }
        },
      })
    );
  };

  useEffect(() => {
    fetchDriversList();
  }, []);

  const closeModal = () => {
    setDrivers(drivers);
  };

  const handleTabChange = (data) => {
    setActiveTab(data);
  };

  const headers = [
    {
      headerName: "Driver Name",
      headerId: "firstName",
    },
    {
      headerName: "Vehicle Type",
      headerId: "vehicleDetails",
      subHeaderId: "vehicleType",
    },
    {
      headerName: "Status",
      headerId: "switch",
    },
    {
      headerName: "Created Date",
      headerId: "activationDate",
    },
    {
      headerName: "Actions",
      headerId: "actions",
    },
  ];

  const handleToggleClick = (row, isActive, onSuccess) => {
    dispatch(
      deactivateDriverAction({
        apiPayloadRequest: {
          _id: row?._id,
          userAuthId: row?.userAuthId,
          active: isActive ? 1 : 0,
        },
        callback: (res) => {
          if (res?.type === 1) {
            if (isActive) {
              Toastify("Activation Successfully!", 1);
            } else {
              Toastify("Deactivation Successfully!", 1);
            }
            if (onSuccess) onSuccess();
          }
        },
      })
    );
  };

  const handleDriverPencilClick = (row) => {
    setDrivers(!drivers);
    setCurrentDriver(row?._id);
  };

  const handleDriverDeleteClick = (row) => {
    console.log("row", row);
    const options = {
      customUI: ({ onClose }) => {
        return (
          <ConfirmToast
            title="Are you sure?"
            message="You want to delete this driver?"
            onClose={() => onClose()}
            onSuccess={() => {
              dispatch(deleteDriverAction(row?._id));
              onClose();
            }}
          />
        );
      },
      closeOnEscape: true,
      closeOnClickOutside: true,
      keyCodeForClose: [8, 32],
      overlayClassName: "!bg-gray-500 !bg-opacity-75",
    };

    confirmAlert(options);
  };

  return (
    <div className="flex bg-zinc-100 h-[100vh]">
      <NavBar active={"Drivers"} role={role} />
      <div className="flex w-full h-full">
        <div className={`flex flex-col w-${shortBar ? "full" : "[70vw]"} h-full`}>
          <Header title={"Welcome"} />
          <div className="flex h-[88vh]  w-full pl-3 pr-4">
            <div className="rounded-xl mb-1 w-full bg-white p-4">
              <DynamicTable
                data={allDriverData}
                headers={headers}
                title={"Drivers"}
                search={true}
                option1="All"
                size={10}
                Dropdown={true}
                width={"w-42"}
                heightDesktop={"h-[50%]"}
                nthChildWidth={
                  "[&>*:nth-child(1)]:w-48 [&>*:nth-child(2)]:w-36 [&>*:nth-child(3)]:w-36 [&>*:nth-child(4)]:w-40 [&>*:nth-child(5)]:w-32"
                }
                nthChildStyle={""}
                showTrackIcon={false}
                handleToggleClick={handleToggleClick}
                // handleToggleClick={(row, isActive) => {
                //     console.log(isActive, row);
                // }}

                // handleEditClick={(row) => {
                //     console.log(row);
                // }}
                handlePencilClick={handleDriverPencilClick}
                handleEyeClick={handleDriverPencilClick}
                handleDeleteClick={handleDriverDeleteClick}
              />
            </div>
          </div>
        </div>
        <ActiveAndInActiveComp
          shortBar={shortBar}
          setShortBar={setShortBar}
          active={activeTab}
          setActiveTab={setActiveTab}
          handleTabChange={handleTabChange}
          listData={allDriverData}
        />
      </div>

      <DriversModal
        closeModal={closeModal}
        setIsOpen={setDrivers}
        id={currentDriverId}
        isOpen={drivers}
        handleToggleClick={handleToggleClick}
        fetchDriversList={fetchDriversList}
      />
    </div>
  );
};

export default Drivers;
