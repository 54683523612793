import React, { useEffect, useState } from "react";
import InnerTab from "../../components/Common/Tabs/InnerTab";
import CardOfViewAll from "../../components/ViewAllCards/CardOfViewAll";
import NavBar from "../../components/Universal/NavBar";
import Header from "../../components/Universal/Header";
import ProfileCircle from "../../assets/icons/profilecircle.svg";
import ProfileCircle2 from "../../assets/icons/profilecircle-1.svg";
import wrong from "../../assets/images/wrong.png";
import Button from "../../components/Common/Buttons/Button";
import TextField from "../../components/Common/InputFields/TextField";
import { customStyles } from "../../constants/Constant";
import {
  deactivateDriverAction,
  deleteUserVehicleTypeAction,
  readDriverAction,
  readVehicleAction,
  saveUserVehicleTypeAction,
} from "../../store/Service/ServiceAction";
import { useDispatch } from "react-redux";
import Toastify from "../../components/Universal/Toastify";
import ReactModal from "react-modal";
import { useLocation, useNavigate } from "react-router-dom";
import { FaTruckFront } from "react-icons/fa6";
import { PiLinkSimpleBold } from "react-icons/pi";
import { BsSearch } from "react-icons/bs";
import ToggleButton from "../../components/Universal/ToggleButton";
import { PiCertificateFill } from "react-icons/pi";
import InputDropdown from "../../components/Common/InputFields/InputDropdown";
import DynamicTable from "../../components/Universal/DynamicTable";

const ViewAllCards = ({ role }) => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("Personal Details");
  const [selected, setSelected] = useState("");
  const [allDriverData, setAllDriverData] = useState([]);
  const [currentDriver, setCurrentDriver] = useState([]);
  const [vehicle, setVehicle] = useState([]);
  const [vehicleData, setVehicleData] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState();
  const [currentVehicleData, setCurrentVehicleData] = useState([]);
  const [imgModal, setImgModal] = useState({
    isOpen: false,
    name: "",
    url: "",
  });
  const [searchTerm, setSearchTerm] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const headers = [
    {
      headerName: "Vehicle Type Name",
      headerId: "vehicle_type_name",
    },
    {
      headerName: "Vehicle Service",
      headerId: "vehicle_service",
    },
    {
      headerName: "Actions",
      headerId: "actions",
    },
  ];

  console.log("selectedVehicle", selectedVehicle);

  const addVehicleHandle = () => {
    if (selectedVehicle) {
      dispatch(
        saveUserVehicleTypeAction({
          apiPayloadRequest: {
            _id: selected,
            vehicleObjId: selectedVehicle,
          },
          callback: (res) => {
            if (res?.type === 1) {
              Toastify("Vehicle Added Sucessfully!", 1);
              fetchSelectedDriverDetails();
            } else {
              Toastify("Failed To Add Vehicle!", 2);
            }
          },
        })
      );
    } else {
      Toastify("Please select a vehicle and proceed", 2);
    }
  };

  const handleDeleteVehicle = (id) => {
    dispatch(
      deleteUserVehicleTypeAction({
        apiPayloadRequest: {
          vehicleObjId: id,
        },
        callback: (res) => {
          if (res?.type === 1) {
            Toastify("Vehicle Deleted Successfully!", 1);
          }
        },
      })
    );
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    dispatch(
      readDriverAction({
        callback: (res) => {
          if (res?.type === 1) {
            setAllDriverData(res?.data);
            console.log("location", location?.state?._id);
            if (location?.state?._id) {
              const tempVehicleData = res?.data?.find((d) => d?._id === location?.state?._id);
              setCurrentDriver(tempVehicleData ?? []);
              setSelected(location?.state?._id);
            } else {
              setCurrentDriver(res?.data[0]);
              setSelected(res?.data[0]?._id);
            }
            setCurrentVehicleData(
              res?.data[0]?.vehicleTypes?.vehicleObjId
                ? res?.data[0]?.vehicleTypes?.vehicleObjId
                : []
            );
            setVehicle(res?.data[0]?.vehicleDetails[0]);
          }
        },
      })
    );

    dispatch(
      readVehicleAction({
        callback: (res) => {
          const data = res?.data;
          data.map((item) => {
            setVehicleData((prev) => [
              ...prev,
              {
                label: item?.vehicle_type_name + " " + item?.vehicle_service,
                value: item?._id,
              },
            ]);
          });
        },
      })
    );
  }, []);

  const fetchSelectedDriverDetails = () => {
    dispatch(
      readDriverAction({
        apiPayloadRequest: {
          _id: selected,
        },
        callback: (res) => {
          if (res?.type === 1) {
            const data = res?.data[0];
            setCurrentDriver(data);
            setCurrentVehicleData(
              data?.vehicleTypes?.vehicleObjId ? data?.vehicleTypes?.vehicleObjId : []
            );
            setVehicle(data?.vehicleDetails[0] ? data?.vehicleDetails[0] : []);
          }
        },
      })
    );
  };

  useEffect(() => {
    if (selected) {
      fetchSelectedDriverDetails();
    }
  }, [selected]);

  const handleToggleClick = (active) => {
    dispatch(
      deactivateDriverAction({
        apiPayloadRequest: {
          _id: currentDriver?._id,
          userAuthId: currentDriver?.userAuthId,
          active: active ? 1 : 0,
        },
        callback: (res) => {
          if (res?.type === 1) {
            if (active) {
              Toastify("Activation Successfully!", 1);
            } else {
              Toastify("Deactivation Successfully!", 1);
            }
            setCurrentDriver({ ...currentDriver, active: active ? 1 : 0 });
          }
        },
      })
    );
  };

  const openImgModal = (img = "", name = "") => {
    if ((img && !imgModal.isOpen) || (imgModal.name !== name && name)) {
      setImgModal({ ...imgModal, isOpen: true, name, url: img });
    } else {
      setImgModal({ ...imgModal, isOpen: false, name: "", url: "" });
    }
  };

  console.log("currentDriver", currentDriver);

  const TransactionsCard = ({
    month,
    day,
    rechargeAmount,
    tripAmount,
    tripCommision,
    balanceAmount,
  }) => {
    return (
      <div className="bg-slate-100 rounded-2xl w-full flex justify-between items-center p-4 mb-1">
        <div className="flex flex-col justify-center items-center p-2 bg-white rounded-md text-xs">
          <p className="font-semibold ">{day}</p>
          <p className="text-black text-opacity-25">{month}</p>
        </div>
        <div className="flex gap-10">
          <div className="flex flex-col justify-center items-start gap-2 text-sm">
            <p className=" text-black text-opacity-40">Recharge Amount</p>
            <p className="text-black font-semibold text-base">{rechargeAmount}</p>
          </div>
          <div className="flex flex-col justify-center gap-2 items-start text-sm">
            <p className=" text-black text-opacity-40">Trip Amount</p>
            <p className="text-black font-semibold text-base">{tripAmount}</p>
          </div>
          <div className="flex flex-col justify-center gap-2 items-start text-sm">
            <p className=" text-black text-opacity-40">Trip Commision</p>
            <p className="text-black font-semibold text-base">{tripCommision}</p>
          </div>
        </div>
        <div className="flex flex-col justify-center items-end gap-2 text-sm">
          <p className=" text-black text-opacity-40">Recharge Amount</p>
          <p className="text-green-500 font-semibold text-base">{balanceAmount}</p>
        </div>
      </div>
    );
  };

  const Tab1 = () => {
    return (
      <div className="flex flex-col gap-6 pt-6 w-full h-[58vh] overflow-auto">
        <div className="flex gap-4 items-start w-full">
          <div className="flex flex-col gap-6 w-full">
            <p className="font-semibold text-sm ">Personal Details</p>
            <div className="flex w-full gap-6">
              <TextField
                label={"First Name"}
                value={currentDriver?.firstName ? currentDriver?.firstName : ""}
              />
              <TextField
                label={"Last Name"}
                value={currentDriver?.lastName ? currentDriver?.lastName : ""}
              />
            </div>
            <div className="flex w-full gap-6">
              <TextField
                label={"Email ID"}
                value={currentDriver?.email ? currentDriver?.email : ""}
              />
              <TextField
                label={"Mobile"}
                value={currentDriver?.mobile ? currentDriver?.mobile : ""}
              />
            </div>
            <div className="flex w-1/2 gap-6">
              <TextField
                label={"Date Of Birth"}
                value={currentDriver?.dob ? currentDriver?.dob : ""}
              />
              <TextField
                label={"Gender"}
                value={currentDriver?.gender ? currentDriver?.gender : ""}
              />
            </div>
          </div>
        </div>
        <div className="flex gap-4 items-start w-full">
          <img src={ProfileCircle2} />
          <div className="flex flex-col gap-6 w-full">
            <p className="font-semibold text-sm ">Emergency Contact 1</p>
            <div className="flex w-full gap-6">
              <TextField label={"Name"} value={currentDriver?.ICEName1 ?? "No Data.."} />
              <TextField label={"Mobile"} value={currentDriver?.ICENumber1 ?? "No Data.."} />
            </div>
            <div className="flex w-full gap-6">
              <TextField label={"Relation"} value={currentDriver?.ICERelation1 ?? "No Data.."} />
            </div>
          </div>
        </div>
        <div className="p-5 flex flex-col w-full">
          <div className="grid grid-cols-2 gap-8 text-center">
            {currentDriver?.drivingLicenceUrl && (
              <div
                className="flex flex-col items-center"
                onClick={() =>
                  openImgModal(currentDriver?.drivingLicenceUrl, "Driving License.png")
                }
              >
                <img
                  src={currentDriver?.drivingLicenceUrl}
                  className="w-[300px] h-[200px] rounded-s-lg p-4 bg-neutral-300 cursor-pointer"
                  alt=""
                />
                <span className="font-extralight"> Driving License.png</span>
              </div>
            )}
            {currentDriver?.aadharCardUrl && (
              <div
                className="flex flex-col items-center"
                onClick={() => openImgModal(currentDriver?.aadharCardUrl, "Aadhar.png")}
              >
                <img
                  src={currentDriver?.aadharCardUrl}
                  className="w-[300px] h-[200px] rounded-s-lg p-4 bg-neutral-300 cursor-pointer"
                  alt=""
                />
                <span className="font-extralight"> Aadhar.png</span>
              </div>
            )}
          </div>
          <ReactModal
            isOpen={imgModal.isOpen}
            style={customStyles}
            onRequestClose={() => openImgModal(false)}
          >
            <div className="w-100 h-90 flex flex-col justify-between ">
              <div className=" p-5 px-3  items-center flex justify-between">
                <div>
                  <h1 className="text-lg font-bold">{imgModal.name}</h1>
                </div>
                <div className="text-lg px-3 cursor-pointer" onClick={() => openImgModal(false)}>
                  <img src={wrong} />
                </div>
              </div>
              <img src={imgModal?.url} className="rounded-lg p-4 bg-red-300" alt="" />
            </div>
          </ReactModal>
        </div>
        {/* <div className="py-4 mt-8 bg-white shadow-lg text-xs flex justify-between items-center border-t">
          <button
            className="text-blue-800 font-semibold"
            onClick={() => navigate("/drivers/")}
          >
            Do it Later
          </button>
          <div className="flex">
            <Button
              background={"bg-gray-300 text-xs"}
              label={"REJECT"}
              textcolor={"text-gray-600"}
              onClick={() => handleToggleClick(false)} //deactivate user
            />
            {currentDriver?.active !== 1 && (
              <Button
                background={"bg-[#F15A2A] text-xs"}
                label={"CONFIRM & ACCEPT"}
                textcolor={"text-white"}
                onClick={() => handleToggleClick(true)} //activate user
              />
            )}
          </div>
        </div> */}
      </div>
    );
  };

  const Tab2 = () => {
    return (
      <div className="p-5 flex flex-col gap-2 w-full h-[58vh] overflow-auto">
        {/* <div className="grid grid-cols-5 gap-8 text-center"> */}

        {vehicle && (
          <div className="flex flex-col gap-2 pt-4 mb-6">
            <div className="text-lg flex gap-2 items-center font-bold capitalize pb-1 ">
              <FaTruckFront />
              <p>
                {vehicle?.vehicleMake} {vehicle?.vehicleType} {vehicle?.vehicleModel}
              </p>
            </div>
            <div className="flex w-full gap-6 mb-5">
              <TextField
                label={"Vehicle Make"}
                value={vehicle?.vehicleType ? vehicle?.vehicleType : "No Data..."}
              />
              <TextField
                label={"Vehicle Type"}
                value={vehicle?.vehicleMake ? vehicle?.vehicleMake : "No Data..."}
              />
              <TextField
                label={"Vehicle Registration Number"}
                value={
                  vehicle?.vehicleRegistrationNo ? vehicle?.vehicleRegistrationNo : "No Data..."
                }
              />
            </div>
            <div className="grid grid-cols-3 gap-4">
              {vehicle?.vehicleRegistrationCertUrl && (
                <div className="w-full bg-gray-100 p-6 text-xs font-bold rounded-lg flex justify-between items-center">
                  <div className="flex gap-2 items-center">
                    <PiCertificateFill size={20} />
                    <p>Registration Certificate</p>
                  </div>
                  <PiLinkSimpleBold
                    size={25}
                    className="cursor-pointer"
                    onClick={() =>
                      openImgModal(vehicle?.vehicleRegistrationCertUrl, "Registration Certificate")
                    }
                  />
                </div>
                // <div
                //   className="flex flex-col items-center"

                // >
                //   <img
                //     src={vehicle?.vehicleRegistrationCertUrl}
                //     className="w-[300px] h-[200px] rounded-s-lg p-4 bg-neutral-300 cursor-pointer"
                //     alt=""
                //   />
                //   <span className="font-extralight">
                //     Registration Certificate
                //   </span>
                // </div>
              )}
              {vehicle?.vehicleInsuranceUrl && (
                <div className="w-full bg-gray-100 p-6 text-xs font-bold rounded-lg flex justify-between items-center">
                  <div className="flex gap-2 items-center">
                    <PiCertificateFill size={20} />
                    <p>Vehicle Insurance</p>
                  </div>
                  <PiLinkSimpleBold
                    size={25}
                    className="cursor-pointer"
                    onClick={() => openImgModal(vehicle?.vehicleInsuranceUrl, "Vehicle Insurance")}
                  />
                </div>
                // <div
                //   className="flex flex-col items-center"
                //   onClick={() =>
                //     openImgModal(vehicle?.vehicleInsuranceUrl, "Insurance.png")
                //   }
                // >
                //   <img
                //     src={vehicle?.vehicleInsuranceUrl}
                //     className="w-[300px] h-[200px] rounded-s-lg p-4 bg-neutral-300 cursor-pointer"
                //     alt=""
                //   />
                //   <span className="font-extralight">Vehicle Insurance</span>
                // </div>
              )}
              {vehicle?.vehiclePolutionCertUrl && (
                <div className="w-full bg-gray-100 p-6 text-xs font-bold rounded-lg flex justify-between items-center">
                  <div className="flex gap-2 items-center">
                    <PiCertificateFill size={20} />
                    <p>Pollution Certificate</p>
                  </div>
                  <PiLinkSimpleBold
                    size={25}
                    className="cursor-pointer"
                    onClick={() =>
                      openImgModal(vehicle?.vehiclePolutionCertUrl, "Pollution Certificate")
                    }
                  />
                </div>
                // <div
                //   className="flex flex-col items-center"
                //   onClick={() =>
                //     openImgModal(
                //       vehicle?.vehiclePolutionCertUrl,
                //       "Pollution.png"
                //     )
                //   }
                // >
                //   <img
                //     src={vehicle?.vehiclePolutionCertUrl}
                //     className="w-[300px] h-[200px] rounded-s-lg p-4 bg-neutral-300 cursor-pointer"
                //     alt=""
                //   />
                //   <span className="font-extralight">Pollution Certificate</span>
                // </div>
              )}
              {vehicle?.vehicleTaxUrl && (
                <div className="w-full bg-gray-100 p-6 text-xs font-bold rounded-lg flex justify-between items-center">
                  <div className="flex gap-2 items-center">
                    <PiCertificateFill size={20} />
                    <p>Vehicle Tax Paperse</p>
                  </div>
                  <PiLinkSimpleBold
                    size={25}
                    className="cursor-pointer"
                    onClick={() => openImgModal(vehicle?.vehicleTaxUrl, "Vehicle Tax Papers")}
                  />
                </div>
                // <div
                //   className="flex flex-col items-center"
                //   onClick={() =>
                //     openImgModal(vehicle?.vehicleTaxUrl, "Tax.png")
                //   }
                // >
                //   <img
                //     src={vehicle?.vehicleTaxUrl}
                //     className="w-[300px] h-[200px] rounded-s-lg p-4 bg-neutral-300 cursor-pointer"
                //     alt=""
                //   />
                //   <span className="font-extralight">Vehicle Tax Papers</span>
                // </div>
              )}
              {vehicle?.vehicleFitnessUrl && (
                <div className="w-full bg-gray-100 p-6 text-xs font-bold rounded-lg flex justify-between items-center">
                  <div className="flex gap-2 items-center">
                    <PiCertificateFill size={20} />
                    <p> Vehicle Fitness Papers</p>
                  </div>
                  <PiLinkSimpleBold
                    size={25}
                    className="cursor-pointer"
                    onClick={() => openImgModal(vehicle?.vehicleTaxUrl, "Vehicle Fitness Papers")}
                  />
                </div>
                // <div
                //   className="flex flex-col items-center"
                //   onClick={() =>
                //     openImgModal(vehicle?.vehicleFitnessUrl, "Fitness.png")
                //   }
                // >
                //   <img
                //     src={vehicle?.vehicleFitnessUrl}
                //     className="w-[300px] h-[200px] rounded-s-lg p-4 bg-neutral-300 cursor-pointer"
                //     alt=""
                //   />
                //   <span className="font-extralight">
                //     Vehicle Fitness Papers
                //   </span>
                // </div>
              )}
            </div>
            <p className="capitalize text-lg font-bold mt-4">assemble the drivers</p>
            <div className="flex items-end justify-between">
              <InputDropdown
                label="Select Vehicle*"
                styles="border-2 w-full"
                options={vehicleData}
                value={selectedVehicle}
                onChange={(event) => {
                  setSelectedVehicle(event.target.value);
                }}
                isError={""}
                errMsg={"Min One Equipment"}
              />
              <div>
                <Button
                  onClick={addVehicleHandle}
                  label={"Add Vehicle"}
                  background="bg-orange-500 text-white w-fit px-5 py-1.5"
                />
              </div>
            </div>
            <DynamicTable
              data={currentDriver?.vehicleTypes?.vehicleObjId || []}
              headers={headers}
              title={""}
              width={"w-42"}
              heightDesktop={"min-h-[25vh]"}
              nthChildStyle={""}
              handleEditClick={(row) => {
                console.log(row);
              }}
              handleToggleClick={() => {}}
              handlePencilClick={() => {}}
              handleEyeClick={(row) => {}}
              handleDeleteClick={(row) => {
                handleDeleteVehicle(row._id);
              }}
            />
          </div>
        )}
        {/* </div> */}
        <ReactModal
          isOpen={imgModal.isOpen}
          style={customStyles}
          onRequestClose={() => openImgModal(false)}
        >
          <div className="flex flex-col">
            <div className=" p-5 px-3  items-center flex justify-between">
              <div>
                <h1 className="text-lg font-bold">{imgModal.name}</h1>
              </div>
              <div className="text-lg px-3 cursor-pointer" onClick={() => openImgModal(false)}>
                <img src={wrong} />
              </div>
            </div>
            <div className="w-full h-[80vh] flex justify-center items-center">
              <img src={imgModal?.url} className="rounded-lg h-[100%] border-2" alt="" />
            </div>
          </div>
        </ReactModal>
      </div>
    );
  };

  const Tab3 = () => {
    return (
      <div className="pt-6 flex flex-col w-full h-[58vh] overflow-auto">
        <div className="flex gap-4 pb-6 items-start w-full">
          <img src={ProfileCircle} />
          <div className="flex flex-col gap-6 w-full">
            <p className="font-semibold text-sm ">Wallet Transactions</p>
          </div>
        </div>
        {currentDriver?.wallet?.map((data, index) => (
          <TransactionsCard
            key={index}
            month={data?.dateofrecharge}
            // day={"05"}
            rechargeAmount={"NA"}
            tripAmount={"NA"}
            tripCommision={"NA"}
            balanceAmount={data?.amount}
          />
        ))}
        {/* <TransactionsCard
                    month={"Jul"}
                    day={"05"}
                    rechargeAmount={3500}
                    tripAmount={2000}
                    tripCommision={1000}
                    balanceAmount={1500}
                />
                <TransactionsCard
                    month={"Jul"}
                    day={"05"}
                    rechargeAmount={3500}
                    tripAmount={2000}
                    tripCommision={1000}
                    balanceAmount={1500}
                />
                <TransactionsCard
                    month={"Jul"}
                    day={"05"}
                    rechargeAmount={3500}
                    tripAmount={2000}
                    tripCommision={1000}
                    balanceAmount={1500}
                />
                <TransactionsCard
                    month={"Jul"}
                    day={"05"}
                    rechargeAmount={3500}
                    tripAmount={2000}
                    tripCommision={1000}
                    balanceAmount={1500}
                />
                <TransactionsCard
                    month={"Jul"}
                    day={"05"}
                    rechargeAmount={3500}
                    tripAmount={2000}
                    tripCommision={1000}
                    balanceAmount={1500}
                />
                <TransactionsCard
                    month={"Jul"}
                    day={"05"}
                    rechargeAmount={3500}
                    tripAmount={2000}
                    tripCommision={1000}
                    balanceAmount={1500}
                /> */}
      </div>
    );
  };

  const handleDriverChange = (id) => {
    setSelected(id);
  };

  const tabHeaders = ["Personal Details", "Vehicle Details", "Wallets Transactions"];

  const tabData = [
    {
      "Personal Details": <Tab1 />,
    },
    {
      "Vehicle Details": <Tab2 />,
    },
    {
      "Wallets Transactions": <Tab3 />,
    },
  ];

  return (
    <div className="flex bg-zinc-100 ">
      <NavBar active={"Home"} role={role} />
      <div className="flex flex-col w-full gap-2">
        <Header title={"Drivers List"} />
        <div className="w-full h-[86vh] pl-3 pr-4 pb-4">
          <div className="flex w-full h-[100%] bg-white rounded-xl">
            <div className="flex flex-col h-[100%]  p-4 gap-1 overflow-auto w-[40%] border-r-[2px] border-zinc-200">
              <div className="relative">
                <input
                  className="border border-opacity-50 w-full border[#d6d6d6] px-4 py-2 rounded-lg placeholder:text-[14px]"
                  type="text"
                  placeholder={"Search..."}
                  value={searchTerm}
                  onChange={handleSearch}
                />
                <BsSearch className="absolute right-4 top-3 text-md text-[#979797]" />
              </div>
              {allDriverData
                ?.filter(
                  (item) =>
                    item?.firstName?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
                    item?.email?.toLowerCase().includes(searchTerm?.toLowerCase())
                )
                .map((row, index) => (
                  <CardOfViewAll
                    key={index}
                    id={row?._id}
                    handleChange={handleDriverChange}
                    selected={selected}
                    data={row}
                  />
                ))}
              {/* <CardOfViewAll id={0} handleChange={setSeleted} selected={selected} /> */}
            </div>
            <div className="w-[60%] h-full">
              <p className="p-5 border-b-[2px] border-zinc-200">
                {currentDriver?.vehicleDetails && currentDriver?.vehicleDetails?.length > 0
                  ? `${currentDriver?.vehicleDetails[0]?.vehicleMake} ${currentDriver?.vehicleDetails[0]?.vehicleType}`
                  : ""}
              </p>
              <div className="p-5 flex justify-between">
                <div className="flex gap-4">
                  {currentDriver?.profilePicUrl && (
                    <img className="h-14 w-14 rounded-full" src={currentDriver?.profilePicUrl} />
                  )}
                  <div className="flex flex-col">
                    <p className="text-xl font-semibold capitalize">
                      {currentDriver?.firstName ? currentDriver?.firstName : ""}{" "}
                      {currentDriver?.lastName ? currentDriver?.lastName : ""}
                    </p>
                    <p className="text-xs font-semibold text-black text-opacity-50">
                      {currentDriver?.email ? currentDriver?.email : ""}
                    </p>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  {currentDriver?.active === 1 ? (
                    <p className="text-green-500 text-xs font-bold">Active</p>
                  ) : (
                    <p className="text-red-500 text-xs font-bold">In-Active</p>
                  )}
                  <ToggleButton
                    handleToggleClick={(status) => {
                      setCurrentDriver((prev) => ({
                        ...prev,
                        active: status ? 1 : 0,
                      }));
                      handleToggleClick(status);
                    }}
                    status={currentDriver?.active}
                  />
                </div>
              </div>
              <div className="px-4">
                <InnerTab
                  active={activeTab}
                  data={tabData}
                  headers={tabHeaders}
                  handleChange={setActiveTab}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewAllCards;
