import * as actionTypes from "../ActionTypes";

export const postSignUpAction = (payload) => {
  return {
    type: actionTypes.SIGN_UP_REQUEST,
    payload: payload,
  };
};

export const postLoginAction = (payload) => {
  return {
    type: actionTypes.LOGIN_REQUEST,
    payload: payload,
  };
};

export const postEmailVerficationAction = (payload) => {
  return {
    type: actionTypes.EMAIL_VERIFICATION_REQUEST,
    payload: payload,
  };
};

export const postResendVerificationAction = (payload) => {
  return {
    type: actionTypes.RESEND_VERIFICATION_REQUEST,
    payload: payload,
  };
};

export const uploadAssestsAction = (payload) => {
  return {
    type: actionTypes.UPLOAD_ASSEST_REQUEST,
    payload: payload,
  };
};

export const readUserByMobileAction = (payload) => {
  return {
    type: actionTypes.READ_USER_BY_MOBILE,
    payload: payload,
  };
};

export const readProfileAction = (payload) => ({
  type: actionTypes.READ_PROFILE_REQUEST,
  payload,
});

export const updateProfileAction = (payload) => ({
  type: actionTypes.UPDATE_PROFILE_REQUEST,
  payload,
});

export const saveAddressAction = (payload) => ({
  type: actionTypes.SAVE_ADDRESS_REQUEST,
  payload,
});

export const updateAddressAction = (payload) => ({
    type: actionTypes.UPDATE_ADDRESS_REQUEST,
    payload,
  });
