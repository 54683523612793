import { useEffect, useState } from "react";
import Eye from "../../assets/icons/eye.svg";
import PhoneInput from "react-phone-input-2";

export default function TextInputField({
  labelText,
  labelColor,
  errorMessage,
  placeholder,
  isFieldRequired,
  htmlFor,
  name,
  value,
  handleInputChange,
  validateFunction = () => false,
  margin,
  type,
  handleViewPassword,
  repeatPasswordCheck,
  repeatPasswordFunction,
  isSubmitted,
  isPhoneInput = false,
  disabled = false,
}) {
  const [ifInvalid, setIfInvalid] = useState(false);

  useEffect(() => {
    if (validateFunction !== false) {
      if (value?.length > 0 && validateFunction(value) === true) {
        setIfInvalid(false);
      }
    }
  }, [validateFunction, value]);

  // console.log(isFieldRequired && value?.length === 0 && isSubmitted, "isSubmitted")

  return (
    <>
      {isPhoneInput ? (
        <div className="relative">
          <PhoneInput
            inputStyle={{ color: "black" }}
            country={"in"}
            value={value}
            onChange={handleInputChange}
            inputProps={{
              required: true,
            }}
          />
          {!validateFunction(value) && isFieldRequired && value?.length > 0 && (
            <p className="text-red-300  absolute bottom-[-18px] text-[10px] left-2">
              {errorMessage}
            </p>
          )}
          {isFieldRequired && value?.length === 0 && isSubmitted && (
            <p className="text-red-300   absolute bottom-[-18px] text-[10px] left-2">
              Required Field
            </p>
          )}
        </div>
      ) : (
        <div className="relative w-full">
          <p
            htmlFor={htmlFor}
            style={{
              color: `${
                // isSubmitted &&
                // validateFunction(value) &&
                isFieldRequired ? "border-red-500" : labelColor
              }`,
            }}
            className="text-[10px] text-greyish font-medium text-black/60"
          >
            {labelText}
            {isFieldRequired && labelText?.length ? (
              <span className="text-red-300 ">*</span>
            ) : (
              ""
            )}
          </p>
          <input
            type={type ? type : "text"}
            id={name}
            name={name}
            value={value}
            onChange={handleInputChange}
            placeholder={placeholder}
            style={{ marginBottom: `${margin}` }}
            disabled={disabled}
            onInvalid={(e) => {
              e.preventDefault();
              setIfInvalid(true);
              e.target.setCustomValidity("");
            }}
            onInput={(e) => {
              if (
                // isSubmitted &&
                // validateFunction(value) &&
                isFieldRequired
              ) {
                setIfInvalid(false);
              }
            }}
            className={`p-2 w-full focus:outline-none ${
              disabled ? "text-opacity-50" : ""
            } text-black text-[14px]  font-medium mt-2 leading-4 rounded-md h-10 placeholder:text-gray-900 placeholder:opacity-30 border ${
              // isSubmitted &&
              (!validateFunction(value) &&
                isFieldRequired &&
                value.length > 0 &&
                name !== "password") ||
              (repeatPasswordCheck?.length > 0 &&
                !repeatPasswordFunction(repeatPasswordCheck, value) &&
                value?.length > 0 &&
                name !== "password")
                ? " border-opacity-20"
                : "border-black border-opacity-20"
            }`}
            required={isFieldRequired}
          />
          {
            // isSubmitted &&
            !validateFunction(value) &&
              isFieldRequired &&
              value?.length > 0 && (
                <p className="text-red-300  absolute bottom-[-18px] text-[10px] left-2">
                  {errorMessage}
                </p>
              )
          }
          {isFieldRequired && value?.length === 0 && isSubmitted && (
            <p className="text-red-300   absolute bottom-[-18px] text-[10px] left-2">
              Required Field
            </p>
          )}

          {repeatPasswordCheck?.length > 0 &&
            !repeatPasswordFunction(repeatPasswordCheck, value) &&
            value?.length > 0 && (
              <p className="text-red-300   absolute bottom-[-18px] text-[10px] left-2">
                {errorMessage}
              </p>
            )}
          {value?.length > 0 && type && (
            <img
              className="absolute top-5 right-5 h-[30%] cursor-pointer"
              src={Eye}
              onClick={() => handleViewPassword((prevState) => !prevState)}
            />
          )}
        </div>
      )}
    </>
  );
}
