import { useEffect, useRef, useState } from "react";
import {
  DirectionsRenderer,
  GoogleMap,
  InfoWindow,
  LoadScript,
  Marker,
} from "@react-google-maps/api";
import NavBar from "../../../components/Universal/NavBar";
import Header from "../../../components/Universal/Header";
import QuickBooking from "../../../components/Common/Sidebar/QuickBookings/QuickBooking";
import ActiveAndInActiveComp from "../../../components/Common/ActiveAndInActiveComp";
import DynamicTable from "../../../components/Universal/DynamicTable";
import AnalyticsCard from "../../../components/Cards/AnalyticsCard";
import Elogo1trip from "../../../assets/images/Elogo1trip.png";
import Ebar1trip from "../../../assets/images/Ebar1trip.png";
import Elogo2 from "../../../assets/images/Elogo2.png";
import Ebar2 from "../../../assets/images/Ebar2.png";
import Elogo3 from "../../../assets/images/Elogo3.png";
import Ebar3 from "../../../assets/images/Ebar3.png";
import { useDispatch, useSelector } from "react-redux";
import { readProfileAction } from "../../../store/LoginAndRegister/LoginAndRegisterAction";
import Unauthorized from "../../../assets/icons/Unauthorized.png";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import {
  getDriverStatusAction,
  readDriversLocationsAction,
  readTodayTripAction,
  readTripAction,
  readTripAnalyticsAction,
  readVehicleAction,
  saveQuickBookingLocation,
} from "../../../store/Service/ServiceAction";
import { useSocket } from "../../../components/Common/SocketContext";
import Toastify from "../../../components/Universal/Toastify";
import Loader from "../../../components/Universal/Loader";
import { customStyles, libraries, todayTripsHeaders } from "../../../constants/Constant";
import Ambulance from "../../../assets/images/Ambulance.png";
import Ambulance4 from "../../../assets/images/Ambulance4.gif";
import { saveQuickBookingLocationSuggestions } from "../../../store/Service/ServiceAction";
import {
  MdBatteryChargingFull,
  MdDoubleArrow,
  MdOutlineRemoveRedEye,
  MdRemoveRedEye,
} from "react-icons/md";
import { IoSpeedometer } from "react-icons/io5";
import DateFilter from "../../../components/Universal/DateFilter";
import MapModel from "../../../components/Universal/MapModel";
import { updateUserDetailsAction } from "../../../store/UserProfile/UserProfileAction";
import { TbRefresh } from "react-icons/tb";
import { FaArrowLeft } from "react-icons/fa";
import { BiSolidMessageSquareError } from "react-icons/bi";
import { Tooltip } from "react-tooltip";
import { GoDotFill } from "react-icons/go";

const mapContainerStyle = {
  height: "100%",
  width: "100%",
};
const center = { lat: 28.70406, lng: 77.102493 };

const Home = ({ shortBar, setShortBar }) => {
  const { id } = useParams();
  const socket = useSocket();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { logout, isLoading } = useAuth0();

  const initialLocationValues = {
    pickup: null,
    drop: null,
  };

  const initialBookingValues = {
    callerName: "",
    dateandTimeofTrip: new Date(),
    typeofTrip: "",
    phone: "",
    userType: "",
    comments: "",
    price: 8000,
  };

  const initialErrorFields = {
    callerName: false,
    phone: false,
    dateandTimeofTrip: false,
    phoneFormat: false,
    userType: false,
    comments: false,
    price: false,
    pickup: false,
    drop: false,
    vehicle: false,
    vehicleQuantity: false,
  };
  const { userDetails } = useSelector((state) => state.userProfile);
  const [assignedDriver, setAssignedDriver] = useState(null);
  const [bookingStatus, setBookingStatus] = useState(false);
  const [locationUpated, setLocationUpdated] = useState(true);
  const [bookingValues, setBookingValues] = useState(initialBookingValues);
  const [locationInput, setLocationInput] = useState(initialLocationValues);
  const [errorFields, setErrorFields] = useState(initialErrorFields);
  const [activeTab, setActiveTab] = useState(1);
  const [role, setRole] = useState(localStorage.getItem("role"));
  const [showContent, setShowContent] = useState(true);
  const [suggestions, setSuggestions] = useState([]);
  const [directionResult, setDirectionResult] = useState([]);
  const [driverDetails, setDriverDetails] = useState([]);
  const [tripData, setTripData] = useState([]);
  const [currentTab, setCurrentTab] = useState(1);
  const [currentDriverIndex, setCurrentDriverIndex] = useState();
  const [tripObj, setTripObj] = useState();
  const [driverStatus, setDriverStatus] = useState("");
  const [usersOnline, setUsersOnline] = useState(0);
  const [callDrivers, setCallDrivers] = useState(false);
  const [currentTripId, setCurrentTripId] = useState("");
  const [showMapsModal, setShowMapsModal] = useState(false);
  const [currentTrip, setCurrentTrip] = useState({});
  const [addOnDetailsList, setAddOnDetailsList] = useState([]);
  const [allVehicleData, setAllVehicleData] = useState([]);
  const [driverLocationUpated, setDriverLocationUpdated] = useState(false);
  const [driverDetailsModalOpen, setDriverDetailsModalOpen] = useState(false);
  const [currentDriverDetails, setCurrentDriverDetails] = useState({});
  const [recenterMap, setRecenterMap] = useState({ recenter: false, position: {} });
  const [activeDriverDetails, setActiveDriverDetails] = useState({});
  const [filterRange, setFilterRange] = useState([
    {
      startDate: new Date(new Date().setHours(0, 0, 0)),
      endDate: new Date(new Date().setHours(23, 59, 59)),
      key: "selection",
    },
  ]);
  const [tripAnalyticsData, setTripAnalyticsData] = useState({
    emergencyTrips: 0,
    nonEmergencyTrips: 0,
    totalEarnings: 0,
  });

  const getDriverLastLocation = () => {
    if (currentTrip?.drivers?.length > 0 && driverDetails?.length > 0) {
      const getLatestDriverDetails = driverDetails?.filter(
        (v) => v?.user?.userAuthId === currentTrip?.drivers[0]?.driverAuthId
      );
      if (getLatestDriverDetails?.length > 0) {
        let tempTrip = currentTrip;
        tempTrip.driverDetails.coords = getLatestDriverDetails[0]?.coords;
        const checkUpdated = tempTrip?.driverDetails?.coords?.locationUpdated;
        tempTrip.driverDetails.coords.locationUpdated = checkUpdated
          ? parseInt(checkUpdated) + 1
          : 1;
        setCurrentTrip(tempTrip);
      }
    }
    setDriverLocationUpdated((prev) => !prev);
  };

  console.log("allVehicleData8", allVehicleData);
  console.log("userDetails", userDetails);
  console.log("recenterMap", recenterMap);
  console.log("driverStatus", driverStatus);

  useEffect(() => {
    dispatch(
      readVehicleAction({
        callback: (res) => {
          if (res?.type === 1) {
            console.log("vehicle data5", res?.data);
            setAllVehicleData(res?.data);
          }
        },
      })
    );
  }, []);

  // Check if there is data in the features' addonObjId arrays
  const checkFeaturesAvailable = (item) => {
    return (
      item.features &&
      item.features.some((feature) => feature.addonObjId && feature.addonObjId.length > 0)
    );
  };

  useEffect(() => {
    if (currentTripId) {
      dispatch(
        readTripAction({
          apiPayloadRequest: {
            _id: currentTripId,
          },
          callback: (res) => {
            if (res.type === 1) {
              const data = res?.data[0];
              setCurrentTrip(data);
              let currentVehicleData = allVehicleData?.find(
                (v) => v?._id === data?.vehicleObjId?.[0]?._id
              );
              if (currentVehicleData) {
                let featuresList = checkFeaturesAvailable(currentVehicleData)
                  ? currentVehicleData?.features?.map((v) => v?.addonObjId[0])
                  : [];
                if (featuresList?.length > 0) {
                  if (data?.addons?.length > 0) {
                    featuresList = featuresList?.map((d) => {
                      const availableAddon = data?.addons?.find((v) => v?._id === d?._id);
                      if (availableAddon) {
                        return { ...d, quantity: Number(availableAddon?.count ?? 0) };
                      }
                      return d;
                    });
                    setAddOnDetailsList(featuresList);
                  }
                }
              }
            }
          },
        })
      );
    }
  }, [currentTripId]);

  useEffect(() => {
    dispatch(
      readTodayTripAction({
        apiPayloadRequest: {
          startDate: filterRange[0]?.startDate,
          endDate: filterRange[0]?.endDate,
        },
        callback: (res) => {
          if (res.type === 1) {
            setTripData(res?.data);
          }
        },
      })
    );
    dispatch(
      readTripAnalyticsAction({
        apiPayloadRequest: {
          startDate: filterRange[0]?.startDate,
          endDate: filterRange[0]?.endDate,
        },
        callback: (res) => {
          if (res.type === 1) {
            const emergencyTrips =
              res?.data?.[0]?.countByTypeOfTrip?.find((d) => d?._id === "Emergency")?.count ?? 0;
            const nonEmergencyTrips =
              res?.data?.[0]?.countByTypeOfTrip?.find((d) => d?._id === "Non-Emergency")?.count ??
              0;
            const totalEarnings =
              res?.data?.[0]?.totalEarnings?.[0]?.totalEarnings?.["$numberDecimal"] ?? 0;

            setTripAnalyticsData({
              emergencyTrips: emergencyTrips,
              nonEmergencyTrips: nonEmergencyTrips,
              totalEarnings: totalEarnings,
            });
          }
        },
      })
    );
  }, [filterRange]);

  useEffect(() => {
    if (driverDetails?.length > 0) {
      const getCount = driverDetails.filter((v) => v.isOnline === true)?.length;
      setUsersOnline(getCount);
    } else {
      setUsersOnline(0);
    }
  }, [driverDetails]);

  const handleDriverDetails = (data) => {
    // alert("Drivers Online");
    if (driverDetails?.length > 0) {
      let tempDriverDetails = [...driverDetails];
      data?.forEach((userAuthId) => {
        const currentDataIndex = tempDriverDetails?.findIndex(
          (item) => item?.user?.userAuthId === userAuthId
        );
        if (currentDataIndex !== -1) tempDriverDetails[currentDataIndex].isOnline = true;
      });
      setDriverDetails(tempDriverDetails);
    }
    //  else {
    //   fetchDriversOnlineFromAPI(data);
    // }
  };

  const handleDriverDetailsUpdate = (newData) => {
    if (newData) {
      setDriverDetails((prev) => {
        if (prev?.length > 0) {
          const currentDataIndex = prev?.findIndex(
            (item) => item?.user?.userAuthId === newData?.userAuthId
          );

          if (currentDataIndex !== -1) {
            const newDataValues = [...prev];
            newDataValues[currentDataIndex].coords = newData?.location?.coords;
            return newDataValues;
          }

          return prev;
        }
      });
    }
  };

  const handleDriverOnline = (driver) => {
    if (driver?.length > 0 && driverDetails?.length > 0) {
      console.log("driverSocket : Online", driver);
      const updatedData = [...driverDetails];
      const getIndex = updatedData?.findIndex((item) => item?.user?.userAuthId === driver?.[0]);
      if (getIndex !== -1) updatedData[getIndex].isOnline = true;
      setDriverDetails(updatedData || []);
    }
  };

  const handleDriverOnlineV2 = (driver) => {
    console.log("driver details", driver);
    if (driver?.userAuthId) {
      console.log("driverSocket : Online", driver);
      const updatedData = [...driverDetails];
      const getIndex = updatedData?.findIndex(
        (item) => item?.user?.userAuthId === driver?.userAuthId
      );
      if (getIndex !== -1)
        updatedData[getIndex].isOnline = driver?.lastTripLog?.[0]?.activityType === "1";
      setDriverDetails(updatedData || []);
    }
  };

  const handleDriverOffline = (driver) => {
    if (driver?.length > 0 && driverDetails?.length > 0) {
      console.log("driverSocket : offline", driver);
      const updatedData = [...driverDetails];
      const getIndex = updatedData?.findIndex((item) => item?.user?.userAuthId === driver[0]);
      if (getIndex !== -1) updatedData[getIndex].isOnline = false;
      setDriverDetails(updatedData || []);
    }
  };

  const handleTabChange = (data) => {
    setActiveTab(data);
  };

  const headers = [
    {
      headerName: "Type of trip",
      headerId: "typeOfTrip",
    },
    {
      headerName: "Vehicle Type",
      headerId: "vehicleType",
    },

    {
      headerName: "Created Date",
      headerId: "createdDate",
    },
    {
      headerName: "Trip Status ",
      headerId: "status",
    },

    {
      headerName: "Actions",
      headerId: "actions",
    },
  ];

  // useEffect(() => {
  //   if (role === undefined || role === null) {
  //     dispatch(
  //       readProfileAction({
  //         callback: async (res) => {
  //           if (res?.type) {
  //             // const userEmail = res?.data?.[0]?.email;
  //             // const isUserAuthenticated = localStorage.getItem("auth");
  //             // if(!isUserAuthenticated) {
  //             //     const msg = await registerWithEmail(userEmail, userEmail);
  //             //     console.log(msg, "home msg");
  //             // }else {
  //             //     // checkMessages();
  //             // }
  //             // const userFirstName = res?.data?.[0]?.firstName;
  //             const roleCode = res?.data?.[0]?.roleCode;
  //             const user = res?.data?.[0];
  //             if (roleCode) {
  //               localStorage.setItem("role", roleCode);
  //               localStorage.setItem(
  //                 "roleName",
  //                 roleCode === "84bts2"
  //                   ? "Admin"
  //                   : roleCode === "96nhe6"
  //                   ? "Executive"
  //                   : roleCode === "63udl9"
  //                   ? "User"
  //                   : roleCode === "43ser5"
  //                   ? "Driver"
  //                   : "Unauthorized"
  //               );
  //               if (roleCode === "84bts2" || roleCode === "96nhe6") setRole(roleCode);
  //             }
  //             if (user) {
  //               localStorage.setItem("user", JSON.stringify(user));
  //               dispatch(updateUserDetailsAction(user));
  //             }

  //             if (roleCode === "96nhe6" || roleCode === "84bts2") navigate("/dashboard");
  //             else if (roleCode === "63udl9" || roleCode === "43ser5") navigate("/");
  //             else ShowUnauthorized();
  //           }
  //         },
  //       })
  //     );
  //   }
  //   //  else {
  //   //   fetchDriversOnlineFromAPI();
  //   // }
  // }, []);

  // const fetchDriversOnlineFromAPI = (
  //   onlineList = [],
  //   isDriverOnline = true
  // ) => {
  //   dispatch(
  //     readDriversLocationsAction({
  //       callback: (res) => {
  //         if (res.type === 1) {
  //           let data = res?.data;
  //           if (data?.length > 0) {
  //             data = data
  //               ?.map((v) => ({ ...v, isOnline: false }))
  //               .filter((x) => x.coords);
  //             if (onlineList?.length > 0) {
  //               onlineList?.forEach((userAuthId) => {
  //                 const currentDataIndex = data?.findIndex(
  //                   (item) => item?.userAuthId === userAuthId
  //                 );
  //                 if (data[currentDataIndex]) {
  //                   if (!isDriverOnline) {
  //                     data[currentDataIndex].isOnline = false;
  //                   } else {
  //                     data[currentDataIndex].isOnline = true;
  //                   }
  //                 }
  //               });
  //             } else {
  //               if (socket?.connected) {
  //                 socket.emit("driversonline", {
  //                   message: "Client requests data",
  //                 });
  //               }
  //             }
  //           }
  //           setDriverDetails(data);
  //         }
  //       },
  //     })
  //   );
  // };

  const fetchDriversList = () => {
    dispatch(
      readDriversLocationsAction({
        callback: (res) => {
          if (res?.type === 1) {
            const data = res?.data
              // ?.filter((i) => i?.coords)
              .map((item) => ({
                ...item,
                isOnline: item?.user?.lastTripLog?.[0]?.activityType === "1",
                coords: item?.user?.lastLocation?.coords
                  ? item?.user?.lastLocation?.coords
                  : {
                      latitude: 17.5145267,
                      longitude: 78.3873372,
                      accuracy: 15.8,
                      speed: -1,
                      speed_accuracy: 1.5,
                      heading: -1,
                      heading_accuracy: 45,
                      altitude: 538,
                      ellipsoidal_altitude: 538,
                      altitude_accuracy: 2,
                      age: 2761,
                    },
              }));
            setDriverDetails(data);
            setTimeout(() => {
              setCallDrivers(true);
            }, 2000);
          }
        },
      })
    );
  };

  useEffect(() => {
    if (role) {
      fetchDriversList();
    }
  }, [role]);

  const handleLocationSelect = (location) => {
    console.log("location12222", location);
    dispatch(saveQuickBookingLocation(location));
  };

  const ShowUnauthorized = () => {
    setTimeout(() => {
      setShowContent(false);
    }, 1500);

    async function onLogout() {
      localStorage.clear();
      logout({ returnTo: window.location.origin }); //auth0 logout
    }

    return (
      <div className="flex flex-col justify-center items-center bg-zinc-100 h-[100vh] gap-5">
        {showContent || isLoading ? (
          <Loader />
        ) : (
          <>
            <img src={Unauthorized} className="h-[20%]" alt="Unauthorized" />
            <p className="text-5xl">Unauthorized User</p>
            <button onClick={onLogout}>Go to Login</button>
          </>
        )}
      </div>
    );
  };

  useEffect(() => {
    if (socket?.connected && callDrivers && locationUpated) {
      console.log("driversonline : Client requests data");
      console.log("driversonline event emit");
      // socket.emit("driversonline", { message: "Client requests data" });
    }

    if (socket?.connected && callDrivers && bookingStatus) {
      console.log("booking : Client requests booking");
      Toastify("Booking Request Sent To Driver", 1);
      console.log("tripObj", tripObj);
      console.log("userId", assignedDriver?.user?.userAuthId);

      socket.emit("booking", {
        userId: assignedDriver?.user?.userAuthId,
        data: tripObj,
      });

      setBookingStatus(false);
    }

    if (socket?.connected && callDrivers) {
      console.log("driversonline listener event set");
      // socket.on("driversonline", (data) => {
      //   console.log("driversonline :", data);
      //   handleDriverDetails(data);
      //   setLocationUpdated(false);
      // });

      // socket.on("useroffline", (data) => {
      //   handleDriverOffline(data);
      //   console.log(data, "User Offline");
      // });

      // socket.on("useronline", (data) => {
      //   handleDriverOnline(data);
      //   console.log(data, "User Online");
      // });

      socket.on("newlocation", (data) => {
        handleDriverDetailsUpdate(data);
        console.log(data, "User newlocation");
      });

      socket.on("bookingaccepted", (data) => {
        // alert("A");
        console.log(data, "bookingaccepted");
        setDriverStatus("A");
        setBookingStatus(false);
      });

      socket.on("bookingrejected", (data) => {
        // alert("R");
        console.log(data, "bookingrejected");
        setDriverStatus("R");
        setBookingStatus(false);
      });

      socket.on("ontrips", (data) => {
        console.log(data, "ontrips");
        Toastify("Driver is currently engaged on aother Trip", 2);
      });
    }
  }, [socket, locationUpated, bookingStatus, callDrivers]);

  return (
    <>
      {role === "84bts2" ? (
        <div className="flex bg-zinc-100 h-[100vh]">
          <NavBar active={"Home"} role={role} />
          <div className="flex w-full h-full">
            <div className={`flex flex-col w-${shortBar ? "full" : "[70vw]"} h-full`}>
              <Header title={"Welcome"} />
              <div className="w-full h-full pl-3 pr-4 pb-4 overflow-auto flex flex-col gap-4">
                <div className="w-full flex justify-end">
                  <DateFilter range={filterRange} setRange={setFilterRange} />
                </div>
                <div className="flex flex-row gap-4">
                  <AnalyticsCard
                    label={"Total  Emergency  Trips"}
                    count={tripAnalyticsData?.emergencyTrips}
                    img1={Ebar1trip}
                    img2={Elogo1trip}
                  />

                  <AnalyticsCard
                    label={"Total  Non  Emergency  Trips"}
                    count={tripAnalyticsData?.nonEmergencyTrips}
                    img1={Ebar2}
                    img2={Elogo2}
                  />

                  <AnalyticsCard
                    label={"Total  Revenue"}
                    count={tripAnalyticsData?.totalEarnings}
                    img1={Ebar3}
                    img2={Elogo3}
                  />
                </div>

                <div className="rounded-xl mb-1 w-full bg-white p-6">
                  <DynamicTable
                    size={7}
                    data={tripData.filter((item) => item.status !== "0")}
                    headers={todayTripsHeaders}
                    showEditIcon={false}
                    showDeleteIcon={false}
                    search={true}
                    title="Trips"
                    width={"w-42"}
                    heightDesktop={"h-[50%]"}
                    handlePencilClick={(row) => {
                      console.log(row);
                      navigate(`/dashboard/${row?._id}`);
                    }}
                    showTrackIcon={true}
                    handleTrackClick={async (row) => {
                      console.log("row", row);
                      let driv = driverDetails;
                      const currentDriver =
                        driv?.length > 0 && row?.drivers?.length > 0
                          ? driv.filter((v) => v?.user?.userAuthId === row?.drivers[0]?.userAuthId)
                          : [];

                      console.log("currentDriver", currentDriver);
                      // const getDistance = await DistanceFromDrop(currentDriver[0], row);
                      // curr.distance = getDistance;
                      if (currentDriver?.length > 0) {
                        // let curr = driv?.length > 0 ? currentDriver[0].lastLocation?.coords : "";
                        row.driverDetails = currentDriver?.length > 0 ? currentDriver[0] : {};
                        // row.driverCurrentPosition = curr;
                      }
                      // setCurrentTrip(row);
                      setCurrentTripId(row?._id);
                      setTimeout(() => {
                        setShowMapsModal((preState) => !preState);
                      }, 200);
                    }}
                  />
                </div>
              </div>
            </div>
            <ActiveAndInActiveComp
              shortBar={shortBar}
              setShortBar={setShortBar}
              active={activeTab}
              setActiveTab={setActiveTab}
              handleTabChange={handleTabChange}
            />
            {showMapsModal && (
              <MapModel
                showMapsModal={showMapsModal}
                setShowMapsModal={setShowMapsModal}
                customStyles={customStyles}
                containerStyle={{
                  width: "100%",
                  height: "500px",
                }}
                currentTrip={currentTrip}
                availableDriversDetail={driverDetails}
                getLastLocation={getDriverLastLocation}
                driverLocationUpated={driverLocationUpated}
                addOnDetailsList={addOnDetailsList}
              />
            )}
          </div>
        </div>
      ) : role === "63udl9" || role === "96nhe6" ? (
        <div className="flex bg-zinc-100 h-[100vh] overflow-x-hidden">
          <NavBar active={"Home"} role={role} />
          <div className="flex w-full">
            <div className="w-[75%]">
              <Header title={"Welcome"} />
              <div className="w-full pl-3 pr-4">
                <div className="flex flex-col gap-4 p-4 bg-white rounded-xl">
                  <p className="text-black font-bold text-lg flex justify-between">
                    Booking{" "}
                    <span className="flex items-center gap-3 cursor-pointer">
                      <TbRefresh onClick={() => fetchDriversList()} />
                      Drivers Online ({usersOnline} / {driverDetails?.length}){" "}
                    </span>
                  </p>
                  <div className="rounded-xl w-[65vw], h-[75vh] relative overflow-hidden">
                    <MapContainer
                      driverDetails={driverDetails}
                      sendResponse={(res) => setDirectionResult(res)}
                      suggestions={(res) => setSuggestions(res)}
                      setDriverDetailsModalOpen={setDriverDetailsModalOpen}
                      setCurrentDriverDetails={setCurrentDriverDetails}
                      recenterMap={recenterMap}
                      setRecenterMap={setRecenterMap}
                      activeDriverDetails={activeDriverDetails}
                    />
                    <DriversListDrawer
                      usersOnline={usersOnline}
                      driverDetails={driverDetails}
                      setDriverDetails={setDriverDetails}
                      userDetails={userDetails}
                      socket={socket}
                      setRecenterMap={setRecenterMap}
                      activeDriverDetails={activeDriverDetails}
                      setActiveDriverDetails={setActiveDriverDetails}
                      handleDriverOnline={handleDriverOnlineV2}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="w-[30%] h-full bg-white">
              <QuickBooking
                initialLocationValues={initialLocationValues}
                initialBookingValues={initialBookingValues}
                initialErrorFields={initialErrorFields}
                bookingValues={bookingValues}
                setBookingValues={setBookingValues}
                locationInput={locationInput}
                setLocationInput={setLocationInput}
                errorFields={errorFields}
                setErrorFields={setErrorFields}
                currentTab={currentTab}
                setCurrentTab={setCurrentTab}
                tripData={tripData}
                setTripData={setTripData}
                onLocationSelect={handleLocationSelect}
                result={directionResult}
                suggestions={suggestions}
                driverDetails={driverDetails}
                setLocationUpdated={setLocationUpdated}
                driverStatus={driverStatus}
                setDriverStatus={setDriverStatus}
                setCurrentDriverIndex={setCurrentDriverIndex}
                setTripObj={setTripObj}
                setBookingStatus={setBookingStatus}
                setAssignedDriver={setAssignedDriver}
                assignedDriver={assignedDriver}
              />
            </div>
          </div>
        </div>
      ) : (
        <ShowUnauthorized />
      )}
    </>
  );
};

export default Home;

function MapContainer(props) {
  const [map, setMap] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [pickupMarker, setPickupMarker] = useState([]);
  const [response, setResponse] = useState(null);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const dispatch = useDispatch();
  const locationMarkers = useSelector((state) => state.service?.locationPoints);
  const polylineRef = useRef(null);
  const infoWindowRef = useRef(null);
  const [mapMarkers, setMapMarkers] = useState([]);
  const [firstLoad, setFirstLoad] = useState(true);

  console.log("locationMarkers", locationMarkers);
  console.log("map12", map);

  useEffect(() => {
    async function loadRoute() {
      console.log("inside loadRoute");
      console.log("locationMarkers", locationMarkers);
      console.log("map", map);
      if (locationMarkers && map) {
        if (locationMarkers?.pickup || locationMarkers?.drop) {
          const markers = locationMarkers;
          const bounds = new window.google.maps.LatLngBounds();
          console.log("test12");
          if (markers?.pickup?.length > 0) bounds.extend(markers.pickup?.[0]?.position);
          if (markers?.drop?.length > 0) bounds.extend(markers.drop[0]?.position);
          console.log("viewport11", markers.pickup[0]?.geometry?.viewport);

          if (markers.pickup[0]?.geometry?.boundingbox) {
            const newBounds = {
              north: markers.pickup[0]?.geometry?.boundingbox?.[1], // max_lat
              south: markers.pickup[0]?.geometry?.boundingbox?.[0], // min_lat
              east: markers.pickup[0]?.geometry?.boundingbox?.[3], // max_lon
              west: markers.pickup[0]?.geometry?.boundingbox?.[2], // min_lon
            };
            console.log("newBounds", newBounds);

            // Convert the bounds into a LatLngBounds object
            const mapBounds = new window.google.maps.LatLngBounds(
              new window.google.maps.LatLng(newBounds.south, newBounds.west),
              new window.google.maps.LatLng(newBounds.north, newBounds.east)
            );

            // Fit the map to the bounding box
            map.fitBounds(mapBounds);
          } else if (markers.pickup[0]?.geometry?.viewport?.northeast) {
            let bounds = new window.google.maps.LatLngBounds();
            bounds.extend(markers.pickup[0]?.geometry?.viewport?.northeast);
            bounds.extend(markers.pickup[0]?.geometry?.viewport?.southwest);
            map.fitBounds(bounds);
          } else if (markers.pickup[0]?.geometry?.viewport) {
            console.log("fitBounds11");
            map.fitBounds(markers.pickup[0]?.geometry?.viewport);
          }
          console.log("markers12", markers);
          if (markers?.pickup && markers?.drop) {
            const markerPins = [...markers?.pickup, ...markers?.drop];
            if (markerPins[0] && markerPins[1]) {
              const directionsService = new window.google.maps.DirectionsService();
              directionsService.route(
                {
                  origin: markerPins[0]?.location,
                  destination: markerPins[1]?.location,
                  travelMode: window.google.maps.TravelMode.DRIVING,
                },
                (result, status) => {
                  if (status === window.google.maps.DirectionsStatus.OK) {
                    if (result?.routes?.length > 0) {
                      const pathArray = result?.routes[0].overview_path;
                      const pathQuarterLength = parseInt(pathArray?.length / 4); //25% length
                      const mark_one = pathArray[pathQuarterLength]?.toJSON();
                      const mark_two = pathArray[pathQuarterLength * 3]?.toJSON();
                      const markerPoints = [mark_one, mark_two];
                      setMarkers(markerPoints);
                      setPickupMarker([]);
                    }
                    setResponse(result);
                    if (props?.sendResponse) props?.sendResponse(result);
                  } else {
                    Toastify("Route Not Found", 2);
                    console.error(`Error fetching directions: ${status}`);
                  }
                }
              );
            }
            if (props?.setRecenterMap) props?.setRecenterMap({ recenter: false, position: {} });
          } else {
            const pickupPoint = markers.pickup[0].position;
            setPickupMarker([pickupPoint]);
            const places = await searchPlacesNearBy(pickupPoint);
            const placesWithPostal = await Promise.all(
              places?.map(async (p) => ({
                ...p,
                postalCode: await getPostalCodeFromCoordinates(
                  p?.geometry?.location?.lat(),
                  p?.geometry?.location?.lng()
                ),
              }))
            );
            dispatch(saveQuickBookingLocationSuggestions(placesWithPostal));
          }
        }
      }
    }
    loadRoute();
  }, [locationMarkers, map]);

  console.log("props.recenterMap", props?.recenterMap);
  console.log("props map", map);

  useEffect(() => {
    if (props?.recenterMap?.recenter) {
      if (map) {
        console.log("props.recenterMap.position", props?.recenterMap?.position);
        if (props?.recenterMap?.position?.lat) {
          map.setCenter(props?.recenterMap?.position);
          map.setZoom(16);
        } else if ((locationMarkers && locationMarkers?.pickup) || locationMarkers?.drop) {
          const markers = locationMarkers;
          const bounds = new window.google.maps.LatLngBounds();
          console.log("test12");
          if (markers?.pickup?.length > 0) bounds.extend(markers.pickup[0].position);
          if (markers?.drop?.length > 0) bounds.extend(markers.drop[0].position);
          console.log("viewport11", markers.pickup[0]?.geometry?.viewport);
          if (markers.pickup[0]?.geometry?.viewport?.northeast) {
            let bounds = new window.google.maps.LatLngBounds();
            bounds.extend(markers.pickup[0]?.geometry?.viewport?.northeast);
            bounds.extend(markers.pickup[0]?.geometry?.viewport?.southwest);
            map.fitBounds(bounds);
          } else if (markers.pickup[0]?.geometry?.viewport) {
            console.log("fitBounds11");
            map.fitBounds(markers.pickup[0]?.geometry?.viewport);
          }
        }
      }
    }
  }, [map, locationMarkers, props?.recenterMap]);

  const removeDuplicateDriverDetails = (driverDetails) => {
    return driverDetails.reduce((acc, current) => {
      const existingMarker = acc.find(
        (item) => item?.user?.userAuthId === current?.user?.userAuthId
      );
      if (!existingMarker) {
        return acc.concat([current]);
      }
      return acc;
    }, []);
  };

  useEffect(() => {
    if (window.google && window.google.maps && map && props.driverDetails) {
      // Clear previous markers
      setMapMarkers([]);

      const uniqueDriverDetails = removeDuplicateDriverDetails(props.driverDetails);

      console.log("uniqueDriverDetails", uniqueDriverDetails);
      // console.log(uniqueDriverDetails, "uniqueDriverDetails");
      // Create new markers
      const newMarkers =
        uniqueDriverDetails?.length > 0 &&
        uniqueDriverDetails.map((marker, index) => (
          // <>
          // {marker?.coords?.latitude &&
          <Marker
            key={index}
            position={{
              lat: marker?.coords?.latitude,
              lng: marker?.coords?.longitude,
            }}
            className={`${
              props?.activeDriverDetails?._id === marker?._id ? "z-[1000] drop-shadow-lg" : ""
            }`}
            icon={{
              url: marker?.isOnline ? Ambulance4 : Ambulance,
              // scaledSize: new window.google.maps.Size(50,50),
              ...(marker?.isOnline
                ? { scaledSize: new window.google.maps.Size(50, 50) }
                : { scale: 0.8 }),
            }}
            onClick={() => setSelectedMarker(marker)}
          />
          // </>
        ));
      setMapMarkers(newMarkers);
      if (firstLoad && newMarkers) {
        const bounds = new window.google.maps.LatLngBounds();
        props.driverDetails.forEach((marker) => {
          bounds.extend(
            new window.google.maps.LatLng(marker?.coords?.latitude, marker?.coords?.longitude)
          );
        });
        map.fitBounds(bounds);
        setFirstLoad(false);
      }
    }
  }, [map, firstLoad, props.driverDetails]);

  const searchPlacesNearBy = async (latLong) => {
    const request = {
      location: latLong,
      radius: 500,
      type: ["hospital"],
    };

    return new Promise((resolve) => {
      const service = new window.google.maps.places.PlacesService(map);
      service.nearbySearch(request, (results, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          const finalResult = results
            ?.slice(0, 10)
            ?.map(({ name, place_id, geometry, rating }) => ({
              placeName: name,
              placeId: place_id,
              geometry,
              position: geometry.location,
              location: geometry.location?.toJSON(),
              rating,
            }));
          resolve(finalResult);
        } else {
          resolve([]);
        }
      });
    });
  };

  const getPostalCodeFromCoordinates = async (latitude, longitude) => {
    const apiUrl = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;
    try {
      const response = await fetch(apiUrl);
      const data = await response.json();

      if (data.address && data?.address?.postcode) {
        const postalCode = data?.address?.postcode;
        return postalCode;
      } else {
        return null;
      }
    } catch (error) {
      console.error("Error fetching postal code:", error);
      return null;
    }
  };

  const onUnmountMap = () => {
    setMap(null);
    setResponse(null);
    setMarkers([]);
    if (props?.sendResponse) props?.sendResponse(null);
  };

  return (
    <>
      <LoadScript
        googleMapsApiKey={process.env.REACT_APP_MAP_API_KEY}
        libraries={libraries}
        mapIds={[process.env.REACT_APP_MAP_ID]}
      >
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          // center={center}
          // zoom={4}
          onLoad={(map) => setMap(map)}
          onUnmount={onUnmountMap}
          options={{
            styles: [
              // {
              //   elementType: "geometry",
              //   stylers: [{ color: "#212121" }],
              // },
              {
                elementType: "labels.icon",
                stylers: [{ visibility: "off" }],
              },
              // {
              //   elementType: "labels.text.fill",
              //   stylers: [{ color: "#757575" }],
              // },
              // {
              //   elementType: "labels.text.stroke",
              //   stylers: [{ color: "#212121" }],
              // },
              // {
              //   featureType: "administrative",
              //   elementType: "geometry",
              //   stylers: [{ color: "#757575" }],
              // },
              {
                featureType: "administrative.country",
                elementType: "labels.text.fill",
                stylers: [{ color: "#9e9e9e" }],
              },
              {
                featureType: "administrative.land_parcel",
                stylers: [{ visibility: "off" }],
              },
              {
                featureType: "administrative.locality",
                elementType: "labels.text.fill",
                stylers: [{ color: "#bdbdbd" }],
              },
              {
                featureType: "poi",
                elementType: "labels.text.fill",
                stylers: [{ color: "#757575" }],
              },
              // {
              //   featureType: "poi.park",
              //   elementType: "geometry",
              //   stylers: [{ color: "#181818" }],
              // },
              {
                featureType: "poi.park",
                elementType: "labels.text.fill",
                stylers: [{ color: "#616161" }],
              },
              // {
              //   featureType: "poi.park",
              //   elementType: "labels.text.stroke",
              //   stylers: [{ color: "#1b1b1b" }],
              // },
              // {
              //   featureType: "road",
              //   elementType: "geometry.fill",
              //   stylers: [{ color: "#2c2c2c" }],
              // },
              {
                featureType: "road",
                elementType: "labels.text.fill",
                stylers: [{ color: "#8a8a8a" }],
              },
              // {
              //   featureType: "road.arterial",
              //   elementType: "geometry",
              //   stylers: [{ color: "#373737" }],
              // },
              // {
              //   featureType: "road.highway",
              //   elementType: "geometry",
              //   stylers: [{ color: "#3c3c3c" }],
              // },
              // {
              //   featureType: "road.highway.controlled_access",
              //   elementType: "geometry",
              //   stylers: [{ color: "#4e4e4e" }],
              // },
              // {
              //   featureType: "road.local",
              //   elementType: "labels.text.fill",
              //   stylers: [{ color: "#616161" }],
              // },
              // {
              //   featureType: "transit",
              //   elementType: "labels.text.fill",
              //   stylers: [{ color: "#757575" }],
              // },
              // {
              //   featureType: "water",
              //   elementType: "geometry",
              //   stylers: [{ color: "#000000" }],
              // },
              // {
              //   featureType: "water",
              //   elementType: "labels.text.fill",
              //   stylers: [{ color: "#3d3d3d" }],
              // },
            ],
          }}
        >
          {mapMarkers}
          {/* {props?.driverDetails?.length > 0 &&
            props?.driverDetails?.map(
              (marker, index) =>
                marker?.coords && (
                  <React.Fragment key={index}>
                    <Marker
                      position={{
                        lat: marker?.coords?.latitude,
                        lng: marker?.coords?.longitude,
                      }}
                      icon={{
                        url: Ambulance4,
                        scaledSize: new window.google.maps.Size(50, 50),
                      }}
                      onClick={() => setSelectedMarker(marker)}
                    />
                    {index < props.driverDetails.length - 1 && (
                      <Polyline
                        path={[
                          {
                            lat: parseFloat(marker?.location?.split(" ")[1]),
                            lng: parseFloat(marker?.location?.split(" ")[3]),
                          },
                          {
                            lat: parseFloat(
                              props.driverDetails[
                                (index + 1) % props.driverDetails.length
                              ]?.location?.split(" ")[1]
                            ),
                            lng: parseFloat(
                              props.driverDetails[
                                (index + 1) % props.driverDetails.length
                              ]?.location?.split(" ")[3]
                            ),
                          },
                        ]}
                        options={{
                          strokeColor: "#FF0000",
                          strokeOpacity: 1.0,
                          strokeWeight: 2,
                        }}
                      />
                    )}
                  </React.Fragment>
                )
            )} */}

          {selectedMarker && (
            <InfoWindow
              position={{
                lat: selectedMarker?.coords?.latitude,
                lng: selectedMarker?.coords?.longitude,
              }}
              onCloseClick={() => setSelectedMarker(null)}
            >
              <div className="my-[8px]">
                <div className="flex gap-2">
                  <div
                    style={{
                      backgroundImage: `url(${selectedMarker?.user?.profilePicUrl})`,
                    }}
                    className="w-9 h-9 rounded-lg bg-black bg-cover bg-no-repeat overflow-hidden"
                  ></div>
                  <div>
                    <div className="flex flex-col justify-between font-semibold">
                      <p className="font-semibold">
                        {selectedMarker?.user?.firstName} {selectedMarker?.user?.lastName}
                      </p>
                      <div className="flex flex-col gap-1 text-xs">
                        <div className="flex gap-2 font-normal">
                          <p className="text-xs">{selectedMarker?.user?.mobile}</p>
                        </div>
                        <div className="flex w-full justify-between">
                          <div className="flex gap-1">
                            <MdBatteryChargingFull size={15} />
                            <p>{selectedMarker?.user?.battery?.level * 100} %</p>
                          </div>
                          <div className="ml-2 flex gap-1">
                            <IoSpeedometer size={15} />
                            <p>{selectedMarker?.coords?.speed} km/h</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </InfoWindow>
          )}
          {pickupMarker &&
            markers?.length === 0 &&
            pickupMarker?.map((marker, index) => (
              <Marker key={index} position={marker} label={{ text: "A", color: "#fff" }} />
            ))}
          {response && (
            <DirectionsRenderer
              directions={response}
              options={{
                polylineOptions: {
                  strokeColor: "#F15A2A",
                  strokeWeight: 5,
                },
                markerOptions: {
                  origin: {
                    icon: {
                      // path: window.google.maps.SymbolPath.CIRCLE,
                      fillColor: "#0000FF",
                      scale: 10,
                    },
                  },
                },
              }}
            />
          )}
        </GoogleMap>
      </LoadScript>
    </>
  );
}

const DriversListDrawer = ({
  usersOnline,
  driverDetails,
  socket,
  userDetails,
  setDriverDetails,
  setRecenterMap,
  activeDriverDetails,
  setActiveDriverDetails,
  handleDriverOnline,
}) => {
  const dispatch = useDispatch();

  const [isDrawerActive, setIsDrawerActive] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isDriverDetailsActive, setIsDriverDetailsActive] = useState(false);

  console.log("activeDriverDetails", activeDriverDetails);

  const handleDriverDeviceInfoUpdate = (data) => {
    if (data?.deviceinfo) {
      setDriverDetails((prev) => {
        const driverData = prev?.find((d) => d?.user?.userAuthId === data?.requserAuthId);
        if (driverData) {
          setActiveDriverDetails((previous) => ({ ...previous, deviceinfo: data?.deviceinfo }));
          return prev.map((driver) =>
            driver?.user?.userAuthId === driverData?.user?.userAuthId
              ? { ...driver, deviceinfo: data?.deviceinfo }
              : driver
          );
        }
        return prev;
      });
    }
  };

  useEffect(() => {
    if (socket?.connected) {
      console.log("socket connected", socket);
      socket.on("requesteddeviceinfo", (data) => {
        console.log(data, "requesteddeviceinfo");
        handleDriverDeviceInfoUpdate(data);
      });
    }
  }, [socket]);

  console.log("driverDetails", driverDetails);

  return (
    <div className={`absolute top-0 left-0 ${isDrawerActive ? "w-5/12 h-full" : "w-fit h-fit"}`}>
      {!isDrawerActive && (
        <button
          className="absolute px-3 py-2 top-16 left-2.5 border-none bg-white drop-shadow-xl"
          onClick={() => setIsDrawerActive(true)}
        >
          <MdDoubleArrow />
        </button>
      )}
      <div
        className={`${
          isDrawerActive ? "flex" : "hidden"
        } absolute p-3 top-16 left-2.5 bg-white drop-shadow-xl w-full flex-col h-[85%] rounded-md`}
      >
        {isDriverDetailsActive ? (
          <div className="flex flex-col h-full overflow-y-auto px-1">
            <div className="flex items-center justify-between w-full gap-3 mb-3 pb-3 border-b border-gray-300">
              <h6 className="flex items-center gap-2 font-medium text-sm">
                <FaArrowLeft
                  className="text-sm cursor-pointer"
                  onClick={() => setIsDriverDetailsActive(false)}
                />
                Driver Details
              </h6>
              <MdDoubleArrow
                className="cursor-pointer rotate-y-180"
                onClick={() => {
                  setIsDriverDetailsActive(false);
                  setIsDrawerActive(false);
                }}
              />
            </div>
            <div className="text-sm">
              <div className="flex justify-between items-center gap-1">
                <p>
                  Name:{" "}
                  <strong>
                    {activeDriverDetails?.user?.firstName} {activeDriverDetails?.user?.lastName}
                  </strong>{" "}
                </p>
                <div className="">
                  Status:{" "}
                  {activeDriverDetails?.user?.lastTripLog?.[0]?.activityType === "1"
                    ? "Active"
                    : "Inactive"}
                </div>
              </div>
              <p>
                Mobile: <strong>{activeDriverDetails?.user?.mobile ?? "NA"}</strong>
              </p>
              <p>
                Email: <strong>{activeDriverDetails?.user?.email ?? "NA"}</strong>
              </p>
              <div className="border-t border-gray-300 py-3 mt-3">
                <h5 className="flex items-center justify-center gap-3 mb-5 font-semibold underline">
                  Device Info{" "}
                  <TbRefresh
                    className="cursor-pointer"
                    onClick={() => {
                      if (socket?.connected) {
                        console.log("requestDevInfo emit", {
                          requserAuthId: activeDriverDetails?.user?.userAuthId,
                          fromuserAuthId: userDetails?.userAuthId,
                        });
                        socket.emit("requestDevInfo", {
                          requserAuthId: activeDriverDetails?.user?.userAuthId,
                          fromuserAuthId: userDetails?.userAuthId,
                        });
                        setRecenterMap({
                          recenter: true,
                          position: {
                            lat:
                              activeDriverDetails?.coords?.latitude ??
                              activeDriverDetails?.user?.lastLocation?.coords?.latitude,
                            lng:
                              activeDriverDetails?.coords?.longitude ??
                              activeDriverDetails?.user?.lastLocation?.coords?.longitude,
                          },
                        });
                      }
                    }}
                  />
                </h5>
                <p>Model Name: {activeDriverDetails?.deviceinfo?.model}</p>
                <p>Manufacturer: {activeDriverDetails?.deviceinfo?.manufacturer}</p>
                <p>Platform: {activeDriverDetails?.deviceinfo?.platform}</p>
                <p>
                  {activeDriverDetails?.deviceinfo?.platform} Version:{" "}
                  {activeDriverDetails?.deviceinfo?.version}
                </p>
                <p>Location: {activeDriverDetails?.deviceinfo?.locationResult ? "On" : "Off"}</p>
              </div>
              <div className="border-t border-gray-300 py-3">
                <h5 className="flex items-center justify-center gap-3 mb-5 font-semibold underline">
                  Permissions
                </h5>
                <p className="capitalize">
                  Location Permission:{" "}
                  {activeDriverDetails?.deviceinfo?.checkBackgroundLocationStatus}
                </p>
                <p className="flex items-center gap-3 relative capitalize">
                  Battery Optimization:{" "}
                  {activeDriverDetails?.deviceinfo?.checkBatteryOptimizationPermission}{" "}
                  <BiSolidMessageSquareError
                    data-tooltip-id="permission-alert"
                    data-tooltip-content="The denial of this permission will restrict app from running in background"
                  />
                  <Tooltip position="right" id="permission-alert" />
                </p>
                <p className="capitalize">
                  Background Location: {activeDriverDetails?.deviceinfo?.checkActivityPermission}{" "}
                </p>
              </div>
              {/* <div className="border-t border-gray-300 py-3">
                <h5 className="flex items-center justify-center gap-3 mb-5 font-semibold underline">
                  Device Logs
                </h5>
                <ul className="w-full flex flex-col m-0 p-0 max-h-[80px] overflow-y-auto">
                  <li>This is a device log for the mobile</li>
                  <li>This is a device log for the mobile</li>
                  <li>This is a device log for the mobile</li>
                  <li>This is a device log for the mobile</li>
                  <li>This is a device log for the mobile</li>
                  <li>This is a device log for the mobile</li>
                  <li>This is a device log for the mobile</li>
                </ul>
              </div> */}
            </div>
          </div>
        ) : (
          <div className="flex flex-col h-full">
            <div className="flex items-center justify-between w-full gap-3 mb-3">
              <input
                value={searchTerm}
                type="search"
                onChange={(e) => setSearchTerm(e.target.value)}
                className="border border-gray-400 rounded-md px-3 py-1 flex-1 text-sm outline-none"
              />
              <span>
                ({usersOnline} / {driverDetails?.length})
              </span>
              <MdDoubleArrow
                className="cursor-pointer rotate-y-180"
                onClick={() => setIsDrawerActive(false)}
              />
            </div>
            <ul className="border rounded-md flex-1 flex flex-col overflow-y-auto">
              {driverDetails
                ?.filter(
                  (item) =>
                    item?.user?.firstName?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
                    item?.user?.mobile?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
                    (item?.isOnline && searchTerm?.toLowerCase() === "online") ||
                    (!item?.isOnline && searchTerm?.toLowerCase() === "offline") ||
                    item?.user?.email?.toLowerCase().includes(searchTerm?.toLowerCase())
                )
                ?.sort((prev, curr) => (curr?.isOnline ? 1 : prev?.isOnline ? -1 : 0))
                ?.map((driver, index) => {
                  return (
                    <li
                      className={`text-sm w-full flex items-center px-2 ${
                        driver?.isOnline ? "bg-green-100" : index % 2 === 0 ? "bg-gray-50" : ""
                      }`}
                      key={"drawer-driver-item" + index}
                    >
                      <div className="border-r flex-1 py-1">
                        {driver?.user?.firstName} {driver?.user?.lastName}
                      </div>
                      <div className="flex justify-between items-center gap-3 pl-3 w-4/12 py-1">
                        <span
                          className={`flex gap-1 items-center rounded-full px-2.5 py-0.5 text-xs font-medium ${
                            driver?.isOnline
                              ? "bg-green-200 text-green-700"
                              : "bg-red-200 text-red-700"
                          }`}
                        >
                          {driver?.isOnline ? "Online" : "Offline"}{" "}
                          <GoDotFill
                            className={`text-base ${
                              driver?.isOnline ? "text-green-600" : "text-red-600"
                            }`}
                          />
                        </span>
                        <TbRefresh
                          className=" cursor-pointer"
                          onClick={() => {
                            dispatch(
                              getDriverStatusAction({
                                apiPayloadRequest: { userAuthId: driver?.user?.userAuthId },
                                callback: (res) => {
                                  console.log("driver status respose", res);
                                  handleDriverOnline(res?.data?.[0]);
                                },
                              })
                            );
                          }}
                        />
                        {/* <MdRemoveRedEye
                          className="cursor-pointer text-secondary w-[18px] object-contain"
                          onClick={() => {
                            setActiveDriverDetails(driver);
                            setIsDriverDetailsActive(true);
                            if (socket?.connected) {
                              console.log("props driver", driver);
                              console.log("requestDevInfo emit", {
                                requserAuthId: driver?.user?.userAuthId,
                                fromuserAuthId: userDetails?.userAuthId,
                              });
                              socket.emit("requestDevInfo", {
                                requserAuthId: driver?.user?.userAuthId,
                                fromuserAuthId: userDetails?.userAuthId,
                              });
                              setRecenterMap({
                                recenter: true,
                                position: {
                                  lat:
                                    driver?.coords?.latitude ??
                                    driver?.user?.lastLocation?.coords?.latitude,
                                  lng:
                                    driver?.coords?.longitude ??
                                    driver?.user?.lastLocation?.coords?.longitude,
                                },
                              });
                            }
                          }}
                        /> */}
                      </div>
                    </li>
                  );
                })}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};
