import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import Tab from "./Tabs/Tab";
import UserCard from "../Universal/UserCard";

import { readDriverAction } from "../../store/Service/ServiceAction";

import Cancel from "../../assets/icons/Cancel.svg";
import ProfileLogo from "../../assets/icons/profileLogo.svg";
import BurgerMenu from "../../assets/icons/burger-menu.svg";

const ActiveAndInActiveComp = ({
  active,
  // setActiveTab,
  shortBar,
  setShortBar,
  handleTabChange,
  listData = null,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [allDriversData, setAllDriversData] = useState([]);
  const [activeTab, setActiveTab] = useState("");
  const [count, setCount] = useState({ active: 0, inactive: 0 });

  useEffect(() => {
    setActiveTab(active);
  }, [active]);

  useEffect(() => {
    if (listData || window.location.pathname?.includes("drivers")) {
      let data = listData?.filter((v) => v.email);
      const activeDrivers = data?.filter((v) => v.active);
      const inActiveDrivers = data?.filter((v) => !v.active);
      if (activeTab === 1) {
        data = activeDrivers;
      } else if (activeTab === 2) {
        data = inActiveDrivers;
      }
      setCount({ active: activeDrivers?.length, inactive: inActiveDrivers?.length });
      setAllDriversData(data);
    }
  }, [activeTab, listData]);

  useEffect(() => {
    if (!window.location.pathname?.includes("drivers") && activeTab) {
      setActiveTab(active);
      dispatch(
        readDriverAction({
          callback: (res) => {
            if (res?.type === 1) {
              let data = res?.data?.filter((v) => v.email);
              const activeDrivers = data?.filter((v) => v.active);
              const inActiveDrivers = data?.filter((v) => !v.active);
              if (activeTab === 1) {
                data = activeDrivers;
              } else if (activeTab === 2) {
                data = inActiveDrivers;
              }
              setCount({ active: activeDrivers?.length, inactive: inActiveDrivers?.length });
              setAllDriversData(data);
            } else {
              setCount({ active: 0, inactive: 0 });
            }
          },
        })
      );
    }
  }, [activeTab]);

  return (
    <>
      {shortBar ? (
        <div className="w-[80px] h-[100vh] bg-white">
          <div className="small-bar pt-6 p-4 gap-2 flex flex-col items-center h-full overflow-auto">
            <img
              src={BurgerMenu}
              className="pb-4 cursor-pointer"
              onClick={() => setShortBar((preState) => !preState)}
            />
            {allDriversData?.map((img, index) => (
              <img
                key={index}
                src={img?.profilePicUrl ? img?.profilePicUrl : ProfileLogo}
                className="border-b-[1px] border-black border-opacity-10 rounded-full"
              />
            ))}
          </div>
        </div>
      ) : (
        <div className="w-[25vw] bg-white h-[100vh]">
          <div className="py-5 flex flex-col gap-1 overflow-auto h-full">
            <div className="flex justify-between items-center px-5 pb-4">
              <p className="font-bold">Drivers List</p>
              <div className="flex items-center gap-2">
                <p
                  className="text-xs text-orange-600 cursor-pointer"
                  onClick={() => navigate("/view-all-cards")}
                >
                  View All
                </p>
                <img
                  className="cursor-pointer"
                  src={Cancel}
                  onClick={() => setShortBar((preState) => !preState)}
                />
              </div>
            </div>
            <div className="mb-4">
              <Tab
                Tab1={`Active (${count.active})`}
                Tab2={`In-Active (${count.inactive})`}
                handleTabClick={handleTabChange}
                activeTab={activeTab}
              />
            </div>
            {allDriversData?.length === 0 ? (
              <div className="flex justify-center items-center h-full w-full">
                <p className="font-bold text-sm text-greyish ">No Data Found</p>
              </div>
            ) : (
              allDriversData?.map((val, index) => (
                <div
                  key={index}
                  className="flex flex-col justify-start items-start gap-2 px-5 pb-5"
                >
                  <UserCard data={val} active={activeTab} />
                </div>
              ))
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ActiveAndInActiveComp;
