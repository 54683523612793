import React, { useState } from 'react'
import NavBar from '../../components/Universal/NavBar'
import Header from '../../components/Universal/Header'
import ActiveAndInActiveComp from '../../components/Common/ActiveAndInActiveComp'
import DynamicTable from '../../components/Universal/DynamicTable'
import CardSamplePic from '../../assets/images/CardSamplePic.png'
import ReactModal from 'react-modal'
import Button from '../../components/Common/Buttons/Button'

const RegisteredUsers = ({ role, shortBar, setShortBar }) => {

    const [showProfile, setShowProfile] = useState(false);

    const [activeTab, setActiveTab] = useState(1);

    const handleTabChange = (data) => {
        setActiveTab(data)
    }

    const data = [
        {
            image: CardSamplePic,
            drivername: "Rohini Sharma",
            vehicleType: "rohini@abc.com",
            status: "Hospital Staff",
            createdDate: '24-Aug-2021 | 19:24',
            call: "Scheduled",

        },
        {
            image: CardSamplePic,
            drivername: "Suresh Kondeti",
            vehicleType: "sk@abc.com",
            status: "Hospital Staff",
            createdDate: '24-Aug-2021 | 19:24',
            call: "In-Progress",
        },
        {
            image: CardSamplePic,
            drivername: "Vijay Anuvula",
            vehicleType: "vijay@abc.com",
            status: "Hospital Staff",
            createdDate: '24-Aug-2021 | 19:24',
            call: "Completed",
        },
        {
            image: CardSamplePic,
            drivername: "Arjun Shyam",
            vehicleType: "arjun@abc.com",
            status: "Hospital Staff",
            createdDate: '24-Aug-2021 | 19:24',
            call: "Completed",
        },
        {
            image: CardSamplePic,
            drivername: "Rajesh Reddy",
            vehicleType: "rj@abc.com",
            status: "Hospital Staff",
            createdDate: '24-Aug-2021 | 19:24',
            call: "Completed",
        },
        {
            image: CardSamplePic,
            drivername: "Soma Suresh",
            vehicleType: "soma.01@abx.com",
            status: "Hospital Staff",
            createdDate: '24-Aug-2021 | 19:24',
            call: "Completed",
        },
        {
            image: CardSamplePic,
            drivername: "Ramesh Vilapam",
            vehicleType: "maesh@abc.com",
            status: "Hospital Staff",
            createdDate: '24-Aug-2021 | 19:24',
            call: "Completed",
        },
        {
            image: CardSamplePic,
            drivername: "Suresh Kondeti",
            vehicleType: "suresh@abc.com",
            status: "Hospital Staff",
            createdDate: '24-Aug-2021 | 19:24',
            call: "Completed",
        },
        {
            image: CardSamplePic,
            drivername: "Vijay Anuvula",
            vehicleType: "vijay@abx.com",
            status: "Hospital Staff",
            createdDate: '24-Aug-2021 | 19:24',
            call: "Completed",
        },
        {
            image: CardSamplePic,
            drivername: "Arjun Shyam",
            vehicleType: "arjun@abc.com",
            status: "Hospital Staff",
            createdDate: '24-Aug-2021 | 19:24',
            call: "Completed",
        },
        {
            image: CardSamplePic,
            drivername: "Rajesh Reddy",
            vehicleType: "raesh@abc.com",
            status: "Hospital Staff",
            createdDate: '24-Aug-2021 | 19:24',
            call: "Completed",
        },
    ];

    const headers = [
        {
            headerName: "User Name",
            headerId: "drivername",
        },
        {
            headerName: "Email Id",
            headerId: "vehicleType",
        },
        {
            headerName: "Type Of User",
            headerId: "status",
        },
        {
            headerName: "Created Date",
            headerId: "createdDate",
        },
        {
            headerName: "Actions",
            headerId: "actions",
        },
    ];



    return (
        <div className='flex bg-zinc-100 h-[100vh]'>
            <NavBar active={"Registered Users"} role={role} />
            <div className='flex w-full h-full'>
                <div className={`flex flex-col w-${shortBar ? 'full' : '[70vw]'} h-full`}>
                    <Header title={"Welcome"} />
                    <div className='flex h-[88vh]  w-full pl-3 pr-4'>
                        <div className='rounded-xl mb-1 w-full bg-white'>
                            {
                                !showProfile ?
                                    <div className='p-4 h-full'>
                                        <DynamicTable
                                            data={[]}
                                            headers={headers}
                                            search={true}
                                            title={"Registered users"}
                                            option1='All'
                                            Dropdown={true}
                                            width={"w-42"}
                                            heightDesktop={"h-[50%]"}
                                            nthChildWidth={"[&>*:nth-child(1)]:w-48 [&>*:nth-child(2)]:w-36 [&>*:nth-child(3)]:w-36 [&>*:nth-child(4)]:w-40 [&>*:nth-child(5)]:w-32"}
                                            nthChildStyle={
                                                ""
                                            }
                                            handleToggleClick={(row, isActive) => {
                                                console.log(isActive, row);
                                            }}
                                            handleEyeClick={(row) => {
                                                console.log(row);
                                                setShowProfile(preState => !preState);
                                            }}
                                            handleEditClick={(row) => {
                                                console.log(row);
                                            }}
                                        />
                                    </div>
                                    :
                                    <div className='flex flex-col bg-white  h-full w-full rounded-xl'>
                                        <div className='h-[20%] bg-slate-300 rounded-t-xl p-6'>
                                            <div className='bg-red-500 h-8 w-8' onClick={() => setShowProfile(preState => !preState)}>
                                            </div>
                                        </div>
                                        <div className='h-full mt-[-5%] '>
                                            <div className='px-[4%]'>
                                                <div className='h-[14vh]   aspect-square bg-black rounded-full'>
                                                </div>
                                            </div>
                                            <div className='px-[4%] py-[1.5%] border-b flex flex-col gap-3'>
                                                <div className='flex justify-between items-center'>
                                                    <div>
                                                        <p className='text-xl font-extrabold'>
                                                            Suresh Kondeti
                                                        </p>
                                                        <p>
                                                            Head Nurse
                                                        </p>
                                                    </div>
                                                    <div className='text-sm'>
                                                        <p className='text-black text-opacity-50 font-medium'>
                                                            Email
                                                        </p>
                                                        <p className='font-bold'>
                                                            rohini55@gmail.com
                                                        </p>
                                                    </div>
                                                    <div className='text-sm'>
                                                        <p className='text-black text-opacity-50 font-medium'>
                                                            Phone
                                                        </p>
                                                        <p className='font-bold'>
                                                            +91 9100243433
                                                        </p>
                                                    </div>
                                                    <div className='text-sm'>
                                                        <p className='text-black text-opacity-50 font-medium'>
                                                            Rewards Earnded
                                                        </p>
                                                        <p className='font-bold'>
                                                            12k
                                                        </p>
                                                    </div>
                                                    <div className='text-sm'>
                                                        <p className='text-black text-opacity-50 font-medium'>
                                                            reward Claimed
                                                        </p>
                                                        <p className='font-bold'>
                                                            06k
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className='flex justify-between items-center'>
                                                    <div className='flex items-center bg-slate-200 rounded-lg p-2 gap-2 text-sm'>
                                                        <div className='h-4 w-4 bg-red-500'>

                                                        </div>
                                                        <p className='text-blue-900 font-medium'>
                                                            Verified
                                                        </p>
                                                    </div>
                                                    <Button label={"Message"} background='bg-orange-500 text-white w-fit px-5 py-1.5' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                            }

                        </div>
                    </div>
                </div>
                <ActiveAndInActiveComp
                    shortBar={shortBar}
                    setShortBar={setShortBar}
                    active={activeTab}
                    setActiveTab={setActiveTab}
                    handleTabChange={handleTabChange}
                />
            </div>
        </div>
    )
}

export default RegisteredUsers