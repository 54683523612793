import React from "react";
import Line4 from "../../../assets/images/Line4.png"

export default function TextInput ({ placeholder,
    name,
    value,
    label,
    propstyle,
    edit
}) {
    return (
        <div className="pl-2"><p className="text-[#555E61] font-semibold text-[11px]">{label}</p>
        <div className="w-full flex justify-between items-center " ><div><input className=" border-[#979AA480] outline-none p-2.5 mt-1 w-full  border-none text-xs text-[#2B2B2B]  font-semibold"
            placeholder={placeholder}
            value={value}></input></div>
           <div className="text-[#2C4493] text-xs font-medium">{edit}</div>                
            </div>
            <div><img src = {Line4} /></div>
            
    </div>
    )
}