import React from "react";
import { FaEdit } from "react-icons/fa";

const TabsComp = ({
  styles = {},
  tabWidthFull = false,
  tabs = [],
  activeTab = 0,
  setActiveTab = () => {},
  isEdit = false,
  setIsEdit = () => {},
  canEdit = false,
}) => {
  return (
    <div className="relative">
      <div style={styles} className={`flex h-fit border text-xs rounded-md overflow-hidden`}>
        {tabs.map((tab, i) => (
          <button
            key={"tab-item" + i}
            onClick={() => setActiveTab(i)}
            className={`${activeTab === i && "font-semibold text-white bg-secondary"} ${
              i !== tabs.length - 1 && "border-r"
            }  px-6 ${tabWidthFull ? "w-full py-[11px]" : "py-3"}`}
          >
            {tab}
          </button>
        ))}
      </div>
      {canEdit && (
        <button
          onClick={() => setIsEdit((prev) => !prev)}
          className={`${
            isEdit && "bg-secondary"
          } absolute -right-[80px] top-0 p-3 px-4 rounded-md border flex gap-2 items-center text-sm font-medium text-black/80 ${
            isEdit && "text-white"
          }`}
        >
          <FaEdit className="mb-[2px]" size={14} />
        </button>
      )}
    </div>
  );
};

export default TabsComp;
