import React from "react";

export default function TextInputField({
  placeholder,
  name,
  containerStyle = "",
  value,
  label,
  inputstyle = "",
  handleChange,
  isError = false,
  isFormatError = false,
  isFormatErrorMessage = "",
  ...props
}) {
  return (
    <div className={`text-[#555E61] font-semibold text-[11px] ${containerStyle ?? ""}`}>
      <label className="text-[#555E61] font-semibold text-[11px]">{label}</label>
      <input
        className={`border-[#979AA480] outline-none p-2.5 mt-1 w-full  border-2 text-xs text-[#2B2B2B]  rounded-md font-semibold ${inputstyle}`}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={handleChange}
        readOnly={!handleChange}
        {...props}
      />
      {isError && (
        <p className="text-red-500 font-normal relative bottom-[-4px] text-[10px] mb-[-5px]">
          {label ? label?.replaceAll(/[^a-zA-Z ]+/g, "") : name} Is Required Field
        </p>
      )}
      {isFormatError && isFormatErrorMessage && (
        <p className="text-red-500 font-normal relative bottom-[-4px] text-[10px] mb-[-5px]">
          {isFormatErrorMessage}
        </p>
      )}
    </div>
  );
}
