import * as actionTypes from "../ActionTypes";

const INITIAL = {
    locationPoints: null,
    suggestions: [],
};

const ServiceReducer = (state = INITIAL, action) => {
    switch (action.type) {
        case actionTypes.SAVE_LOCATIONS_POINTS:
            return { ...state, locationPoints: action.payload };
        case actionTypes.SAVE_SUGGESTIONS:
            return { ...state, suggestions: action.payload };
        default:
            return state;
    }
};

export default ServiceReducer;
