import React, { useEffect, useState } from "react";
import classNames from "classnames";

const ToggleButton = ({ handleToggleClick, status }) => {
  const [isActive, setIsActive] = useState();
  console.log(status, "status");

  const toggleButtonClasses = classNames(
    "relative inline-block w-[34px] h-[22px] rounded-full transition duration-300 ease-in-out",
    {
      "bg-[#979592]": !isActive,
      "bg-green-500": isActive,
    }
  );

  const handleToggle = () => {
    setIsActive(!isActive);
    handleToggleClick(!isActive);
  };

  useEffect(() => {
    setIsActive(status == 1 ? true : false);
  }, [status]);

  return (
    <button className={toggleButtonClasses} onClick={handleToggle}>
      <span
        className={classNames(
          "inline-block absolute top-[2.5px] left-[3px] w-4 h-4 rounded-full bg-white shadow transform transition duration-300 ease-in-out",
          {
            "translate-x-3": isActive,
            "translate-x-0": !isActive,
          }
        )}
      ></span>
    </button>
  );
};

export default ToggleButton;
