import React, { useCallback, useEffect, useState } from "react";
import ReactModal from "react-modal";
import Omni from "../../assets/images/Omni.png";
import { GoogleMap, DirectionsRenderer, LoadScript, Marker } from "@react-google-maps/api";
import { MdOutlineRefresh } from "react-icons/md";
import { libraries } from "../../constants/Constant";
import { formattedDate } from "../../utils/utilsHelperFunctions";
import Toastify from "./Toastify";
import Button from "../Common/Buttons/Button";
import TextField from "../Common/InputFields/TextField";
import MarkComplete from "../../assets/images/Mark Complete (1).png";
import wrong from "../../assets/images/wrong.png";
import ProfileCircle from "../../assets/icons/profilecircle.svg";
import TextLine from "../Cards/TextLine";
import { FaMinus, FaPlus } from "react-icons/fa";
import { customStyles, customContentStyles } from "../../constants/Constant";

const MapModel = ({
  showMapsModal,
  setShowMapsModal,
  containerStyle,
  currentTrip,
  getLastLocation = () => {},
  driverLocationUpated = false,
  availableDriversDetail,
  addOnDetailsList,
}) => {
  const [map, setMap] = useState(null);
  const [directions, setDirections] = useState(null);
  const [currentDriver, setCurrentDriver] = useState({});
  const [placeNames, setPlaceNames] = useState({ from: "", to: "" });
  const [pickupDistance, setPickupDistance] = useState("0 KM");
  const [detailsCollapse, setDetailsCollapse] = useState(false);

  console.log("availableDriversDetail", availableDriversDetail);
  console.log("addOnDetailsList", addOnDetailsList);
  console.log("currentTrip5", currentTrip);

  useEffect(() => {
    if (availableDriversDetail?.length > 0 && currentTrip?._id) {
      console.log("currentTrip?.drivers?.[0]?.userAuthId", currentTrip?.drivers?.[0]?.userAuthId);
      const found = availableDriversDetail?.find((d) => {
        return d?.user?.userAuthId === currentTrip?.drivers?.[0]?.userAuthId;
        // return d?.userAuthId === "google-oauth2|116759178528672705534";
      });
      if (found) {
        setCurrentDriver(found);
      }
    }
  }, [availableDriversDetail, currentTrip]);

  const updatePickupDistance = async () => {
    const distance = await getPickupDistance();
    setPickupDistance(distance);
  };

  useEffect(() => {
    if (currentTrip?._id && currentDriver?.coords?.latitude) {
      updatePickupDistance();
    }
  }, [currentTrip, window?.google, currentDriver]);

  const center = {
    lat: parseFloat(currentTrip.fromLatitude),
    lng: parseFloat(currentTrip.fromLongitude),
  };

  const markers = [
    {
      lat: parseFloat(currentTrip.fromLatitude),
      lng: parseFloat(currentTrip.fromLongitude),
    },
    {
      lat: parseFloat(currentTrip.toLatitude),
      lng: parseFloat(currentTrip.toLongitude),
    },
  ];

  const onLoad = useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback() {
    setMap(map);
  }, []);

  useEffect(() => {
    if (window.google && window.google.maps && map && currentTrip) {
      console.log("Whatttttt");
      const bounds = new window.google.maps.LatLngBounds();
      markers.forEach((marker) => bounds.extend(marker));
      map.fitBounds(bounds);

      const directionsService = new window.google.maps.DirectionsService();
      directionsService.route(
        {
          origin: markers[0],
          destination: markers[1],
          travelMode: window.google.maps ? window.google.maps.TravelMode.DRIVING : "DRIVING",
        },
        (result, status) => {
          if (window.google.maps && status === window.google.maps.DirectionsStatus.OK) {
            setDirections(result);
          } else {
            console.error(`Directions service failed due to ${status}`);
          }
        }
      );

      const placesService = new window.google.maps.places.PlacesService(map);

      const getPlaceDetails = (placeId) => {
        return new Promise((resolve, reject) => {
          placesService.getDetails(
            {
              placeId,
            },
            (place, status) => {
              if (
                window.google.maps &&
                status === window.google.maps.places.PlacesServiceStatus.OK &&
                place
              ) {
                resolve(place?.formatted_address);
              } else {
                reject(status);
              }
            }
          );
        });
      };

      async function getFromToPlaces() {
        if (currentTrip?.fromPlaceId || currentTrip?.toPlaceId) {
          const getFromPlaceName = await getPlaceDetails(currentTrip?.fromPlaceId);
          const getToPlaceName = await getPlaceDetails(currentTrip?.toPlaceId);
          setPlaceNames({
            from: getFromPlaceName || `${currentTrip?.fromLatitude} ${currentTrip?.fromLongitude}`,
            to: getToPlaceName || `${currentTrip?.toLatitude} ${currentTrip?.toLongitude}`,
          });
        }
      }

      getFromToPlaces();

      async function getDriverToPickup() {
        if (currentTrip?.driverDetails) {
          const distance = await getPickupDistance();
          setPickupDistance(distance);
        }
      }

      getDriverToPickup();
    }
  }, [map, currentTrip]);

  useEffect(() => {
    // setCurrentDriver(currentTrip?.driverDetails);
    Toastify("Driver current location updated successfully!", 1);

    async function getDriverToPickup() {
      if (currentTrip?.driverDetails) {
        const distance = await getPickupDistance();
        setPickupDistance(distance);
      }
    }

    getDriverToPickup();
  }, [driverLocationUpated]);

  const refreshDriverDetails = () => {
    getLastLocation();
  };

  const getPickupDistance = async () => {
    let pickupDistancpickupDistancee = "0 KM";
    console.log("", pickupDistance);
    return new Promise((resolve, reject) => {
      console.log("window.google.maps", window?.google?.maps);
      if (window?.google && window?.google?.maps && map && currentTrip) {
        const origins = [
          {
            lat: parseFloat(currentTrip?.fromLatitude),
            lng: parseFloat(currentTrip?.fromLongitude),
          },
        ];
        const destinations = [
          {
            lat: currentDriver?.coords?.latitude,
            lng: currentDriver?.coords?.longitude,
          },
        ];

        const directionsService = new window.google.maps.DistanceMatrixService();
        directionsService.getDistanceMatrix(
          {
            origins,
            destinations,
            travelMode: window.google.maps ? window.google.maps.TravelMode.DRIVING : "DRIVING",
          },
          (result, status) => {
            if (window.google.maps && status === window.google.maps.DirectionsStatus.OK) {
              const distanceText = result.rows[0].elements[0]?.distance?.text;
              console.log("distanceText", distanceText);
              resolve(distanceText);
            } else {
              console.error(`Distance service failed due to ${status}`);
              resolve(pickupDistance);
            }
          }
        );
      }
    });
  };

  console.log("currentDriver", currentDriver);
  console.log("currentTrip", currentTrip);

  return (
    <ReactModal
      className={"w-full flex justify-center h-full py-4"}
      style={{
        ...customStyles,
        ...customContentStyles,
      }}
      isOpen={showMapsModal}
      onRequestClose={() => setShowMapsModal((prevState) => !prevState)}
    >
      <>
        <div className="w-[60dvw] small-bar items-center overflow-hidden bg-white border rounded-xl">
          <div className="flex justify-between items-center py-4 px-8 border-b">
            {currentTrip?.status === "7" && (
              <div className="flex p-1 rounded-md gap-1 bg-green-200 text-sm">
                <img src={MarkComplete} />
              </div>
            )}
            <img
              className="cursor-pointer"
              src={wrong}
              onClick={() => setShowMapsModal((preState) => !preState)}
            />
          </div>

          <div className=" h-[85vh] overflow-auto">
            <div className="bg-secondary/5 rounded-xl m-8 border">
              <div className={`px-6 ${detailsCollapse ? "py-3" : "pt-3"}`}>
                <p
                  className={`w-full flex items-center font-semibold ${
                    detailsCollapse ? "justify-between" : "justify-end"
                  }`}
                  onClick={() => {
                    setDetailsCollapse((prev) => !prev);
                  }}
                >
                  {detailsCollapse && "Trip Details"}
                  {detailsCollapse ? (
                    <FaPlus className="text-lg cursor-pointer" />
                  ) : (
                    <FaMinus className="text-lg cursor-pointer" />
                  )}
                </p>
              </div>
              <div className={`${detailsCollapse ? "hidden" : ""}`}>
                <div className="p-6 border-b">
                  <div className="flex gap-4 items-start w-full">
                    <img src={ProfileCircle} />
                    <div className="flex flex-col gap-6 w-full">
                      <p className="font-semibold text-sm ">Customer Details</p>
                      <div className="flex w-full gap-6 font-bold">
                        <TextField label={"Name"} value={currentTrip?.callerName} />
                        <TextField label={"Phone Number"} value={currentTrip?.callerPhoneNumber} />
                      </div>
                      <div className="flex w-full gap-6 font-bold">
                        <TextField label={"Comments"} value={currentTrip?.comments} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-6 border-b">
                  <div className="flex gap-4 items-start w-full">
                    <img src={ProfileCircle} />
                    <div className="flex flex-col gap-6 w-full">
                      <p className="font-semibold text-sm ">Trip Details</p>
                      <div className="flex w-full gap-6 font-bold">
                        <TextField
                          label={"Trip Created Date"}
                          value={formattedDate(currentTrip?.timeOfBookingRequest)}
                        />
                        <TextField
                          label={"Trip Date"}
                          value={formattedDate(currentTrip?.dateandTimeofTrip)}
                        />
                      </div>
                      <div className="flex w-full gap-6 font-bold">
                        <TextField label={"Pickup Point"} value={currentTrip?.fromAddress} />
                      </div>
                      <div className="flex w-full gap-6 font-bold">
                        <TextField label={"Drop Point"} value={currentTrip?.toAddress} />
                      </div>
                    </div>
                  </div>
                  <div className="flex gap-40 items-center justify-around px-6 pt-5">
                    <div className="flex flex-col gap-4 h-full">
                      <p className="text-black text-opacity-40 text-xs font-bold">TOTAL KMS</p>
                      <p className="text-2xl pb-2">
                        {currentTrip?.distance ? currentTrip?.distance : "0"} km
                      </p>
                    </div>
                    <div className="flex flex-col gap-4 h-full">
                      <p className="text-black text-opacity-40 text-xs font-bold">TOTAL AMOUNT</p>
                      <p className="text-2xl ">{currentTrip?.price ? currentTrip?.price : "0"}</p>
                    </div>
                  </div>
                </div>
                <div className="flex gap-40 p-6 border-b justify-between">
                  <div className="flex flex-col gap-3">
                    <div className="flex gap-4 mb-4">
                      <img src={ProfileCircle} />
                      <div className="flex flex-col gap-6 w-full">
                        <p className="font-semibold text-sm ">Selected Vehicle Details</p>
                      </div>
                    </div>
                    <p className="text-black text-opacity-40 text-xs font-bold">VEHICLE TYPE</p>
                    <div className="flex gap-2 items-center">
                      <div className="h-12 w-12 bg-slate-200 rounded-lg items-center flex">
                        <img className="w-10 h-6" src={Omni} />
                      </div>
                      {currentTrip?.vehicleObjId?.length > 0 ? (
                        <p className="text-xs font-bold">
                          {currentTrip?.vehicleObjId[0]?.vehicle_type_name}{" "}
                          {currentTrip?.vehicleObjId[0]?.vehicle_service}
                        </p>
                      ) : currentTrip?.driverDetails?.user?.vehicleDetails &&
                        currentTrip?.driverDetails?.user?.vehicleDetails?.length > 0 ? (
                        <p className="text-xs font-bold">
                          {currentTrip?.driverDetails?.user?.vehicleDetails[0]?.vehicleMake}{" "}
                          {currentTrip?.driverDetails?.user?.vehicleDetails[0]?.vehicleModel}
                        </p>
                      ) : (
                        <p className="text-xs font-bold">-</p>
                      )}
                    </div>
                  </div>
                  <ul>
                    {addOnDetailsList?.map((addon) => {
                      return (
                        <li>
                          <TextLine
                            label={addon?.addon}
                            value={`${addon?.quantity ?? 0} ${addon?.measurement}`}
                            styles={"font-semibold text-[14px] text-[#2B2B2B] "}
                          />
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className="p-6">
                  <div className="flex gap-4 items-start w-full">
                    <img src={ProfileCircle} />
                    <div className="flex flex-col gap-6 w-full">
                      <p className="font-semibold text-sm ">Driver Details</p>
                      <div className="grid grid-cols-12">
                        <div className="w-14 h-14 bg-gray-400 rounded-lg col-span-3">
                          <img src={currentDriver?.user?.profilePicUrl} />
                        </div>
                        <div className="flex w-full gap-6 font-bold col-span-9">
                          <TextField
                            label={"Driver Name"}
                            value={
                              (currentDriver?.user?.firstName ?? "") +
                              " " +
                              (currentDriver?.user?.lastName ?? "")
                            }
                          />
                          <TextField label={"Mobile"} value={currentDriver?.user?.mobile} />
                        </div>
                      </div>
                      <div className="flex w-full gap-6 font-bold">
                        <p className="flex justify-between">
                          <span className="text-black text-opacity-40 font-medium">
                            Driver to Pickup Distance
                          </span>
                          <span className="text-3md px-3">{pickupDistance}</span>
                          <MdOutlineRefresh
                            className="cursor-pointer text-opacity-40"
                            size={26}
                            onClick={refreshDriverDetails}
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <LoadScript
              googleMapsApiKey={process.env.REACT_APP_MAP_API_KEY}
              libraries={libraries}
              mapIds={[process.env.REACT_APP_MAP_ID]}
            >
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={10}
                onLoad={onLoad}
                onUnmount={onUnmount}
              >
                {/* {currentTrip?.driverCurrentPosition ?
              <Marker
                position={{ lat: parseFloat(currentTrip?.driverCurrentPosition?.latitude), lng: parseFloat(currentTrip?.driverCurrentPosition?.longitude) }}
                icon={{ url: Omni }}
                // label={{ text: "Current location", color: "#000" }}
              /> : */}
                {currentDriver?.coords?.latitude ? (
                  <Marker
                    position={{
                      lat: parseFloat(currentDriver?.coords?.latitude),
                      lng: parseFloat(currentDriver?.coords?.longitude),
                    }}
                    icon={{ url: Omni }}
                    // label={{ text: "Current location", color: "#000" }}
                  />
                ) : currentTrip?.acceptedLatitude ? (
                  <Marker
                    position={{
                      lat: currentTrip?.acceptedLatitude,
                      lng: currentTrip?.acceptedLogitude,
                    }}
                    icon={{ url: Omni }}
                    // label={{ text: " Last location", color: "#000" }}
                  />
                ) : (
                  ""
                )}
                {directions && (
                  <DirectionsRenderer
                    directions={directions}
                    options={{
                      polylineOptions: {
                        strokeColor: "#FF0000",
                        strokeOpacity: 0.8,
                        strokeWeight: 4,
                      },
                    }}
                  />
                )}
                {/* {markers.map((marker, index) => (
              <Marker
                key={index}
                position={{ lat: marker.lat, lng: marker.lng }}
                label={{ text: String.fromCharCode(65 + index), color: "#fff" }}
              />
            ))} */}
              </GoogleMap>
            </LoadScript>

            {currentTrip?.status === "7" && (
              <div className="p-4 shadow-2xl">
                <div className="flex items-center float-right">
                  <Button
                    label="Send Invoice to Customer"
                    background={"bg-white h-10 mt-7 border-[1px] text-blue-900 border-blue-900"}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    </ReactModal>
  );
};

export default MapModel;
