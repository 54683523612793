import Axios from "../../utils/axios";

//Get Country data
export const postSignUp = (payload) => {
  const URL = "/signup";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

// /login
export const postLogin = (payload) => {
  const URL = "/login";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const postEmailVerfication = (payload) => {
  const URL = "/verifyEmail";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

// /resendVerification
export const postResendVerification = (payload) => {
  const URL = "/resendVerification";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const uploadAssests = (payload) => {
  const URL = "/uploadAsset";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const readProfile = (payload) => {
  const URL = "/profile";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const readUserByMobile = (payload) => {
  const URL = "/users/readbymobile";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const updateProfile = (payload) => {
  const URL = "/profile/update";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const saveAddress = (payload) => {
  const URL = "/addressDetails/save";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const updateAddress = (payload) => {
  const URL = "/addressDetails/update";
  return Axios.post(URL, payload).then((res) => res.data);
};
