import ReactModal from "react-modal";

import { customStyles, customContentStyles } from "../constants/Constant";
import { FaXmark } from "react-icons/fa6";
import { FaPrint } from "react-icons/fa";
import { GiCloudDownload } from "react-icons/gi";

const TripInvoiceModal = ({
  isOpen,
  closeModal,
  handleReceiptDownload,
  handlePrintReceipt,
  receiptToPrint,
  getReceiptTemplate,
}) => {
  return (
    <ReactModal
      isOpen={isOpen}
      style={{
        ...customStyles,
        ...customContentStyles,
      }}
      onRequestClose={closeModal}
    >
      <div className="w-[32vw] h-[90vh] bg-white rounded-xl flex flex-col justify-between p-5">
        <div className="flex justify-between mb-2">
          <h2 className="font-semibold text-xl">Receipt</h2>
          <FaXmark className="cursor-pointer" onClick={closeModal} />
        </div>
        {getReceiptTemplate()}
        <div className="grid grid-cols-2 text-white gap-2">
          <button
            className="flex gap-3 items-center justify-center border-none bg-primary rounded-md px-7 py-2 text-sm"
            onClick={() => {
              handlePrintReceipt(null, () => receiptToPrint.current);
            }}
          >
            <FaPrint />
            Print Receipt
          </button>
          <button
            className="flex gap-3 items-center justify-center border-none bg-primary rounded-md px-7 py-2 text-sm"
            onClick={handleReceiptDownload}
          >
            <GiCloudDownload />
            Download Receipt
          </button>
        </div>
      </div>
    </ReactModal>
  );
};

export default TripInvoiceModal;
