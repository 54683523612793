import React from "react";
// import CardSamplePic from "../../assets/images/CardSamplePic.png"
import ProfileLogo from "../../assets/icons/profileLogo.svg";
import { useNavigate } from "react-router-dom";

const UserCard = (props) => {
  const navigate = useNavigate();
  const data = props.data;

  console.log("data89", data);

  return (
    <div
      className="group cursor-pointer p-4 w-[100%] flex bg-slate-100 rounded-lg hover:bg-[#2D4493] gap-5"
      onClick={() => navigate("/view-all-cards", { state: { _id: data?._id } })}
    >
      <div className="w-1/6">
        <img
          src={data?.profilePicUrl ? data?.profilePicUrl : ProfileLogo}
          className="size-10 min-w-10 min-h-10 rounded-full"
        />
      </div>
      <div className="flex flex-col gap-2 w-3/4">
        <div className="text-[14px]">
          <div className="flex justify-between">
            <p className="group-hover:text-white  text-sm text-[#2D4493]">{data?.firstName}</p>
            {/* <p className="group-hover:text-white font-medium text-sm text-black">0</p> */}
          </div>
          <p className="font-medium group-hover:text-white">
            {data?.vehicleDetails && data?.vehicleDetails?.length > 0
              ? `${data?.vehicleDetails[0]?.vehicleMake} ${data?.vehicleDetails[0]?.vehicleType}`
              : ""}
          </p>
        </div>
        <p className="text-[13px] group-hover:text-white group-hover:text-opacity-50 text-black text-opacity-50 break-all">
          {data?.email}
        </p>
        <div className="flex gap-2 mt-1">
          <button className="px-2 py-1 text-xs group-hover:text-white group-hover:border-white group-hover:border-opacity-30 text-[#555E61] border border-black rounded-md border-opacity-30">
            Message
          </button>
          <button className="px-2 py-1 text-xs group-hover:text-white group-hover:border-white group-hover:border-opacity-30 text-[#555E61] border border-black rounded-md border-opacity-30">
            View
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserCard;
