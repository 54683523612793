import React, { useState } from "react";
import Line4 from "../../../assets/images/Line4.png";
import { Autocomplete, LoadScript } from "@react-google-maps/api";
import { libraries } from "../../../constants/Constant";
import { useSocket } from "../SocketContext";

export default function LocationInput({
  name,
  label,
  placeholder,
  value,
  lineWidth,
  edit = true,
  styles,
  iconStyle,
  inputStyle = "",
  onChange,
  formattedAddress,
  setFormattedAddress,
}) {
  const autocompleteRef = React.useRef(null);

  const getPostalCodeFromCoordinates = async (latitude, longitude) => {
    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_MAP_API_KEY}`;
    try {
      const response = await fetch(apiUrl);
      const data = await response.json();
      // Check if address contains postal code information
      if (data?.results?.length > 0) {
        const postalCode = data?.results[0]?.address_components?.find((v) =>
          v.types?.includes("postal_code")
        )?.long_name;
        if (postalCode) {
          return postalCode;
        }
        console.log("Postal Code not found");
        return null;
      } else {
        console.log("Postal Code not found");
        return null;
      }
    } catch (error) {
      console.error("Error fetching postal code:", error);
      return null;
    }
  };

  const handlePlaceSelect = async () => {
    if (autocompleteRef.current) {
      const newMarkers = [];
      const place = autocompleteRef.current?.getPlace();
      if (place?.geometry) {
        const location = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        };
        const geometry = {
          location: location,
          viewport: JSON.parse(JSON.stringify(place?.geometry?.viewport)),
        };
        const position = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        };
        const placeId = place.place_id;
        const placeName = place.name;
        const formattedAddress = place.formatted_address;
        console.log("place45", place);

        setFormattedAddress(place.formatted_address);

        let getAddress = place?.address_components?.filter((v) => v.types?.includes("postal_code"));
        let postalCode = "";
        if (getAddress?.length > 0) {
          postalCode = getAddress && getAddress?.length > 0 ? getAddress[0]?.long_name : "";
        } else {
          postalCode = await getPostalCodeFromCoordinates(location?.lat, location?.lng);
        }
        newMarkers.push({
          position,
          placeId,
          placeName,
          geometry,
          location,
          postalCode,
          formattedAddress,
        });
        if (onChange) onChange(newMarkers, name);
      }
    }
  };

  return (
    <div className="pl-2">
      <p className={`text-[10px] text-[#555E61] ${styles} font-semibold mt-4 ml-2`}>{label}</p>
      <div className="w-full flex flex-row justify-between  items-center ">
        <LoadScript
          googleMapsApiKey={process.env.REACT_APP_MAP_API_KEY}
          libraries={libraries}
          mapIds={[process.env.REACT_APP_MAP_ID]}
          onUnmount={() => {
            console.log("load unmount");
          }}
        >
          <Autocomplete
            onLoad={(autocomplete) => {
              autocompleteRef.current = autocomplete;
            }}
            className="w-full"
            onPlaceChanged={handlePlaceSelect}
            // onUnmount={() => autocompleteRef.current = null}
            onUnmount={() => {
              console.log("unmount");
              autocompleteRef.current = null;
            }}
          >
            <input
              name={name}
              value={formattedAddress}
              onChange={(e) => setFormattedAddress(e.target.value)}
              className={`${inputStyle} w-full p-2 outline-none font-semibold border-0 text-xs text-ellipsis`}
              placeholder={placeholder}
              type="text"
              // disabled={edit}
            />
          </Autocomplete>
        </LoadScript>
        {/* <div>
                    <input
                    name={name}
                    className={`${inputStyle} w-full p-2 outline-none font-semibold border-0 text-xs text-ellipsis`}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    />
                </div> */}
        {/* <div className={`text-[#2C4493] text-[11px]  font-medium ${iconStyle}`}>{edit}</div> */}
      </div>
      <div className={`${lineWidth}`}>
        <img src={Line4} />
      </div>
    </div>
  );
}
