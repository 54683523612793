import React, { useState, useEffect } from 'react';
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css'

import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const FileUploadComponent = ({ onUpload }) => {
    const [files, setFiles] = useState([]);

    const handleUpdateFiles = (fileItems) => {
        setFiles(fileItems.map((fileItem) => fileItem.file));
    };

    const handleEvent = (file) => {
        if (file) {
            onUpload([file.file]);
        }
    };

    return (
        <div>
            <FilePond
                files={files}
                onupdatefiles={handleUpdateFiles}
                allowMultiple={false}
                maxFiles={1}
                acceptedFileTypes={['image/jpeg', 'image/png']}
                oninit={() => console.log('FilePond instance has initialized')}
                onaddfilestart={handleEvent}
            />
        </div>
    )
}

export default FileUploadComponent;