import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ReactModal from "react-modal";

import TextInputField from "../../../../components/Common/InputFields/TextInputField";

import { readDriverAction } from "../../../../store/Service/ServiceAction";
import { customStyles, customContentStyles } from "../../../../constants/Constant";
import { dateDiff } from "../../../../utils/utilsHelperFunctions";

import wrong from "../../../../assets/images/wrong.png";
import Line4 from "../../../../assets/images/Line4.png";
import usercircle from "../../../../assets/images/usercircle.png";
import escalamationIcon from "../../../../assets/icons/escalamation.svg";
import activeSymbol from "../../../../assets/icons/activeSymbol.svg";
import ToggleButton from "../../../../components/Universal/ToggleButton";

const DriversModal = ({
  id,
  isOpen,
  closeModal,
  setIsOpen,
  handleToggleClick,
  fetchDriversList,
}) => {
  const dispatch = useDispatch();

  const initialTariffValues = {
    firstName: "",
    lastName: "",
    dob: "",
    gender: "",
    email: "",
    mobile: "",
  };

  const [driverValues, setDriverValues] = useState(initialTariffValues);
  const [currentDriver, setCurrentDriver] = useState(null);

  const fetchDriverDetails = (id) => {
    dispatch(
      readDriverAction({
        apiPayloadRequest: {
          _id: id,
        },
        callback: (res) => {
          if (res?.type === 1) {
            const data = res?.data[0];
            setCurrentDriver(data);
            setDriverValues({
              firstName: data?.firstName || "",
              lastName: data?.lastName || "",
              dob: data?.dob || "",
              gender: data?.gender || "",
              email: data?.email || "",
              mobile: data?.mobile || "",
            });
          }
        },
      })
    );
  };

  useEffect(() => {
    if (id !== "") {
      fetchDriverDetails(id);
    } else {
      setDriverValues(initialTariffValues);
      setCurrentDriver(null);
    }
  }, [id]);

  console.log("currentDriver", currentDriver);

  return (
    <div>
      <ReactModal
        isOpen={isOpen}
        style={{
          ...customStyles,
          ...customContentStyles,
        }}
        onRequestClose={closeModal}
      >
        <div className="w-[70vw] h-[90vh] flex flex-col justify-between  small-bar items-center overflow-auto bg-white">
          <div className="w-full p-3 px-3  items-center flex justify-between">
            <div>
              {currentDriver?.active === 1 ? (
                <div className="bg-[#32B559] bg-opacity-10 rounded-md pl-2.5 pr-4 py-1.5 text-sm flex items-center gap-1.5 font-medium">
                  <img src={activeSymbol} className="w-5" />
                  Active
                </div>
              ) : (
                <div className="bg-[#b54332] bg-opacity-10 rounded-md pl-2.5 pr-4 py-1.5 text-sm flex items-center gap-1.5 font-medium">
                  <img src={escalamationIcon} className="w-5" />
                  Inactive
                </div>
              )}
            </div>
            <div
              className="text-lg px-3 cursor-pointer flex items-center flex-row gap-6"
              onClick={() => setIsOpen(!isOpen)}
            >
              <div>
                <img src={wrong} />{" "}
              </div>
            </div>
          </div>

          <img className="w-full h-[1px]" src={Line4} />

          <div className="p-6 w-full flex flex-col gap-5">
            <div className="w-full flex flex-row gap-3 justify-between items-center">
              <div className="flex flex-col gap-2">
                <div className="text-2xl font-bold flex gap-4 items-center">
                  <p className="capitalize inline-block">
                    {currentDriver?.firstName} {currentDriver?.lastName}
                  </p>
                  <ToggleButton
                    handleToggleClick={(isActive) => {
                      handleToggleClick(currentDriver, isActive, () => {
                        fetchDriverDetails(id);
                        fetchDriversList();
                      });
                    }}
                    status={currentDriver?.active ? 1 : 0}
                  />
                </div>
                <div>
                  <p className="text-xs font-medium">
                    On Boarded {dateDiff(currentDriver?.activationDate)} days ago
                  </p>
                  {/* <p className="text-xs font-medium">Added by <span className="text-blue-800">John Doe</span>, 1yr 2month ago</p> */}
                </div>
              </div>
              <img src={currentDriver?.profilePicUrl} className="w-1/12" />
            </div>

            <div className="w-full flex flex-row gap-20">
              <div className="flex flex-col">
                <p className="font-semibold text-[12px] opacity-70 mb-1">
                  Type of Vehicles Allocated
                </p>
                <div className="grid grid-cols-6 gap-2">
                  {currentDriver?.vehicleTypes?.vehicleObjId?.map((vehicle) => {
                    return (
                      <div className="flex flex-col">
                        <img src={vehicle?.vehicle_icon_url} className="w-full aspect-video object-cover" />
                        <p className="text-[10px] font-medium mt-1">
                          {vehicle?.vehicle_type_name}({vehicle?.vehicle_service})
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>

              {/* <div className="flex flex-col gap-2">
                <p className="font-semibold text-[12px] opacity-70">TOTAL TRIPS</p>
                <p className="font-semibold text-lg">0</p>
              </div>

              <div className="flex flex-col gap-2">
                <p className="font-semibold text-[12px] opacity-70">TOTAL INCOME</p>
                <p className="font-semibold text-lg">0</p>
              </div> */}
            </div>

            <img src={Line4} />

            <div className="flex gap-2 items-center">
              <img src={usercircle} /> <p className="text-xs font-semibold"> Personal Details</p>{" "}
            </div>

            <div className="flex flex-row justify-around">
              <div className="w-full flex flex-row gap-3 items-center">
                <div className="w-full">
                  <TextInputField
                    name={"firstName"}
                    // handleChange={handleDriverChange}
                    value={driverValues?.firstName}
                    label="First Name"
                    placeholder="Enter First Name"
                  />
                </div>
                <div className="w-full">
                  <TextInputField
                    name={"lastName"}
                    // handleChange={handleDriverChange}
                    value={driverValues?.lastName}
                    label="Last Name"
                    placeholder="Enter Last Name"
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-row justify-around">
              {/* <TextInput label='Date Of Birth' placeholder='16/01/1993' />
                                <TextInput label='Gender' placeholder='Male' /> */}
              <div className="w-full flex flex-row gap-3 items-center">
                <div className="w-full">
                  <TextInputField
                    name={"dob"}
                    // handleChange={handleDriverChange}
                    value={driverValues?.dob}
                    label="Date Of Birth"
                    placeholder="Enter Date Of Birth"
                  />
                </div>
                <div className="w-full">
                  <TextInputField
                    name={"gender"}
                    // handleChange={handleDriverChange}
                    value={driverValues?.gender}
                    label="Gender"
                    placeholder="Enter Gender"
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-row justify-around">
              {/* <TextInput label='Email ID' placeholder='prasadangarapu@gmail.com' />
                                <TextInput label='Mobile Number' placeholder='+91 91000950567' /> */}
              <div className="w-full flex flex-row gap-3 items-center">
                <div className="w-full">
                  <TextInputField
                    name={"email"}
                    // handleChange={handleDriverChange}
                    value={driverValues?.email}
                    label="Email ID"
                    placeholder="Enter Email ID"
                  />
                </div>
                <div className="w-full">
                  <TextInputField
                    name={"mobile"}
                    // handleChange={handleDriverChange}
                    value={driverValues?.mobile}
                    label="Mobile Number"
                    placeholder="Enter Mobile Number"
                  />
                </div>
              </div>
            </div>

            {/* <p className="text-[10px] text-blue-600 flex gap-1 items-center">Show more details <img src={arrow} /></p> */}

            <h4 className="font-semibold">Attachments</h4>

            <div className="grid grid-cols-2 gap-8 text-center">
              {currentDriver?.drivingLicenceUrl && (
                <div className="flex flex-col items-center">
                  <img
                    src={currentDriver?.drivingLicenceUrl}
                    className="w-[300px] h-[200px] rounded-s-lg p-4 bg-neutral-300"
                  />
                  <span className="font-extralight"> Driving License.png</span>
                </div>
              )}
              {currentDriver?.aadharCardUrl && (
                <div className="flex flex-col items-center">
                  <img
                    src={currentDriver?.aadharCardUrl}
                    className="w-[300px] h-[200px] rounded-s-lg p-4 bg-neutral-300"
                  />
                  <span className="font-extralight">Aadhar.png</span>
                </div>
              )}
              {/* {currentDriver?.drivingLicenceUrl &&
                                <div className="flex flex-col items-center">
                                    <img src={currentDriver?.vehicleDetails && currentDriver?.vehicleDetails[0]?.vehicleInsuranceUrl} className="w-[300px] h-[200px] rounded-s-lg p-4 bg-neutral-300"  />
                                    <span className="font-extralight">  Insurance.png</span>
                                </div> }
                                {currentDriver?.drivingLicenceUrl &&
                                <div className="flex flex-col items-center">
                                    <img src={currentDriver?.vehicleDetails && currentDriver?.vehicleDetails[0]?.vehiclePolutionCertUrl} className="w-[300px] h-[200px] rounded-s-lg p-4 bg-neutral-300"  />
                                    <span className="font-extralight">  Pollution.png</span>
                                </div> }
                                {currentDriver?.drivingLicenceUrl &&
                                <div className="flex flex-col items-center">
                                    <img src={currentDriver?.vehicleDetails && currentDriver?.vehicleDetails[0]?.vehicleTaxUrl} className="w-[300px] h-[200px] rounded-s-lg p-4 bg-neutral-300"  />
                                    <span className="font-extralight">  Vehicle Tax.png</span>
                                </div> } */}
            </div>

            {/* <img className="w-11/12" src={Groupimg} /> */}

            {/* <p className="text-[10px] text-blue-600 flex gap-1 items-center">Show More <img src={arrow} /></p> */}

            {/* <div className="flex items-center gap-2"><img src={Messages} /> <p className="font-semibold text-xs">Reviews(77)</p></div>

                            <div className="flex items-center gap-2"><img className="pb-10" src={Initial} />
                                <div className="w-full bg-slate-100 p-3 rounded-lg">
                                    <div className="flex flex-row justify-between"> <p className="text-xs font-semibold">Rohith Sharma</p> <div className="flex flex-row opacity-70"><p className="text-[10px] font-semibold">Jul 24, 2023  <span>...</span></p></div> </div>
                                    <p className="text-[11px] font-medium pt-2">Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                        when an unknown printer took a galley of type and scrambled it to make a type</p>

                                </div></div>

                            <div className="flex items-center gap-2"><img className="pb-10" src={Initial} />
                                <div className="w-full bg-slate-100 p-3 rounded-lg">
                                    <div className="flex flex-row justify-between"> <p className="text-xs font-semibold">Abhinav Mukundh</p> <div className="flex flex-row opacity-70"><p className="text-[10px] font-semibold">Jul 24, 2023  <span>...</span></p></div> </div>
                                    <p className="text-[11px] font-medium pt-2">Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                        when an unknown printer took a galley of type and scrambled it to make a type</p>

                                </div>
                            </div>

                            <p className="text-[10px] text-blue-600 flex gap-1 items-center">Show More <img src={arrow} /></p> */}
          </div>
        </div>
      </ReactModal>
    </div>
  );
};

export default DriversModal;
