import React, { useEffect, useState } from "react";
import UserHeader from "../components/Universal/UserHeader";
import TextInputField from "../components/Universal/TextInputField";
import TabsComp from "../components/Universal/TabComp";
import { useDispatch } from "react-redux";
import {
  updateProfileAction,
  readProfileAction,
  saveAddressAction,
  updateAddressAction,
  uploadAssestsAction,
} from "../store/LoginAndRegister/LoginAndRegisterAction";
import FileUploadComponent from "../components/Common/InputFields/FileUploadComponent";
import DateTimeInput from "../components/Common/InputFields/DateTimeInput";
import Toastify from "../components/Universal/Toastify";

const genderOptions = ["Male", "Female", "Other"];

const Profile = () => {
  const dispatch = useDispatch();

  const intialProfile = {
    _id: "",
    firstName: "",
    lastName: "",
    mobile: "",
    email: "",
    dob: "",
    gender: "",
    ICEName1: "",
    ICEName2: "",
    ICENumber1: "",
    ICENumber2: "",
    ICERelation1: "",
    ICERelation2: "",
    aadharCardUrl: "",
    profilePicUrl: "",
    drivingLicenceUrl: "",
  };

  const [profile, setProfile] = useState(intialProfile);
  const [editProfile, setEditProfile] = useState(intialProfile);
  const [address, setAddress] = useState();

  const [activeTab, setActiveTab] = useState(0);
  const [isEdit, setIsEdit] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditProfile((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDateTime = (value) => {
    setEditProfile((prev) => ({ ...prev, ["dob"]: value }));
  };

  const handleGender = (value) => {
    if (value === 0) {
      setEditProfile((prev) => ({ ...prev, ["gender"]: "Male" }));
    } else if (value === 1) {
      setEditProfile((prev) => ({ ...prev, ["gender"]: "Female" }));
    } else {
      setEditProfile((prev) => ({ ...prev, ["gender"]: "Other" }));
    }
  };

  const onUpload = (uploadedFiles, name) => {
    const file = uploadedFiles[0];

    const formData = new FormData();
    formData.append("file", file);

    dispatch(
      uploadAssestsAction({
        apiPayloadRequest: formData,
        callback: (res) => {
          setEditProfile((prevInputs) => ({
            ...prevInputs,
            [name]: res?.url,
          }));
        },
      })
    );
  };

  const handleSubmitProfile = (e) => {
    e.preventDefault();

    dispatch(
      updateProfileAction({
        apiPayloadRequest: editProfile,
        callback: (res) => {
          if (res?.type === 1) {
            fetchProfileData();
            Toastify("Profile updated successfully!", 1);
          }
        },
      })
    );
  };

  const fetchProfileData = () => {
    dispatch(
      readProfileAction({
        callback: (res) => {
          if (res?.type === 1) {
            setProfile(res?.data[0]);
            setEditProfile(res?.data[0]);
          }
        },
      })
    );
  };

  useEffect(() => {
    fetchProfileData();
  }, []);

  useEffect(() => {
    if (!isEdit) {
      setEditProfile(profile);
    }
  }, [isEdit]);

  console.log('profile654', profile)

  return (
    <div className="flex flex-col h-full overflow-y-auto relative">
      <UserHeader activeTab={"profile"} />
      <div className="flex flex-col items-center w-full h-full md:pt-[80px] md:pb-6 pb-[80px] py-6 px-[5%]">
        <TabsComp
          tabs={["Profile Details"]}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          canEdit={true}
        />
        <div className="h-full md:w-2/3 w-full p-6 border border-black/20 rounded-md mt-6">
          <div className="flex flex-col md:grid md:grid-cols-3 w-full gap-8">
            {activeTab === 0 ? (
              !isEdit ? (
                <ProfileDetailsDisplay profile={profile} />
              ) : (
                <ProfileDetailsEdit
                  editProfile={editProfile}
                  handleDateTime={handleDateTime}
                  handleChange={handleChange}
                  handleGender={handleGender}
                  onUpload={onUpload}
                  handleSubmitProfile={handleSubmitProfile}
                />
              )
            ) : null}
            {activeTab === 1 ? (
              !isEdit ? (
                <AddressDetailsDisplay profile={profile} />
              ) : (
                <AddressDetailsEdit />
              )
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

const ProfileDetailsDisplay = ({ profile }) => {
  return (
    <>
      <DisplayField title={"First Name"} value={profile?.firstName || "--"} />
      <DisplayField title={"Last Name"} value={profile?.lastName || "--"} />
      <DisplayField title={"Phone"} value={profile?.mobile || "--"} />
      <DisplayField title={"Email"} value={profile?.email || "--"} />
      <DisplayField title={"Gender"} value={profile?.gender || "--"} />
      <DisplayField title={"DOB"} value={profile?.dob || "--"} />
      <div className="col-span-3">
        <p className="text-xs text-black/70">Address Details</p>
        <div className="flex flex-col md:grid md:grid-cols-3 gap-8 p-6 rounded-md border border-black/20 mt-2">
          <DisplayField title={"Address"} value={profile?.addressLine1 || "--"} />
          <DisplayField title={"Landmark"} value={profile?.landmark || "--"} />
          <DisplayField title={"City"} value={profile?.city || "--"} />
          <DisplayField title={"State"} value={profile?.state || "--"} />
          <DisplayField title={"Pincode"} value={profile?.pincode || "--"} />
        </div>
      </div>
      {/* <div className="col-span-3">
        <p className="text-xs text-black/70">Emergency Contact 1</p>
        <div className="flex flex-col md:grid md:grid-cols-3 gap-8 p-6 rounded-md border border-black/20 mt-2">
          <DisplayField title={"Name"} value={profile?.ICEName1 || "--"} />
          <DisplayField title={"Contact Number"} value={profile?.ICENumber1 || "--"} />
          <DisplayField title={"Relation"} value={profile?.ICERelation1 || "--"} />
        </div>
      </div>
      <div className="col-span-3">
        <p className="text-xs text-black/70">Emergency Contact 2</p>
        <div className="flex flex-col md:grid md:grid-cols-3 gap-8 p-6 rounded-md border border-black/20 mt-2">
          <DisplayField title={"Name"} value={profile?.ICEName2 || "--"} />
          <DisplayField title={"Contact Number"} value={profile?.ICENumber2 || "--"} />
          <DisplayField title={"Relation"} value={profile?.ICERelation2 || "--"} />
        </div>
      </div> */}
    </>
  );
};

const ProfileDetailsEdit = ({
  editProfile,
  handleDateTime,
  handleChange,
  handleGender,
  onUpload,
  handleSubmitProfile,
}) => {
  return (
    <>
      <div className="col-span-3">
        <p className="text-[10px] text-black/60 font-medium mb-2">Profile Picture Upload</p>
        <FileUploadComponent onUpload={(file) => onUpload(file, "profilePicUrl")} />
      </div>
      <TextInputField
        name={"firstName"}
        labelText={"First Name"}
        value={editProfile?.firstName}
        handleInputChange={handleChange}
      />
      <TextInputField
        name={"lastName"}
        labelText={"Last Name"}
        value={editProfile?.lastName}
        handleInputChange={handleChange}
      />
      <TextInputField
        name={"mobile"}
        labelText={"Phone"}
        value={editProfile?.mobile}
        handleInputChange={handleChange}
      />
      <TextInputField
        name={"email"}
        labelText={"Email"}
        value={editProfile?.mobile}
        handleInputChange={handleChange}
      />
      <DateTimeInput label={"DOB"} handleChange={handleDateTime} value={editProfile?.dob} />
      <div>
        <p className="text-[10px] text-black/60 font-medium mb-2">Gender</p>
        <TabsComp
          styles={{ fontSize: "11px" }}
          tabWidthFull={true}
          tabs={genderOptions}
          activeTab={editProfile?.gender === "Male" ? 0 : editProfile?.gender === "Female" ? 1 : 2}
          setActiveTab={handleGender}
        />
      </div>
      <div className="col-span-3">
        <p className="text-xs text-black/70">Address Details</p>
        <div className="flex flex-col md:grid md:grid-cols-3 gap-8 p-6 rounded-md border border-black/20 mt-2">
          <TextInputField
            name={"address"}
            labelText={"Address"}
            value={editProfile?.address}
            handleInputChange={handleChange}
          />
          <TextInputField
            name={"landmark"}
            labelText={"Landmark"}
            value={editProfile?.landmark}
            handleInputChange={handleChange}
          />
          <TextInputField
            name={"city"}
            labelText={"City"}
            value={editProfile?.city}
            handleInputChange={handleChange}
          />
          <TextInputField
            name={"state"}
            labelText={"State"}
            value={editProfile?.state}
            handleInputChange={handleChange}
          />
          <TextInputField
            name={"pincode"}
            labelText={"Pincode"}
            value={editProfile?.pincode}
            handleInputChange={handleChange}
          />
        </div>
      </div>
      <div className="col-span-3">
        <p className="text-xs text-black/70">Emergency Contact 1</p>
        <div className="flex flex-col md:grid md:grid-cols-3 gap-8 p-6 rounded-md border border-black/20 mt-2">
          <TextInputField
            name={"ICEName1"}
            labelText={"Name"}
            value={editProfile?.ICEName1}
            handleInputChange={handleChange}
          />
          <TextInputField
            name={"ICENumber1"}
            labelText={"Contact Number"}
            value={editProfile?.ICENumber1}
            handleInputChange={handleChange}
          />
          <TextInputField
            name={"ICERelation1"}
            labelText={"Relation"}
            value={editProfile?.ICERelation1}
            handleInputChange={handleChange}
          />
        </div>
      </div>
      <div className="col-span-3">
        <p className="text-xs text-black/70">Emergency Contact 2</p>
        <div className="flex flex-col md:grid md:grid-cols-3 gap-8 p-6 rounded-md border border-black/20 mt-2">
          <TextInputField
            name={"ICEName2"}
            labelText={"Name"}
            value={editProfile?.ICEName2}
            handleInputChange={handleChange}
          />
          <TextInputField
            name={"ICENumber2"}
            labelText={"Contact Number"}
            value={editProfile?.ICENumber2}
            handleInputChange={handleChange}
          />
          <TextInputField
            name={"ICERelation2"}
            labelText={"Relation"}
            value={editProfile?.ICERelation2}
            handleInputChange={handleChange}
          />
        </div>
      </div>
      {/* <div className="col-span-3">
        <p className="text-[10px] text-black/60 font-medium mb-2">
          Aadhar Card Upload
        </p>
        <FileUploadComponent
          onUpload={(file) => onUpload(file, "aadharCardUrl")}
        />
      </div>
      <div className="col-span-3">
        <p className="text-[10px] text-black/60 font-medium mb-2">
          Driving License Upload
        </p>
        <FileUploadComponent
          onUpload={(file) => onUpload(file, "drivingLicenceUrl")}
        />
      </div>
      <div></div> */}
      <button
        onClick={handleSubmitProfile}
        className="text-sm py-3 px-5 bg-primary text-white rounded-lg"
      >
        Save Profile
      </button>
    </>
  );
};

const AddressDetailsDisplay = ({ profile }) => {
  return (
    <>
      <DisplayField
        style={"col-span-3"}
        title={"Address Line 1"}
        value={profile?.firstName || "--"}
      />
      <DisplayField
        style={"col-span-3"}
        title={"Address Line 2"}
        value={profile?.middleName || "--"}
      />
      <DisplayField
        style={"col-span-3"}
        title={"Address Line 3"}
        value={profile?.lastName || "--"}
      />
      <DisplayField style={"col-span-3"} title={"Landmark"} value={profile?.mobile || "--"} />
      <DisplayField title={"City"} value={profile?.email || "--"} />
      <DisplayField title={"state"} value={profile?.gender || "--"} />
      <DisplayField title={"pincode"} value={profile?.dob || "--"} />
    </>
  );
};

const AddressDetailsEdit = () => {
  return (
    <>
      <div className="col-span-3">
        <TextInputField labelText={"Address Line 1"} />
      </div>
      <div className="col-span-3">
        <TextInputField labelText={"Address Line 2"} />
      </div>
      <div className="col-span-3">
        <TextInputField labelText={"Address Line 3"} />
      </div>
      <div className="col-span-3">
        <TextInputField labelText={"Landmark"} />
      </div>
      <TextInputField labelText={"City"} />
      <TextInputField labelText={"State"} />
      <TextInputField labelText={"Pincode"} />
    </>
  );
};

const DisplayField = ({ title, value, style }) => {
  return (
    <div className={style}>
      <p className="text-[10px] text-black/80">{title}</p>
      <p className="py-2 font-medium text-sm border-b border-black/20">{value}</p>
    </div>
  );
};

export default Profile;
