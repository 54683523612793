import React, { useCallback, useEffect, useState } from "react";
import NavBar from "../../../components/Universal/NavBar";
import Header from "../../../components/Universal/Header";
import Tab from "../../../components/Common/Tabs/Tab";
import UpcomingTrips from "../../../components/Common/Sidebar/QuickBookings/UpcomingTrips";
import TableImg from "../../../assets/images/tableImg.png";
import DynamicTable from "../../../components/Universal/DynamicTable";
import ReactModal from "react-modal";
import { customStyles, todayTripsHeaders } from "../../../constants/Constant";
import TextField from "../../../components/Common/InputFields/TextField";
import ProfileCircle from "../../../assets/icons/profilecircle.svg";
import MarkComplete from "../../../assets/images/Mark Complete (1).png";
import wrong from "../../../assets/images/wrong.png";
import Omni from "../../../assets/images/Omni.png";
import Button from "../../../components/Common/Buttons/Button";
import driverimage from "../../../assets/images/driverimage.png";
import { formattedDate } from "../../../utils/utilsHelperFunctions";
import { useDispatch } from "react-redux";
import {
  readDriversLocationsAction,
  readTodayTripAction,
  readTripAction,
  readVehicleAction,
} from "../../../store/Service/ServiceAction";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import MapModel from "../../../components/Universal/MapModel";
import { useNavigate } from "react-router-dom";
import { useSocket } from "../../../components/Common/SocketContext";
import { addDays } from "date-fns";

const Trips = ({ role }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const socket = useSocket();
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_MAP_API_KEY,
  });
  const [activeTab, setActiveTab] = useState(1);

  const [showProfileModal, setShowProfileModal] = useState(false);
  const [showMapsModal, setShowMapsModal] = useState(false);
  const [tripData, setTripData] = useState([]);
  const [tripAllData, setTripAllData] = useState([]);
  const [upcomingTripData, setUpcomingTripData] = useState([]);
  const [abandonTripData, setAbandonTripData] = useState([]);
  const [typeOfTrip, setTypeOfTrip] = useState("All");
  const [currentTrip, setCurrentTrip] = useState({});
  const [currentTripId, setCurrentTripId] = useState("");
  const [locationUpated, setLocationUpdated] = useState(true);
  const [driverLocationUpated, setDriverLocationUpdated] = useState(false);
  const [availableDriversDetail, setAvailableDriversDetail] = useState([]);
  const [filterRange, setFilterRange] = useState([
    {
      startDate: new Date(new Date().setHours(0, 0, 0)),
      endDate: new Date(new Date().setHours(23, 59, 59)),
      key: "selection",
    },
  ]);
  const [allVehicleData, setAllVehicleData] = useState([]);
  const [addOnDetailsList, setAddOnDetailsList] = useState([]);

  // Check if there is data in the features' addonObjId arrays
  const checkFeaturesAvailable = (item) => {
    return (
      item.features &&
      item.features.some((feature) => feature.addonObjId && feature.addonObjId.length > 0)
    );
  };

  useEffect(() => {
    if (currentTripId) {
      dispatch(
        readTripAction({
          apiPayloadRequest: {
            _id: currentTripId,
          },
          callback: (res) => {
            if (res.type === 1) {
              const data = res?.data[0];
              setCurrentTrip(data);
              let currentVehicleData = allVehicleData?.find(
                (v) => v?._id === data?.vehicleObjId?.[0]?._id
              );
              if (currentVehicleData) {
                let featuresList = checkFeaturesAvailable(currentVehicleData)
                  ? currentVehicleData?.features?.map((v) => v?.addonObjId[0])
                  : [];
                if (featuresList?.length > 0) {
                  if (data?.addons?.length > 0) {
                    featuresList = featuresList?.map((d) => {
                      const availableAddon = data?.addons?.find((v) => v?._id === d?._id);
                      if (availableAddon) {
                        return { ...d, quantity: Number(availableAddon?.count ?? 0) };
                      }
                      return d;
                    });
                    setAddOnDetailsList(featuresList);
                  }
                }
              }
            }
          },
        })
      );
    }
  }, [currentTripId]);

  const handleTabChange = (data) => {
    setActiveTab(data);
  };

  const handleClickBook = (id) => {
    navigate(`/dashboard/${id}`);
  };

  const containerStyle = {
    width: "100%",
    height: "100%",
    borderRadius: "10px",
  };

  useEffect(() => {
    dispatch(
      readTodayTripAction({
        apiPayloadRequest: {
          startDate: filterRange[0]?.startDate,
          endDate: filterRange[0]?.endDate,
        },
        callback: (res) => {
          if (res.type === 1) {
            setTripAllData(res?.data?.filter((item) => item?.status !== "0"));
          }
        },
      })
    );
    dispatch(
      readTodayTripAction({
        apiPayloadRequest: {
          startDate: new Date(),
          endDate: addDays(new Date(), 1),
        },
        callback: (res) => {
          if (res.type === 1) {
            setUpcomingTripData(res?.data?.filter((item) => item?.status !== "0"));
          }
        },
      })
    );
    dispatch(
      readTodayTripAction({
        apiPayloadRequest: {
          status: "0",
          startDate: filterRange[0]?.startDate,
          endDate: filterRange[0]?.endDate,
        },
        callback: (res) => {
          if (res.type === 1) {
            setAbandonTripData(res?.data);
          }
        },
      })
    );
  }, [filterRange]);

  useEffect(() => {
    dispatch(
      readVehicleAction({
        callback: (res) => {
          if (res?.type === 1) {
            setAllVehicleData(res?.data);
          }
        },
      })
    );
  }, []);

  useEffect(() => {
    setTripData(tripAllData?.filter((d) => d?.typeofTrip === typeOfTrip || typeOfTrip === "All"));
  }, [tripAllData, typeOfTrip]);

  useEffect(() => {
    if (role) {
      dispatch(
        readDriversLocationsAction({
          callback: (res) => {
            if (res?.type === 1) {
              const data = res?.data
                // ?.filter((i) => i?.coords)
                .map((item) => ({
                  ...item,
                  isOnline: false,
                  coords: item?.user?.lastLocation?.coords
                    ? item?.user?.lastLocation?.coords
                    : {
                        latitude: 17.5145267,
                        longitude: 78.3873372,
                        accuracy: 15.8,
                        speed: -1,
                        speed_accuracy: 1.5,
                        heading: -1,
                        heading_accuracy: 45,
                        altitude: 538,
                        ellipsoidal_altitude: 538,
                        altitude_accuracy: 2,
                        age: 2761,
                      },
                }));

              setAvailableDriversDetail(data);
            }
          },
        })
      );
    }
  }, [role]);

  const handleDriverDetails = (data) => {
    // alert("Drivers Online");
    if (availableDriversDetail?.length > 0) {
      let tempDriverDetails = [...availableDriversDetail];
      data?.forEach((userAuthId) => {
        const currentDataIndex = tempDriverDetails?.findIndex(
          (item) => item?.user?.userAuthId === userAuthId
        );
        if (currentDataIndex !== -1) tempDriverDetails[currentDataIndex].isOnline = true;
      });
      setAvailableDriversDetail(tempDriverDetails);
    }
    //  else {
    //   fetchDriversOnlineFromAPI(data);
    // }
  };

  const handleDriverOffline = (driver) => {
    if (driver?.length > 0 && availableDriversDetail?.length > 0) {
      const updatedData = [...availableDriversDetail];
      const getIndex = updatedData?.findIndex((item) => item?.user?.userAuthId === driver[0]);
      if (getIndex !== -1) updatedData[getIndex].isOnline = false;
      setAvailableDriversDetail(updatedData || []);
    }
  };

  const handleDriverDetailsUpdate = (newData) => {
    if (newData) {
      setAvailableDriversDetail((prev) => {
        if (prev?.length > 0) {
          const currentDataIndex = prev?.findIndex(
            (item) => item?.user?.userAuthId === newData?.userAuthId
          );

          if (currentDataIndex !== -1) {
            const newDataValues = [...prev];
            newDataValues[currentDataIndex].coords = newData?.location?.coords;
            return newDataValues;
          }

          return prev;
        }
      });
    }
  };

  const handleDriverOnline = (driver) => {
    if (driver?.length > 0 && availableDriversDetail?.length > 0) {
      console.log("driverSocket : Online", driver);
      const updatedData = [...availableDriversDetail];
      const getIndex = updatedData?.findIndex((item) => item?.user?.userAuthId === driver[0]);
      if (getIndex !== -1) updatedData[getIndex].isOnline = true;
      setAvailableDriversDetail(updatedData || []);
    }
  };

  useEffect(() => {
    if (socket?.connected && locationUpated) {
      console.log("driversonline : Client requests data");
      socket.emit("driversonline", { message: "Client requests data" });
    }

    if (socket?.connected) {
      socket.on("driversonline", (data) => {
        console.log("driversonline :", data);
        handleDriverDetails(data);
        setLocationUpdated(false);
      });

      socket.on("useroffline", (data) => {
        handleDriverOffline(data);
        console.log(data, "User Offline");
      });

      socket.on("useronline", (data) => {
        handleDriverOnline(data);
        console.log(data, "User Online");
      });

      socket.on("newlocation", (data) => {
        handleDriverDetailsUpdate(data);
        console.log(data, "User newlocation");
      });

      // socket.on("useroffline", (data) => {
      //   handleDriverOffline(data);
      //   console.log(data, "User Offline");
      // });
    }
  }, [socket, locationUpated]);

  const DistanceFromDrop = (driver, trip = null) => {
    if (!driver || !trip) return "";
    return new Promise((resolve) => {
      const origin = {
        lat: parseFloat(driver?.lastLocation?.coords?.latitude),
        lng: parseFloat(driver?.lastLocation?.coords?.longitude),
      };
      const destination = trip
        ? { lat: parseFloat(trip?.toLatitude), lng: parseFloat(trip?.toLongitude) }
        : { lat: parseFloat(currentTrip?.toLatitude), lng: parseFloat(currentTrip?.toLongitude) };
      const service = new window.google.maps.DistanceMatrixService();
      if (service) {
        service.getDistanceMatrix(
          {
            origins: [origin],
            destinations: [destination],
            travelMode: "DRIVING",
          },
          (result, status) => {
            if (status === window.google.maps.DirectionsStatus.OK) {
              const distanceText = result.rows[0].elements[0]?.distance?.text;
              const durationText = result.rows[0].elements[0]?.duration?.text;
              const distanceDuration = `${distanceText} ${durationText}`;
              resolve(distanceDuration);
            }
          }
        );
      }
    });
  };

  const getDriverLastLocation = () => {
    if (currentTrip?.drivers?.length > 0 && availableDriversDetail?.length > 0) {
      const getLatestDriverDetails = availableDriversDetail?.filter(
        (v) => v?.user?.userAuthId === currentTrip?.drivers[0]?.driverAuthId
      );
      if (getLatestDriverDetails?.length > 0) {
        let tempTrip = currentTrip;
        tempTrip.driverDetails.coords = getLatestDriverDetails[0]?.coords;
        const checkUpdated = tempTrip?.driverDetails?.coords?.locationUpdated;
        tempTrip.driverDetails.coords.locationUpdated = checkUpdated
          ? parseInt(checkUpdated) + 1
          : 1;
        setCurrentTrip(tempTrip);
      }
    }
    setDriverLocationUpdated((prev) => !prev);
  };

  return (
    <div className="flex bg-zinc-200">
      <NavBar active={"Trips"} role={role} />
      <div className="flex w-full bg-pinl-400">
        <div className="w-[75%]">
          <Header title={"Today's Trips"} />
          <div className="flex h-[88vh] w-full pl-3 pr-4">
            <div className="rounded-xl mb-1 w-full bg-white p-6">
              <DynamicTable
                title={"Today's Trips"}
                data={tripData}
                size={7}
                headers={todayTripsHeaders}
                search={true}
                Dropdown={true}
                showDateFilter={true}
                dateFilter={filterRange}
                handleDateFilter={setFilterRange}
                dropDownChangeHandler={setTypeOfTrip}
                width={"w-42"}
                heightDesktop={"h-[75%]"}
                nthChildWidth={
                  " [&>*:nth-child(3)]:w-32 [&>*:nth-child(2)]:w-32 [&>*:nth-child(4)]:w-40 [&>*:nth-child(4)]:mr-8"
                }
                nthChildStyle={""}
                showEditIcon={false}
                showDeleteIcon={false}
                showTrackIcon={true}
                handleTrackClick={async (row) => {
                  console.log("row", row);
                  let driv = availableDriversDetail;
                  const currentDriver =
                    driv?.length > 0 && row?.drivers?.length > 0
                      ? driv.filter((v) => v?.user?.userAuthId === row?.drivers[0]?.userAuthId)
                      : [];

                  console.log("currentDriver", currentDriver);
                  // const getDistance = await DistanceFromDrop(currentDriver[0], row);
                  // curr.distance = getDistance;
                  if (currentDriver?.length > 0) {
                    // let curr = driv?.length > 0 ? currentDriver[0].lastLocation?.coords : "";
                    row.driverDetails = currentDriver?.length > 0 ? currentDriver[0] : {};
                    // row.driverCurrentPosition = curr;
                  }
                  // setCurrentTrip(row);
                  setCurrentTripId(row?._id);
                  setTimeout(() => {
                    setShowMapsModal((preState) => !preState);
                  }, 200);
                }}
                handleToggleClick={(row, isActive) => {
                  console.log(isActive, row);
                }}
                handlePencilClick={(row) => {
                  handleClickBook(row?._id);
                }}
              />
            </div>
          </div>
        </div>
        <div className="w-[30%] bg-white p-4  flex flex-col gap-2 overflow-auto h-[100vh]">
          <div className="mb-1">
            {" "}
            <Tab
              Tab1={"Upcoming trips"}
              Tab2={"Abandon Trips"}
              handleTabClick={handleTabChange}
              activeTab={activeTab}
            />
          </div>
          {activeTab === 1 ? (
            <div className="flex flex-col gap-2">
              {upcomingTripData?.length > 0 &&
                upcomingTripData.map((trip) => (
                  <UpcomingTrips
                    price={trip?.price}
                    active={true}
                    buttonName={"Assign Driver"}
                    lineWidth1={"w-0"}
                    lineWidth2={"w-0"}
                    edit1={"10:00 AM"}
                    edit2={"11:37 PM"}
                    background1={"bg-zinc-200"}
                    background2={"bg-zinc-200"}
                    tripDate={trip?.dateandTimeofTrip}
                    value1={trip?.fromAddress ? trip?.fromAddress : "-"}
                    value2={trip?.toAddress ? trip?.toAddress : "-"}
                    inputStyle1={"bg-zinc-200"}
                    inputStyle2={"bg-zinc-200"}
                  />
                ))}
            </div>
          ) : (
            <div className="flex flex-col gap-2">
              {abandonTripData.length > 0 &&
                abandonTripData.map((trip) => (
                  <UpcomingTrips
                    id={trip._id}
                    price={null}
                    active={true}
                    buttonName={"Book Ambulance"}
                    lineWidth1={"w-0"}
                    lineWidth2={"w-0"}
                    edit1={"10:00 AM"}
                    edit2={"11:37 PM"}
                    phoneStyle={"hidden"}
                    onClick={handleClickBook}
                    background1={"bg-zinc-200"}
                    background2={"bg-zinc-200"}
                    value1={
                      trip?.fromAddress
                        ? `${trip?.fromAddress} ${
                            trip?.fromPincode ? ", " + trip?.fromPincode : ""
                          }`
                        : "-"
                    }
                    value2={
                      trip?.toAddress
                        ? `${trip?.toAddress} ${trip?.toPincode ? ", " + trip?.toPincode : ""}`
                        : "-"
                    }
                    inputStyle1={"bg-zinc-200"}
                    inputStyle2={"bg-zinc-200"}
                  />
                ))}
            </div>
          )}
        </div>
      </div>

      {showMapsModal && (
        <MapModel
          showMapsModal={showMapsModal}
          setShowMapsModal={setShowMapsModal}
          customStyles={customStyles}
          containerStyle={containerStyle}
          currentTrip={currentTrip}
          availableDriversDetail={availableDriversDetail}
          getLastLocation={getDriverLastLocation}
          driverLocationUpated={driverLocationUpated}
          addOnDetailsList={addOnDetailsList}
        />
      )}
    </div>
  );
};

export default Trips;
