export function dateDiff(date) {
  if (!date) return "0";
  const date1 = new Date(date);
  const date2 = new Date();
  const diffTime = Math.abs(date2 - date1);
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
}

export function formattedDate(date) {
  const moment = require("moment");
  if (!date) return "";
  const convertedDate = moment(date).format("DD/MM/YYYY HH:MM A");
  return convertedDate;
}

export const getTripStatus = (status) => {
  if (status === "0") {
    return { bgColor: "140, 140, 118", text: "Abondon Trip" };
  } else if (status === "1") {
    return { bgColor: "140, 140, 118", text: "Trip Estimate Given" };
  } else if (status === "3") {
    return { bgColor: "140, 140, 118", text: "Driver Assigned" };
  } else if (status === "8") {
    return { bgColor: "199, 199, 24", text: "Driver Accepted" };
  } else if (status === "9") {
    return { bgColor: "199, 199, 24", text: "Driver Rejected" };
  } else if (status === "4") {
    return { bgColor: "199, 173, 24", text: "Driver to Pickup point" };
  } else if (status === "6") {
    return { bgColor: "199, 120, 24", text: "Trip Started" };
  } else if (status === "7") {
    return { bgColor: "41, 199, 24", text: "Trip Completed" };
  } else if (status === "10") {
    return { bgColor: "41, 199, 24", text: "Trip Canceled" };
  }
};

export const getTripStatusOld = (status) => {
  if (status === "0") {
    return { bgColor: "140, 140, 118", text: "Abondon Trip" };
  } else if (status === "1") {
    return { bgColor: "140, 140, 118", text: "Vehicle Type Not Assigned" };
  } else if (status === "2") {
    return { bgColor: "140, 140, 118", text: "Cost Estimated" };
  } else if (status === "3") {
    return { bgColor: "199, 199, 24", text: "Assigned to Driver" };
  } else if (status === "4") {
    return { bgColor: "199, 173, 24", text: "Driver Started to Pickup" };
  } else if (status === "6") {
    return { bgColor: "199, 120, 24", text: "Trip Started" };
  } else if (status === "7") {
    return { bgColor: "41, 199, 24", text: "Trip Completed" };
  }
};

export const compareDate = (date1, date2) => {
  const d1 = new Date(date1?.getFullYear(), date1?.getMonth(), date1?.getDate());
  const d2 = new Date(date2?.getFullYear(), date2?.getMonth(), date2?.getDate());

  if (d1.getTime() === d2.getTime()) {
    return 0; // Dates are equal
  } else if (d1 < d2) {
    return -1; // date1 is before date2
  } else {
    return 1; // date1 is after date2
  }
};
