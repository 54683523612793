import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import wrong from "../../../../assets/images/wrong.png";
import Line4 from "../../../../assets/images/Line4.png";
import TextInputField from "../../../../components/Common/InputFields/TextInputField";
import Button from "../../../../components/Common/Buttons/Button";
import { customStyles, customContentStyles } from "../../../../constants/Constant";
import InputDropdown from "../../../../components/Common/InputFields/InputDropdown";
import { useDispatch } from "react-redux";
import {
  readAgentAction,
  readRegionAction,
  saveAgentAction,
} from "../../../../store/Service/ServiceAction";
import Toastify from "../../../../components/Universal/Toastify";

const AddAgentModal = ({ id, closeModal, isOpen, setIsOpen, getAgentsList }) => {
  const dispatch = useDispatch();

  const initialAgentValues = {
    firstName: "",
    phone: "",
    email: "",
    password: "",
  };

  const [agentValues, setAgentValues] = useState(initialAgentValues);
  const [regionUpdated, setRegionUpdated] = useState(false);
  const [regionOptions, setRegionOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState();
  const [currentAgent, setCurrentAgent] = useState(null);

  useEffect(() => {
    if (!regionUpdated) {
      dispatch(
        readRegionAction({
          callback: (res) => {
            if (res?.type === 1) {
              const options = res?.data?.map((item) => ({
                value: item?._id,
                label: item?.region_name,
              }));
              setRegionOptions(options);
              setRegionUpdated(true);
            }
          },
        })
      );
    }
  }, [regionUpdated]);

  useEffect(() => {
    if (id !== "") {
      dispatch(
        readAgentAction({
          apiPayloadRequest: {
            _id: id,
          },
          callback: (res) => {
            if (res?.type === 1) {
              const data = res?.data[0];
              setCurrentAgent(data);
              setAgentValues({
                firstName: data?.firstName || "",
                phone: data?.mobile || "",
                email: data?.email || "",
              });

              if (data?.regionObjId?.length > 0) {
                const regionID = data?.regionObjId[0]?._id;
                setSelectedOption(regionID);
              }
            }
          },
        })
      );
    } else {
      setAgentValues(initialAgentValues);
      setCurrentAgent(null);
    }
  }, [id]);

  const handleChange = (event) => {
    const { name, value } = event.target;

    setAgentValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleResetInputs = async () => {
    setAgentValues(initialAgentValues);
    setRegionUpdated(false);
    setSelectedOption("");
    setCurrentAgent(null);
  };

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleSubmit = () => {
    if (id !== "") {
      //update agent
      return false;
    } else {
      dispatch(
        saveAgentAction({
          apiPayloadRequest: {
            firstName: agentValues?.firstName,
            email: agentValues?.email,
            phone: "+91" + String(agentValues?.phone),
            password: agentValues?.password,
            regionObjId: selectedOption,
          },
          callback: (res) => {
            if (res?.type === 1) {
              getAgentsList();
              Toastify("Agent Saved Successfully!", 1);
              handleResetInputs();
              closeModal();
            } else if (res?.type === "2") {
              Toastify(res?.message, 2);
              if (res?.data?.message) {
                Toastify(res?.data?.message, 2);
              }
              // setAgentValues(initialAgentValues);
              setSelectedOption();
            }
          },
        })
      );
    }
  };

  const cancelPopup = () => {
    handleResetInputs();
    closeModal();
  };

  return (
    <div>
      <ReactModal
        isOpen={isOpen}
        style={{
          ...customStyles,
          ...customContentStyles,
        }}
        onRequestClose={cancelPopup}
      >
        <div className="w-[45vw] flex flex-col justify-between bg-white border rounded-lg overflow-hidden">
          <div>
            <div className="p-5 px-3  items-center flex justify-between">
              <div>
                <h1 className="text-lg font-bold">
                  {id === "" ? "Add New Agent" : "Update Agent"}
                </h1>
              </div>
              <div className="text-lg px-3 cursor-pointer" onClick={cancelPopup}>
                <img src={wrong} />
              </div>
            </div>
            <img className="w-full  h-[1px]" src={Line4} />
            <div className="m-5 w-[42vw] flex flex-col gap-5">
              <div className="w-full flex flex-row gap-3 items-center">
                <div className="w-full">
                  <TextInputField
                    name="firstName"
                    handleChange={handleChange}
                    value={agentValues.firstName}
                    label="First Name"
                    placeholder="Enter First Name"
                  />
                </div>
                <div className="w-full">
                  <TextInputField
                    name="phone"
                    handleChange={handleChange}
                    value={agentValues.phone}
                    label="Phone Number"
                    placeholder="Enter Phone Number"
                  />
                </div>
              </div>

              <div className="w-full flex flex-row gap-3 items-center">
                <div className="w-1/2">
                  {/* <TextInputField label=' Email' placeholder='Enter email id' /> */}
                  <TextInputField
                    name="email"
                    handleChange={handleChange}
                    value={agentValues.email}
                    label="Email"
                    placeholder="Enter Email"
                  />
                </div>
                <div className="w-1/2">
                  <InputDropdown
                    label="Select Region"
                    styles="border-2"
                    options={regionOptions}
                    value={selectedOption}
                    onChange={handleSelectChange}
                  />
                </div>
              </div>

              {id === "" && (
                <div className="w-full flex flex-row gap-3 items-center">
                  <div className="w-1/2">
                    <TextInputField
                      name="password"
                      handleChange={handleChange}
                      value={agentValues.password}
                      label="Password"
                      placeholder="Enter Password"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="px-3 pt-3 pb-2 mb-5 shadow-2xl flex mx-5 justify-end">
            <div className="flex items-center">
              <Button
                onClick={cancelPopup}
                label="Cancel"
                background={"bg-slate-300 px-6"}
                textcolor={"text-slate-600"}
              />
              <Button
                onClick={handleSubmit}
                label={id === "" ? "Add" : "Update"}
                background={"bg-[#F15A2A] px-8"}
                textcolor={"text-white"}
              />
            </div>
          </div>
        </div>
      </ReactModal>
    </div>
  );
};

export default AddAgentModal;
