import React from "react";
import { IoShareSocialSharp } from "react-icons/io5";
import { BiSolidSpreadsheet } from "react-icons/bi";
import { IoPersonCircleOutline } from "react-icons/io5";
import { NavLink, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/productLogo.png";
import { FaAmbulance, FaWallet } from "react-icons/fa";
import { useSelector } from "react-redux";

const UserHeader = ({ activeTab = "", setActiveTab = () => {} }) => {
  const { userDetails } = useSelector((state) => state.userProfile);
  console.log("userDetails55", userDetails);

  const tabs = [
    {
      name: "Trip",
      route: "/trip",
    },
  ];

  const navigate = useNavigate();
  return (
    <>
      <div className="absolute w-full px-[5%] md:flex hidden justify-between border font-semibold bg-white z-10">
        <div className="flex items-center gap-8">
          <NavLink
            to="/"
            style={{ backgroundImage: `url(${Logo})` }}
            className="w-14 aspect-square bg-contain bg-center bg-no-repeat"
          ></NavLink>
          <HeaderTabs
            tabs={tabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            navigate={navigate}
          />
        </div>
        <div className="flex items-center gap-6 text-sm">
          <NavLink
            to={"/myTrips"}
            className={`${
              activeTab === "myTrips" ? "border-b-4 border-secondary font-semibold" : ""
            } flex items-center gap-1 h-full`}
          >
            <BiSolidSpreadsheet size={18} />
            Trips
          </NavLink>
          <NavLink
            to={"/profile"}
            className={`${
              activeTab === "profile" ? "border-b-4 border-secondary font-semibold" : ""
            } flex items-center h-full`}
          >
            <img
              src={userDetails?.profilePicUrl}
              className="w-9 rounded-full aspect-square bg-secondary bg-no-repeat bg-cover"
            />
          </NavLink>
        </div>
      </div>
      <div className="fixed w-full py-[12px] px-[5%] flex md:hidden justify-around border-t font-semibold bg-white z-50 bottom-0 text-[10px] text-black/60">
        <button
          onClick={() => navigate("/trip")}
          className={`${activeTab === 0 && "text-primary"} flex flex-col gap-1 items-center`}
        >
          <FaAmbulance size={15} /> Trip
        </button>
        <button
          onClick={() => navigate("/wallet")}
          className={`${activeTab === "wallet" && "text-primary"} flex flex-col gap-1 items-center`}
        >
          <FaWallet size={15} /> Wallet
        </button>
        <button
          onClick={() => navigate("/myTrips")}
          className={`${
            activeTab === "myTrips" && "text-primary"
          } flex flex-col gap-1 items-center`}
        >
          <BiSolidSpreadsheet size={15} /> My Trips
        </button>
        <button
          onClick={() => navigate("/profile")}
          className={`${
            activeTab === "profile" && "text-primary"
          } flex flex-col gap-1 items-center`}
        >
          <IoPersonCircleOutline size={16} /> Profile
        </button>
      </div>
    </>
  );
};

const HeaderTabs = ({ tabs, activeTab = 0, navigate }) => {
  return (
    <div className="text-sm h-full">
      {tabs?.map((tab, index) => (
        <button
          key={"ta-item" + index}
          onClick={() => navigate(tab.route)}
          className={`${
            activeTab === index ? "border-b-4 border-secondary font-semibold" : ""
          } px-3 h-full`}
        >
          {tab?.name}
        </button>
      ))}
    </div>
  );
};

export default UserHeader;
