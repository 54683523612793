import * as actionTypes from "../ActionTypes";
import { put, call, takeLatest, takeEvery } from "redux-saga/effects";
import * as api from "./ServiceAPI";
import { actionUpdateGlobalLoaderSagaAction } from "../GlobalLoader/GlobalLoaderAction";

function* apiRequestSaga(apiFunction, action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(apiFunction, payload);
    if (action?.payload?.callback) action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveRegionRequestSaga(action) {
  yield* apiRequestSaga(api.saveRegion, action);
}

function* savePincodeRegionRequestSaga(action) {
  yield* apiRequestSaga(api.savePincodeRegion, action);
}

function* readRegionRequestSaga(action) {
  yield* apiRequestSaga(api.readRegion, action);
}

function* deletePincodeRegionRequestSaga(action) {
  yield* apiRequestSaga(api.deletePincodeRegion, action);
}

function* updateRegionRequestSaga(action) {
  yield* apiRequestSaga(api.updateRegion, action);
}

function* deleteRegionRequestSaga(action) {
  yield* apiRequestSaga(api.deleteRegion, action);
}

function* saveAddonRequestSaga(action) {
  yield* apiRequestSaga(api.saveAddon, action);
}

function* deleteAddonRequestSaga(action) {
  yield* apiRequestSaga(api.deleteAddon, action);
}

function* updateAddonRequestSaga(action) {
  yield* apiRequestSaga(api.updateAddon, action);
}

function* readAddonRequestSaga(action) {
  yield* apiRequestSaga(api.readAddon, action);
}

function* saveVehicleRequestSaga(action) {
  yield* apiRequestSaga(api.saveVehicle, action);
}

function* saveAddonVehicleRequestSaga(action) {
  yield* apiRequestSaga(api.saveAddonVehicle, action);
}

function* saveUserVehicleTypeRequestSaga(action) {
  yield* apiRequestSaga(api.saveUserVehicleType, action);
}

function* deleteUserVehicleTypeRequestSaga(action) {
  yield* apiRequestSaga(api.deleteUserVehicleType, action);
}

function* readVehicleRequestSaga(action) {
  yield* apiRequestSaga(api.readVehicle, action);
}

function* deleteVehicleRequestSaga(action) {
  yield* apiRequestSaga(api.deleteVehicle, action);
}

function* deleteAddonVehicleRequestSaga(action) {
  yield* apiRequestSaga(api.deleteAddonVehicle, action);
}

function* updateVehicleRequestSaga(action) {
  yield* apiRequestSaga(api.updateVehicle, action);
}

function* saveTarrifRequestSaga(action) {
  yield* apiRequestSaga(api.saveTarrif, action);
}

function* saveAddonTarrifRequestSaga(action) {
  yield* apiRequestSaga(api.saveAddonTarrif, action);
}

function* readTarrifRequestSaga(action) {
  yield* apiRequestSaga(api.readTarrif, action);
}

function* readTripTarrifRequestSaga(action) {
  yield* apiRequestSaga(api.readTripTarrif, action);
}

function* deleteTarrifRequestSaga(action) {
  yield* apiRequestSaga(api.deleteTarrif, action);
}

function* updateTarrifRequestSaga(action) {
  yield* apiRequestSaga(api.updateTarrif, action);
}

function* updateAddonTarrifRequestSaga(action) {
  yield* apiRequestSaga(api.updateAddonTarrif, action);
}

function* saveDiscountRequestSaga(action) {
  yield* apiRequestSaga(api.saveDiscount, action);
}

function* readDiscountRequestSaga(action) {
  yield* apiRequestSaga(api.readDiscount, action);
}

function* deleteDiscountRequestSaga(action) {
  yield* apiRequestSaga(api.deleteDiscount, action);
}

function* updateDiscountRequestSaga(action) {
  yield* apiRequestSaga(api.updateDiscount, action);
}

function* deactivateDiscountRequestSaga(action) {
  yield* apiRequestSaga(api.deactivateDiscount, action);
}

function* readDriversLocationsRequestSaga(action) {
  yield* apiRequestSaga(api.readDriversLocations, action);
}

function* assignTripToDriverRequestSaga(action) {
  yield* apiRequestSaga(api.assignTripToDriver, action);
}

function* getDriverStatusRequestSaga(action) {
  yield* apiRequestSaga(api.getDriverStatus, action);
}

function* readDriverRequestSaga(action) {
  yield* apiRequestSaga(api.readDriver, action);
}

function* deactivateDriverRequestSaga(action) {
  yield* apiRequestSaga(api.deactivateDriver, action);
}

function* deleteDriverRequestSaga(action) {
  yield* apiRequestSaga(api.deleteDriver, action);
}

function* readAgentRequestSaga(action) {
  yield* apiRequestSaga(api.readAgent, action);
}

function* deactivateAgentRequestSaga(action) {
  yield* apiRequestSaga(api.deactivateAgent, action);
}

function* saveAgentRequestSaga(action) {
  yield* apiRequestSaga(api.saveAgent, action);
}

function* readTypeOfUsersRequestSaga(action) {
  yield* apiRequestSaga(api.readTypeOfUsers, action);
}

function* SaveTripRequestSaga(action) {
  yield* apiRequestSaga(api.saveTrip, action);
}

function* UpdateTripRequestSaga(action) {
  yield* apiRequestSaga(api.updateTrip, action);
}

function* readTripRequestSaga(action) {
  yield* apiRequestSaga(api.readTrip, action);
}

function* readTodayTripRequestSaga(action) {
  yield* apiRequestSaga(api.readTodayTrip, action);
}

function* readTripAnalyticsRequestSaga(action) {
  yield* apiRequestSaga(api.readAnalyticsTrip, action);
}

function* SaveTripAddonRequestSaga(action) {
  yield* apiRequestSaga(api.saveTripAdddon, action);
}

function* UpdateTripAddonRequestSaga(action) {
  yield* apiRequestSaga(api.updateTripAdddon, action);
}

function* SaveTripDriverRequestSaga(action) {
  yield* apiRequestSaga(api.saveTripDriver, action);
}

function* UpdateTripDriverRequestSaga(action) {
  yield* apiRequestSaga(api.updateTripDriver, action);
}

function* CheckDriverBusyRequestSaga(action) {
  yield* apiRequestSaga(api.checkDriverBusy, action);
}

function* SearchAddressRequestSaga(action) {
  yield* apiRequestSaga(api.searchAddress, action);
}

export default function* ServiceWatcherSaga() {
  yield takeLatest(actionTypes.SAVE_REGION_REQUEST, saveRegionRequestSaga);

  yield takeLatest(actionTypes.SAVE_PINCODE_REGION_REQUEST, savePincodeRegionRequestSaga);

  yield takeLatest(actionTypes.READ_REGION_REQUEST, readRegionRequestSaga);

  yield takeLatest(actionTypes.DELETE_PINCODE_REGION_REQUEST, deletePincodeRegionRequestSaga);

  yield takeLatest(actionTypes.UPDATE_REGION_REQUEST, updateRegionRequestSaga);

  yield takeLatest(actionTypes.DELETE_REGION_REQUEST, deleteRegionRequestSaga);

  yield takeLatest(actionTypes.SAVE_ADDON_REQUEST, saveAddonRequestSaga);

  yield takeLatest(actionTypes.DELETE_ADDON_REQUEST, deleteAddonRequestSaga);

  yield takeLatest(actionTypes.UPDATE_ADDON_REQUEST, updateAddonRequestSaga);

  yield takeLatest(actionTypes.READ_ADDON_REQUEST, readAddonRequestSaga);

  yield takeLatest(actionTypes.SAVE_VEHICLE_REQUEST, saveVehicleRequestSaga);

  yield takeEvery(actionTypes.SAVE_ADDON_VEHICLE_REQUEST, saveAddonVehicleRequestSaga);

  yield takeEvery(actionTypes.SAVE_USER_VEHICLE_TYPE_REQUEST, saveUserVehicleTypeRequestSaga);

  yield takeEvery(actionTypes.DELETE_USER_VEHICLE_TYPE_REQUEST, deleteUserVehicleTypeRequestSaga);

  yield takeEvery(actionTypes.READ_VEHICLE_REQUEST, readVehicleRequestSaga);

  yield takeLatest(actionTypes.DELETE_VEHICLE_REQUEST, deleteVehicleRequestSaga);

  yield takeLatest(actionTypes.DELETE_ADDON_VEHICLE_REQUEST, deleteAddonVehicleRequestSaga);

  yield takeLatest(actionTypes.UPDATE_VEHICLE_REQUEST, updateVehicleRequestSaga);

  yield takeEvery(actionTypes.SAVE_TARIFF_REQUEST, saveTarrifRequestSaga);

  yield takeEvery(actionTypes.SAVE_ADDON_TARIFF_REQUEST, saveAddonTarrifRequestSaga);

  yield takeLatest(actionTypes.READ_TARIFF_REQUEST, readTarrifRequestSaga);

  yield takeEvery(actionTypes.READ_TRIP_TARIFF_REQUEST, readTripTarrifRequestSaga);

  yield takeLatest(actionTypes.DELETE_TARIFF_REQUEST, deleteTarrifRequestSaga);

  yield takeEvery(actionTypes.UPDATE_TARIFF_REQUEST, updateTarrifRequestSaga);

  yield takeEvery(actionTypes.UPDATE_ADDON_TARIFF_REQUEST, updateAddonTarrifRequestSaga);

  yield takeLatest(actionTypes.SAVE_DISCOUNT_REQUEST, saveDiscountRequestSaga);

  yield takeLatest(actionTypes.READ_DISCOUNT_REQUEST, readDiscountRequestSaga);

  yield takeLatest(actionTypes.DELETE_DISCOUNT_REQUEST, deleteDiscountRequestSaga);

  yield takeLatest(actionTypes.UPDATE_DISCOUNT_REQUEST, updateDiscountRequestSaga);

  yield takeLatest(actionTypes.DEACTIVATE_DISCOUNT_REQUEST, deactivateDiscountRequestSaga);

  yield takeLatest(actionTypes.READ_DRIVERS_LOCATION_REQUEST, readDriversLocationsRequestSaga);

  yield takeLatest(actionTypes.ASSIGN_TRIP_TO_DRIVER_REQUEST, assignTripToDriverRequestSaga);

  yield takeLatest(actionTypes.GET_DRIVER_STATUS_REQUEST, getDriverStatusRequestSaga);

  yield takeEvery(actionTypes.READ_DRIVER_REQUEST, readDriverRequestSaga);

  yield takeLatest(actionTypes.DEACTIVATE_DRIVER_REQUEST, deactivateDriverRequestSaga);

  yield takeLatest(actionTypes.DELETE_DRIVER_REQUEST, deleteDriverRequestSaga);

  yield takeLatest(actionTypes.READ_AGENT_REQUEST, readAgentRequestSaga);

  yield takeLatest(actionTypes.DEACTIVATE_AGENT_REQUEST, deactivateAgentRequestSaga);

  yield takeLatest(actionTypes.SAVE_AGENT_REQUEST, saveAgentRequestSaga);

  yield takeLatest(actionTypes.READ_TYPE_USERS_REQUEST, readTypeOfUsersRequestSaga);

  yield takeLatest(actionTypes.SAVE_TRIP_REQUEST, SaveTripRequestSaga);
  yield takeLatest(actionTypes.UPDATE_TRIP_REQUEST, UpdateTripRequestSaga);
  yield takeEvery(actionTypes.READ_TRIP_REQUEST, readTripRequestSaga);
  yield takeEvery(actionTypes.READ_TODAY_TRIP_REQUEST, readTodayTripRequestSaga);
  yield takeEvery(actionTypes.READ_TRIP_ANALYTICS_REQUEST, readTripAnalyticsRequestSaga);

  yield takeLatest(actionTypes.SAVE_TRIP_ADDON_REQUEST, SaveTripAddonRequestSaga);
  yield takeLatest(actionTypes.UPDATE_TRIP_ADDON_REQUEST, UpdateTripAddonRequestSaga);
  yield takeLatest(actionTypes.SAVE_TRIP_DRIVER_REQUEST, SaveTripDriverRequestSaga);
  yield takeLatest(actionTypes.UPDATE_TRIP_DRIVER_REQUEST, UpdateTripDriverRequestSaga);
  yield takeLatest(actionTypes.CHECK_DRIVER_BUSY_REQUEST, CheckDriverBusyRequestSaga);

  yield takeLatest(actionTypes.SEARCH_ADDRESS_REQUEST, SearchAddressRequestSaga);
}
