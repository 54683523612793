import React from "react";
import QuickBookingLocation from "./QuickBookingLocation";
import Line4 from "../../../../assets/images/Line4.png";
import OutlineButton from "../../Buttons/OutlineButton";
import phone from "../../../../assets/images/phone.png";
import Vector from "../../../../assets/images/Vector.png";
import Vector2 from "../../../../assets/images/Vector2.png";
import { FaRupeeSign } from "react-icons/fa";
import { formattedDate } from "../../../../utils/utilsHelperFunctions";

export default function UpcomingTrips({
  id,
  onClick,
  price,
  phoneStyle,
  buttonName,
  edit1,
  edit2,
  background1,
  background2,
  lineWidth2,
  lineWidth1,
  value1,
  value2,
  tripDate,
  inputStyle1,
  inputStyle2,
}) {
  return (
    <div className=" flex flex-col gap-2 bg-zinc-200 rounded-lg w-full p-3 ">
      <div className="flex gap-2 items-center">
        <img src={Vector} className="h-3" />
        <p className="font-semibold text-sm text-gray-500">{value1}</p>
      </div>
      <div className="flex gap-2 items-center">
        <img src={Vector2} className="h-3" />
        <p className="font-semibold text-sm text-gray-500">{value2}</p>
      </div>
      <div>
        <img className="w-full" src={Line4} />{" "}
      </div>

      <p className="font-medium text-sm flex justify-between">
        <span>Trip Date</span>
        <span className="text-black">{formattedDate(tripDate ?? "")}</span>
      </p>
      <div>
        <img className="w-full" src={Line4} />{" "}
      </div>

      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-row items-center">
          <div className={`p-1.5 bg-slate-300 rounded ${phoneStyle}`}>
            <img className="w-4" src={phone} />
          </div>
          <div>
            <OutlineButton label={buttonName} onClick={() => onClick(id)} />
          </div>
        </div>
        <div className="flex flex-col">
          <div>
            <p className="text-[10px] text-[#555E61] opacity-60">{"Final Cost"}</p>
          </div>
          <div className="text-[12px] font-semibold ml-2 mt-1 mb-1 flex gap-0 items-center">
            <FaRupeeSign size={10} /> {price ?? "-"}
          </div>
        </div>
      </div>
    </div>
  );
}
