import { toast } from 'react-toastify';

const Toastify = (message, type = 2) => {
    switch (type) {
        case 1:
            toast.success(message);
            break;
        case 2:
            toast.error(message);
            break;
      default:
        toast(message);
    }
};

export default Toastify;