import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import wrong from "../../../assets/images/wrong.png";
import Line4 from "../../../assets/images/Line4.png";
import TextInputField from "../../../components/Common/InputFields/TextInputField";
import Button from "../../../components/Common/Buttons/Button";
import { customContentStyles, customStyles } from "../../../constants/Constant";
import TextInput from "../../../components/Common/InputFields/TextInput";
import { uploadAssestsAction } from "../../../store/LoginAndRegister/LoginAndRegisterAction";
import { useDispatch } from "react-redux";
import FileUploadComponent from "../../../components/Common/InputFields/FileUploadComponent";
import {
  readAddonAction,
  saveAddonAction,
  updateAddonAction,
} from "../../../store/Service/ServiceAction";
import Toastify from "../../../components/Universal/Toastify";

const ServiceAddOnmodal = (props) => {
  const dispatch = useDispatch();

  const initialAddOnValues = {
    addOn: "",
    measurement: "",
    descriptions: "",
    icon: "",
  };

  const [addOnValues, setAddOnValues] = useState(initialAddOnValues);
  const [currentAddon, setCurrentAddon] = useState();
  const [errorFields, setErrorFields] = useState(initialAddOnValues);

  const handleChange = (event) => {
    const { name, value } = event.target;

    setAddOnValues((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErrorFields({ ...errorFields, [name]: false });
  };

  const handleResetInputs = async () => {
    setAddOnValues(initialAddOnValues);
    setErrorFields(initialAddOnValues);
    setCurrentAddon("");
  };

  const onUpload = (uploadedFiles) => {
    const file = uploadedFiles[0];

    const formData = new FormData();
    formData.append("file", file);

    dispatch(
      uploadAssestsAction({
        apiPayloadRequest: formData,
        callback: (res) => {
          setAddOnValues((prevInputs) => ({
            ...prevInputs,
            icon: res?.url,
          }));
          setErrorFields({ ...errorFields, icon: false });
        },
      })
    );
  };

  const handleSubmit = () => {
    if (!addOnValues?.addOn) {
      setErrorFields({ ...errorFields, addOn: true });
      return false;
    } else if (!addOnValues?.measurement) {
      setErrorFields({ ...errorFields, measurement: true });
      return false;
    } else if (!addOnValues?.descriptions) {
      setErrorFields({ ...errorFields, descriptions: true });
      return false;
    } else if (!addOnValues?.icon) {
      setErrorFields({ ...errorFields, icon: true });
      return false;
    }
    if (props?.id !== "") {
      if (
        addOnValues?.addOn !== currentAddon?.addOn ||
        addOnValues?.measurement !== currentAddon?.measurement ||
        addOnValues?.descriptions !== currentAddon?.descriptions ||
        addOnValues?.icon !== currentAddon?.icon
      ) {
        dispatch(
          updateAddonAction({
            apiPayloadRequest: {
              _id: props?.id,
              addon: addOnValues?.addOn,
              measurement: addOnValues?.measurement,
              addon_description: addOnValues?.descriptions,
              iconUrl: addOnValues?.icon,
            },
            callback: (res) => {
              if (res?.type === 1) {
                Toastify("Addon Updated Successfuly!", 1);
                props?.setAddonUpdated(true);
                handleResetInputs();
                props?.closeModal();
              }
            },
          })
        );
      }
    } else {
      dispatch(
        saveAddonAction({
          apiPayloadRequest: {
            addon: addOnValues?.addOn,
            measurement: addOnValues?.measurement,
            addon_description: addOnValues?.descriptions,
            iconUrl: addOnValues?.icon,
          },
          callback: (res) => {
            if (res?.type === 1) {
              Toastify("Addon Saved Successfuly!", 1);
              props?.setAddonUpdated(true);
              handleResetInputs();
              props?.closeModal();
            }
          },
        })
      );
    }
  };

  const cancelPopup = () => {
    handleResetInputs();
    props?.closeModal();
  };

  useEffect(() => {
    if (props?.id !== "") {
      dispatch(
        readAddonAction({
          apiPayloadRequest: {
            _id: props?.id,
          },
          callback: (res) => {
            if (res?.type === 1) {
              const data = res?.data[0];
              setAddOnValues({
                addOn: data?.addon,
                measurement: data?.measurement,
                descriptions: data?.addon_description,
                icon: data?.iconUrl,
              });

              setCurrentAddon({
                addOn: data?.addon,
                measurement: data?.measurement,
                descriptions: data?.addon_description,
                icon: data?.iconUrl,
              });
            }
          },
        })
      );
    } else {
      setAddOnValues(initialAddOnValues);
    }
  }, [props?.id]);

  return (
    <div>
      <ReactModal
        isOpen={props?.isOpen}
        style={{
          ...customStyles,
          ...customContentStyles,
        }}
        onRequestClose={cancelPopup}
      >
        <div className="w-[40dvw] max-h-[95vh] small-bar items-center overflow-y-auto bg-white border rounded-xl">
          <div>
            <div className=" p-5 px-3  items-center flex justify-between">
              <div>
                <h1 className="text-lg font-bold">
                  {props?.id === "" ? "Add New Add Ons" : "Update Add Ons"}
                </h1>
              </div>
              <div className="text-lg px-3 cursor-pointer" onClick={cancelPopup}>
                <img src={wrong} />
              </div>
            </div>

            <img className="w-full h-[1px]" src={Line4} />

            <div className="m-5 w-[37vw] flex flex-col gap-5">
              <div className="w-full flex flex-row gap-3 items-center">
                <div className="w-full">
                  <TextInputField
                    name="addOn"
                    label="Add On *"
                    placeholder="Enter Add On"
                    handleChange={handleChange}
                    value={addOnValues?.addOn}
                    isError={errorFields?.addOn}
                  />
                </div>
                <div className="w-full">
                  <TextInputField
                    name="measurement"
                    label="Measurement *"
                    placeholder="Enter measurement"
                    handleChange={handleChange}
                    value={addOnValues?.measurement}
                    isError={errorFields?.measurement}
                  />
                </div>
              </div>

              <div>
                <div className="w-full ">
                  <TextInputField
                    name="descriptions"
                    label="Description *"
                    placeholder="Enter description"
                    inputstyle={"pb-14"}
                    handleChange={handleChange}
                    value={addOnValues?.descriptions}
                    isError={errorFields?.descriptions}
                  />
                </div>
              </div>
              <div className="p-4 border rounded-lg">
                <p className="text-[11px] font-medium">Upload Add On icon *</p>
                <FileUploadComponent onUpload={onUpload} />
                <TextInputField
                  name="icon"
                  placeholder="Enter URL Here"
                  handleChange={handleChange}
                  value={addOnValues?.icon}
                  isError={errorFields?.icon}
                />
              </div>
            </div>
          </div>

          <div className="px-3 pt-3 pb-2 mb-5 shadow-2xl flex mx-5 justify-end">
            <div className="flex items-center">
              <Button
                onClick={cancelPopup}
                label="Cancel"
                background={"bg-slate-300 px-6"}
                textcolor={"text-slate-600"}
              />
              <Button
                onClick={handleSubmit}
                label={props?.id === "" ? "Add" : "Update"}
                background={"bg-[#F15A2A] px-8"}
                textcolor={"text-white"}
              />
            </div>
          </div>
        </div>
      </ReactModal>
    </div>
  );
};

export default ServiceAddOnmodal;
