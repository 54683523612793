import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import wrong from "../../../assets/images/wrong.png";
import Line4 from "../../../assets/images/Line4.png";
import TextInputField from "../../../components/Common/InputFields/TextInputField";
import Button from "../../../components/Common/Buttons/Button";
import { customStyles, customContentStyles } from "../../../constants/Constant";
import { useDispatch } from "react-redux";
import {
  deletePincodeRegionAction,
  readRegionAction,
  savePincodeRegionAction,
  saveRegionAction,
  updateRegionAction,
} from "../../../store/Service/ServiceAction";
import Toastify from "../../../components/Universal/Toastify";
import MapComponent from "../../../components/Universal/MapComponent";
import { deletePincodeRegion } from "../../../store/Service/ServiceAPI";

const ServiceAddRegionMapmodal = (props) => {
  const dispatch = useDispatch();

  const initialRegionValues = {
    region_name: "",
    region_code: "",
    pincode: "",
    commission: "",
    pincodeArray: [],
  };

  const [regionInputs, setRegionInputs] = useState(initialRegionValues);
  const [currentRegion, setCurrentRegion] = useState({});
  const [pincodeDetails, setPincodeDetails] = useState([]);
  const [deletePincodeId, setDeletePincodeId] = useState("");
  const [errorFields, setErrorFields] = useState(initialRegionValues);

  console.log("props99", props);

  useEffect(() => {
    if (props?.id !== "" && props?.id !== undefined && props?.isOpen) {
      dispatch(
        readRegionAction({
          apiPayloadRequest: {
            _id: props?.id,
          },
          callback: (res) => {
            if (res?.type === 1) {
              const data = res?.data[0];
              const currentPincodes = data?.pincodes?.map((item) => item?.pincode) || [];
              setRegionInputs({
                region_name: data?.region_name || "",
                region_code: data?.region_code || "",
                commission: data?.commission || "",
                pincode: "",
                pincodeArray: currentPincodes,
              });

              setCurrentRegion({
                region_name: data?.region_name || "",
                region_code: data?.region_code || "",
                commission: data?.commission || "",
                pincode: "",
                pincodeArray: currentPincodes,
              });
            }
          },
        })
      );
    } else {
      setRegionInputs(initialRegionValues);
    }
  }, [props?.id, props?.isOpen]);

  // useEffect(() => {
  //     if (regionInputs?.pincode && currentRegion?.pincodeArray?.toString() !== regionInputs?.pincode?.toString()) {
  //         addPincodeToArray();
  //         setDeletePincodeId("");
  //     }
  // }, [regionInputs?.pincode])

  const handleRegionChange = (event) => {
    const { name, value } = event?.target;

    setRegionInputs((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErrorFields({ ...errorFields, [name]: false });
  };

  const addPincodeToArray = (
    newPincodeList = regionInputs?.pincode,
    newPincodeDetails = pincodeDetails
  ) => {
    // if(!regionInputs?.pincode) {
    //     setErrorFields({...errorFields, pincode: "Pin Code Is Required Field" });
    //     return false;
    // }else if(regionInputs?.pincode && regionInputs?.pincode?.length <= 5) {
    //     setErrorFields({...errorFields, pincode: "Pin Code Should be 6 char length" });
    //     return false;
    // }
    if (props?.id !== "") {
      const newPincodes = newPincodeList.filter((x) => !currentRegion?.pincodeArray.includes(x));
      const existRemoved = currentRegion?.pincodeArray.filter((x) => !newPincodeList.includes(x));

      if (
        currentRegion?.pincodeArray?.length !== newPincodeList?.length ||
        newPincodes?.length > 0 ||
        existRemoved?.length > 0
      ) {
        // add new pincodes
        if (newPincodes?.length > 0) {
          newPincodes.forEach((pin) => {
            const currentPincodeDetails = newPincodeDetails?.find((v) => v.pincode === pin);
            const { placeID, center, coords } = currentPincodeDetails;
            dispatch(
              savePincodeRegionAction({
                apiPayloadRequest: {
                  _id: props?.id,
                  pincode: pin,
                  placeid: placeID,
                  centerlat: center?.lat,
                  centerlng: center?.lng,
                  nelat: coords[0]?.ne?.lat,
                  nelng: coords[0]?.ne?.lng,
                  nwlat: coords[1]?.nw?.lat,
                  nwlng: coords[1]?.nw?.lng,
                  swlat: coords[2]?.sw?.lat,
                  swlng: coords[2]?.sw?.lng,
                  selat: coords[3]?.se?.lat,
                  selng: coords[3]?.se?.lng,
                },
                callback: (res) => {
                  if (res?.type === 1) {
                    setRegionInputs((prev) => ({
                      ...prev,
                      pincodeArray: newPincodeList,
                      pincode: "",
                    }));
                    console.log("Pincode Added Successfully!", pin);
                    // Toastify('Pincode Saved Successfully!', 1);
                  }
                },
              })
            );
          });
        }

        // delete removed pincodes
        if (existRemoved?.length > 0) {
          existRemoved.forEach((pin) => {
            dispatch(
              deletePincodeRegion({
                apiPayloadRequest: {
                  _id: props?.id,
                  pincode: pin,
                },
                callback: (res) => {
                  if (res?.type === 1) {
                    setRegionInputs((prev) => ({
                      ...prev,
                      pincodeArray: newPincodeList,
                    }));
                    console.log("Pincode Deleted Successfully!", pin);
                    // Toastify('Pincode Deleted Successfully!', 1);
                  }
                },
              })
            );
          });
        }
      }
    } else {
      setRegionInputs((prev) => ({
        ...prev,
        pincodeArray: newPincodeList,
      }));
    }
    setErrorFields({ ...errorFields, pincode: false });
  };

  const deletePincodeFromArray = (index) => {
    if (props?.id !== "") {
      setDeletePincodeId(regionInputs?.pincodeArray[index]);
      dispatch(
        deletePincodeRegionAction({
          apiPayloadRequest: {
            _id: props?.id,
            pincode: regionInputs?.pincodeArray[index],
          },
          callback: (res) => {
            if (res?.type === 1) {
              const updatedData = regionInputs?.pincodeArray.filter((_, idx) => idx !== index);
              setRegionInputs((prev) => ({
                ...prev,
                pincodeArray: updatedData,
                pincode: "",
              }));

              Toastify("Pincode Deleted Successfully!", 1);
            }
          },
        })
      );
    } else {
      if (regionInputs?.pincodeArray[index]) setDeletePincodeId(regionInputs?.pincodeArray[index]);
      const updatedData = regionInputs?.pincodeArray.filter((_, idx) => idx !== index);
      setRegionInputs((prev) => ({
        ...prev,
        pincodeArray: updatedData,
        pincode: "",
      }));
    }
  };

  const handleAddRegion = () => {
    let errors = {};
    if (!regionInputs?.region_name) {
      errors["region_name"] = true;
    }
    if (regionInputs?.pincodeArray?.length === 0) {
      errors["pincode"] = "Pin Code is Required Field";
    }
    if (regionInputs?.commission <= 0) {
      errors["commission"] = "Commision is Required Field";
    }
    if (regionInputs?.region_code?.length === 0) {
      errors["region_code"] = "Region Code is Required Field";
    }
    if (Object.keys(errors)?.length > 0) {
      setErrorFields({ ...errorFields, ...errors });
      return false;
    }
    if (props?.id !== "") {
      // regionInputs?.pincodeArray?.length > 0 &&
      // regionInputs?.pincodeArray?.map(async (pincode) => {
      //     const currentPincodeDetails = pincodeDetails?.find(v => v.pincode === pincode);
      //     const { placeID, center, coords } = currentPincodeDetails;
      //     dispatch(savePincodeRegionAction({
      //         apiPayloadRequest: {
      //             _id: props?._id,
      //             pincode: pincode,
      //             placeid: placeID,
      //             centerlat: center?.lat,
      //             centerlng: center?.lng,
      //             nelat: coords[0]?.ne?.lat,
      //             nelng: coords[0]?.ne?.lng,
      //             nwlat: coords[1]?.nw?.lat,
      //             nwlng: coords[1]?.nw?.lng,
      //             swlat: coords[2]?.sw?.lat,
      //             swlng: coords[2]?.sw?.lng,
      //             selat: coords[3]?.se?.lat,
      //             selng: coords[3]?.se?.lng,
      //         },
      //         callback: res => {
      //             if (res?.type === 1) {

      //             }
      //         }
      //     }))
      // })
      if (
        regionInputs?.region_name !== currentRegion?.region_name ||
        regionInputs?.commission !== currentRegion?.commission ||
        regionInputs?.region_code !== currentRegion?.commission
      ) {
        dispatch(
          updateRegionAction({
            apiPayloadRequest: {
              _id: props?.id,
              region_name: regionInputs?.region_name,
              region_code: regionInputs?.region_code,
              commission: regionInputs?.commission,
            },
            callback: (res) => {
              if (res?.type === 1) {
                Toastify("Region Updated Successfully!", 1);
                handleResetInputs();
                props?.setRegionUpdated(true);
                props.closeModal();
              } else {
                Toastify("Region Update Failed!");
              }
            },
          })
        );
      } else {
        Toastify("Change Region Name To Update");
      }
    } else {
      dispatch(
        saveRegionAction({
          apiPayloadRequest: {
            region_name: regionInputs?.region_name,
            region_code: regionInputs?.region_code,
            commission: regionInputs?.commission,
          },
          callback: (res) => {
            if (res?.type === 1) {
              Toastify("Region Saved Successfully!", 1);

              regionInputs?.pincodeArray?.length > 0 &&
                regionInputs?.pincodeArray?.map(async (pincode) => {
                  const currentPincodeDetails = pincodeDetails?.find((v) => v.pincode === pincode);
                  const { placeID, center, coords } = currentPincodeDetails;
                  dispatch(
                    savePincodeRegionAction({
                      apiPayloadRequest: {
                        _id: res?.data[0]?._id,
                        pincode: pincode,
                        placeid: placeID,
                        centerlat: center?.lat,
                        centerlng: center?.lng,
                        nelat: coords[0]?.ne?.lat,
                        nelng: coords[0]?.ne?.lng,
                        nwlat: coords[1]?.nw?.lat,
                        nwlng: coords[1]?.nw?.lng,
                        swlat: coords[2]?.sw?.lat,
                        swlng: coords[2]?.sw?.lng,
                        selat: coords[3]?.se?.lat,
                        selng: coords[3]?.se?.lng,
                      },
                      callback: (res) => {
                        if (res?.type === 1) {
                        }
                      },
                    })
                  );
                });
              handleResetInputs();
              props?.setRegionUpdated(true);
              props.closeModal();
            } else {
              Toastify("Region Save Failed!");
            }
          },
        })
      );
    }
  };

  const handlePincodes = (value) => {
    setPincodeDetails(value);
    const pincodeList = value.map((v) => v.pincode);
    setRegionInputs((prev) => ({
      ...prev,
      pincode: pincodeList,
      pincodeArray: pincodeList,
    }));
    setDeletePincodeId("");
    setErrorFields({ ...errorFields, pincode: false });
    addPincodeToArray(pincodeList, value);
  };

  const handleResetInputs = async () => {
    setRegionInputs(initialRegionValues);
    setErrorFields(initialRegionValues);
    setCurrentRegion({});
    setPincodeDetails([]);
    setDeletePincodeId("");
  };

  const cancelPopup = () => {
    handleResetInputs();
    props?.closeModal();
  };

  return (
    <ReactModal
      isOpen={props?.isOpen}
      style={{
        ...customStyles,
        ...customContentStyles,
      }}
      onRequestClose={cancelPopup}
    >
      <div className="w-[90vw] h-[90vh] flex flex-col justify-between bg-white ">
        <div className="flex flex-col h-full">
          <div className="p-5 px-3  items-center flex justify-between">
            <div>
              <h1 className="text-lg font-bold">
                {props?.id === "" ? "Add New Regions" : "Update Regions"}
              </h1>
            </div>
            <div className="text-lg px-3 cursor-pointer" onClick={cancelPopup}>
              <img src={wrong} />
            </div>
          </div>

          <img className="w-full h-[1px]" src={Line4} />
          <div className="w-full flex flex-row gap-2 flex-1">
            <div className="p-4 w-3/4 flex flex-col gap-2">
              <MapComponent
                handlePincodes={handlePincodes}
                handleDeletePincode={deletePincodeId}
                id={props?.id}
                pincodeList={regionInputs?.pincodeArray}
                region={regionInputs?.region_name}
              />
            </div>

            <div className="p-4 w-1/4 flex flex-col gap-2">
              <div className="w-full flex flex-row gap-3 items-center">
                <TextInputField
                  name={"region_name"}
                  label="Enter Region Name *"
                  placeholder="Region Name"
                  handleChange={handleRegionChange}
                  value={regionInputs?.region_name}
                  isError={errorFields?.region_name}
                />
              </div>
              <div className="w-full flex flex-col gap-3 items-center">
                <TextInputField
                  name={"region_code"}
                  label="Enter Region Code *"
                  placeholder="Region Code"
                  handleChange={handleRegionChange}
                  value={regionInputs?.region_code}
                  isError={errorFields?.region_code}
                />
                <p className="text-[0.7rem] text-primary/80 mb-2">
                  Note: Based on this field only Trip Id's will be generated
                </p>
              </div>
              <div className="w-full flex flex-row gap-3 items-center">
                <TextInputField
                  name={"commission"}
                  label="Enter Region Commision(%) *"
                  type="number"
                  placeholder="Region Commision"
                  handleChange={handleRegionChange}
                  value={regionInputs?.commission}
                  isError={errorFields?.commission}
                />
              </div>
              <div className=" w-full overflow-scroll p-2 border-2 border-[#979AA480] grid grid-cols-3 gap-2 max-h-[300px]  rounded-lg pb-12 mt-4 ">
                {regionInputs?.pincodeArray?.length === 0 && <label>Pincode list</label>}
                {regionInputs?.pincodeArray?.length > 0 &&
                  regionInputs?.pincodeArray.map((pincode, index) => (
                    <div className="bg-[#979AA480] rounded-md flex flex-row items-center p-2 px-3 gap-3">
                      <div>
                        {" "}
                        <p className="text-[12px] text-blue-900 font-semibold">{pincode}</p>{" "}
                      </div>
                      <img
                        src={wrong}
                        className="h-2 cursor-pointer"
                        onClick={() => deletePincodeFromArray(index)}
                      />
                    </div>
                  ))}
              </div>
              {errorFields?.pincode && (
                <p className="text-red-500 relative bottom-[-4px] text-[10px] left-2 mb-[-12px]">
                  Pincode Is Required Field
                </p>
              )}
              <div className="p-4 shadow-2xl mt-6">
                <div className="flex items-center float-right">
                  <Button
                    onClick={cancelPopup}
                    label="Cancel"
                    background={"bg-slate-300 px-6"}
                    textcolor={"text-slate-600"}
                  />
                  <Button
                    onClick={handleAddRegion}
                    label={props?.id === "" ? "Add" : "Update"}
                    background={"bg-[#F15A2A] px-8"}
                    textcolor={"text-white"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

export default ServiceAddRegionMapmodal;
