import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import wrong from "../assets/images/wrong.png";
import SOSBg from "../assets/images/sos_bg.png";
import SOS from "../assets/images/sos.png";
import EmergencyService from "../assets/images/emergency_service.svg";
import NonEmergencyService from "../assets/images/non_emergency_service.svg";
import AfterLifeService from "../assets/images/after_life_service.svg";
import SpecialService from "../assets/images/special_service.svg";
import AirCargoService from "../assets/images/air_cargo_service.svg";
import TrainAmbulanceService from "../assets/images/train_ambulance_service.svg";
import CorporateService from "../assets/images/corporate_services.svg";
import HomecareService from "../assets/images/homecare_services.svg";
import logo from "../assets/images/productLogo.png";
import { HiMenuAlt1 } from "react-icons/hi";
import ReactModal from "react-modal";
import { customContentStyles, customStyles } from "../constants/Constant";

const Header = ({ menu }) => {
  const navigate = useNavigate();
  const { userDetails } = useSelector((state) => state.userProfile);

  return (
    <div className="w-full flex justify-between items-center py-4">
      <div className="flex items-center text-[13px] text-secondary gap-1 font-medium">
        <HiMenuAlt1 className="md:hidden block mr-1" size={22} color="#0E2D9B" />
        <div className="LOGO pr-3 text-xl font-medium cursor-pointer text-primary">
          <NavLink className="text-lg font-semibold text-primary" to="/">
            <img src={logo} />
          </NavLink>
        </div>
        {menu.map((item, index) => (
          <button
            className="hidden md:block hover:bg-secondary/10 py-2 px-3 rounded-[50px]"
            key={"menu-item" + index}
          >
            {item?.name}
          </button>
        ))}
      </div>
      {userDetails ? (
        <>
          {userDetails?.roleCode === "84bts2" || userDetails?.roleCode === "96nhe6" ? (
            <button
              onClick={() => navigate("/dashboard")}
              className="text-sx text-primary font-semibold"
            >
              Dashboard
            </button>
          ) : (
            <button
              onClick={() => navigate("/profile")}
              style={{ backgroundImage: `url(${userDetails?.profilePicUrl})` }}
              className="w-9 rounded-full aspect-square bg-secondary bg-no-repeat bg-cover"
            ></button>
          )}
        </>
      ) : (
        <div className="flex items-center text-[13px] text-primary gap-4 font-semibold">
          <NavLink to="/login">Login</NavLink>
          <button className="text-white bg-primary py-2 px-3 rounded-[50px]">Sign Up</button>
        </div>
      )}
    </div>
  );
};

const LandingPage = () => {
  const navigate = useNavigate();

  const [serviceModalOpen, setServiceModalOpen] = useState(false);

  const menuList = [
    {
      name: "Emergency",
    },
    {
      name: "Non-Emergency",
    },
    {
      name: "After Life",
    },
    {
      name: "Special",
    },
  ];

  return (
    <div className="h-screen px-[5%] flex flex-col">
      <Header menu={menuList} />
      <div className="w-full h-full gap-2 flex md:flex-row flex-col justify-center items-center md:m-0">
        <div className="md:w-1/2 w-full h-full flex flex-col justify-center items-center text-primary font-semibold md:py-10 px-14">
          <div className="w-full">
            <p className="text-5xl leading-tight font-bold tracking-wide">
              Rapid Response, Reliable Care.
            </p>
            <div className="grid grid-cols-2 gap-3 pt-10 w-full">
              <a
                className="bg-[#D9F2FD] p-3 rounded-2xl flex flex-col justify-center items-center gap-10 cursor-pointer"
                href="tel:7331105533"
              >
                <img src={EmergencyService} className="h-16 mt-4" />
                <p className="rounded-xl bg-white text-black w-full font-semibold text-wrap text-center px-3 py-2">
                  Emergency Service
                </p>
              </a>
              <div
                className="bg-[#DFF4F1] p-3 rounded-2xl flex flex-col justify-center items-center gap-10 cursor-pointer"
                onClick={() => navigate("/trip")}
              >
                <img src={NonEmergencyService} className="h-16 mt-4" />
                <p className="rounded-xl bg-white text-black w-full font-semibold text-wrap text-center px-3 py-2">
                  Non Emergency Service
                </p>
              </div>
              <div
                className="bg-[#FCEDDF] p-3 rounded-2xl flex flex-col justify-center items-center gap-10 cursor-pointer"
                onClick={() => navigate("/trip")}
              >
                <img src={AfterLifeService} className="h-16 mt-4" />
                <p className="rounded-xl bg-white text-black w-full font-semibold text-wrap text-center px-3 py-2">
                  After Life Service
                </p>
              </div>
              <div
                className="bg-[#ECE9FF] p-3 rounded-2xl flex flex-col justify-center items-center gap-10 cursor-pointer"
                onClick={() => setServiceModalOpen(true)}
              >
                <img src={SpecialService} className="h-16 mt-4" />
                <p className="rounded-xl bg-white text-black w-full font-semibold text-wrap text-center px-3 py-2">
                  Special Service
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="md:w-1/2 w-full md:h-full h-screen md:flex hidden justify-center items-center md:p-0 pb-[10%]">
          <div
            style={{ backgroundImage: `url(${SOSBg})` }}
            className="md:w-[80%] w-full aspect-square rounded-md bg-center bg-no-repeat bg-cover flex justify-center items-center"
          >
            <a href="tel:7331105533">
              <img src={SOS} className="w-full" />
            </a>
          </div>
        </div>
      </div>
      <ReactModal
        isOpen={serviceModalOpen}
        style={{
          ...customStyles,
          ...customContentStyles,
        }}
        onRequestClose={() => setServiceModalOpen(false)}
      >
        <div className="w-fit h-fit flex flex-col justify-between  small-bar items-center overflow-auto bg-white">
          <div className="w-full p-3 px-6 items-center flex justify-between">
            <h4 className="text-xl font-bold">Special Services</h4>
            <div
              className="text-lg cursor-pointer flex items-center flex-row gap-6"
              onClick={() => setServiceModalOpen(false)}
            >
              <div>
                <img src={wrong} />{" "}
              </div>
            </div>
          </div>
          <div className="px-6 pb-6 pt-4 grid grid-cols-3 gap-3 w-full">
            <a
              className="bg-[#D9F2FD] p-3 rounded-2xl flex flex-col justify-center items-center gap-10 cursor-pointer"
              href="tel:7331105533"
            >
              <img src={SpecialService} className="h-16 mt-4" />
              <p className="rounded-xl bg-white text-black w-full font-semibold text-wrap text-center px-3 py-2">
                Air Ambulance
              </p>
            </a>
            <a
              className="bg-[#DFF4F1] p-3 rounded-2xl flex flex-col justify-center items-center gap-10 cursor-pointer"
              href="tel:7331105533"
            >
              <img src={AirCargoService} className="h-16 mt-4" />
              <p className="rounded-xl bg-white text-black w-full font-semibold text-wrap text-center px-3 py-2">
                Air Cargo
              </p>
            </a>
            <a
              className="bg-[#FCEDDF] p-3 rounded-2xl flex flex-col justify-center items-center gap-10 cursor-pointer"
              href="tel:7331105533"
            >
              <img src={TrainAmbulanceService} className="h-16 mt-4" />
              <p className="rounded-xl bg-white text-black w-full font-semibold text-wrap text-center px-3 py-2">
                Train Ambulance
              </p>
            </a>
            <a
              className="bg-[#ECE9FF] p-3 rounded-2xl flex flex-col justify-center items-center gap-10 cursor-pointer"
              href="tel:7331105533"
            >
              <img src={CorporateService} className="h-16 mt-4" />
              <p className="rounded-xl bg-white text-black w-full font-semibold text-wrap text-center px-3 py-2">
                Corprate Services
              </p>
            </a>
            <a
              className="bg-[#ECE9FF] p-3 rounded-2xl flex flex-col justify-center items-center gap-10 cursor-pointer"
              href="tel:7331105533"
            >
              <img src={HomecareService} className="h-16 mt-4" />
              <p className="rounded-xl bg-white text-black w-full font-semibold text-wrap text-center px-3 py-2">
                Homecare Services
              </p>
            </a>
          </div>
        </div>
      </ReactModal>
    </div>
  );
};

export default LandingPage;
