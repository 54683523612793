import React, { Fragment, useEffect, useState } from "react";
import UserHeader from "../components/Universal/UserHeader";
import { FaArrowUp } from "react-icons/fa6";
import TabsComp from "../components/Universal/TabComp";
import { FaLocationDot } from "react-icons/fa6";
import DriverImage from "../assets/images/driverimage.png";
import { TiStarFullOutline } from "react-icons/ti";
import { FaRupeeSign, FaStar } from "react-icons/fa";
import Omni from "../assets/images/Omni.png";
import { FaArrowAltCircleRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { readTripAction } from "../store/Service/ServiceAction";
import moment from "moment";
import { GiPathDistance } from "react-icons/gi";
import { getTripStatus } from "../utils/utilsHelperFunctions";
import LoaderSpinner from "../components/Universal/LoaderSpinner";

const MyTrips = () => {
  const dispatch = useDispatch();
  const { userDetails } = useSelector((state) => state.userProfile);
  const [activeTab, setActiveTab] = useState(0);
  const [tripsData, setTripsData] = useState([]);
  const [tripsLoading, setTripsLoading] = useState(true);

  useEffect(() => {
    if (userDetails?.userAuthId) {
      setTripsLoading(true);
      dispatch(
        readTripAction({
          apiPayloadRequest: {
            userid: userDetails?.userAuthId,
          },
          callback: (res) => {
            if (res.type === 1) {
              setTripsData(res?.data);
              setTripsLoading(false);
            }
          },
        })
      );
    }
  }, [userDetails]);

  console.log("tripsData", tripsData);

  return (
    <div className="flex flex-col md:h-screen overflow-y-auto relative">
      <UserHeader activeTab={"myTrips"} />
      <div className="flex md:flex-row flex-col gap-[2.5%] w-full md:h-screen md:pt-[80px] md:pb-6 pb-[80px] py-6 px-[5%]">
        <StatCard stats={{ total: tripsData?.length, current_month: tripsData?.length }} />
        <div className="flex flex-col items-center w-full h-full">
          {/* <TabsComp
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            tabs={["Upcoming", "Completed", "Canceled"]}
            canEdit={false}
          /> */}
          {tripsLoading ? (
            <div className="h-[80vh] flex flex-col items-center justify-center">
              <LoaderSpinner className="!text-black" />
              <p>Loading trips..</p>
            </div>
          ) : (
            <>
              {activeTab === 0 && <TripsList data={tripsData} type="upcoming" />}
              {activeTab === 1 && <TripsList data={tripsData} type="completed" />}
              {activeTab === 2 && <TripsList data={tripsData} type="canceled" />}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const StatCard = ({ stats }) => {
  return (
    <div className="flex md:w-4/12 flex-col md:mb-0 mb-6 w-full h-fit rounded-lg p-4  bg-gradient-to-tr from-red-500 via-primary via-[40%] to-amber-300 text-white">
      <div className="flex flex-col gap-10 w-full border-b border-white/30 pb-4">
        <p className="md:text-xs text-[10px] font-semibold">Total trips</p>
        <div className="w-full flex justify-between">
          <p className="text-5xl font-medium">{stats?.total?.toString()?.padStart(2, "0")}</p>
          <div className="w-10 h-10 rounded-full flex justify-center items-center bg-white/30">
            <FaArrowUp size={20} />
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-10 w-full pt-4">
        <p className="md:text-xs text-[10px] font-semibold">Total trips in this months</p>
        <div className="w-full flex justify-between">
          <p className="text-5xl font-medium">
            {stats?.current_month?.toString()?.padStart(2, "0")}
          </p>
          <div className="w-10 h-10 rounded-full flex justify-center items-center bg-white/30">
            <FaArrowUp size={20} />
          </div>
        </div>
      </div>
    </div>
  );
};

const TripsList = ({ data = [] }) => {
  return (
    <div className="flex flex-col gap-14 w-full h-full md:overflow-y-auto mt-6 px-4">
      <div>
        <div className="flex flex-col gap-4 w-full h-full mt-2">
          {data?.map((trip, index) => (
            <Fragment key={"trip-item" + index}>
              {(index === 0 ||
                (index > 0 &&
                  moment(trip?.timeOfBookingRequest).format("DD/MM/YYYY") !==
                    moment(trip?.[index - 1]?.timeOfBookingRequest).format("DD/MM/YYYY"))) && (
                <p className="text-3xl text-black/80 font-medium w-full">
                  {moment(trip?.timeOfBookingRequest).format("DD MMM, YYYY")}
                </p>
              )}

              <TripCard trip={trip} />
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

const TripCard = ({ trip }) => {
  const navigate = useNavigate();

  const statusResult = getTripStatus(trip?.status);

  return (
    <div
      onClick={() => navigate("/tripDetails", { state: { _id: trip?._id } })}
      className="flex md:flex-row flex-col justify-between w-full border hover:border-black/30 cursor-pointer transition-all duration-300 rounded-md p-4 gap-6"
    >
      <div className="flex md:flex-row flex-col gap-6 w-full">
        <div className="flex flex-col items-center md:w-1/4 w-full">
          <div
            style={{
              backgroundImage: `url(${
                trip?.vehicle?.[0]?.vehicle_icon_url && trip?.vehicle?.[0]?.vehicle_icon_url !== ""
                  ? trip?.vehicle?.[0]?.vehicle_icon_url
                  : Omni
              })`,
            }}
            className="w-20 aspect-square rounded-md bg-center bg-contain bg-no-repeat"
          ></div>
          <p className="text-xs text-black/50 font-semibold">
            {trip?.vehicleObjId?.[0]?.vehicle_type_name} ({trip?.vehicleObjId?.[0]?.vehicle_service}
            )
          </p>
        </div>
        <div className="flex flex-col md:gap-2 gap-6 w-full">
          <p className="text-sm font-semibold flex gap-4 items-center justify-center">
            {trip?.fromAddress?.length > 30
              ? trip?.fromAddress?.substring(0, 30) + ".."
              : trip?.fromAddress}
            <span className=" text-red-500">
              <FaArrowAltCircleRight size={30} />{" "}
            </span>{" "}
            {trip?.toAddress?.length > 30
              ? trip?.toAddress?.substring(0, 30) + ".."
              : trip?.toAddress}
          </p>
          <div className="flex md:flex-row flex-col justify-between gap-4 p-3 rounded-md bg-secondary/5 items-center text-sm font-medium w-full">
            {trip?.drivers?.[0] && (
              <div className="flex gap-3 items-center">
                <img
                  src={
                    trip?.drivers?.[0]?.profilePicUrl && trip?.drivers?.[0]?.profilePicUrl !== ""
                      ? trip?.drivers?.[0]?.profilePicUrl
                      : DriverImage
                  }
                  className="w-12 aspect-square rounded-md object-cover object-top overflow-hidden"
                />
                <div className="grid md:grid-cols-2 grid-cols-1 md:gap-8 gap-3 w-full">
                  <p className="NAME text-center flex items-center justify-center">
                    {trip?.drivers?.[0]?.firstName} {trip?.drivers?.[0]?.lastName}{" "}
                    {trip?.drivers?.[0]?.mobile && `(${trip?.drivers?.[0]?.mobile})`}
                  </p>
                </div>
              </div>
            )}
            <button
              className="border-none px-4 py-1.5 rounded-md text-xs font-semibold"
              style={{
                color: `rgb(${statusResult?.bgColor})`,
                background: `rgba(${statusResult?.bgColor}, 0.2)`,
              }}
            >
              {statusResult?.text}
            </button>
            <p className="RATING flex gap-2 items-center whitespace-nowrap font-semibold">
              <GiPathDistance className="mb-[2px]" size={20} /> {trip?.distance} Km
            </p>
          </div>
        </div>
      </div>
      <div className="md:w-1/5 w-full h-full flex md:flex-col justify-between items-end">
        <p className="md:text-sm text-xl font-semibold text-secondary">
          {trip?.timeOfBookingRequest
            ? moment(trip?.timeOfBookingRequest)?.format("hh:mm A")
            : "--:--"}
        </p>
        <div className="flex flex-col items-end">
          <p className="text-[10px] text-black/50 font-medium">TOTAL AMOUNT</p>
          <p className={`text-lg text-black font-semibold flex items-center`}>
            <FaRupeeSign className="" size={15} />{" "}
            <span>{trip?.price ? parseFloat(trip?.price)?.toFixed(2) : "--"}</span>
          </p>
        </div>
      </div>
    </div>
  );
};

const LocationsComp = () => {
  return (
    <div className="flex flex-col gap-6 w-full pr-6 border-r">
      <div className="flex justify-between">
        <div className="flex gap-2">
          <FaLocationDot color="#0E2D9B" />
          <div className="">
            <p className="text-xs text-black/50">From</p>
            <p className="text-sm text-black font-semibold">Kukatpally housing board colony ...</p>
          </div>
        </div>
        <button className="text-xs text-secondary">Edit</button>
      </div>
      <div className="flex justify-between">
        <div className="flex gap-2">
          <FaLocationDot color="#F15A2A" />
          <div className="">
            <p className="text-xs text-black/50">From</p>
            <p className="text-sm text-black font-semibold">Vijayawada, junction road...</p>
          </div>
        </div>
        <button className="text-xs text-secondary">Edit</button>
      </div>
    </div>
  );
};

const DriverCard = () => {
  return (
    <div className="flex justify-between w-3/4 rounded-md items-center">
      <div className="flex gap-4">
        <div
          style={{ backgroundImage: `url(${DriverImage})` }}
          className="w-20 aspect-square rounded-md bg-center bg-contain bg-no-repeat"
        ></div>
        <div className="flex flex-col justify-between text-sm">
          <p className="font-semibold text-lg">John Doe</p>
          <div className="flex gap-1 font-semibold text-lg">
            <TiStarFullOutline color="#ebc034" size={25} /> <p>4.8</p>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-between h-full py-6 items-end">
        <p className="text-xs text-black/50">Final Cost</p>
        <p className={`text-sm text-black font-semibold flex items-center`}>
          <FaRupeeSign size={13} /> <span>3000.00</span>
        </p>
      </div>
    </div>
  );
};

export default MyTrips;
