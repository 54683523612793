import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import { combineReducers, createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { Auth0Provider } from "@auth0/auth0-react";
import { ToastContainer } from "react-toastify";
import GlobalLoaderReducer from "./store/GlobalLoader/GlobalLoaderReducer";
import ServiceReducer from "./store/Service/ServiceReducer";
import { webportalAppRootSaga } from "./store";
import "react-toastify/dist/ReactToastify.css";
import { SocketProvider } from "./components/Common/SocketContext";
import UserProfileReducer from "./store/UserProfile/UserProfileReducer";
import { BrowserRouter } from "react-router-dom";

const reducers = combineReducers({
  globalLoader: GlobalLoaderReducer,
  userProfile: UserProfileReducer,
  service: ServiceReducer,
});

const sagaMiddleware = createSagaMiddleware();
const store = createStore(reducers, applyMiddleware(sagaMiddleware));

sagaMiddleware.run(webportalAppRootSaga);
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
    authorizationParams={{
      redirect_uri: window.location.origin,
      audience: process.env.REACT_APP_AUDIENCE_URL,
      scope: "openid email offline_access",
    }}
  >
    <Provider store={store}>
      <SocketProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
        <ToastContainer />
      </SocketProvider>
    </Provider>
  </Auth0Provider>
);
