import * as actionTypes from "../ActionTypes";

export const updateTokenAction = (payload) => ({
  type: actionTypes.UPDATE_USER_TOKEN,
  payload,
});

export const updateUserDetailsAction = (payload) => ({
  type: actionTypes.UPDATE_USER_DETAILS,
  payload,
});
