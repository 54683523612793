import React from "react";
import BackgroundMapLocation from "../Locations/BackroundMapLocation";
import TextLine from "../../../Cards/TextLine";


export default function MapLocationandTextLine({ active,lineWidth1,lineWidth2, pickup, drop }) {
    return (
        <div  className="flex flex-col gap-3">
            <div>
                <BackgroundMapLocation active={active} lineWidth1={lineWidth1} lineWidth2={lineWidth2} pickup={pickup} drop={drop}/>
            </div>
{/* 
            <div> <TextLine label={'Oxygen'} value={'5 Ltrs'} styles={'font-semibold text-[14px] text-[#2B2B2B] '} /> </div>
            <div> <TextLine label={'Helpers'} value={'2 Mem'} styles={'font-semibold text-[14px] text-[#2B2B2B] '} /> </div>
            <div> <TextLine label={'Distance'} value={'29Km'} styles={'font-semibold text-[16px] text-[#2B2B2B] '} /> </div>
            <div> <TextLine label={'Price'} value={'8000'} styles={'font-semibold text-[16px] text-[#2B2B2B] '} Linestyle={'w-0'} /> </div> */}


        </div>
    )
}