import * as actionTypes from "../ActionTypes";

const INITIAL = {
    token: '',
    userDetails: '',
};

const UserProfileReducer = (state = INITIAL, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_USER_TOKEN:
            return { ...state, token: action.payload };
        case actionTypes.UPDATE_USER_DETAILS:
            return { ...state, userDetails: action.payload };
        default:
            return state;
    }
};

export default UserProfileReducer;
