import React, { useEffect, useState } from "react";
import bellIcon from "../../assets/images/bell.png";
import DropDown from "../Common/InputFields/DropDown";
import { readRegionAction } from "../../store/Service/ServiceAction";
import { useDispatch } from "react-redux";
import { io } from "socket.io-client";

const Header = ({ title }) => {
  const [allRegionData, setAllRegionData] = useState([]);
  const dispatch = useDispatch();

  const userData = JSON.parse(localStorage.getItem("user"));
  const todayDate = new Date().toLocaleDateString("en-IN", {
    dateStyle: "full",
  });
  const isDashboardPage = window.location.pathname === "/dashboard";

  useEffect(() => {
    if (isDashboardPage) {
      dispatch(
        readRegionAction({
          callback: (res) => {
            if (res?.type === 1) {
              const regions = res?.data.map((v) => v.region_name);
              setAllRegionData(regions);
            }
          },
        })
      );
    }
  }, []);



  return (
    <>
      {title === "Welcome" ? (
        <div className="flex h-[80px] justify-between text-center items-center px-4 w-full">
          <div className="flex items-center gap-3">
            <p className="text-2xl font-bold">
              Welcome,{" "}
              {userData?.firstName ? userData?.firstName : userData?.email}!
            </p>
            <p className="whitespace-nowrap  text-gray-500 text-sm flex">
              {/* Friday, 01 September 2023 */}
              {todayDate} (IST +5:30 timezone)
            </p>
          </div>
          {/* <div className="flex items-center gap-3">
            {isDashboardPage && <DropDown optionsList={allRegionData} />}
            {isDashboardPage && (
              <DropDown
                Option1={"Today"}
                Option2={"January"}
                Option3={"February"}
                Option4={"March"}
                Option5={"April"}
              />
            )}
            <img src={bellIcon} />
          </div> */}
        </div>
      ) : (
        <div className="flex h-[80px] justify-between text-center items-center px-4 w-full">
          <p className="text-2xl font-bold">{title}</p>
          {/* <img src={bellIcon} /> */}
        </div>
      )}
    </>
  );
};

export default Header;
