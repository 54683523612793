import React, { useEffect, useState } from "react";
import Star from "../../../../assets/images/Star.png";
import driverimage from "../../../../assets/images/driverimage.png";
import OutlineButton from "../../Buttons/OutlineButton";
import Phone from "../../../../assets/images/phone.png";
import Toastify from "../../../Universal/Toastify";
import moment from "moment";
import { libraries } from "../../../../constants/Constant";
import { LoadScript } from "@react-google-maps/api";

export default function AssignDriverContainer({
  name,
  driverImageUrl,
  rating,
  number,
  onClick,
  assign,
  selectedVehicleData,
  vehicleTypes,
  pickupLocation,
  driverLastLocation,
  coords,
}) {
  const [distance, setDistance] = useState("-");
  const isDriverAssignable = vehicleTypes?.some(
    (d) => d?.vehicleObjId === selectedVehicleData?._id
  );

  const updateDriverDistance = (origin, destination) => {
    console.log("origin", origin);
    console.log("destination", destination);
    console.log("window.google", window?.google?.maps);
    if (window?.google?.maps) {
      const directionsService = new window.google.maps.DirectionsService();
      console.log("directionsService", directionsService);
      if (directionsService) {
        directionsService.route(
          {
            origin: origin,
            destination: destination,
            travelMode: window?.google?.maps?.TravelMode?.DRIVING,
          },
          (result, status) => {
            if (status === window?.google?.maps?.DirectionsStatus?.OK) {
              if (result?.routes?.length > 0) {
                console.log(result);
                setDistance(result?.routes?.[0]?.legs?.[0]?.distance?.text);
              }
            } else {
              // Toastify("Route Not Found", 2);
              console.error(`Error fetching directions: ${status}`);
            }
          }
        );
      }
    }
  };

  useEffect(() => {
    const driverCoordinates = coords ?? driverLastLocation?.coords;
    if (pickupLocation?.lat && driverCoordinates?.latitude) {
      updateDriverDistance(pickupLocation, {
        lat: driverCoordinates?.latitude,
        lng: driverCoordinates?.longitude,
      });
    }
  }, [pickupLocation, driverLastLocation, coords, window?.google]);

  const getLastSeen = (time) => {
    const currDate = new Date();
    const lastSeen = new Date(time);
    const diff = Math.abs(currDate - lastSeen);
    const minutes = Math.floor((diff / (1000 * 60)) % 60);
    const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
    return hours > 0 ? `${hours}h ${minutes}m` : `${minutes}mins`;
  };

  return (
    <div className="w-full p-3 bg-slate-100 rounded-lg flex flex-row">
      <div className="mt-1">
        <img className="w-[75px] rounded-md" src={driverImageUrl ? driverImageUrl : driverimage} />{" "}
      </div>
      <div className="pl-2 flex flex-row  w-full ">
        <div className="flex flex-col w-full">
          <div className="flex flex-row w-full justify-between  ">
            <div>
              {" "}
              <p className="font-bold text-[14px] ">{name}</p>
            </div>
            {rating && (
              <div className="flex flex-row items-center">
                <div>
                  <img src={Star} />
                </div>
                <div>
                  <p className="text-[12px] font-semibold pl-2 text-[#2B2B2B] opacity-50 ">
                    {rating}
                  </p>{" "}
                </div>
              </div>
            )}
          </div>

          <div>
            <p className="text-[12px] font-semibold text-[#2B2B2B] opacity-50">
              {distance} away from pickup location
              {/* {getLastSeen(driverLastLocation?.lastUpdatedDate)} ago */}
            </p>
          </div>

          <div className="flex flex-col justify-between items-start">
            <div className="flex items-center gap-2">
              <p className="text-xs font-semibold">{number}</p>{" "}
              <div className="p-1 bg-slate-300 rounded-lg">
                <img src={Phone} />
              </div>{" "}
            </div>

            <div className="flex items-center">
              {assign && (
                <OutlineButton
                  className={isDriverAssignable ? "" : "border-none bg-slate-300"}
                  labelClassName={isDriverAssignable ? "" : "text-slate-600"}
                  label={isDriverAssignable ? "Assign" : "Vehicle Not Available"}
                  onClick={
                    isDriverAssignable
                      ? onClick
                      : () => {
                          Toastify("Can't assign the driver!!", 2);
                        }
                  }
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
