import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import wrong from "../../../assets/images/wrong.png";
import Line4 from "../../../assets/images/Line4.png";
import TextInputField from "../../../components/Common/InputFields/TextInputField";
import Button from "../../../components/Common/Buttons/Button";
import { customContentStyles, customStyles, customStyles3 } from "../../../constants/Constant";
import InputDropdown from "../../../components/Common/InputFields/InputDropdown";
import { useDispatch } from "react-redux";
import {
  readTarrifAction,
  readVehicleAction,
  saveAddonTarrifAction,
  updateAddonTarrifAction,
  saveTarrifAction,
  updateTarrifAction,
  readRegionAction,
  deleteTarrifAction,
} from "../../../store/Service/ServiceAction";
import Toastify from "../../../components/Universal/Toastify";
import OutlineButton from "../../../components/Common/Buttons/OutlineButton";

const ServiceTariffModal = (props) => {
  const dispatch = useDispatch();

  const intialRegionAndVehicle = {
    region: "",
    vehicleObjId: "",
  };

  const id = props?.id !== "" ? props?.id : "no";
  const myTariffdata = props?.tariffData.filter((row) => row.regionObjId + row.vehicleObjId === id);

  const [currentAddons, setCurrentAddons] = useState([]);
  const [featureStates, setFeatureStates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [regionAndVehicle, setRegionAndVehicle] = useState(intialRegionAndVehicle);

  const initialTariffValues = {
    tyepofcharge: "",
    charges: "",
    chargestype: 1,
    fromkm: "",
    tokm: "",
    addons: [],
  };

  const [tariffArray, setTariffArray] = useState([]);
  const [currentTariffArray, setCurrentTariffArray] = useState([]);
  const [tariffValues, setTariffValues] = useState(initialTariffValues);
  const [errorFields, setErrorFields] = useState(initialTariffValues);
  const [currentTariff, setCurrentTariff] = useState();

  const handleResetInputs = () => {
    setTariffArray([]);
    setRegionAndVehicle(intialRegionAndVehicle);
    setTariffValues(initialTariffValues);
    setErrorFields(initialTariffValues);
    setCurrentTariff("");
    setCurrentAddons([]);
    setFeatureStates([]);
    setIsLoading(false);
  };

  const typeOfChargeOptions = [
    {
      value: 0,
      label: "Per Km",
    },
    {
      value: 1,
      label: "Fixed Value",
    },
  ];

  const chargeOptions = [
    {
      value: 0,
      label: "Percentage",
    },
    {
      value: 1,
      label: "Fixed Value",
    },
  ];

  const regionOptions = props?.allRegionData2?.map((item) => ({
    value: item?._id,
    label: item?.region_name,
  }));

  const vehicleOptions = props?.allVehicleData.map((item) => ({
    value: item?._id,
    label: item?.vehicle_type_name + " " + item?.vehicle_service,
  }));

  // Check if there is data in the features' addonObjId arrays
  const checkFeaturesAvailable = (dataArray) => {
    return dataArray.some(
      (item) =>
        item.features &&
        item.features.some((feature) => feature.addonObjId && feature.addonObjId.length > 0)
    );
  };

  const handleVehicleTypeChange = (event) => {
    const value = event.target.value; //vehicleObjId
    setRegionAndVehicle((prev) => ({
      ...prev,
      vehicleObjId: value,
    }));
    setErrorFields({ ...errorFields, vehicleObjId: false });
    if (value) {
      dispatch(
        readVehicleAction({
          apiPayloadRequest: {
            _id: value,
          },
          callback: (res) => {
            if (res?.type === 1) {
              const data = res?.data[0];
              const features = data?.features;

              tariffArray.map((_, index) => {
                const updatedTariff = [...tariffArray];
                updatedTariff[index].addons = checkFeaturesAvailable(res?.data)
                  ? features.map((item) => ({
                      _id: item?.addonObjId?.[0]?._id || "",
                      addon: item?.addonObjId?.[0]?.addon || "",
                      amount: 0,
                    }))
                  : [];
                setTariffArray(updatedTariff);
              });

              setFeatureStates(
                checkFeaturesAvailable(res?.data)
                  ? features.map((item) => ({
                      _id: item?.addonObjId?.[0]?._id || "",
                      addon: item?.addonObjId?.[0]?.addon || "",
                      amount: 0,
                    }))
                  : []
              );
            }
          },
        })
      );
    }
  };

  const handleRegionChange = (event) => {
    setRegionAndVehicle((prev) => ({
      ...prev,
      region: event.target.value,
    }));
    setErrorFields({ ...errorFields, region: false });
  };

  const handleTariffChange = (event, index) => {
    const { name, value } = event?.target;

    const newTariff = [...tariffArray];

    newTariff[index] = {
      ...newTariff[index],
      [name]: value,
    };

    setTariffArray(newTariff);

    setTariffValues((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErrorFields({ ...errorFields, [name]: false });
  };

  const handleChargeTypeChange = (event, index) => {
    const newTariff = [...tariffArray];

    newTariff[index] = {
      ...newTariff[index],
      tyepofcharge: parseInt(event.target.value),
    };

    setTariffArray(newTariff);

    setTariffValues((prev) => ({
      ...prev,
      tyepofcharge: parseInt(event.target.value),
    }));
    setErrorFields({ ...errorFields, tyepofcharge: false });
  };

  const handleFeatureChange = (event, tariffIndex, index) => {
    const { value } = event?.target;

    // Update tariffArray immediately
    const newTariff = [...tariffArray];
    newTariff[tariffIndex].addons[index] = {
      ...newTariff[tariffIndex].addons[index],
      amount: value ? parseInt(value) : "",
    };
    setTariffArray(newTariff);
  };

  const deleteRow = (index) => {
    const newTariff = [...tariffArray];
    if (id !== "no") {
      dispatch(
        deleteTarrifAction({
          apiPayloadRequest: {
            _id: newTariff[index]._id,
          },
          callback: (res) => {
            if (res.type === 1) {
              Toastify("Tariff Deleted Successfully!", 1);
              setTariffArray(newTariff.filter((_, idx) => idx !== index));
            } else {
              Toastify("Failed To Delete Tariff", 2);
            }
          },
        })
      );
    } else {
    }
  };

  const areObjectsEqual = (obj1, obj2) => {
    // Get the keys of both objects
    const keys1 = Object.keys(obj1);

    // Check if values are the same for each key
    for (const key of keys1) {
      if (key !== "addons" && obj1[key] !== obj2[key]) {
        return false;
      }
    }

    // If all checks pass, objects are equal
    return true;
  };

  const handleSubmit = () => {
    // if (isNaN(Number(tariffValues?.tyepofcharge))) {
    //   setErrorFields({ ...errorFields, tyepofcharge: true });
    //   return false;
    // } else if (!tariffValues?.vehicleObjId) {
    //   setErrorFields({ ...errorFields, vehicleObjId: true });
    //   return false;
    // } else if (tariffValues?.fromkm === "") {
    //   setErrorFields({ ...errorFields, fromkm: true });
    //   return false;
    // } else if (tariffValues?.tokm === "") {
    //   setErrorFields({ ...errorFields, tokm: true });
    //   return false;
    // } else if (tariffValues?.charges === "") {
    //   setErrorFields({ ...errorFields, charges: true });
    //   return false;
    // }
    // const specifications = featureStates?.find((v) => v.amount === ""); //to find empty specs amount
    // if (featureStates && specifications) {
    //   setErrorFields({ ...errorFields, specs: true });
    //   return false;
    // }

    // const payload1 = {
    //   vehicleObjId: tariffValues?.vehicleObjId,
    //   tyepofcharge: tariffValues?.tyepofcharge,
    //   fromkm: tariffValues?.fromkm,
    //   tokm: tariffValues?.tokm,
    //   charges: tariffValues?.charges,
    // };

    // if (props?.id !== "") {
    //   if (
    //     tariffValues?.vehicleObjId !== currentTariff?.vehicleObjId ||
    //     tariffValues?.tyepofcharge !== currentTariff?.tyepofcharge ||
    //     tariffValues?.chargestype !== currentTariff?.chargestype ||
    //     tariffValues?.charges !== currentTariff?.charges ||
    //     tariffValues?.fromkm !== currentTariff?.fromkm ||
    //     tariffValues?.tokm !== currentTariff?.tokm
    //   ) {
    //     payload1._id = props?.id;
    //     dispatch(
    //       updateTarrifAction({
    //         apiPayloadRequest: payload1,
    //         callback: (res) => {
    //           if (res?.type === 1) {
    //             featureStates.map(async (item) => {
    //               const exist = currentAddons.find(
    //                 (v) => v.addonObjId === item._id
    //               );
    //               if (exist && exist.amount !== item.amount) {
    //                 dispatch(
    //                   updateAddonTarrifAction({
    //                     apiPayloadRequest: {
    //                       _id: res?.data[0]?._id,
    //                       addonObjId: item?._id,
    //                       amount: item?.amount,
    //                     },
    //                     callback: (res) => {
    //                       if (res?.type !== 1) {
    //                         Toastify("Failed To update Addon Amount");
    //                       }
    //                     },
    //                   })
    //                 );
    //               }
    //             });
    //             Toastify("Tariff Updated Successfully!", 1);
    //             props?.setTariffUpdated(true);
    //             handleResetInputs();
    //             props.closeModal();
    //           } else {
    //             Toastify("Failed To Save Tariff");
    //           }
    //         },
    //       })
    //     );
    //   }
    // }
    // else {

    tariffArray.map((tariff, tariffIndex) => {
      if (tariff._id) {
        if (!areObjectsEqual(tariff, currentTariffArray[tariffIndex])) {
          setIsLoading(true);
          dispatch(
            updateTarrifAction({
              apiPayloadRequest: {
                _id: tariff._id,
                vehicleObjId: regionAndVehicle.vehicleObjId,
                regionObjId: regionAndVehicle.region,
                tyepofcharge: tariff.tyepofcharge,
                charges: tariff.charges,
                chargestype: tariff.chargestype,
                fromkm: tariff.fromkm,
                tokm: tariff.tokm,
              },
              callback: (res) => {
                if (res?.type === 1) {
                  Toastify("Tariff Updated Successfully!", 1);
                } else {
                  Toastify("Failed To Update Tariff");
                }
              },
            })
          );
        }

        tariff.addons.map((item, index) => {
          if (!areObjectsEqual(item, currentTariffArray[tariffIndex].addons[index])) {
            setIsLoading(true);
            dispatch(
              updateAddonTarrifAction({
                apiPayloadRequest: {
                  _id: tariff._id,
                  addonObjId: item?._id,
                  amount: item?.amount,
                },
                callback: (res) => {
                  if (res?.type !== 1) {
                    Toastify("Failed To save Addon Amount");
                  }
                },
              })
            );
          }
        });
      } else {
        setIsLoading(true);
        dispatch(
          saveTarrifAction({
            apiPayloadRequest: {
              vehicleObjId: regionAndVehicle.vehicleObjId,
              regionObjId: regionAndVehicle.region,
              tyepofcharge: tariff.tyepofcharge,
              charges: tariff.charges,
              chargestype: tariff.chargestype,
              fromkm: tariff.fromkm,
              tokm: tariff.tokm,
            },
            callback: (res) => {
              if (res?.type === 1) {
                Toastify("Tariff Saved Successfully!", 1);
                tariff.addons.map((item) => {
                  dispatch(
                    saveAddonTarrifAction({
                      apiPayloadRequest: {
                        _id: res?.data[0]._id,
                        addonObjId: item?._id,
                        amount: item?.amount,
                      },
                      callback: (res) => {
                        if (res?.type !== 1) {
                          Toastify("Failed To save Addon Amount");
                        }
                      },
                    })
                  );
                });
              } else {
                Toastify("Failed To Save Tariff");
              }
            },
          })
        );
      }
    });
    isUpdated();
  };

  useEffect(() => {
    if (id !== "no") {
      const data = myTariffdata[0];
      const tariffs = data?.tariffs;
      const addonTariff = tariffs[0]?.addontarrifs;
      let getVehicleObjId = vehicleOptions?.find(
        (v) => v.label === (data?.vehicle_name + " " + data?.vehicle_service || data?.vehicle_name)
      )?.value;
      if (!getVehicleObjId) getVehicleObjId = data?.vehicleObjId;

      setRegionAndVehicle({
        region: data?.regionObjId,
        vehicleObjId: data?.vehicleObjId,
      });

      tariffs.map((item) => {
        setTariffArray((prev) => [
          ...prev,
          {
            _id: item?.tarrifId,
            tyepofcharge: item?.tyepofcharge || "",
            charges: item?.charges || "",
            chargestype: item?.chargestype || "",
            fromkm: item?.fromkm || "",
            tokm: item?.tokm || "",
            addons: item?.addontarrifs?.map((addon) => ({
              _id: addon?._id || "",
              addon: addon?.addon || "",
              measurement: addon?.measurement || "",
              amount: addon?.amount || 0,
            })),
          },
        ]);

        setCurrentTariffArray((prev) => [
          ...prev,
          {
            _id: item?.tarrifId,
            tyepofcharge: item?.tyepofcharge || "",
            charges: item?.charges || "",
            chargestype: item?.chargestype || "",
            fromkm: item?.fromkm || "",
            tokm: item?.tokm || "",
            addons: item?.addontarrifs?.map((addon) => ({
              _id: addon?._id || "",
              addon: addon?.addon || "",
              measurement: addon?.measurement || "",
              amount: addon?.amount || 0,
            })),
          },
        ]);
      });

      setFeatureStates(
        addonTariff
          ? addonTariff.map((item) => ({
              _id: item?._id || "",
              addon: item?.addon || "",
              amount: item?.amount || 0,
            }))
          : []
      );
      // setFeatureStates(addonTariff);
    } else {
      setTariffArray([]);
      setRegionAndVehicle(intialRegionAndVehicle);
    }
  }, [id]);

  const cancelPopup = () => {
    handleResetInputs();
    props?.closeModal();
  };

  const isUpdated = () => {
    setTimeout(() => {
      props.setTariffUpdated(true);
      setTimeout(() => {
        cancelPopup();
      }, 500);
    }, 2000);
  };

  const addNewRow = () => {
    setTariffArray((prev) => [
      ...prev,
      {
        ...initialTariffValues,
        addons: featureStates.map((item) => ({
          _id: item?._id,
          addon: item?.addon,
          amount: 0,
        })),
      },
    ]);
  };

  return (
    <ReactModal
      isOpen={props?.isOpen}
      style={{
        ...customStyles,
        ...customContentStyles,
      }}
      onRequestClose={cancelPopup}
    >
      <div className="w-[80dvw] max-h-[95dvh] small-bar items-center overflow-y-auto bg-white border rounded-xl">
        <div className="w-full">
          <div className="w-full sticky top-0 bg-white">
            <div className="p-5 px-3  items-center flex justify-between">
              <div>
                <h1 className="text-lg font-bold">
                  {props?.id === "" ? "Add New Tariff" : "Update Tariff"}
                </h1>
              </div>
              <div className="text-lg px-3 cursor-pointer" onClick={cancelPopup}>
                <img src={wrong} />
              </div>
            </div>
            <img className="w-full h-[1px]" src={Line4} />
          </div>
          <div className="p-5 w-full flex flex-col gap-6 items-center">
            <div className="w-full flex gap-3 items-center justify-between p-5 border rounded-lg">
              <div className="flex w-full gap-3">
                <div className="w-1/4">
                  <InputDropdown
                    label="Region"
                    styles="border-2"
                    options={regionOptions}
                    value={regionAndVehicle?.region}
                    onChange={handleRegionChange}
                    selectName={"Region"}
                    isError={errorFields?.region}
                  />
                </div>
                <div className="w-1/4">
                  <InputDropdown
                    label="Type Of Vehicle"
                    styles="border-2"
                    options={vehicleOptions}
                    value={regionAndVehicle?.vehicleObjId}
                    onChange={handleVehicleTypeChange}
                    selectName={"Vehicle"}
                    isError={errorFields?.vehicleObjId}
                  />
                </div>
              </div>
              <div className="w-full flex gap-4 justify-end">
                <div
                  className="flex text-xs font-semibold w-1/4 justify-center items-center py-[9px] px-6 bg-[#F15A2A] text-white rounded-md cursor-pointer"
                  onClick={addNewRow}
                >
                  + Add Row
                </div>
                {/* <div className="flex text-xs font-semibold w-1/6 justify-center items-center py-[9px] px-6  bg-red-500 text-white rounded-md cursor-pointer">
                  Delete Tariff
                </div> */}
              </div>
            </div>
            {regionAndVehicle.vehicleObjId && (
              <div className="w-[100%] p-5">
                <div className="flex bg-slate-100 items-center justify-between rounded-lg font-bold p-3 mb-4 text-xs">
                  <div>Type Of Charge</div>
                  <div>From KM</div>
                  <div>To KM</div>
                  <div>Per KM Charge/Value</div>
                  {featureStates?.length > 0 &&
                    featureStates?.map((item, index) => (
                      <div key={index}>
                        {index + 1}
                        {". "} {item?.addon}
                      </div>
                    ))}
                  <div className=" w-[30px]"></div>
                </div>
                {tariffArray?.map((_, index) => (
                  <AddonsCard
                    key={index}
                    typeOfChargeOptions={typeOfChargeOptions}
                    tariffValues={tariffValues}
                    handleChargeTypeChange={handleChargeTypeChange}
                    errorFields={errorFields}
                    index={index}
                    handleTariffChange={handleTariffChange}
                    featureStates={featureStates}
                    handleFeatureChange={handleFeatureChange}
                    rowsInput={tariffArray}
                    deleteRow={deleteRow}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="px-3 pt-3 pb-2 mb-5 shadow-2xl flex mx-5 justify-end">
          <div className="flex items-center">
            <Button
              onClick={cancelPopup}
              label="Cancel"
              background={"bg-slate-300 px-6"}
              textcolor={"text-slate-600"}
            />
            <Button
              onClick={handleSubmit}
              label={props?.id === "" ? "Add" : "Update"}
              background={"bg-[#F15A2A] px-8"}
              textcolor={"text-white"}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

export default ServiceTariffModal;

const AddonsCard = ({
  typeOfChargeOptions,
  tariffValues,
  handleChargeTypeChange,
  errorFields,
  index,
  handleTariffChange,
  featureStates,
  handleFeatureChange,
  rowsInput,
  deleteRow,
}) => {
  return (
    <div className="flex justify-between items-center gap-x-5 border border-black border-opacity-20 rounded-xl mt-1 text-sm font-medium p-3 ">
      <div>
        <InputDropdown
          // label='Type Of Charge'
          styles="border-2"
          options={typeOfChargeOptions}
          value={rowsInput[index]?.tyepofcharge}
          onChange={(e) => handleChargeTypeChange(e, index)}
          selectName={"Charge"}
          isError={errorFields?.tyepofcharge}
        />
      </div>
      <div>
        <TextInputField
          name={"fromkm"}
          handleChange={(e) => handleTariffChange(e, index)}
          value={rowsInput[index]?.fromkm}
          // label='From KM'
          placeholder="Enter from KM"
          isError={errorFields?.fromkm}
        />
      </div>
      <div>
        <TextInputField
          name={"tokm"}
          handleChange={(e) => handleTariffChange(e, index)}
          value={rowsInput[index]?.tokm}
          // label='To KM'
          placeholder="Enter to KM"
          isError={errorFields?.tokm}
        />
      </div>
      <div>
        <TextInputField
          name={"charges"}
          handleChange={(e) => handleTariffChange(e, index)}
          value={rowsInput[index]?.charges}
          // label={tariffValues?.tyepofcharge?.toString() === "1" ? 'Charge' : 'Per KM Charge'}
          placeholder="Enter Amount"
          isError={errorFields?.charges}
        />
      </div>
      {rowsInput[index].addons?.map((item, idx) => (
        <div key={idx}>
          <TextInputField
            placeholder="Enter Amount"
            value={item?.amount}
            handleChange={(event) => handleFeatureChange(event, index, idx)}
            isError={errorFields?.specs && item?.amount === ""}
          />
        </div>
      ))}
      <div>
        <OutlineButton label="Delete" onClick={() => deleteRow(index)} />
      </div>
    </div>
  );
};
