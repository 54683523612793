import { useEffect, useMemo, useState } from "react";
import { FaLocationDot } from "react-icons/fa6";
import { FaRupeeSign, FaStar } from "react-icons/fa";
import { IoCall, IoSpeedometer } from "react-icons/io5";
import { FaChevronDown } from "react-icons/fa";
import Omni from "../assets/images/Omni.png";
import MapBackground from "../assets/images/Backgroundmap.png";
import Animation from "../assets/images/animation.gif";
import NotFound from "../assets/images/not_found.png";
import Wind from "../assets/images/wind.gif";
import Completed from "../assets/images/completed.png";
import WhatsApp from "../assets/images/whatsapp.png";
import Mail from "../assets/images/mail.png";
import Download from "../assets/images/download.png";
import Toastify from "../components/Universal/Toastify";
import UserHeader from "../components/Universal/UserHeader";
import LocationInputV2 from "../components/Common/InputFields/LocationInputV2";
import { initialLocationValues, libraries } from "../constants/Constant";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  readAddonAction,
  readDriversLocationsAction,
  readTripTarrifAction,
  readVehicleAction,
  saveQuickBookingLocation,
  saveQuickBookingLocationSuggestions,
  saveTripAction,
  saveTripAddonAction,
  saveTripDriverAction,
  updateTripAction,
  updateTripAddonAction,
} from "../store/Service/ServiceAction";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import {
  DirectionsRenderer,
  GoogleMap,
  InfoWindow,
  LoadScript,
  Marker,
} from "@react-google-maps/api";
import { ToWords } from "to-words";

import Ambulance from "../assets/images/Ambulance.png";
import Ambulance4 from "../assets/images/Ambulance4.gif";
import { MdBatteryChargingFull } from "react-icons/md";
import CounterInputs from "../components/Common/InputFields/CounterInputs";
import { useSocket } from "../components/Common/SocketContext";
import ConfirmToast from "../components/Common/ConfirmToast";
import { confirmAlert } from "react-confirm-alert";
import { customStyles, customContentStyles } from "../constants/Constant";
import ReactModal from "react-modal";
import wrong from "../assets/images/wrong.png";
import { updateProfileAction } from "../store/LoginAndRegister/LoginAndRegisterAction";
import { updateUserDetailsAction } from "../store/UserProfile/UserProfileAction";
import LoaderSpinner from "../components/Universal/LoaderSpinner";

const Trip = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const socket = useSocket();

  const { userDetails } = useSelector((state) => state.userProfile);

  console.log("userDetails", userDetails);

  const [activeTab, setActiveTab] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [selectedVehicleData, setSelectedVehicleData] = useState({});
  const [currentModal, setCurrentModal] = useState(0);
  const [currentTripDetails, setCurrentTripDetails] = useState({});
  const [locationInput, setLocationInput] = useState(initialLocationValues);
  const [pickUpFormattedAddress, setPickUpFormattedAddress] = useState("");
  const [dropFormattedAddress, setDropFormattedAddress] = useState("");
  const [distance, setDistance] = useState({ km: "", time: "" });
  const [directionResult, setDirectionResult] = useState([]);
  const [allVehicleData, setAllVehicleData] = useState([]);
  const [tariffLoading, setTariffLoading] = useState(false);
  const [addonsLoading, setAddonsLoading] = useState(false);
  const [addonList, setAddonList] = useState([]);
  const [selectedAddonsData, setSelectedAddonsData] = useState([]);
  const [driverDetails, setDriverDetails] = useState([]);
  const [callDrivers, setCallDrivers] = useState(false);
  const [locationUpated, setLocationUpdated] = useState(true);
  const [tripComment, setTripComment] = useState("");
  const [nearbyDrivers, setNearbyDrivers] = useState([]);
  const [tripsRejected, setTripsRejected] = useState(0);
  const [assignedDriverDetails, setAssignedDriverDetails] = useState({});
  const [recenterMap, setRecenterMap] = useState(false);
  const [mobileModalOpen, setMobileModalOpen] = useState(false);

  const totalTripPrice = useMemo(() => {
    return (
      selectedVehicleData?.tariffDetails?.calculatedcharges +
      selectedVehicleData?.tariffDetails?.addontarrifs?.reduce((prev, item) => {
        const amount = selectedVehicleData?.featuresList?.find(
          (feature) => feature?._id === item?.addonObjId
        )?.quantity;
        return prev + (item?.amount ? Number(item?.amount) : 0) * (amount ?? 0);
      }, 0)
    );
  }, [selectedVehicleData]);

  console.log("locationInput89", locationInput);
  console.log("assignedDriverDetails", assignedDriverDetails);
  console.log("totalTripPrice", totalTripPrice);
  console.log("selectedVehicleData", selectedVehicleData);
  console.log("currentModal", currentModal);

  const fetchDriverDetails = () => {
    dispatch(
      readDriversLocationsAction({
        callback: (res) => {
          if (res?.type === 1) {
            const data = res?.data
              // ?.filter((i) => i?.coords)
              .map((item) => ({
                ...item,
                isOnline: false,
                coords: item?.user?.lastLocation?.coords
                  ? item?.user?.lastLocation?.coords
                  : {
                      latitude: 17.5145267,
                      longitude: 78.3873372,
                      accuracy: 15.8,
                      speed: -1,
                      speed_accuracy: 1.5,
                      heading: -1,
                      heading_accuracy: 45,
                      altitude: 538,
                      ellipsoidal_altitude: 538,
                      altitude_accuracy: 2,
                      age: 2761,
                    },
              }));
            setDriverDetails(data);
            setTimeout(() => {
              setCallDrivers(true);
            }, 2000);
          }
        },
      })
    );
  };

  const fetchAddonDetails = () => {
    setAddonsLoading(true);
    dispatch(
      readAddonAction({
        callback: (res) => {
          if (res?.type === 1) {
            setAddonList(res?.data);
          }
          setAddonsLoading(false);
        },
      })
    );
  };

  console.log("addonList", addonList);

  useEffect(() => {
    fetchDriverDetails();
    fetchAddonDetails();
  }, []);

  const handleDriverDetails = (data) => {
    if (driverDetails?.length > 0) {
      let tempDriverDetails = [...driverDetails];
      data?.forEach((userAuthId) => {
        const currentDataIndex = tempDriverDetails?.findIndex(
          (item) => item?.user?.userAuthId === userAuthId
        );
        if (currentDataIndex !== -1) tempDriverDetails[currentDataIndex].isOnline = true;
      });
      setDriverDetails(tempDriverDetails);
    }
  };

  const handleDriverDetailsUpdate = (newData) => {
    if (newData) {
      setDriverDetails((prev) => {
        if (prev?.length > 0) {
          const currentDataIndex = prev?.findIndex(
            (item) => item?.user?.userAuthId === newData?.userAuthId
          );

          if (newData?.userAuthId === assignedDriverDetails?.user?.userAuthId) {
            setAssignedDriverDetails((prev) => ({ ...prev, coords: newData?.location?.coords }));
          }

          if (currentDataIndex !== -1) {
            const newDataValues = [...prev];
            newDataValues[currentDataIndex].coords = newData?.location?.coords;
            return newDataValues;
          }

          return prev;
        }
      });
    }
  };

  const handleDriverOnline = (driver) => {
    if (driver?.length > 0 && driverDetails?.length > 0) {
      console.log("driverSocket : Online", driver);
      const updatedData = [...driverDetails];
      const getIndex = updatedData?.findIndex((item) => item?.user?.userAuthId === driver[0]);
      if (getIndex !== -1) updatedData[getIndex].isOnline = true;
      setDriverDetails(updatedData || []);
    }
  };

  const handleDriverOffline = (driver) => {
    if (driver?.length > 0 && driverDetails?.length > 0) {
      console.log("driverSocket : offline", driver);
      const updatedData = [...driverDetails];
      const getIndex = updatedData?.findIndex((item) => item?.user?.userAuthId === driver[0]);
      if (getIndex !== -1) updatedData[getIndex].isOnline = false;
      setDriverDetails(updatedData || []);
    }
  };

  const saveTripDriverDetails = (driverId) => {
    const findDriver = driverDetails?.find((d) => d?._id === driverId);
    console.log("findDriver", findDriver);
    console.log("distance", distance);
    dispatch(
      saveTripDriverAction({
        apiPayloadRequest: {
          _id: currentTripDetails?._id,
          // driverAuthId: findDriver?.user?.userAuthId,
          driverObjId: findDriver?._id,
          acceptedLatitude: locationInput?.pickup?.[0]?.location?.lat,
          acceptedLogitude: locationInput?.pickup?.[0]?.location?.lng,
          distanceToPickup:
            distance.km.split(" ")[1] === "km"
              ? distance.km.split(" ")[0]
              : parseFloat(distance.km.split(" ")[0]) / 1000,
        },
        callback: (res) => {
          if (res?.type === 1) {
            //updated driver
            Toastify("Driver Assigned Successfully!", 1);
            setAssignedDriverDetails(findDriver);
            setCurrentModal(6);
          } else {
            Toastify("Driver Assigning Failed!", 2);
          }
        },
      })
    );
  };

  const updateCurrentTripStatus = (details) => {
    console.log("eventstatuschange details", details);
    console.log("eventstatuschange currentTripDetails", currentTripDetails);
    if (details?.tripId === currentTripDetails?._id) {
      setCurrentTripDetails((prev) => ({ ...prev, status: details?.status }));
      if (details?.status === 7) {
        setCurrentModal(7);
      }
    }
  };

  useEffect(() => {
    if (socket?.connected && callDrivers && locationUpated) {
      console.log("driversonline : Client requests data");
      socket.emit("driversonline", { message: "Client requests data" });
    }

    if (socket?.connected && callDrivers) {
      socket.on("driversonline", (data) => {
        console.log("driversonline :", data);
        handleDriverDetails(data);
        setLocationUpdated(false);
      });

      socket.on("useroffline", (data) => {
        handleDriverOffline(data);
        console.log(data, "User Offline");
      });

      socket.on("useronline", (data) => {
        handleDriverOnline(data);
        console.log(data, "User Online");
      });

      socket.on("newlocation", (data) => {
        handleDriverDetailsUpdate(data);
        console.log(data, "User newlocation");
      });

      socket.on("bookingaccepted", (response) => {
        console.log(response, "bookingaccepted");
        saveTripDriverDetails(response?.data?._id);
      });

      socket.on("eventstatuschange", (response) => {
        console.log(response, "eventstatuschange");
        console.log(response?.data, "eventstatuschange");
        updateCurrentTripStatus(response?.data);
      });

      socket.on("bookingrejected", (data) => {
        console.log(data, "bookingrejected");
        setTripsRejected((prev) => {
          if (prev + 1 >= nearbyDrivers?.length) {
            setCurrentModal(5);
          }
          return prev + 1;
        });
      });
    }
  }, [socket, locationUpated, callDrivers]);

  const returnTariffDetails = async (
    selectedVehicleData,
    locationDetails = null,
    distanceDetails = null
  ) => {
    return new Promise((resolve, reject) => {
      console.log("inside promise");
      const actualLocation = locationDetails ?? locationInput;
      const actualDistance = distanceDetails ?? distance;
      dispatch(
        readTripTarrifAction({
          apiPayloadRequest: {
            // _id: editID ?? id,
            fromLatitude: actualLocation?.pickup[0]?.location?.lat,
            fromLongitude: actualLocation?.pickup[0]?.location?.lng,
            fromPincode: actualLocation.pickup[0]?.postalCode,
            fromPlaceId: actualLocation?.pickup[0]?.placeId,
            vehicleObjId: selectedVehicleData?._id,
            distance:
              actualDistance?.km?.split(" ")[1] === "km"
                ? actualDistance?.km?.split(" ")[0]
                : parseFloat(actualDistance?.km?.split(" ")[0]) / 1000,
          },
          callback: (res) => {
            console.log("inside res", res);
            if (res?.type === 1) {
              resolve(res?.data[0]);
            }
            resolve([]);
          },
        })
      );
    });
  };

  useEffect(() => {
    dispatch(
      readVehicleAction({
        callback: (res) => {
          if (res?.type === 1) {
            setAllVehicleData(res?.data);
          }
        },
      })
    );
  }, []);

  console.log("allVehicleData", allVehicleData);
  console.log("distance", distance);

  const handleTripSubmit = (updatedUserDetails = null) => {
    console.log("userDetails inside handleTripSubmit", userDetails);
    const latestUserDetails = updatedUserDetails ?? userDetails;
    if (!latestUserDetails?.mobile) {
      setMobileModalOpen(true);
      return;
    }
    setCurrentModal((prev) => prev + 1);
    if (!currentTripDetails?._id) {
      dispatch(
        saveTripAction({
          apiPayloadRequest: {
            bookinguserAuthId: "",
            callerName: `${latestUserDetails?.firstName} ${latestUserDetails?.lastName}`,
            callerPhoneNumber: latestUserDetails?.mobile ?? "",
            typeOfUser: "App User",
            typeOfUserCode: "63udl9",
            fromLatitude: locationInput?.pickup?.[0]?.location?.lat,
            fromLongitude: locationInput?.pickup?.[0]?.location?.lng,
            fromPincode: locationInput.pickup?.[0]?.postalCode,
            fromPlaceId: locationInput?.pickup?.[0]?.placeId,
            fromAddress: locationInput?.pickup?.[0]?.formattedAddress,
            toLatitude: locationInput?.drop?.[0]?.location?.lat,
            toLongitude: locationInput?.drop?.[0]?.location?.lng,
            toPincode: locationInput.drop?.[0]?.postalCode,
            toPlaceId: locationInput?.drop?.[0]?.placeId,
            toAddress: locationInput?.drop?.[0]?.formattedAddress,
            comments: tripComment,
            timeOfBookingRequest: new Date().toISOString(),
            typeofTrip: "Non-Emergency",
            distance:
              distance.km.split(" ")[1] === "km"
                ? distance.km.split(" ")[0]
                : parseFloat(distance.km.split(" ")[0]) / 1000,
          },
          callback: (res) => {
            if (res?.type === 1) {
              setCurrentTripDetails((prev) => ({
                ...prev,
                ...(res?.data?.[0] ?? {}),
              }));
              Toastify("Trip Initiated Successfully!", 1);
              dispatch(
                readTripTarrifAction({
                  apiPayloadRequest: {
                    _id: res?.data?.[0]?._id,
                    fromLatitude: locationInput?.pickup?.[0]?.location?.lat,
                    fromLongitude: locationInput?.pickup?.[0]?.location?.lng,
                    fromPincode: locationInput.pickup?.[0]?.postalCode,
                    fromPlaceId: locationInput?.pickup?.[0]?.placeId,
                    vehicleObjId: selectedVehicleData?._id,
                    distance:
                      distance?.km?.split(" ")[1] === "km"
                        ? distance?.km?.split(" ")[0]
                        : parseFloat(distance?.km?.split(" ")[0]) / 1000,
                  },
                  callback: (resp) => {
                    setCurrentTripDetails((prev) => ({
                      ...prev,
                      tripId: resp?.data[0]?.tripId,
                    }));
                  },
                })
              );
            } else if (res?.type === 2 || res?.type === 3) {
              Toastify(res?.message, 2);
            }
          },
        })
      );
    } else {
      dispatch(
        updateTripAction({
          apiPayloadRequest: {
            _id: currentTripDetails?._id,
            callerName: `${latestUserDetails?.firstName} ${latestUserDetails?.lastName}`,
            callerPhoneNumber: latestUserDetails?.mobile ?? "",
            typeOfUser: "App User",
            typeOfUserCode: "63udl9",
            fromLatitude: locationInput?.pickup[0]?.location?.lat,
            fromLongitude: locationInput?.pickup[0]?.location?.lng,
            fromPincode: locationInput.pickup[0]?.postalCode,
            fromPlaceId: locationInput?.pickup[0]?.placeId,
            fromAddress: locationInput?.pickup[0]?.formattedAddress,
            toLatitude: locationInput?.drop[0]?.location?.lat,
            toLongitude: locationInput?.drop[0]?.location?.lng,
            toPincode: locationInput.drop[0]?.postalCode,
            toPlaceId: locationInput?.drop[0]?.placeId,
            toAddress: locationInput?.pickup[0]?.formattedAddress,
            comments: tripComment,
            // dateandTimeofTrip: new Date().toISOString(),
            typeofTrip: "Non-Emergency",
            distance:
              distance.km.split(" ")[1] === "km"
                ? distance.km.split(" ")[0]
                : parseFloat(distance.km.split(" ")[0]) / 1000,
          },
          callback: (res) => {
            if (res?.type === 1) {
              Toastify("Trip Updated Successfully!", 1);
              setCurrentTripDetails((prev) => ({
                ...prev,
                fromLatitude: locationInput?.pickup[0]?.location?.lat,
                fromLongitude: locationInput?.pickup[0]?.location?.lng,
                fromPincode: locationInput.pickup[0]?.postalCode,
                fromPlaceId: locationInput?.pickup[0]?.placeId,
                fromAddress: locationInput?.pickup[0]?.formattedAddress,
                toLatitude: locationInput?.drop[0]?.location?.lat,
                toLongitude: locationInput?.drop[0]?.location?.lng,
                toPincode: locationInput.drop[0]?.postalCode,
                toPlaceId: locationInput?.drop[0]?.placeId,
                toAddress: locationInput?.pickup[0]?.formattedAddress,
                comments: tripComment,
                distance:
                  distance.km.split(" ")[1] === "km"
                    ? distance.km.split(" ")[0]
                    : parseFloat(distance.km.split(" ")[0]) / 1000,
              }));
            } else if (res?.type === 2 || res?.type === 3) {
              Toastify(res?.message, 2);
            }
          },
        })
      );
    }
    setTripsRejected(0);
    setNearbyDrivers([]);
  };

  const callTariff = async () => {
    try {
      if (distance.km !== "" && allVehicleData.length) {
        const resultList = allVehicleData.map((vehicle) => returnTariffDetails(vehicle));
        console.log("resultList", resultList);
        setTariffLoading(true);
        Promise.allSettled(resultList)
          .then((result) => {
            console.log(result, "result123");
            setAllVehicleData((prev) =>
              prev?.map((d, i) => ({
                ...d,
                cost: result?.[i]?.value?.cost,
                tariffDetails: result?.[i]?.value ?? null,
              }))
            );
          })
          .catch((error) => console.log(error))
          .finally(() => setTariffLoading(false));
      }
    } catch (error) {
      console.error("Error fetching tariff details:", error);
    }
  };

  useEffect(() => {
    callTariff();
  }, [distance]);

  useEffect(() => {
    if (directionResult && directionResult?.routes) {
      const route = directionResult?.routes;
      const distanceCalculated = route[0]?.legs[0]?.distance?.text;
      const durationCalculated = route[0]?.legs[0]?.duration?.text;
      if (distanceCalculated) setDistance({ km: distanceCalculated, time: durationCalculated });
    } else {
      setDistance({ km: "", time: "" });
      setLocationInput(initialLocationValues);
    }
  }, [directionResult]);

  useEffect(() => {
    if (location?.state?.pickup || location?.state?.drop) {
      setLocationInput({
        pickup: location?.state?.pickup,
        drop: location?.state?.drop,
      });
      setPickUpFormattedAddress(location?.state?.pickup?.[0]?.formattedAddress);
      setDropFormattedAddress(location?.state?.drop?.[0]?.formattedAddress);
      setShowModal(true);
    }
  }, [location]);

  useEffect(() => {
    if (locationInput.pickup || locationInput.drop) {
      dispatch(saveQuickBookingLocation(locationInput));
    }
  }, [locationInput]);

  const handleLocationInputChange = (data, name) => {
    setLocationInput((prev) => ({
      ...prev,
      [name]: data,
    }));
  };

  console.log("allVehicleData", allVehicleData);
  console.log("driverDetails", driverDetails);
  console.log("distance", distance);
  console.log("directionResult", directionResult);
  console.log("locationInput89", locationInput);

  const updateBookingPrice = (totalCharges) => {
    dispatch(
      updateTripAction({
        apiPayloadRequest: {
          _id: currentTripDetails?._id,
          vehicleObjId: selectedVehicleData?._id,
          price: totalCharges,
          distance:
            distance.km.split(" ")[1] === "km"
              ? distance.km.split(" ")[0]
              : parseFloat(distance.km.split(" ")[0]) / 1000,
        },
        callback: (res) => {
          if (res?.type === 1) {
            Toastify("Trip Updated Successfully!", 1);
          } else if (res?.type === 2 || res?.type === 3) {
            Toastify(res?.message, 2);
          }
        },
      })
    );
  };

  const sendBookingRequest = (driver) => {
    if (socket?.connected && callDrivers) {
      console.log("booking : Client requests booking");

      socket.emit("booking", {
        userId: driver?.user?.userAuthId,
        // userId: "google-oauth2|116759178528672705534",
        data: {
          _id: driver._id,
          // _id: "65ef2b5f3484da88c1d3b97a",
          tripId: currentTripDetails?._id,
          distance:
            distance?.km?.split(" ")[1] === "km"
              ? distance?.km?.split(" ")[0]
              : parseFloat(distance?.km?.split(" ")[0]) / 1000,
          typeofTrip: currentTripDetails?.typeofTrip,
          vehicleObjId: selectedVehicleData?._id,
          vehicleName:
            selectedVehicleData?.vehicle_type_name + " " + selectedVehicleData?.vehicle_service,
          featuresList: currentTripDetails?.featuresList,
          price: totalTripPrice,
          calculatedcharges: selectedVehicleData?.tariffDetails?.calculatedcharges,
          pickup: locationInput?.pickup?.[0]?.location,
          fromAddress: locationInput?.pickup?.[0]?.formattedAddress,
          drop: locationInput?.drop?.[0]?.location,
          toAddress: locationInput?.drop?.[0]?.formattedAddress,
          callerName: userDetails?.firstName + " " + userDetails?.lastName,
          callerPhoneNumber: userDetails?.mobile,
        },
      });

      // setTimeout(() => saveTripDriverDetails("65ef2b5f3484da88c1d3b97a"), 5000);
    }
  };

  const sendBookingRequestToDrivers = (drivers) => {
    setTripsRejected(0);

    updateBookingPrice(totalTripPrice);
    drivers?.map((driver) => {
      sendBookingRequest(driver);
    });
    Toastify("Booking Request Sent To Driver", 1);
  };

  const haversineDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Radius of the Earth in km
    const dLat = ((lat2 - lat1) * Math.PI) / 180;
    const dLon = ((lon2 - lon1) * Math.PI) / 180;
    const a =
      0.5 -
      Math.cos(dLat) / 2 +
      (Math.cos((lat1 * Math.PI) / 180) * Math.cos((lat2 * Math.PI) / 180) * (1 - Math.cos(dLon))) /
        2;
    return R * 2 * Math.asin(Math.sqrt(a));
  };

  const getDriversDistanceFromPickup = async (
    driver,
    pickupLat,
    pickupLon,
    driverLat,
    driverLon
  ) => {
    console.log(
      "driver",
      driver,
      "pickupLat",
      pickupLat,
      "pickupLon",
      pickupLon,
      "pickupLon",
      driverLat,
      "driverLon",
      driverLon
    );
    return new Promise((resolve, reject) => {
      if (window?.google?.maps) {
        const directionsService = new window.google.maps.DirectionsService();
        console.log("directionsService", directionsService);
        if (directionsService) {
          directionsService.route(
            {
              origin: { lat: pickupLat, lng: pickupLon },
              destination: {
                lat: driverLat,
                lng: driverLon,
              },
              travelMode: window?.google?.maps?.TravelMode?.DRIVING,
            },
            (result, status) => {
              if (status === window?.google?.maps?.DirectionsStatus?.OK) {
                if (result?.routes?.length > 0) {
                  console.log("result1234", result);
                  resolve({ driver, result });
                }
              } else {
                // Toastify("Route Not Found", 2);
                console.error(`Error fetching directions: ${status}`);
                resolve(null);
              }
            }
          );
        }
      } else {
        resolve(null);
      }
    }, []);
  };

  // Function to get drivers within a 2-2.5 km range
  const getNearbyDrivers = async () => {
    setCurrentModal(4);
    const minDistance = 0; // Minimum distance in km
    const maxDistance = 6; // Maximum distance in km

    const promiseList = [];

    driverDetails?.filter((driver) => {
      // const distance = haversineDistance(pickupLat, pickupLon, driverLat, driverLon);
      const promise = getDriversDistanceFromPickup(
        driver,
        locationInput?.pickup?.[0]?.location?.lat,
        locationInput?.pickup?.[0]?.location?.lng,
        driver?.coords?.latitude,
        driver?.coords?.longitude
      );
      promiseList.push(promise);
    });

    console.log("promiseList", promiseList);

    const nearbyDriversList = [];

    Promise.allSettled(promiseList).then((results) => {
      console.log("driverDistance results", results);
      results?.map((result) => {
        const driverDistance = Math.abs(
          (result.value?.result?.routes?.[0]?.legs?.[0]?.distance?.value ?? 0) / 1000
        );
        console.log("driverDistance", driverDistance);
        console.log("driverDistance 78", result.value?.result?.routes?.[0]?.legs?.[0]?.distance);
        console.log("driverDistance driver", result?.value?.driver);
        console.log(
          "driverDistance driver",
          result?.value?.driver?.user?.firstName + " " + result?.value?.driver?.user?.lastName
        );
        console.log(
          "driverDistance if condition",
          result.value?.result &&
            Number(driverDistance) >= Number(minDistance) &&
            Number(driverDistance) <= Number(maxDistance)
        );
        if (
          result.value?.result &&
          Number(driverDistance) >= Number(minDistance) &&
          Number(driverDistance) <= Number(maxDistance)
        ) {
          nearbyDriversList.push(result?.value?.driver);
        }
      });
      console.log("driverDistance nearbyDriversList", nearbyDriversList);
      if (nearbyDriversList?.length === 0) {
        setCurrentModal(5);
      } else {
        sendBookingRequestToDrivers(nearbyDriversList);
        setNearbyDrivers(nearbyDriversList);
      }
    });
  };

  console.log("currentTripDetails", currentTripDetails);

  return (
    <div className="flex flex-col md:h-screen overflow-y-auto relative">
      <UserHeader activeTab={activeTab} setActiveTab={setActiveTab} />

      <div className="flex md:flex-row flex-col gap-[2.5%] w-full md:h-screen md:pt-[80px] md:pb-6 pb-[80px] py-6 px-[5%] md:overflow-hidden overflow-y-auto">
        <TripInputs
          setShowModal={setShowModal}
          handleLocationInputChange={handleLocationInputChange}
          pickUpFormattedAddress={pickUpFormattedAddress}
          setPickUpFormattedAddress={setPickUpFormattedAddress}
          dropFormattedAddress={dropFormattedAddress}
          setDropFormattedAddress={setDropFormattedAddress}
          locationInput={locationInput}
          distance={distance}
          setRecenterMap={setRecenterMap}
          setCurrentModal={setCurrentModal}
          setSelectedVehicleData={setSelectedVehicleData}
        />
        <div className="flex md:flex-row flex-col-reverse w-full h-full md:rounded-lg md:overflow-hidden border border-black/20">
          <div
            className={`${
              showModal ? "w-full p-5" : "w-0 p-0"
            } transition-all duration-300 md:h-full ${
              showModal ? "h-screen" : "h-0"
            } bg-white md:overflow-auto overflow-hidden`}
          >
            {currentModal === 0 &&
              (addonsLoading ? (
                <DetailsLoadingModal
                  title="Fetching Add-on details..."
                  subtitle="The services will be loaded soon"
                  btnEnable={false}
                  currentTripDetails={currentTripDetails}
                />
              ) : (
                <AddonListModal
                  showModal={showModal}
                  selectedAddonsData={selectedAddonsData}
                  setSelectedAddonsData={setSelectedAddonsData}
                  setShowModal={setShowModal}
                  addonData={addonList}
                />
              ))}
            {currentModal === 1 &&
              (tariffLoading ? (
                <DetailsLoadingModal
                  title="We are fetching tariff details..."
                  subtitle="Your vehicles will be loaded soon"
                  btnEnable={false}
                  currentTripDetails={currentTripDetails}
                />
              ) : (
                <DetailsModal
                  showModal={showModal}
                  selectedVehicleData={selectedVehicleData}
                  setSelectedVehicleData={setSelectedVehicleData}
                  setShowModal={setShowModal}
                  allVehicleData={allVehicleData}
                  handleTripSubmit={handleTripSubmit}
                  totalTripPrice={totalTripPrice}
                />
              ))}
            {currentModal === 2 && (
              <SelectQuatitiesModal
                setCurrentModal={setCurrentModal}
                selectedVehicleData={selectedVehicleData}
                setSelectedVehicleData={setSelectedVehicleData}
                distance={distance}
                tripComment={tripComment}
                setTripComment={setTripComment}
                currentTripDetails={currentTripDetails}
                setCurrentTripDetails={setCurrentTripDetails}
                totalTripPrice={totalTripPrice}
              />
            )}
            {currentModal === 3 && (
              <ConfirmModal
                setCurrentModal={setCurrentModal}
                getNearbyDrivers={getNearbyDrivers}
                distance={distance}
                locationInput={locationInput}
                totalTripPrice={totalTripPrice}
                selectedVehicleData={selectedVehicleData}
                setShowModal={setShowModal}
                setSelectedVehicleData={setSelectedVehicleData}
              />
            )}
            {currentModal === 4 && (
              <DetailsLoadingModal
                setCurrentModal={setCurrentModal}
                setSelectedVehicleData={setSelectedVehicleData}
                currentTripDetails={currentTripDetails}
              />
            )}
            {currentModal === 5 && (
              <DetailsLoadingModal
                btnEnable={false}
                title="Unable to locate any Drivers nearby!!"
                subtitle="Please contact the Customer Support team for booking your request"
                showContactBtn={true}
                currentTripDetails={currentTripDetails}
              />
            )}
            {currentModal === 6 && (
              <DriverArrivingModal
                setCurrentModal={setCurrentModal}
                assignedDriverDetails={assignedDriverDetails}
                selectedVehicleData={selectedVehicleData}
                locationInput={locationInput}
                totalTripPrice={totalTripPrice}
                setSelectedVehicleData={setSelectedVehicleData}
                currentTripDetails={currentTripDetails}
              />
            )}
            {currentModal === 7 && (
              <TripCompletedModal
                setSelected={setSelectedVehicleData}
                setCurrentModal={setCurrentModal}
                setShowModal={setShowModal}
                assignedDriverDetails={assignedDriverDetails}
                currentTripDetails={currentTripDetails}
                selectedVehicleData={selectedVehicleData}
                totalTripPrice={totalTripPrice}
                locationInput={locationInput}
              />
            )}
          </div>
          <div className="w-full md:h-full h-[50vh] relative overflow-hidden">
            <MapContainer
              driverDetails={driverDetails}
              sendResponse={(res) => setDirectionResult(res)}
              suggestions={(res) => {}}
              recenterMap={recenterMap}
              setRecenterMap={setRecenterMap}
            />
          </div>
          <AddMobileModal
            mobileModalOpen={mobileModalOpen}
            setMobileModalOpen={setMobileModalOpen}
            userDetails={userDetails}
            handleTripSubmit={handleTripSubmit}
          />
        </div>
      </div>
    </div>
  );
};

const DetailsModal = ({
  showModal,
  selectedVehicleData = {},
  setSelectedVehicleData,
  setShowModal,
  allVehicleData,
  handleTripSubmit,
  totalTripPrice,
}) => {
  // Check if there is data in the features' addonObjId arrays
  const checkFeaturesAvailable = (item) => {
    return (
      item.features &&
      item.features.some((feature) => feature.addonObjId && feature.addonObjId.length > 0)
    );
  };

  const handleVehicleSelect = (currentVehicleData) => {
    const featuresList = checkFeaturesAvailable(currentVehicleData)
      ? currentVehicleData?.features?.map((v) => v?.addonObjId[0])
      : [];

    if (featuresList?.length > 0) {
      currentVehicleData.featuresList = featuresList;
    } else {
      currentVehicleData.featuresList = [];
    }
    setSelectedVehicleData(currentVehicleData);
  };

  return (
    <div
      className={`${
        showModal ? "opacity-100 delay-500 duration-300" : "opacity-0 text-0"
      } transition-all flex flex-col gap-2 h-full`}
    >
      <p className="text-2xl font-semibold mb-2">Choose Vehicle</p>
      <p className="text-sm font-semibold px-2">Recommended</p>
      <div className="h-full w-full flex flex-col gap-2 overflow-y-auto px-2">
        {allVehicleData?.map((vehicle, index) => {
          return (
            vehicle?.tariffDetails?.calculatedcharges > 0 && (
              <VehicleCard
                vehicle={vehicle}
                showModal={showModal}
                selected={vehicle?._id === selectedVehicleData?._id}
                index={index}
                key={"vehicle-type-card" + index}
                setSelected={handleVehicleSelect}
                totalTripPrice={totalTripPrice}
              />
            )
          );
        })}
      </div>
      <div className="p-4 w-full shadowV1 rounded-xl flex md:flex-row flex-col-reverse justify-between items-center mt-2 gap-2">
        <button
          onClick={() => {
            setShowModal(false);
            setSelectedVehicleData({});
          }}
          className="text-sm py-2 px-5 border-2 border-primary text-primary rounded-lg md:w-fit w-full"
        >
          Change location
        </button>
        <button
          onClick={() => {
            if (selectedVehicleData?._id) {
              handleTripSubmit();
            }
          }}
          className="text-sm py-2 px-5 bg-primary text-white rounded-lg md:w-fit w-full"
          data-tooltip-id="confirmAmbulance"
          data-tooltip-content="Please select a vehicle to proceed"
        >
          Confirm Ambulance
        </button>
        <Tooltip position="top" id="confirmAmbulance" />
      </div>
    </div>
  );
};

const AddonListModal = ({
  showModal,
  selectedAddonsData,
  setSelectedAddonsData,
  setShowModal,
  addonData,
}) => {
  // Check if there is data in the features' addonObjId arrays
  const checkFeaturesAvailable = (item) => {
    return (
      item.features &&
      item.features.some((feature) => feature.addonObjId && feature.addonObjId.length > 0)
    );
  };

  const handleAddonSelect = (currentAddonData) => {
    if (selectedAddonsData?.includes(currentAddonData?._id)) {
      setSelectedAddonsData((prev) => prev?.filter((d) => d !== currentAddonData?._id));
    } else {
      setSelectedAddonsData((prev) => [...prev, currentAddonData?._id]);
    }
  };

  console.log('selectedAddonsData', selectedAddonsData)

  return (
    <div
      className={`${
        showModal ? "opacity-100 delay-500 duration-300" : "opacity-0 text-0"
      } transition-all flex flex-col gap-2 h-full`}
    >
      <p className="text-2xl font-semibold mb-2">Choose Preferred Addons</p>
      <div className="h-full w-full flex flex-col gap-2 overflow-y-auto px-2">
        {addonData?.map((addon, index) => {
          return (
            <AddonCard
              addon={addon}
              showModal={showModal}
              selected={selectedAddonsData?.includes(addon?._id)}
              index={index}
              key={"addon-item-card" + index}
              setSelected={handleAddonSelect}
            />
          );
        })}
      </div>
      <div className="p-4 w-full shadowV1 rounded-xl flex md:flex-row flex-col-reverse justify-between items-center mt-2 gap-2">
        <button
          onClick={() => {
            setShowModal(false);
            setSelectedAddonsData({});
          }}
          className="text-sm py-2 px-5 border-2 border-primary text-primary rounded-lg md:w-fit w-full"
        >
          Change location
        </button>
        <button
          onClick={() => {
            if (selectedAddonsData?.length > 0) {
              // call API for tarrif search
            }
          }}
          className="text-sm py-2 px-5 bg-primary text-white rounded-lg md:w-fit w-full"
          data-tooltip-id="confirmAmbulance"
          data-tooltip-content="Please select a vehicle to proceed"
        >
          Search Ambulances
        </button>
        <Tooltip position="top" id="confirmAmbulance" />
      </div>
    </div>
  );
};

const SelectQuatitiesModal = ({
  setCurrentModal,
  selectedVehicleData,
  setSelectedVehicleData,
  distance,
  tripComment,
  setTripComment,
  currentTripDetails,
  setCurrentTripDetails,
  totalTripPrice,
}) => {
  const dispatch = useDispatch();
  const handleFeatureQuantitySelect = (featureIndex, count) => {
    let vehicleFeatures = selectedVehicleData?.featuresList;
    vehicleFeatures[featureIndex].quantity = count;
    setSelectedVehicleData((prev) => ({ ...prev, featuresList: vehicleFeatures }));
    saveAddonsDetails(vehicleFeatures);
  };

  const saveAddonsDetails = (vehicleFeatures = null) => {
    const actualVehicleFeatures = vehicleFeatures ?? selectedVehicleData?.featuresList;
    actualVehicleFeatures?.map(async (item) => {
      const singleprice =
        selectedVehicleData?.tariffDetails?.addontarrifs?.find((d) => d?.addonObjId === item?._id)
          ?.amount ?? 0;
      if (item?.isSaved) {
        dispatch(
          updateTripAddonAction({
            apiPayloadRequest: {
              _id: currentTripDetails?._id,
              addonObjId: item?._id,
              addonCount: item?.quantity,
              singleprice: singleprice,
              totalprice: singleprice * (item?.quantity ?? 0),
            },
            callback: (res) => {
              if (res?.type === 1) {
                //updated equipmentArray
              }
            },
          })
        );
      } else {
        dispatch(
          saveTripAddonAction({
            apiPayloadRequest: {
              _id: currentTripDetails?._id,
              addonObjId: item?._id,
              addonCount: item?.quantity ?? 0,
              singleprice: singleprice,
              totalprice: singleprice * (item?.quantity ?? 0),
            },
            callback: (res) => {
              if (res?.type === 1) {
                //updated equipmentArray
              }
            },
          })
        );
      }
    });

    setSelectedVehicleData((prev) => {
      const newfeatureList = prev?.featuresList?.map((d) => ({ ...d, isSaved: true }));
      return { ...prev, featuresList: newfeatureList };
    });

    const updatedTripPrice =
      selectedVehicleData?.tariffDetails?.calculatedcharges +
      selectedVehicleData?.tariffDetails?.addontarrifs?.reduce((prev, item) => {
        const amount = actualVehicleFeatures?.find(
          (feature) => feature?._id === item?.addonObjId
        )?.quantity;
        return prev + (item?.amount ? Number(item?.amount) : 0) * (amount ?? 0);
      }, 0);

    dispatch(
      updateTripAction({
        apiPayloadRequest: {
          _id: currentTripDetails?._id,
          vehicleObjId: selectedVehicleData?._id,
          price: updatedTripPrice ?? 0,
          distance:
            distance.km.split(" ")[1] === "km"
              ? distance.km.split(" ")[0]
              : parseFloat(distance.km.split(" ")[0]) / 1000,
        },
        callback: (res) => {
          if (res?.type === 1) {
            Toastify("Trip Updated Successfully!", 1);
          } else if (res?.type === 2 || res?.type === 3) {
            Toastify(res?.message, 2);
          }
        },
      })
    );
  };

  return (
    <div className="flex flex-col gap-4 justify-between h-full">
      <div className="p-2 pb-4 flex gap-3 items-center border-b border-black/10">
        <div
          style={{ backgroundImage: `url(${Omni})` }}
          className="w-12 aspect-square rounded-md bg-contain bg-center bg-no-repeat"
        ></div>
        <p className="font-semibold text-lg">
          {selectedVehicleData?.vehicle_type_name} ({selectedVehicleData?.vehicle_service})
        </p>
      </div>
      <div className="flex flex-col h-full gap-4 px-2 overflow-y-auto">
        <div className="flex flex-col">
          {/* <p className="font-semibold">Quantity Requirement</p> */}
          <p className="text-xs font-medium text-black/50">Select your Options</p>
        </div>
        {selectedVehicleData && (
          <div className="p-4 rounded-lg border">
            <p className="text-lg font-bold mb-2">Quantity Requirement</p>
            {selectedVehicleData?.featuresList?.length > 0 &&
              selectedVehicleData?.featuresList?.map((feature, i) => (
                <div key={"feature-item" + i}>
                  <div>
                    <p className="text-xs font-medium">
                      How many {feature?.addon} do you need?
                      <span className="text-[10px] text-slate-400"> ({feature?.measurement})</span>
                    </p>
                  </div>

                  <div className="flex flex-row gap-2">
                    <CounterInputs
                      name={feature?.addon}
                      limit={5}
                      value={feature?.quantity ? feature?.quantity : 0}
                      onChange={(count) => {
                        handleFeatureQuantitySelect(i, count);
                      }}
                    />
                  </div>
                </div>
              ))}
          </div>
        )}
        <div className="flex flex-col">
          <p className="text-xs font-semibold">
            Comments <span className="font-normal text-black/40 ">(Optional)</span>
          </p>
          <div className="border border-black/20 rounded-md p-2">
            <textarea
              className="w-full focus:outline-none text-xs"
              rows={4}
              value={tripComment}
              onChange={(e) => setTripComment(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="p-4 w-full md:flex-row flex-col-reverse border border-black/10 rounded-md flex justify-between items-center mt-2 gap-2">
        <button
          onClick={() => setCurrentModal((prev) => prev - 1)}
          className="text-sm py-2 px-5 border-2 border-primary text-primary rounded-md md:w-fit w-full"
        >
          Back
        </button>
        <button
          onClick={() => {
            const myFeatureList = selectedVehicleData?.featuresList.map((item) => ({
              feature: item?.addon + " " + item?.quantity + " " + item?.measurement,
            }));
            setCurrentTripDetails((prev) => ({ ...prev, featuresList: myFeatureList }));
            setCurrentModal((prev) => prev + 1);
          }}
          className="text-sm py-2 px-5 bg-primary text-white rounded-md md:w-fit w-full"
        >
          Confirm Requirements
        </button>
      </div>
    </div>
  );
};

const ConfirmModal = ({
  setCurrentModal,
  getNearbyDrivers,
  distance,
  locationInput,
  totalTripPrice,
  selectedVehicleData,
  setShowModal,
  setSelectedVehicleData,
}) => {
  const handleEditAddress = (type) => {
    setShowModal(false);
    setCurrentModal(0);
    setSelectedVehicleData({});
    document.querySelector(`#${type}AddressInput`)?.focus();
  };

  return (
    <div className="flex flex-col gap-4 justify-between h-full">
      <div className="flex flex-col gap-6">
        <div className="p-2 pb-4 flex gap-3 items-center border-b border-black/10">
          <div
            style={{ backgroundImage: `url(${Omni})` }}
            className="w-12 aspect-square rounded-md bg-contain bg-center bg-no-repeat"
          ></div>
          <p className="font-semibold text-lg">
            {selectedVehicleData?.vehicle_type_name} ({selectedVehicleData?.vehicle_service})
          </p>
        </div>
        <div
          style={{ backgroundImage: `url(${MapBackground})` }}
          className="rounded-md bg-center bg-contain text-xs"
        >
          <div className="m-6 shadow-lg p-4 gap-4 flex flex-col bg-white rounded-md">
            <div className="flex justify-between items-center max-w-full gap-1">
              <FaLocationDot color="#0E2D9B" size={14} />
              <p className="whitespace-nowrap overflow-hidden overflow-ellipsis flex flex-1">
                {locationInput?.pickup?.[0]?.formattedAddress}
              </p>
              <button
                className="text-secondary font-medium"
                onClick={() => handleEditAddress("pickup")}
              >
                Edit
              </button>
            </div>
            <div className="flex justify-between items-center max-w-full gap-1">
              <FaLocationDot color="#F15A2A" size={14} />
              <p className="whitespace-nowrap overflow-hidden overflow-ellipsis flex flex-1">
                {locationInput?.drop?.[0]?.formattedAddress}
              </p>
              <button
                className="text-secondary font-medium"
                onClick={() => handleEditAddress("drop")}
              >
                Edit
              </button>
            </div>
          </div>
        </div>
        {selectedVehicleData?.featuresList?.length > 0 && (
          <div className="flex justify-around flex-wrap">
            {selectedVehicleData?.featuresList?.map((feature, i) => (
              <div className="flex flex-col" key={"feature-item" + i}>
                <p className="text-[12px] font-semibold">{feature?.addon}</p>
                <div className="flex gap-1 items-end ">
                  <p className="text-3xl text-primary">
                    {feature?.quantity ? feature?.quantity : 0}
                  </p>
                  <p className="text-[9px] text-black/40 font-medium pb-1">
                    {feature?.measurement}
                  </p>
                </div>
              </div>
            ))}
          </div>
        )}
        <div className="p-3 rounded-md bg-primary/20 flex justify-between items-center">
          <p className="text-xs font-medium text-black/80">Distance ({distance?.km})</p>
          <div className="flex gap-1">
            <p className="font-semibold">₹{totalTripPrice}</p>
          </div>
        </div>
      </div>

      <div className="p-4 w-full md:flex-row flex-col-reverse border border-black/10 rounded-md flex justify-between items-center mt-2 gap-2">
        <button
          onClick={() => setCurrentModal((prev) => prev - 1)}
          className="text-sm py-2 px-5 border-2 border-primary text-primary rounded-md md:w-fit w-full"
        >
          Back
        </button>
        <button
          onClick={() => {
            getNearbyDrivers();
          }}
          className="text-sm py-2 px-5 bg-primary text-white rounded-md md:w-fit w-full"
        >
          Book Ambulance
        </button>
      </div>
    </div>
  );
};

const DetailsLoadingModal = ({
  setCurrentModal,
  title = "Searching nearby drivers...",
  subtitle = "Your ride will start soon",
  btnEnable = true,
  showContactBtn = false,
  setSelectedVehicleData,
  currentTripDetails = {},
}) => {
  return (
    <div className="w-full h-full flex flex-col justify-between items-center gap-2">
      <div className="flex flex-col justify-center items-center h-full">
        <div className="relative">
          <div
            style={{ backgroundImage: `url(${showContactBtn ? NotFound : Animation})` }}
            className="w-36 aspect-square bg-contain bg-center bg-no-repeat"
          ></div>
          {!showContactBtn && (
            <div
              style={{ backgroundImage: `url(${Wind})` }}
              className="absolute bottom-6 -left-8 w-10 aspect-square bg-contain bg-center bg-no-repeat rotate-180"
            ></div>
          )}
        </div>
        <p className="text-lg font-semibold mt-5 text-center">{title}</p>
        <p className="text-center text-sm">{subtitle}</p>
      </div>
      {btnEnable && ![6, 7].includes(currentTripDetails) && (
        <div className="p-4 w-full border border-black/10 rounded-md flex justify-center items-center mt-2">
          <button
            onClick={() => {
              const options = {
                customUI: ({ onClose }) => {
                  return (
                    <ConfirmToast
                      title="Are you sure?"
                      message="You want to cancel this trip?"
                      deleteButtonText="Yes"
                      onClose={() => onClose()}
                      onSuccess={() => {
                        setCurrentModal(0);
                        setSelectedVehicleData({});
                        onClose();
                      }}
                    />
                  );
                },
                closeOnEscape: true,
                closeOnClickOutside: true,
                keyCodeForClose: [8, 32],
                overlayClassName: "!bg-gray-500 !bg-opacity-75",
              };

              confirmAlert(options);
            }}
            className="text-sm py-2 px-5 bg-primary text-white rounded-md w-full"
          >
            Request for cancel
          </button>
        </div>
      )}
      {showContactBtn && (
        <div className="p-4 w-full border border-black/10 rounded-md flex justify-center items-center mt-2">
          <a
            href="tel:+917331105533"
            rel="noreferrer"
            className="text-sm py-2 px-5 text-center bg-primary text-white rounded-md w-full"
          >
            Contact Support
          </a>
        </div>
      )}
    </div>
  );
};

const DriverArrivingModal = ({
  setCurrentModal,
  assignedDriverDetails,
  selectedVehicleData,
  locationInput,
  totalTripPrice,
  currentTripDetails,
}) => {
  const [extend, setExtend] = useState(false);
  const [timeToArrive, setTimeToArrive] = useState("-");
  const [timeToReach, setTimeToReach] = useState("-");

  useEffect(() => {
    if (window?.google?.maps && currentTripDetails?.status === 6) {
      const directionsService = new window.google.maps.DirectionsService();
      console.log("directionsService", directionsService);
      if (directionsService) {
        directionsService.route(
          {
            origin: locationInput?.drop?.[0]?.location,
            destination: {
              lat: assignedDriverDetails?.coords?.latitude,
              lng: assignedDriverDetails?.coords?.longitude,
            },
            travelMode: window?.google?.maps?.TravelMode?.DRIVING,
          },
          (result, status) => {
            if (status === window?.google?.maps?.DirectionsStatus?.OK) {
              if (result?.routes?.length > 0) {
                console.log("result123", result);
                setTimeToReach(result?.routes?.[0]?.legs?.[0]?.duration?.text);
              }
            } else {
              // Toastify("Route Not Found", 2);
              console.error(`Error fetching directions: ${status}`);
            }
          }
        );
      }
    }
  }, [currentTripDetails]);

  useEffect(() => {
    if (window?.google?.maps) {
      const directionsService = new window.google.maps.DirectionsService();
      console.log("directionsService", directionsService);
      if (directionsService) {
        directionsService.route(
          {
            origin: locationInput?.pickup?.[0]?.location,
            destination: {
              lat: assignedDriverDetails?.coords?.latitude,
              lng: assignedDriverDetails?.coords?.longitude,
            },
            travelMode: window?.google?.maps?.TravelMode?.DRIVING,
          },
          (result, status) => {
            if (status === window?.google?.maps?.DirectionsStatus?.OK) {
              if (result?.routes?.length > 0) {
                console.log("result123", result);
                setTimeToArrive(result?.routes?.[0]?.legs?.[0]?.duration?.text);
              }
            } else {
              // Toastify("Route Not Found", 2);
              console.error(`Error fetching directions: ${status}`);
            }
          }
        );
      }
    }
  }, []);

  return (
    <div className="flex flex-col gap-4 justify-between h-full">
      <div className="flex justify-between">
        <div className="flex flex-col items-start">
          {currentTripDetails?.status === 6 ? (
            <>
              <p className="font-semibold">You will reach your destination in</p>
              <span className="font-semibold">{timeToReach}</span>
            </>
          ) : (
            <>
              <p className="font-semibold">Your ambulance is arriving in</p>
              <span className="font-semibold">{timeToArrive}</span>
            </>
          )}
        </div>
        <div
          style={{ backgroundImage: `url(${Omni})` }}
          className="w-14 aspect-square rounded-md bg-contain bg-center bg-no-repeat"
        ></div>
      </div>
      <div className="flex flex-col gap-6 h-full overflow-y-auto px-2">
        <DriverCard assignedDriverDetails={assignedDriverDetails} />
        {/* <div className="flex justify-between items-center">
          <p className="font-semibold">Share Pin</p>
          <div className="flex gap-1">
            {["2", "4", "5", "6"].map((num, index) => (
              <div
                key={"otp-digit" + index}
                className="w-8 h-8 bg-secondary/10 rounded-sm flex justify-center items-center text-sm text-secondary font-semibold"
              >
                {num}
              </div>
            ))}
          </div>
        </div> */}
        <BillAccordian
          extend={extend}
          setExtend={setExtend}
          selectedVehicleData={selectedVehicleData}
          totalTripPrice={totalTripPrice}
        />
        <LocationsComp locationInput={locationInput} />
      </div>
      <div className="p-4 w-full border border-black/10 rounded-md flex justify-center items-center mt-2">
        {![6, 7].includes(currentTripDetails) && (
          <button
            onClick={() => {
              const options = {
                customUI: ({ onClose }) => {
                  return (
                    <ConfirmToast
                      title="Are you sure?"
                      message="You want to cancel this trip?"
                      deleteButtonText="Yes"
                      onClose={() => onClose()}
                      onSuccess={() => {
                        setCurrentModal(0);
                        onClose();
                      }}
                    />
                  );
                },
                closeOnEscape: true,
                closeOnClickOutside: true,
                keyCodeForClose: [8, 32],
                overlayClassName: "!bg-gray-500 !bg-opacity-75",
              };

              confirmAlert(options);
            }}
            className="text-sm py-2 px-5 bg-primary text-white rounded-md w-full"
          >
            Request for cancel
          </button>
        )}
      </div>
    </div>
  );
};

const TripCompletedModal = ({
  setSelected,
  setShowModal,
  setCurrentModal,
  assignedDriverDetails,
  currentTripDetails,
  selectedVehicleData,
  totalTripPrice,
  locationInput,
}) => {
  const [rating, setRating] = useState();

  const toWords = new ToWords({
    localeCode: "en-IN",
    converterOptions: {
      currency: true,
    },
  });

  const handleSubmit = () => {
    setSelected("");
    setShowModal(false);
    setCurrentModal(0);
    Toastify("Your rating has been submitted!", 1);
  };

  return (
    <div className="flex flex-col gap-6 py-6 px-2 md:px-0 md:py-0 md:h-full h-screen  overflow-auto">
      <p className="text-xl font-semibold text-center">YOUR TRIP IS COMPLETED</p>
      <DriverCard isCompleted={true} assignedDriverDetails={assignedDriverDetails} />
      <div className="TRIP py-6 border-y flex flex-col gap-6">
        <p className="font-semibold text-sm">TRIP</p>
        <LocationsComp locationInput={locationInput} />
        <div className="p-3 w-full text-center bg-primary/10 rounded-md">
          <p>
            Thanks for Availing "<span className="font-bold text-primary">TEN</span>" Services
          </p>
        </div>
      </div>
      <div className="PRICE-DETAILS flex flex-col gap-6">
        <p className="font-semibold text-sm">PRICE DETAILS</p>
        <div className="flex justify-between text-xs">
          <p className="text-black/50">Trip ID</p>
          <p>{currentTripDetails?.tripId}</p>
        </div>
        <div className="flex justify-between text-xs">
          <p className="text-black/50">Vehicle Type</p>
          <p>
            {selectedVehicleData?.vehicle_type_name} ({selectedVehicleData?.vehicle_service})
          </p>
        </div>
        <div className="flex justify-between text-xs">
          <p className="text-black/50">Total Amount</p>
          <p className={`text-sm text-black font-semibold flex items-center`}>
            <FaRupeeSign size={13} /> <span>{Number(totalTripPrice ?? 0)?.toFixed(2)}</span>
          </p>
        </div>
        <div className="p-4 w-full text-center font-medium text-secondary bg-secondary/10 rounded-md">
          <p>Please Pay {toWords.convert(totalTripPrice)}</p>
        </div>
      </div>
      <div className="RECEIPT py-6 border-y flex flex-col gap-6">
        <p className="font-semibold text-sm">RECEIPT</p>
        <div className="flex justify-around">
          <button className="flex flex-col justify-center items-center gap-3">
            <div
              style={{ backgroundImage: `url(${Mail})` }}
              className="w-12 aspect-square bg-contain bg-center bg-no-repeat"
            ></div>
            <p className="text-xs text-black/50">Email</p>
          </button>
          <button className="flex flex-col justify-center items-center gap-3">
            <div
              style={{ backgroundImage: `url(${WhatsApp})` }}
              className="w-12 aspect-square bg-contain bg-center bg-no-repeat"
            ></div>
            <p className="text-xs text-black/50">Whatsapp</p>
          </button>
          <button className="flex flex-col justify-center items-center gap-3">
            <div
              style={{ backgroundImage: `url(${Download})` }}
              className="w-12 aspect-square bg-contain bg-center bg-no-repeat"
            ></div>
            <p className="text-xs text-black/50">Download</p>
          </button>
        </div>
      </div>
      <div className="PRICE-DETAILS flex flex-col gap-6 items-center">
        <div className="px-4">
          <p className="font-bold text-sx text-center">HOW WAS YOUR TRIP?</p>
          <p className="text-sx text-black/50 text-center pt-2">
            Your feeback will help us improve driving experience better.
          </p>
        </div>
        <RatingStars rating={rating} setRating={setRating} />
        <button
          onClick={handleSubmit}
          className="mt-10 text-sm py-3 border-2 border-primary text-primary rounded-md font-semibold w-full"
        >
          Submit
        </button>
      </div>
    </div>
  );
};

const RatingStars = ({ rating, setRating }) => {
  return (
    <div className="flex gap-2">
      {Array.apply(null, Array(5)).map((_, i) => (
        <button onClick={() => setRating(i)} key={"rating-star" + i}>
          {i <= rating ? (
            <FaStar key={i} size={40} color="#EFBC51" />
          ) : (
            <FaStar key={i} size={40} color="#cfd4d1" />
          )}
        </button>
      ))}
    </div>
  );
};

const BillAccordian = ({
  extend = false,
  setExtend = () => {},
  selectedVehicleData,
  totalTripPrice,
}) => {
  console.log("BillAccordian totalTripPrice", totalTripPrice);
  return (
    <div className={`py-4 border-y flex flex-col ${extend ? "gap-3" : ""}`}>
      <div
        className="flex justify-between items-center cursor-pointer"
        onClick={() => setExtend((prev) => !prev)}
      >
        <p className="text-sm font-semibold">Ambulance Details</p>
        <button>
          <FaChevronDown
            className={`${extend ? "rotate-180" : ""} transition-all duration-200`}
            size={14}
          />
        </button>
      </div>
      {extend && (
        <>
          <div className={`mt-2 text-xs flex justify-between`}>
            <p className="font-medium text-black/50">Vehicle Type</p>
            <p className="font-medium text-black">
              {selectedVehicleData?.vehicle_type_name} ({selectedVehicleData?.vehicle_service})
            </p>
          </div>
          {selectedVehicleData?.featuresList?.map((feature, i) => (
            <div className={`text-xs flex justify-between`} key={"addon-item" + i}>
              <p className="font-medium text-black/50">{feature?.addon}</p>
              <p className="font-medium text-black">
                {feature?.quantity} {feature?.measurement}
              </p>
            </div>
          ))}
          <div className={`text-xs flex justify-between`}>
            <p className="font-medium text-black/50">Total Amount</p>
            <p className={`text-sm text-black font-semibold flex items-center`}>
              <FaRupeeSign size={extend ? 13 : 0} />{" "}
              <span>{Number(totalTripPrice ?? 0)?.toFixed(2)}</span>
            </p>
          </div>
        </>
      )}
    </div>
  );
};

const VehicleCard = ({ showModal, selected, setSelected, index, vehicle, totalTripPrice }) => {
  return (
    <div
      onClick={() => setSelected(vehicle)}
      className={`${
        showModal ? "p-4 opacity-100" : "opacity-0"
      } w-full flex flex-col justify-center items-center border text-black ${
        selected ? "border-[3px] border-secondary/90" : " bg-white"
      } rounded-xl cursor-pointer transition-all duration-300 z-10`}
    >
      <div className="w-full flex justify-between items-center">
        <div className="flex gap-2">
          <div
            style={{ backgroundImage: `url(${vehicle?.vehicle_icon_url})` }}
            className="VEHICLE-IMAGE w-24 aspect-[16/10] rounded-md bg-no-repeat bg-cover bg-top mr-2"
          ></div>
          <div className="flex flex-col justify-center">
            <p className="text-[1.3rem] font-semibold leading-[110%]">
              {vehicle?.vehicle_type_name}
            </p>
            {/* <p className="text-opacity-50 font-medium text-[10px]">{vehicle.minsAway} min away</p> */}
            <p className="text-opacity-70 text-md">{vehicle?.vehicle_service}</p>
          </div>
        </div>
        <div
          className={`${
            vehicle?.features?.length > 0 ? "flex" : "hidden"
          } items-center h-fit whitespace-nowrap gap-1 w-[80px] pt-2 self-start font-bold text-base`}
        >
          <FaRupeeSign size={14} />
          {Number(vehicle?.tariffDetails?.calculatedcharges ?? 0)?.toFixed(2)}
        </div>
      </div>

      <div className={`${selected ? "opacity-100 mt-2 gap-3" : "opacity-0"} flex flex-col`}>
        <p className={`${selected ? "text-xs" : "text-[0px]"}`}>
          Fugiat tempor incididunt ex ea eu. Magna ex fugiat eiusmod consectetur consequat do id id
          dolore. Fugiat amet proident mollit excepteur. Non enim quis est duis minim labore laboris
          nisi deserunt. Culpa eiusmod laboris cupidatat velit reprehenderit occaecat ad duis
          consequat. Elit non anim eiusmod ad nulla id sit tempor elit aliqua dolor eu in.
        </p>
        <div
          className={`${
            selected && vehicle?.features ? "flex" : "hidden"
          } flex-wrap gap-4 font-semibold`}
        >
          {vehicle?.features?.map((feature, featureIndex) => {
            return (
              <div
                className="flex flex-col items-center gap-1"
                key={"vehicle-feature-item" + featureIndex}
              >
                <div className="rounded-[50px] bg-white">
                  <div
                    className={`${
                      selected ? "w-[35px]" : "w-0"
                    } aspect-square bg-contain bg-slate-50 rounded-full flex items-center justify-center p-1`}
                  >
                    <img src={feature?.addonObjId?.[0]?.iconUrl} className="w-full" />
                  </div>
                </div>
                <p className={`${selected ? "text-[8px]" : "text-[0px]"} text-center`}>
                  {feature?.addonObjId?.[0]?.addon}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
const AddonCard = ({ showModal, selected, setSelected, index, addon }) => {
  return (
    <div
      onClick={() => setSelected(addon)}
      className={`${
        showModal ? "p-4 opacity-100" : "opacity-0"
      } w-full flex flex-col justify-center items-center border text-black ${
        selected ? "border-[3px] border-secondary/90 bg-secondary/20 ":" bg-white"
      } rounded-xl cursor-pointer transition-all duration-300 z-10`}
    >
      <div className="w-full flex justify-between items-center">
        <div className="flex gap-2">
          <div
            style={{ backgroundImage: `url(${addon?.iconUrl})` }}
            className="VEHICLE-IMAGE w-24 aspect-[16/10] rounded-md bg-no-repeat bg-contain bg-top mr-2"
          ></div>
          <div className="flex flex-col justify-center">
            <p className="text-[1.3rem] font-semibold leading-[110%]">{addon?.addon}</p>
            <p className="text-opacity-70 text-md">{addon?.addon_description}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const LocationsComp = ({ locationInput }) => {
  return (
    <div className="flex flex-col gap-4 relative">
      <div className="flex justify-between">
        <div className="flex gap-2">
          <FaLocationDot color="#0E2D9B" />
          <div className="">
            <p className="text-xs text-black/50">From</p>
            <p className="text-sm text-black font-semibold overflow-hidden overflow-ellipsis">
              {locationInput?.pickup?.[0]?.formattedAddress}
            </p>
          </div>
        </div>
        {/* <button className="text-xs text-secondary">Edit</button> */}
      </div>
      <div className="flex justify-between">
        <div className="flex gap-2">
          <FaLocationDot color="#F15A2A" />
          <div className="">
            <p className="text-xs text-black/50">From</p>
            <p className="text-sm text-black font-semibold overflow-hidden overflow-ellipsis">
              {locationInput?.drop?.[0]?.formattedAddress}
            </p>
          </div>
        </div>
        {/* <button className="text-xs text-secondary">Edit</button> */}
      </div>
    </div>
  );
};

const QuantityTab = ({
  quantities = ["-", "1", "2", "3", "5", "10"],
  // selectedValue = 0,
  // setSelectedValue = () => {},
}) => {
  const [selectedValue, setSelectedValue] = useState(0);
  return (
    <div className="flex flex-col gap-1">
      <p className="text-[12px] font-medium">How many liters of oxygen do you need ?</p>
      <div className="flex gap-1 text-xs">
        {quantities.map((item, index) => (
          <button
            key={"quantity-item" + index}
            onClick={() => setSelectedValue(index)}
            className={`w-8 aspect-square rounded-[4px] ${
              selectedValue === index ? "bg-secondary text-white" : "border border-black/20"
            }`}
          >
            {item}
          </button>
        ))}
      </div>
    </div>
  );
};

const DriverCard = ({ isCompleted = false, assignedDriverDetails, driverImageClassName = "" }) => {
  return (
    <div className="flex justify-between w-full p-5 bg-black/5 rounded-md items-center">
      <div className="flex gap-2">
        <img
          src={assignedDriverDetails?.user?.profilePicUrl}
          className={`w-16 aspect-square rounded-md object-top object-cover ${driverImageClassName}`}
        />
        <div className="flex flex-col justify-between text-sm">
          <div>
            <p className="font-semibold text-lg">
              {assignedDriverDetails?.user?.firstName} {assignedDriverDetails?.user?.lastName}
            </p>
            <p className="font-medium text-xs">
              {isCompleted ? "Completed" : assignedDriverDetails?.user?.mobile}
            </p>
          </div>
          {/* <div className="flex gap-1 font-medium">
            <TiStarFullOutline color="#ebc034" size={18} /> <p>4.8</p>
          </div> */}
        </div>
      </div>
      {isCompleted ? (
        <div
          style={{ backgroundImage: `url(${Completed})` }}
          className="w-20 h-20 bg-contain bg-no-repeat bg-center"
        ></div>
      ) : (
        <a
          href={`tel:${assignedDriverDetails?.user?.mobile}`}
          className="flex justify-center items-center w-16 h-16 rounded-full bg-primary/10"
        >
          <IoCall color="#F15A2A" size={16} />
        </a>
      )}
    </div>
  );
};

const InputText = ({ placeholder = "Enter your location" }) => {
  return (
    <div className="flex p-3 items-center gap-2 rounded-md bg-black/5">
      <FaLocationDot color="#F15A2A" />
      <input
        placeholder={placeholder}
        className="w-full bg-transparent focus:outline-none text-sm"
      />
    </div>
  );
};

const TripInputs = ({
  setShowModal,
  locationInput,
  handleLocationInputChange,
  pickUpFormattedAddress,
  setPickUpFormattedAddress,
  dropFormattedAddress,
  setDropFormattedAddress,
  distance,
  setRecenterMap,
  setCurrentModal,
  setSelectedVehicleData,
}) => {
  return (
    <div className="md:w-1/3 w-full h-full flex flex-col justify-between">
      <div className="flex flex-col gap-3 w-full h-fit border-2 border-secondary/10 rounded-xl p-4 bg-white">
        <p className="font-bold">Find a Trip</p>
        <LocationInputV2
          name="pickup"
          id="pickupAddressInput"
          placeholder="Enter your location"
          value={locationInput.pickup}
          onChange={handleLocationInputChange}
          formattedAddress={pickUpFormattedAddress}
          setFormattedAddress={setPickUpFormattedAddress}
          locationArrow={true}
          inputBoxClassName="bg-secondary/[0.07] border-none"
          inputStyle="bg-transparent"
        />
        <LocationInputV2
          name="drop"
          id="dropAddressInput"
          placeholder="Enter hospital location"
          value={locationInput.drop}
          onChange={handleLocationInputChange}
          formattedAddress={dropFormattedAddress}
          setFormattedAddress={setDropFormattedAddress}
          inputBoxClassName="bg-secondary/[0.07] border-none"
          inputStyle="bg-transparent"
        />
        <button
          onClick={() => {
            setShowModal(true);
            setCurrentModal(0);
            setSelectedVehicleData({});
            setRecenterMap(true);
          }}
          className="bg-secondary text-white flex w-full justify-center transition-all delay-150 py-3 px-10 mt-3 rounded-lg drop-shadow-none hover:drop-shadow-xl font-medium text-sm hover:scale-[101%] hover:-translate-y-1"
        >
          Search
        </button>
      </div>
      {distance && (
        <div className="py-4 text-center font-semibold">
          {distance?.km} {distance?.time}
        </div>
      )}
    </div>
  );
};

const MapContainer = ({ driverDetails, sendResponse, recenterMap, setRecenterMap }) => {
  const [map, setMap] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [pickupMarker, setPickupMarker] = useState([]);
  const [response, setResponse] = useState(null);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const dispatch = useDispatch();
  const [mapMarkers, setMapMarkers] = useState([]);
  const [firstLoad, setFirstLoad] = useState(true);
  const locationMarkers = useSelector((state) => state.service?.locationPoints);

  console.log("locationMarkers", locationMarkers);
  console.log("map12", map);

  useEffect(() => {
    async function loadRoute() {
      console.log("inside loadRoute987987");
      console.log("locationMarkers987987", locationMarkers);
      console.log("locationMarkers9879871", JSON.stringify(locationMarkers));
      console.log("map987987", map);
      if (locationMarkers && map) {
        if (locationMarkers?.pickup || locationMarkers?.drop) {
          const markers = locationMarkers;
          const bounds = new window.google.maps.LatLngBounds();
          console.log("test12987987");
          if (markers?.pickup?.length > 0) bounds.extend(markers.pickup[0].position);
          if (markers?.drop?.length > 0) bounds.extend(markers.drop[0].position);
          console.log("viewport11987987", markers.pickup[0]?.geometry?.viewport);
          if (markers.pickup[0]?.geometry?.viewport?.northeast) {
            let bounds = new window.google.maps.LatLngBounds();
            bounds.extend(markers.pickup[0]?.geometry?.viewport?.northeast);
            bounds.extend(markers.pickup[0]?.geometry?.viewport?.southwest);
            map.fitBounds(bounds);
          } else if (markers.pickup[0]?.geometry?.viewport) {
            console.log("fitBounds11987987");
            map.fitBounds(markers.pickup[0]?.geometry?.viewport);
          }
          console.log("markers12987987", markers);
          if (markers?.pickup && markers?.drop) {
            const markerPins = [...markers?.pickup, ...markers?.drop];
            if (markerPins[0] && markerPins[1]) {
              const directionsService = new window.google.maps.DirectionsService();
              directionsService.route(
                {
                  origin: markerPins[0]?.location,
                  destination: markerPins[1]?.location,
                  travelMode: window?.google?.maps?.TravelMode.DRIVING,
                },
                (result, status) => {
                  if (status === window?.google?.maps?.DirectionsStatus.OK) {
                    if (result?.routes?.length > 0) {
                      const pathArray = result?.routes[0].overview_path;
                      const pathQuarterLength = parseInt(pathArray?.length / 4); //25% length
                      const mark_one = pathArray[pathQuarterLength]?.toJSON();
                      const mark_two = pathArray[pathQuarterLength * 3]?.toJSON();
                      const markerPoints = [mark_one, mark_two];
                      setMarkers(markerPoints);
                      setPickupMarker([]);
                    }
                    setResponse(result);
                    if (sendResponse) sendResponse(result);
                  } else {
                    Toastify("Route Not Found", 2);
                    console.error(`Error fetching directions: ${status}`);
                  }
                }
              );
            }
          } else {
            const pickupPoint = markers.pickup[0].position;
            setPickupMarker([pickupPoint]);
            const places = await searchPlacesNearBy(pickupPoint);
            const placesWithPostal = await Promise.all(
              places?.map(async (p) => ({
                ...p,
                postalCode: await getPostalCodeFromCoordinates(
                  p?.geometry?.location?.lat(),
                  p?.geometry?.location?.lng()
                ),
              }))
            );
            dispatch(saveQuickBookingLocationSuggestions(placesWithPostal));
          }
        }
      }
    }
    loadRoute();
    setRecenterMap(false);
  }, [locationMarkers, map, recenterMap]);

  const removeDuplicateDriverDetails = (driverDetails) => {
    return driverDetails.reduce((acc, current) => {
      const existingMarker = acc.find(
        (item) => item?.user?.userAuthId === current?.user?.userAuthId
      );
      if (!existingMarker) {
        return acc.concat([current]);
      }
      return acc;
    }, []);
  };

  useEffect(() => {
    if (window.google && window.google.maps && map && driverDetails) {
      // Clear previous markers
      setMapMarkers([]);

      const uniqueDriverDetails = removeDuplicateDriverDetails(driverDetails);
      // Create new markers
      const newMarkers =
        uniqueDriverDetails?.length > 0 &&
        uniqueDriverDetails.map((marker, index) => (
          <Marker
            key={"map-marker" + index}
            position={{
              lat: marker?.coords?.latitude,
              lng: marker?.coords?.longitude,
            }}
            icon={{
              url: marker?.isOnline ? Ambulance4 : Ambulance,
              ...(marker?.isOnline
                ? { scaledSize: new window.google.maps.Size(50, 50) }
                : { scale: 0.8 }),
            }}
            onClick={() => setSelectedMarker(marker)}
          />
        ));
      setMapMarkers(newMarkers);
      if (firstLoad && newMarkers) {
        const bounds = new window.google.maps.LatLngBounds();
        driverDetails?.forEach((marker) => {
          bounds.extend(
            new window.google.maps.LatLng(marker?.coords?.latitude, marker?.coords?.longitude)
          );
        });
        map.fitBounds(bounds);
        setFirstLoad(false);
      }
    }
  }, [map, firstLoad, driverDetails]);

  const searchPlacesNearBy = async (latLong) => {
    const request = {
      location: latLong,
      radius: 500,
      type: ["hospital"],
    };

    return new Promise((resolve) => {
      const service = new window.google.maps.places.PlacesService(map);
      service.nearbySearch(request, (results, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          const finalResult = results
            ?.slice(0, 10)
            ?.map(({ name, place_id, geometry, rating }) => ({
              placeName: name,
              placeId: place_id,
              geometry,
              position: geometry.location,
              location: geometry.location?.toJSON(),
              rating,
            }));
          resolve(finalResult);
        } else {
          resolve([]);
        }
      });
    });
  };

  const getPostalCodeFromCoordinates = async (latitude, longitude) => {
    const apiUrl = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;
    try {
      const response = await fetch(apiUrl);
      const data = await response.json();

      if (data.address && data?.address?.postcode) {
        const postalCode = data?.address?.postcode;
        return postalCode;
      } else {
        return null;
      }
    } catch (error) {
      console.error("Error fetching postal code:", error);
      return null;
    }
  };

  const onUnmountMap = () => {
    setMap(null);
    setResponse(null);
    setMarkers([]);
    console.log("Map unmount");
    if (sendResponse) sendResponse(null);
  };

  return (
    <LoadScript
      googleMapsApiKey={process.env.REACT_APP_MAP_API_KEY}
      libraries={libraries}
      mapIds={[process.env.REACT_APP_MAP_ID]}
    >
      <GoogleMap
        mapContainerStyle={{
          height: "100%",
          width: "100%",
        }}
        // center={center}
        // zoom={4}
        onLoad={(map) => {
          setMap(map);
          console.log("Map onLoad:", map);
          console.log("Map renderingType:", map.getRenderingType());
        }}
        onUnmount={onUnmountMap}
        options={{ mapId: process.env.REACT_APP_MAP_ID }}
      >
        {mapMarkers}

        {selectedMarker && (
          <InfoWindow
            position={{
              lat: selectedMarker?.coords?.latitude,
              lng: selectedMarker?.coords?.longitude,
            }}
            onCloseClick={() => setSelectedMarker(null)}
          >
            <div className="my-[8px]">
              <div className="flex gap-2">
                <div
                  style={{
                    backgroundImage: `url(${selectedMarker?.user?.profilePicUrl})`,
                  }}
                  className="w-9 h-9 rounded-lg bg-black bg-cover bg-no-repeat overflow-hidden"
                ></div>
                <div>
                  <div className="flex flex-col justify-between font-semibold">
                    <p className="font-semibold">
                      {selectedMarker?.user?.firstName} {selectedMarker?.user?.lastName}
                    </p>
                    <div className="flex flex-col gap-1 text-xs">
                      <div className="flex gap-2 font-normal">
                        <p className="text-xs">{selectedMarker?.user?.mobile}</p>
                      </div>
                      <div className="flex w-full justify-between">
                        <div className="flex gap-1">
                          <MdBatteryChargingFull size={15} />
                          <p>{selectedMarker?.user?.battery?.level * 100} %</p>
                        </div>
                        <div className="ml-2 flex gap-1">
                          <IoSpeedometer size={15} />
                          <p>{selectedMarker?.coords?.speed} km/h</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </InfoWindow>
        )}
        {pickupMarker &&
          markers?.length === 0 &&
          pickupMarker?.map((marker, index) => (
            <Marker
              key={"map-location-marker" + index}
              position={marker}
              label={{ text: "A", color: "#fff" }}
            />
          ))}
        {response && (
          <DirectionsRenderer
            directions={response}
            options={{
              polylineOptions: {
                strokeColor: "#F15A2A",
                strokeWeight: 5,
              },
              markerOptions: {
                origin: {
                  icon: {
                    // path: window.google.maps.SymbolPath.CIRCLE,
                    fillColor: "#0000FF",
                    scale: 10,
                  },
                },
              },
            }}
          />
        )}
      </GoogleMap>
    </LoadScript>
  );
};

const AddMobileModal = ({ mobileModalOpen, setMobileModalOpen, userDetails, handleTripSubmit }) => {
  const dispatch = useDispatch();
  const [isProfileUpdating, setIsProfileUpdating] = useState(false);
  const handleAddMobile = (e) => {
    e.preventDefault();
    setIsProfileUpdating(true);
    dispatch(
      updateProfileAction({
        apiPayloadRequest: {
          _id: userDetails?._id,
          firstName: userDetails?.firstName,
          mobile: e.target.mobile.value,
        },
        callback: (res) => {
          if (res?.type === 1) {
            const updatedUserDetails = { ...userDetails, mobile: e.target.mobile.value };
            dispatch(updateUserDetailsAction(updatedUserDetails));
            setTimeout(() => {
              setIsProfileUpdating(false);
              handleTripSubmit(updatedUserDetails);
              setMobileModalOpen(false);
            }, 1000);
          } else {
            setIsProfileUpdating(false);
          }
        },
      })
    );
  };
  return (
    <ReactModal
      className={"w-full flex justify-center h-full py-4"}
      style={{
        ...customStyles,
        ...customContentStyles,
      }}
      isOpen={mobileModalOpen}
      onRequestClose={() => setMobileModalOpen(false)}
    >
      <>
        <div className="w-[25dvw] small-bar items-center overflow-hidden bg-white border rounded-xl">
          <div className="flex justify-end items-center py-4 px-8 border-b">
            <img className="cursor-pointer" src={wrong} onClick={() => setMobileModalOpen(false)} />
          </div>
          <form onSubmit={handleAddMobile} className="w-full flex flex-col p-4">
            <label className="font-medium mb-1">Add Mobile Number to continue</label>
            <p className="text-xs text-gray-5 00 mb-2">
              This mobile number will be used to create trips and send trip updates
            </p>
            <input
              type="tel"
              name="mobile"
              className="border rounded-md px-3 py-1.5 mb-6 text-sm"
              placeholder="Enter Mobile Number"
            />
            <button
              type="submit"
              className="text-sm flex justify-center items-center py-2 px-5 bg-primary text-white rounded-lg text-center"
            >
              {isProfileUpdating ? <LoaderSpinner /> : "Confirm and Continue"}
            </button>
          </form>
        </div>
      </>
    </ReactModal>
  );
};

export default Trip;
