import React from "react";
import PickDropLocation from "../Locations/PickDropLocation";
import LocationInput from "../../InputFields/LocationInput";
import Line4 from "../../../../assets/images/Line4.png";

export default function QuickBookingLocation({
  value1,
  value2,
  background1,
  background2,
  edit1,
  edit2,
  lineWidth1,
  lineWidth2,
  inputStyle1,
  inputStyle2,
}) {
  const placeName1 = value1?.length > 0 ? <>{value1[0]?.placeName} <small>({value1[0]?.postalCode})</small></> : "";
  const placeName2 = value2?.length > 0 ? <>{value2[0]?.placeName} <small>({value2[0]?.postalCode})</small></>  : "";
  return (
    <div>
      <div className="flex flex-row w-full x">
        <div className="mt-2">
          <PickDropLocation height={"h-4"} />
        </div>
        <div className="flex flex-col items-center w-full mt-2 px-2 ">
          <div className={`${background1} w-full`}>
            <span className="text-sm font-semibold" styles={"hidden"} placeholder="Pickup Location">
              {placeName1}
            </span>
          </div>
          <div className={`${lineWidth1}`}>
            <img src={Line4} />
          </div>
          <div className={`${background1} w-full`}>
            <span className="text-sm font-semibold" styles={"hidden"} placeholder="Drop Location">
              {placeName2}
            </span>
          </div>
          <div className={`${lineWidth2}`}>
            <img src={Line4} />
          </div>
          {/* <div className={`${background1} w-full`}><LocationInput value={value1} edit={edit1} lineWidth={lineWidth1} styles={'hidden'} inputStyle={inputStyle1} /></div> */}
          {/* <div className={`${background2} w-full` }><LocationInput value={value2} edit={edit2} lineWidth={lineWidth2} styles={'hidden'} inputStyle={inputStyle2} /></div> */}
        </div>
      </div>
    </div>
  );
}
