import React, { useEffect, useState } from "react";
import Logo from "../../assets/icons/Logo.svg";
import tenIcon from "../../assets/images/productLogo.png";
// import ProfilePic from '../../assets/icons/profileLogo.svg'
// import network from '../../assets/icons/network.svg'
import { NavLink, useNavigate } from "react-router-dom";
import { adminNavbarConsts, userNavbarConsts } from "../../constants/Constant";
import NavbarModal from "../../Screens/Admin/Modals/NavbarModal/Navbarmodal";
import Logout from "../../assets/icons/logout.svg";
import { useAuth0 } from "@auth0/auth0-react";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { useSelector } from "react-redux";

const NavBar = ({ active }) => {
  const [isOpen, setIsOpen] = useState(false);
  const role = localStorage.getItem("role");
  const { userDetails } = useSelector((state) => state.userProfile);
  const { logout } = useAuth0();

  const closeModal = () => {
    setIsOpen(false);
  };

  const updatedNavbarConst = role === "84bts2" ? adminNavbarConsts : userNavbarConsts;

  const navigate = useNavigate();

  const onLogout = async () => {
    localStorage.clear();
    logout({ returnTo: window.location.origin }); //auth0 logout
  };

  return (
    <div className="min-w-[60px] h-[100vh] flex flex-col justify-between items-center pt-5 ml-[12px]">
      <img src={Logo} className="w-[50%]" />
      <div className="bg-[#0E2D9B] h-[68%] rounded-xl flex items-end justify-center">
        <div
          className={`flex flex-col items-center justify-center h-[100%] p-[12px] ${
            role === "84bts2" ? "gap-8" : "gap-10"
          }  `}
        >
          {updatedNavbarConst?.map((item, index) => (
            <div
              key={index}
              className={
                active === item.title
                  ? "bg-white bg-opacity-10 rounded-xl p-3 cursor-pointer relative"
                  : "hover:bg-white hover:bg-opacity-10 rounded-xl p-3 cursor-pointer relative"
              }
              onClick={() => navigate(item.route)}
            >
              <img
                src={item.icon}
                width={20}
                data-tooltip-id={item.title}
                data-tooltip-content={item.title}
                className="max-w-fit"
              />
              <Tooltip id={item.title} place="right" className="z-[1000]" />
            </div>
          ))}
          <div onClick={onLogout} key={"logout"} className="p-3 cursor-pointer relative">
            <img
              src={Logout}
              className="h-[90%]"
              data-tooltip-id="logout-menu"
              data-tooltip-content="Logout"
            />
            <Tooltip id="logout-menu" place="right" className="z-[1000]" />
          </div>
        </div>
      </div>
      {userDetails?.profilePicUrl && (
        <NavLink className="flex flex-col items-center" to="/profile">
          <img className="w-[35px] h-[35px] rounded-full" src={userDetails?.profilePicUrl} />
        </NavLink>
      )}

      <NavbarModal closeModal={closeModal} setIsOpen={setIsOpen} isOpen={isOpen} />
    </div>
  );
};

export default NavBar;
