import React, { useEffect, useState } from "react";
import NavBar from "../../components/Universal/NavBar";
import Header from "../../components/Universal/Header";
import ActiveAndInActiveComp from "../../components/Common/ActiveAndInActiveComp";
import InnerTab from "../../components/Common/Tabs/InnerTab";
import DynamicTable from "../../components/Universal/DynamicTable";
import { Tab1data, Tab1headers } from "../../constants/ServicesConstants";
import { Tab2data, Tab2headers } from "../../constants/ServicesConstants";
import { Tab3data, Tab3headers } from "../../constants/ServicesConstants";
import { Tab4data, Tab4headers } from "../../constants/ServicesConstants";
import { Tab5data, Tab5headers } from "../../constants/ServicesConstants";
import ServiceAddRegionmodal from "./Modals/ServiceAddRegionmodal";
import ServiceAddRegionmodal2 from "./Modals/ServiceAddRegionmodal2";
import ServiceAddOnmodal from "./Modals/ServiceAddOnmodal";
import ServiceAddOnmodal2 from "./Modals/ServiceAddOnmodal2";
import ServiceAddVehicle from "./Modals/ServiceAddVehicle";
import ServiceAddVehicle2 from "./Modals/ServiceAddVehicle2";
import ServiceTariffModal from "./Modals/ServiceTariffModal";
import ServiceDiscountModal from "./Modals/ServiceDiscountModal";
import ServiceDiscountModal2 from "./Modals/ServiceDiscountModal2";
import ServiceAddRegionMapmodal from "./Modals/ServiceAddRegionWithMapmodal";
import {
  deactivateDiscountAction,
  deleteAddonAction,
  deleteDiscountAction,
  deleteRegionAction,
  deleteTarrifAction,
  deleteVehicleAction,
  readAddonAction,
  readDiscountAction,
  readRegionAction,
  readTarrifAction,
  readVehicleAction,
} from "../../store/Service/ServiceAction";
import { useDispatch } from "react-redux";
import Toastify from "../../components/Universal/Toastify";
import swal from "sweetalert";
import ConfirmToast from "../../components/Common/ConfirmToast";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

const Services = ({ role, shortBar, setShortBar }) => {
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState(1);
  const [activeInnerTab, setActiveInnerTab] = useState("Regions");

  const [isOpen, setIsOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const [addOn, setAddOn] = useState(false);
  const [addOn1, setAddOn1] = useState(false);

  const [addVehicle, setAddVehicle] = useState(false);
  const [addVehicle1, setAddVehicle1] = useState(false);

  const [tariff, setTariff] = useState(false);

  const [discount, setDiscount] = useState(false);
  const [discount1, setDiscount1] = useState(false);

  const [regionsData, setRegionData] = useState([]);

  const handleTabChange = (data) => {
    setActiveTab(data);
  };

  const closeModals = () => {
    setModalOpen(false);
    setCurrentRegionId("");
  };

  const handleEyeClick1 = () => {
    setAddOn1(!addOn1);
  };
  const closeModals1 = () => {
    setAddOn1(addOn1);
  };

  const handleEyeClick2 = () => {
    setAddVehicle1(!addVehicle1);
  };
  const closeModals2 = () => {
    setAddVehicle(addVehicle);
  };

  const handleEyeClick4 = () => {
    setDiscount1(!discount1);
  };
  const closeModals4 = () => {
    setDiscount1(discount1);
  };

  //Region*******************************************************
  const [allRegionData, setAllRegionData] = useState([]);
  const [currentPincodes, setCurrentPincodes] = useState([]);
  const [curentRegionId, setCurrentRegionId] = useState("");
  const [regionUpdated, setRegionUpdated] = useState(true);

  const handleButtonClick = () => {
    setIsOpen(!isOpen);
    setCurrentRegionId("");
  };

  const handleRegionEyeClick = (row) => {
    setModalOpen(!modalOpen);
    setCurrentPincodes(row?.pincodes);
    setCurrentRegionId(row?._id);
  };

  const handleRegionPencilClick = (row) => {
    setCurrentRegionId(row?._id);
    setIsOpen(true);
  };

  const handleRegionDelete = (row) => {
    const options = {
      customUI: ({ onClose }) => {
        return (
          <ConfirmToast
            title="Are you sure?"
            message="You want to delete this Region?"
            onClose={() => onClose()}
            onSuccess={() => {
              dispatch(
                deleteRegionAction({
                  apiPayloadRequest: {
                    _id: row?._id,
                  },
                  callback: (res) => {
                    if (res?.type === 1) {
                      const updatedData = allRegionData2.filter((item) => item?._id !== row?._id);
                      setAllRegionData2(updatedData);
                      Toastify("Region Deleted Successfully!", 1);
                    } else {
                      Toastify("Region Delete Failed!");
                    }
                  },
                })
              );
            }}
          />
        );
      },
      closeOnEscape: true,
      closeOnClickOutside: true,
      keyCodeForClose: [8, 32],
      overlayClassName: "!bg-gray-500 !bg-opacity-75",
    };

    confirmAlert(options);
  };

  const closeModal = () => {
    setIsOpen(false);
    setCurrentRegionId("");
  };

  const Tab1 = () => {
    return (
      <div className="mt-5">
        <DynamicTable
          data={allRegionData}
          headers={Tab1headers}
          search={true}
          button={true}
          handleButtonClick={handleButtonClick}
          title="Regions"
          label="Add New Region"
          width={"w-42"}
          heightDesktop={"h-[50%]"}
          nthChildWidth={
            "[&>*:nth-child(1)]:w-40 [&>*:nth-child(2)]:w-56 [&>*:nth-child(3)]:w-40 [&>*:nth-child(4)]:w-32 [&>*:nth-child(5)]:w-32"
          }
          handleEyeClick={(row) => handleRegionEyeClick(row)}
          handlePencilClick={(row) => handleRegionPencilClick(row)}
          handleDeleteClick={(row) => handleRegionDelete(row)}
        />
      </div>
    );
  };

  //Addon*******************************************************
  const [allAddon, setAllAddon] = useState([]);
  const [currentAddonId, setCurrentAddonId] = useState("");
  const [addonUpdated, setAddonUpdated] = useState(true);

  const handleButtonClick1 = () => {
    setAddOn(!addOn);
    setCurrentAddonId("");
  };

  const closeModal1 = () => {
    setAddOn(false);
    setCurrentAddonId("");
  };

  const handleAddonEyeClick = (row) => {
    setAddOn(!addOn);
    setCurrentAddonId(row?._id);
  };

  const handleAddonPencilClick = (row) => {
    setCurrentAddonId(row?._id);
    setAddOn(!addOn);
  };

  const handleAddonDelete = (row) => {
    const options = {
      customUI: ({ onClose }) => {
        return (
          <ConfirmToast
            title="Are you sure?"
            message="You want to delete this Addon?"
            onClose={() => onClose()}
            onSuccess={() => {
              dispatch(
                deleteAddonAction({
                  apiPayloadRequest: {
                    _id: row?._id,
                  },
                  callback: (res) => {
                    if (res?.type === 1) {
                      const updatedData = allAddon.filter((item) => item?._id !== row?._id);
                      setAllAddon(updatedData);
                      Toastify("Addon Deleted Successfully!", 1);
                    } else {
                      Toastify("Addon Delete Failed!");
                    }
                  },
                })
              );
            }}
          />
        );
      },
      closeOnEscape: true,
      closeOnClickOutside: true,
      keyCodeForClose: [8, 32],
      overlayClassName: "!bg-gray-500 !bg-opacity-75",
    };

    confirmAlert(options);
  };

  const Tab2 = () => {
    return (
      <div className="mt-5">
        <DynamicTable
          data={allAddon}
          headers={Tab2headers}
          search={true}
          option1="All"
          title="Add Ons"
          button={true}
          handleButtonClick={handleButtonClick1}
          buttonstyle="py-2"
          label="Add New Add Ons"
          width={"w-42"}
          heightDesktop={"h-[50%]"}
          nthChildWidth={
            "[&>*:nth-child(1)]:w-44 [&>*:nth-child(2)]:w-56 [&>*:nth-child(3)]:w-44 [&>*:nth-child(4)]:w-32 [&>*:nth-child(5)]:w-32"
          }
          handleEyeClick={(row) => handleAddonEyeClick(row)}
          handlePencilClick={(row) => handleAddonPencilClick(row)}
          handleDeleteClick={(row) => handleAddonDelete(row)}
        />
      </div>
    );
  };

  //Region Version 2*******************************************************
  const [allRegionData2, setAllRegionData2] = useState([]);
  const [currentPincodes2, setCurrentPincodes2] = useState([]);
  const [currentRegion2Id, setCurrentRegion2Id] = useState("");
  const [region2Updated, setRegion2Updated] = useState(true);

  const handleButtonClickRegion2 = () => {
    setIsOpen(!isOpen);
    setCurrentRegion2Id("");
  };

  const handleRegion2EyeClick = (row) => {
    // setModalOpen(!modalOpen)
    // setCurrentPincodes2(row?.pincodes);
    // setCurrentRegion2Id(row?._id);
    setCurrentRegion2Id(row?._id);
    setIsOpen(true);
  };

  const handleRegion2PencilClick = (row) => {
    setCurrentRegion2Id(row?._id);
    setIsOpen(true);
  };

  const handleRegion2Delete = (row) => {
    const options = {
      customUI: ({ onClose }) => {
        return (
          <ConfirmToast
            title="Are you sure?"
            message="You want to delete this Region?"
            onClose={() => onClose()}
            onSuccess={() => {
              dispatch(
                deleteRegionAction({
                  apiPayloadRequest: {
                    _id: row?._id,
                  },
                  callback: (res) => {
                    if (res?.type === 1) {
                      const updatedData = allRegionData2.filter((item) => item?._id !== row?._id);
                      setAllRegionData2(updatedData);
                      Toastify("Region Deleted Successfully!", 1);
                    } else {
                      Toastify("Region Delete Failed!");
                    }
                  },
                })
              );
            }}
          />
        );
      },
      closeOnEscape: true,
      closeOnClickOutside: true,
      keyCodeForClose: [8, 32],
      overlayClassName: "!bg-gray-500 !bg-opacity-75",
    };

    confirmAlert(options);
  };

  // const closeModalRegion2 = () => {
  //     setIsOpen(false);
  //     setCurrentRegionId('');
  // }

  const Tab6 = () => {
    return (
      <div className="mt-5 h-full">
        <div className="mt-5 h-full">
          <DynamicTable
            data={allRegionData2}
            headers={Tab1headers}
            size={2}
            search={true}
            button={true}
            handleButtonClick={handleButtonClickRegion2}
            title="Regions"
            label="Add New Region"
            width={"w-42"}
            heightDesktop={"h-[50%]"}
            nthChildWidth={
              "[&>*:nth-child(1)]:w-40 [&>*:nth-child(2)]:w-56 [&>*:nth-child(3)]:w-40 [&>*:nth-child(4)]:w-32 [&>*:nth-child(5)]:w-32"
            }
            handleEyeClick={(row) => handleRegion2EyeClick(row)}
            handlePencilClick={(row) => handleRegion2PencilClick(row)}
            handleDeleteClick={(row) => handleRegion2Delete(row)}
          />
        </div>
      </div>
    );
  };

  //Vehicle*******************************************************
  const [allVehicleData, setAllVehicleData] = useState([]);
  const [currentVehicleId, setCurrentVehicleId] = useState("");
  const [vehicleUpdated, setVehicleUpdated] = useState(true);

  const handleButtonClick2 = () => {
    setAddVehicle(!addVehicle);
    setCurrentVehicleId("");
  };

  const closeModal2 = () => {
    setAddVehicle(false);
    setCurrentVehicleId("");
  };

  const handleVehicleEyeClick = (row) => {
    setAddVehicle(!addVehicle);
    setCurrentVehicleId(row?._id);
  };

  const handleVehiclePencilClick = (row) => {
    setAddVehicle(!addVehicle);
    setCurrentVehicleId(row?._id);
  };

  const handleVehicleDelete = (row) => {
    const options = {
      customUI: ({ onClose }) => {
        return (
          <ConfirmToast
            title="Are you sure?"
            message="You want to delete this Vehicle Type?"
            onClose={() => onClose()}
            onSuccess={() => {
              dispatch(
                deleteVehicleAction({
                  apiPayloadRequest: {
                    _id: row?._id,
                  },
                  callback: (res) => {
                    if (res?.type === 1) {
                      const updatedData = allVehicleData.filter((item) => item?._id !== row?._id);
                      setAllVehicleData(updatedData);
                      Toastify("Vehicle Deleted Successfully!", 1);
                    } else {
                      Toastify("Vehicle Delete Failed!");
                    }
                  },
                })
              );
            }}
          />
        );
      },
      closeOnEscape: true,
      closeOnClickOutside: true,
      keyCodeForClose: [8, 32],
      overlayClassName: "!bg-gray-500 !bg-opacity-75",
    };

    confirmAlert(options);
  };

  const Tab3 = () => {
    return (
      <div className="mt-5">
        <DynamicTable
          data={allVehicleData}
          headers={Tab3headers}
          search={true}
          option1="All"
          title="Vehicle Type"
          button={true}
          handleButtonClick={handleButtonClick2}
          buttonstyle="py-2"
          label="Add New Vehicle"
          width={"w-42"}
          heightDesktop={"h-[50%]"}
          nthChildWidth={
            "[&>*:nth-child(1)]:w-56 [&>*:nth-child(2)]:w-42 [&>*:nth-child(3)]:w-44 [&>*:nth-child(4)]:w-32 [&>*:nth-child(5)]:w-32"
          }
          handleEyeClick={(row) => handleVehicleEyeClick(row)}
          handlePencilClick={(row) => handleVehiclePencilClick(row)}
          handleDeleteClick={(row) => handleVehicleDelete(row)}
        />
      </div>
    );
  };

  //Tariff*******************************************************
  const [tariffData, setTariffData] = useState([]);
  const [currentTariffId, setCurrentTariffId] = useState("");
  const [tariffUpdated, setTariffUpdated] = useState(true);

  console.log(tariffData, currentTariffId, "tariffData");

  const handleButtonClick3 = () => {
    setTariff(!tariff);
    setCurrentTariffId("");
  };

  const closeModal3 = () => {
    setTariff(false);
    setCurrentTariffId("");
  };

  const handleTariffEyeClick = (row) => {
    setTariff(!tariff);
    setCurrentTariffId(row.regionObjId + row.vehicleObjId);
  };

  const handleTariffPencilClick = (row) => {
    setTariff(!tariff);
    setCurrentTariffId(row?._id);
  };

  const handleTariffDelete = (row) => {
    const options = {
      customUI: ({ onClose }) => {
        return (
          <ConfirmToast
            title="Are you sure?"
            message="You want to delete this Tariff?"
            onClose={() => onClose()}
            onSuccess={() => {
              dispatch(
                deleteTarrifAction({
                  apiPayloadRequest: {
                    _id: row?._id,
                  },
                  callback: (res) => {
                    if (res?.type === 1) {
                      const updatedData = tariffData.filter((item) => item?._id !== row?._id);
                      setTariffData(updatedData);
                      Toastify("Tariff Deleted Successfully!", 1);
                    } else {
                      Toastify("Tariff Delete Failed!");
                    }
                  },
                })
              );
            }}
          />
        );
      },
      closeOnEscape: true,
      closeOnClickOutside: true,
      keyCodeForClose: [8, 32],
      overlayClassName: "!bg-gray-500 !bg-opacity-75",
    };

    confirmAlert(options);
  };

  const Tab4 = () => {
    return (
      <div className="mt-5">
        <DynamicTable
          noDelete={true}
          data={tariffData}
          headers={Tab4headers}
          search={true}
          option1="All"
          title="Tariff"
          button={true}
          size={6}
          buttonstyle="py-2"
          label="Add New Tariff"
          handleButtonClick={handleButtonClick3}
          width={"w-42"}
          heightDesktop={"h-[50%]"}
          nthChildWidth={
            "[&>*:nth-child(1)]:w-44 [&>*:nth-child(2)]:w-52 [&>*:nth-child(3)]:w-52 [&>*:nth-child(4)]:w-20"
          }
          nthChildStyle={""}
          handleEyeClick={(row) => {
            console.log(row, "row12323");
            handleTariffEyeClick(row);
          }}
          handlePencilClick={(row) => handleTariffEyeClick(row)}
          handleDeleteClick={(row) => handleTariffDelete(row)}
        />
      </div>
    );
  };

  //Discount*******************************************************
  const [discountData, setDiscountData] = useState([]);
  const [currentDiscountId, setCurrentDiscountId] = useState("");
  const [discountUpdated, setDiscountUpdated] = useState(true);

  const handleButtonClick4 = () => {
    setDiscount(!discount);
    setCurrentDiscountId("");
  };

  const closeModal4 = () => {
    setDiscount(false);
    setCurrentDiscountId("");
  };

  const handleDiscountEyeClick = (row) => {
    setDiscount(!discount);
    setCurrentDiscountId(row?._id);
  };

  const handleDiscountPencilClick = (row) => {
    setDiscount(!discount);
    setCurrentDiscountId(row?._id);
  };

  const handleDiscountDelete = (row) => {
    const options = {
      customUI: ({ onClose }) => {
        return (
          <ConfirmToast
            title="Are you sure?"
            message="You want to delete this Discount?"
            onClose={() => onClose()}
            onSuccess={() => {
              dispatch(
                deleteDiscountAction({
                  apiPayloadRequest: {
                    _id: row?._id,
                  },
                  callback: (res) => {
                    if (res?.type === 1) {
                      const updatedData = discountData.filter((item) => item?._id !== row?._id);
                      setDiscountData(updatedData);
                      Toastify("Discount Deleted Successfully!", 1);
                    } else {
                      Toastify("Discount Delete Failed!");
                    }
                  },
                })
              );
            }}
          />
        );
      },
      closeOnEscape: true,
      closeOnClickOutside: true,
      keyCodeForClose: [8, 32],
      overlayClassName: "!bg-gray-500 !bg-opacity-75",
    };

    confirmAlert(options);
  };

  const handleToggleClick = (row, isActive) => {
    dispatch(
      deactivateDiscountAction({
        apiPayloadRequest: {
          _id: row?._id,
          active: isActive ? 1 : 0,
        },
        callback: (res) => {
          if (res?.type === 1) {
            if (isActive) {
              Toastify("Activation Successfully!", 1);
            } else {
              Toastify("Deactivation Successfully!", 1);
            }
          }
        },
      })
    );
  };

  const Tab5 = () => {
    return (
      <div className="mt-5">
        <DynamicTable
          data={discountData}
          headers={Tab5headers}
          search={true}
          option1="All"
          title="Discounts"
          button={true}
          handleButtonClick={handleButtonClick4}
          buttonstyle="py-2"
          label="Add New Discount"
          width={"w-42"}
          heightDesktop={"h-[50%]"}
          nthChildWidth={
            "[&>*:nth-child(1)]:w-44 [&>*:nth-child(2)]:w-36 [&>*:nth-child(3)]:w-40 [&>*:nth-child(4)]:w-44 [&>*:nth-child(5)]:w-32"
          }
          nthChildStyle={""}
          handleToggleClick={(row, isActive) => {
            console.log(isActive, row);
            handleToggleClick(row, isActive);
          }}
          handleEyeClick={(row) => handleDiscountEyeClick(row)}
          handlePencilClick={(row) => handleDiscountPencilClick(row)}
          handleDeleteClick={(row) => handleDiscountDelete(row)}
        />
      </div>
    );
  };

  useEffect(() => {
    if (regionUpdated) {
      dispatch(
        readRegionAction({
          callback: (res) => {
            if (res?.type === 1) {
              setAllRegionData(res?.data);
              setRegionUpdated(false);
            }
          },
        })
      );
    }
  }, [regionUpdated]);

  useEffect(() => {
    if (region2Updated) {
      dispatch(
        readRegionAction({
          callback: (res) => {
            if (res?.type === 1) {
              setAllRegionData2(res?.data);
              setRegion2Updated(false);
            }
          },
        })
      );
    }
  }, [region2Updated]);

  useEffect(() => {
    if (addonUpdated) {
      dispatch(
        readAddonAction({
          callback: (res) => {
            if (res?.type === 1) {
              setAllAddon(res?.data);
              setAddonUpdated(false);
            }
          },
        })
      );
    }
  }, [addonUpdated]);

  useEffect(() => {
    if (vehicleUpdated) {
      dispatch(
        readVehicleAction({
          callback: (res) => {
            if (res?.type === 1) {
              setAllVehicleData(res?.data);
              setVehicleUpdated(false);
            }
          },
        })
      );
    }
  }, [vehicleUpdated]);

  useEffect(() => {
    if (tariffUpdated) {
      dispatch(
        readTarrifAction({
          callback: (res) => {
            if (res?.type === 1) {
              setTariffData(res?.data);
              setTariffUpdated(false);
            }
          },
        })
      );
    }
  }, [tariffUpdated]);

  useEffect(() => {
    if (discountUpdated) {
      dispatch(
        readDiscountAction({
          callback: (res) => {
            if (res?.type === 1) {
              setDiscountData(res?.data);
              setDiscountUpdated(false);
            }
          },
        })
      );
    }
  }, [discountUpdated]);

  const tabHeaders = [
    "Regions",
    // 'Regions Version 2',
    "Add Ons",
    "Vehicle Types",
    "Tariff",
    "Discount",
  ];

  const tabData = [
    // {
    //     'Regions': <Tab1 />
    // },
    {
      Regions: <Tab6 />,
    },
    {
      "Add Ons": <Tab2 />,
    },
    {
      "Vehicle Types": <Tab3 />,
    },
    {
      Tariff: <Tab4 />,
    },
    {
      Discount: <Tab5 />,
    },
  ];

  return (
    <div className="flex bg-zinc-100 h-[100vh]">
      <NavBar active={"Services"} role={role} />
      <div className="flex w-full h-full">
        <div className={`flex flex-col w-${shortBar ? "full" : "[70vw]"} h-full`}>
          <Header title={"Welcome"} />
          <div className="flex h-[88vh]  w-full pl-3  pr-4">
            <div className="rounded-xl mb-1 w-full bg-white p-4 h-full">
              <InnerTab
                active={activeInnerTab}
                data={tabData}
                headers={tabHeaders}
                handleChange={setActiveInnerTab}
              />
            </div>
          </div>
        </div>
        <ActiveAndInActiveComp
          shortBar={shortBar}
          setShortBar={setShortBar}
          active={activeTab}
          setActiveTab={setActiveTab}
          handleTabChange={handleTabChange}
        />
      </div>
      <ServiceAddRegionMapmodal
        closeModal={closeModal}
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        id={currentRegion2Id}
        setRegionUpdated={setRegion2Updated}
      />
      <ServiceAddOnmodal
        closeModal={closeModal1}
        setIsOpen={setAddOn}
        isOpen={addOn}
        id={currentAddonId}
        setAddonUpdated={setAddonUpdated}
      />
      <ServiceAddVehicle
        closeModal={closeModal2}
        setIsOpen={setAddVehicle}
        isOpen={addVehicle}
        allAddon={allAddon}
        id={currentVehicleId}
        setVehicleUpdated={setVehicleUpdated}
      />
      <ServiceTariffModal
        closeModal={closeModal3}
        setIsOpen={setTariff}
        isOpen={tariff}
        allVehicleData={allVehicleData}
        id={currentTariffId}
        tariffData={tariffData}
        setTariffUpdated={setTariffUpdated}
        allRegionData2={allRegionData2}
      />
      <ServiceDiscountModal
        closeModal={closeModal4}
        setIsOpen={setDiscount}
        isOpen={discount}
        allRegionData={allRegionData2}
        id={currentDiscountId}
        setDiscountUpdated={setDiscountUpdated}
      />
      {/* Extra */}
      <ServiceAddRegionmodal2
        closeModals={closeModals}
        setModalOpen={setModalOpen}
        modalOpen={modalOpen}
        handleEyeClick={handleRegionEyeClick}
        data={currentPincodes}
        setData={setCurrentPincodes}
        id={curentRegionId}
      />
      <ServiceAddOnmodal2
        closeModal={closeModals1}
        setIsOpen={setAddOn1}
        isOpen={addOn1}
        handleButtonClick={handleButtonClick}
      />
      <ServiceAddVehicle2
        closeModal={closeModals2}
        setIsOpen={setAddVehicle1}
        isOpen={addVehicle1}
      />
      <ServiceDiscountModal2
        closeModal={closeModals4}
        setIsOpen={setDiscount1}
        isOpen={discount1}
      />
    </div>
  );
};

export default Services;
