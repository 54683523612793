import React from "react";
import Line4 from "../../assets/images/Line4.png";
import { FaRupeeSign } from "react-icons/fa";

export default function TextLine({
  label,
  value,
  styles,
  Linestyle,
  rupee = false,
}) {
  return (
    <div>
      <div className="flex flex-row justify-between bg items-center py-2">
        <div className="text-[#555E61] text-[14px] font-semibold">
          <p>{label}</p>
        </div>
        <div className="flex gap-1 items-center">
          {rupee && <FaRupeeSign size={12} />}
          <p className={styles}>{value}</p>
        </div>
      </div>
      <div>
        <img className={`${Linestyle}`} src={Line4} />{" "}
      </div>
    </div>
  );
}
