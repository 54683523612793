import React, { useEffect, useState } from 'react'
import LoginForm from './LoginForm';
import SignupForm from './SignupForm';
import ForgotPasswordForm from './ForgotPasswordForm';
import OtpForm from './OtpForm';
import EmailVerification from './EmailVerification';
import { LoginPageHooks } from './hooks/LoginHooks';
import { withAuthenticationRequired, useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import { updateTokenAction } from '../../store/UserProfile/UserProfileAction';

const LoginPage = () => {
    const dispatch = useDispatch();

    const { currentForm, setCurrentForm, navigate } = LoginPageHooks();

    const { getAccessTokenSilently, isAuthenticated } = useAuth0();


    // useEffect(() => {
    //     const token = localStorage.getItem('token');
    //     if (token !== null) {
    //         navigate('/dashboard')
    //     }
    // }, [])

    useEffect(() => {
        // localStorage.clear();
        const token = localStorage.getItem('token');
        if(isAuthenticated && !token) {
            async function fetchToken() {
                const token = localStorage.getItem('token');
                if(!token) {
                    const accessToken = await getAccessTokenSilently()
                    localStorage.setItem('token', accessToken)
                    dispatch(updateTokenAction(accessToken))
                    console.log(accessToken, "accessToken");
                }
                navigate("/dashboard")
            }
            fetchToken();
        }else if (isAuthenticated) {
            dispatch(updateTokenAction(token))
            navigate("/dashboard")
        }
    }, [])

    return (
        <div className='bg-image-class flex bg-[#F5F5F5] h-full w-full'>
             {/* <div className='h-[100vh] w-1/2'>
            </div> */}
            {/* <div className='flex justify-center items-center h-[100vh] w-full'>
                {currentForm === "login" &&
                    <LoginForm
                        setCurrentForm={setCurrentForm}
                    />
                }
                {currentForm === "signup" &&
                    <SignupForm
                        setCurrentForm={setCurrentForm}
                    />
                }
                {currentForm === "validate" &&
                    <EmailVerification
                        setCurrentForm={setCurrentForm}
                    />
                }
                {currentForm === "forgotpassword" &&
                    <ForgotPasswordForm
                        setCurrentForm={setCurrentForm}
                    />
                }
                {currentForm === "otp" &&
                    <OtpForm
                        setCurrentForm={setCurrentForm}
                    />
                }
            </div> */}
        </div>
    )
}

// export default LoginPage;
export default withAuthenticationRequired(LoginPage);