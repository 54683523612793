import React, { useState } from "react";

export default function CounterInputs({ name, limit, value, onChange = null }) {
  const counterArray = Array.from({ length: limit }, (_, i) => i + 1);

  // const [selectedValue, setSelectedValue] = useState("");

  const handleClick = (val) => {
    // setSelectedValue(val);
    if (onChange) onChange(val);
  };

  return (
    <div className="flex gap-1">
      {counterArray?.map((count, index) => (
        <div
          id={name}
          key={index}
          className={`p-2 rounded-md w-fit text-xs font-semibold border-[#D9D9D9] border-[1px] cursor-pointer ${
            value === count ? "bg-blue-800" : ""
          }`}
          onClick={() => handleClick(count)}
        >
          <div className={`w-4 text-center text-[#555E61] ${value === count ? "text-white" : ""}`}>
            {" "}
            {count}
          </div>
        </div>
      ))}
    </div>
  );
}
