import React from "react";

export default function OutlineButton({ label, onClick, className, labelClassName }) {
  return (
    <>
      {" "}
      {label?.length > 0 && (
        <div
          className={`border-[#F15A2A] w-fit border-2 rounded-md items-center p-[3px] px-4 m-1 cursor-pointer ${
            className ?? ""
          }`}
          onClick={onClick}
        >
          <p
            className={`text-[#F15A2A]  font-bold text-[10px] text-center ${labelClassName ?? ""}`}
          >
            {label}
          </p>
        </div>
      )}
    </>
  );
}
