import React, { useEffect, useState } from "react";
import NavBar from "../../../components/Universal/NavBar";
import Header from "../../../components/Universal/Header";
import Tab from "../../../components/Common/Tabs/Tab";
import UpcomingTrips from "../../../components/Common/Sidebar/QuickBookings/UpcomingTrips";
import DynamicTable from "../../../components/Universal/DynamicTable";
import ReactModal from "react-modal";
import { abandonTripsHeaders, customStyles } from "../../../constants/Constant";
import Button from "../../../components/Common/Buttons/Button";
import TextField from "../../../components/Common/InputFields/TextField";
import MarkComplete from "../../../assets/images/Mark Complete (1).png";
import wrong from "../../../assets/images/wrong.png";
import Omni from "../../../assets/images/Omni.png";
import ProfileCircle from "../../../assets/icons/profilecircle.svg";
import driverimage from "../../../assets/images/driverimage.png";
import { useDispatch } from "react-redux";
import { readTodayTripAction } from "../../../store/Service/ServiceAction";
import MapModel from "../../../components/Universal/MapModel";
import { useJsApiLoader } from "@react-google-maps/api";
import { formattedDate } from "../../../utils/utilsHelperFunctions";
import { addDays } from "date-fns";
import { useNavigate } from "react-router-dom";

const AbondonTrips = ({ role }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_MAP_API_KEY,
  });
  const [activeTab, setActiveTab] = useState(1);
  const [showMapsModal, setShowMapsModal] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [tripData, setTripData] = useState([]);
  const [currentTrip, setCurrentTrip] = useState({});
  const [typeOfTrip, setTypeOfTrip] = useState("All");
  const [tripAllData, setTripAllData] = useState([]);
  const [upcomingTripData, setUpcomingTripData] = useState([]);
  const [abandonTripData, setAbandonTripData] = useState([]);
  const [filterRange, setFilterRange] = useState([
    {
      startDate: new Date(new Date().setHours(0, 0, 0)),
      endDate: new Date(new Date().setHours(23, 59, 59)),
      key: "selection",
    },
  ]);

  const containerStyle = {
    width: "800px",
    height: "500px",
  };

  const isShort = true;

  const handleTabChange = (data) => {
    setActiveTab(data);
  };

  useEffect(() => {
    dispatch(
      readTodayTripAction({
        apiPayloadRequest: {
          status: "0",
          startDate: filterRange[0]?.startDate,
          endDate: filterRange[0]?.endDate,
        },
        callback: (res) => {
          if (res.type === 1) {
            setTripAllData(res?.data);
            setAbandonTripData(res?.data);
          }
        },
      })
    );
    dispatch(
      readTodayTripAction({
        apiPayloadRequest: {
          startDate: new Date(),
          endDate: addDays(new Date(), 1),
        },
        callback: (res) => {
          if (res.type === 1) {
            setUpcomingTripData(res?.data?.filter((item) => item?.status !== "0"));
          }
        },
      })
    );
  }, [filterRange]);

  useEffect(() => {
    setTripData(tripAllData?.filter((d) => d?.typeofTrip === typeOfTrip || typeOfTrip === "All"));
  }, [tripAllData, typeOfTrip]);

  return (
    <div className="flex bg-zinc-200">
      <NavBar active={"AbondonTrips"} />
      <div className="flex w-full bg-pinl-400">
        <div className="w-[75%]">
          <Header title={"Abondon Trips"} role={role} />
          <div className="flex h-[88vh] w-full pl-3 pr-4">
            <div className="rounded-xl mb-1 w-full bg-white p-6">
              <DynamicTable
                title={"Abondon Trips"}
                size={7}
                data={tripData}
                headers={abandonTripsHeaders}
                search={true}
                Dropdown={true}
                showDateFilter={true}
                dateFilter={filterRange}
                handleDateFilter={setFilterRange}
                dropDownChangeHandler={setTypeOfTrip}
                showEditIcon={false}
                showDeleteIcon={false}
                width={"w-42"}
                heightDesktop={"h-[50%]"}
                // handleEyeClick={(row) => {
                //   console.log(row);
                //   setShowProfileModal((preState) => !preState);
                //   console.log(row, "row");
                //   setCurrentTrip(row);
                // }}
                handlePencilClick={(row) => {
                  navigate(`/dashboard/${row?._id}`);
                }}
              />
            </div>
          </div>
        </div>
        <div className="w-[25%]  bg-white p-4  flex flex-col gap-2 overflow-auto h-[100vh]">
          <div className="mb-1">
            {" "}
            <Tab
              Tab1={"Upcoming trips"}
              Tab2={"Abandon Trips"}
              handleTabClick={handleTabChange}
              activeTab={activeTab}
            />
          </div>
          {activeTab === 1 ? (
            <div className="flex flex-col gap-2">
              {upcomingTripData.length > 0 &&
                upcomingTripData
                  .filter((item) => item.status === "2")
                  .map((trip) => (
                    <UpcomingTrips
                      price={""}
                      active={true}
                      buttonName={"Assign Driver"}
                      lineWidth1={"w-0"}
                      lineWidth2={"w-0"}
                      edit1={"10:00 AM"}
                      edit2={"11:37 PM"}
                      background1={"bg-zinc-200"}
                      background2={"bg-zinc-200"}
                      value1={
                        trip?.fromAddress
                          ? `${trip?.fromAddress} ${
                              trip?.fromPincode ? ", " + trip?.fromPincode : ""
                            }`
                          : "-"
                      }
                      value2={
                        trip?.toAddress
                          ? `${trip?.toAddress} ${trip?.toPincode ? ", " + trip?.toPincode : ""}`
                          : "-"
                      }
                      inputStyle1={"bg-zinc-200"}
                      inputStyle2={"bg-zinc-200"}
                    />
                  ))}
            </div>
          ) : (
            <div className="flex flex-col gap-2">
              {abandonTripData.length > 0 &&
                abandonTripData.map((trip) => (
                  <UpcomingTrips
                    id={trip._id}
                    price={""}
                    active={true}
                    buttonName={"Book Ambulance"}
                    lineWidth1={"w-0"}
                    lineWidth2={"w-0"}
                    edit1={"10:00 AM"}
                    edit2={"11:37 PM"}
                    phoneStyle={"hidden"}
                    background1={"bg-zinc-200"}
                    background2={"bg-zinc-200"}
                    value1={
                      trip?.fromAddress
                        ? `${trip?.fromAddress} ${
                            trip?.fromPincode ? ", " + trip?.fromPincode : ""
                          }`
                        : "-"
                    }
                    value2={
                      trip?.toAddress
                        ? `${trip?.toAddress} ${trip?.toPincode ? ", " + trip?.toPincode : ""}`
                        : "-"
                    }
                    inputStyle1={"bg-zinc-200"}
                    inputStyle2={"bg-zinc-200"}
                  />
                ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AbondonTrips;
