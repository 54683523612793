import React from "react";
import ReactModal from "react-modal";
import wrong from '../../../assets/images/wrong.png'
import Line4 from '../../../assets/images/Line4.png'
import TextInputField from "../../../components/Common/InputFields/TextInputField";
import Button from "../../../components/Common/Buttons/Button";
import { customStyles } from "../../../constants/Constant";
import TextInput from "../../../components/Common/InputFields/TextInput";
import Group1 from '../../../assets/images/Group1.png'


const ServiceAddOnmodal2 = (props) => {


    return (
        <div>
            <ReactModal
                isOpen={props?.isOpen}
                style={customStyles}
                onRequestClose={props?.closeModal}

            >
                <div className='w-[40vw] h-[70vh] flex flex-col justify-between '>

                    <div>
                        <div className=' p-5 px-3  items-center flex justify-between'>
                            <div>
                                <h1 className='text-lg font-bold'>{props?.id === '' ? 'Add New Add Ons' : 'Update Add Ons'}</h1>
                            </div>
                            <div className='text-lg px-3 cursor-pointer' onClick={() => props?.setIsOpen(!props?.isOpen)}>
                                <img src={wrong} />
                            </div>
                        </div>

                        <img className='w-full h-[1px]' src={Line4} />

                        <div className='m-5 w-[37vw] flex flex-col gap-8'>
                            <div className='w-full flex flex-row gap-3 items-center'>
                                <div className="w-full"><TextInput label='Add On' placeholder='Stretcher' /></div>
                                <div className="w-full"><TextInput label='Measurement' placeholder='2 meters' /></div>
                            </div>

                            <div>
                                <p className="text-[11px] font-medium">Description</p>
                                <div className="w-full mt-4 pl-4 pb-4 border-b-[2px] text-slate-700 rounded-md flex text-[11px] ">
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                </div>
                            </div>

                            <div className="w-full"><TextInput label='Add On image' placeholder='' /><img className="-mt-9 pl-4" src={Group1} /></div>

                            <div>
                                {/* <p className="text-[11px] font-medium">Upload Add On icon</p>
                                <div className="w-full py-12 mt-1 bg-slate-100 rounded-md flex justify-center items-center">
                                    <div><p className="text-[10px]">Browse files to upload</p></div>
                                </div> */}
                            </div>

                        </div>
                    </div>

                    <div className='p-4 shadow-2xl  '>
                        <div className='flex items-center float-right'>
                            <Button label='Cancel' background={'bg-slate-300 px-6'} textcolor={'text-slate-600'} />
                            <Button label={props?.id === '' ? 'Add'  : 'Update'} background={'bg-[#F15A2A] px-8'} textcolor={'text-white'} />
                        </div>

                    </div>

                </div>


            </ReactModal>

        </div>
    )
}

export default ServiceAddOnmodal2;