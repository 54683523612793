const ConfirmToast = ({ onClose, onSuccess, title, message, deleteButtonText = null }) => {
  return (
    <div className="bg-blackishV2 w-[30dvw] gap-3 flex flex-col items-center px-20 py-12 bg-white">
      <h1 className="font-bold text-2xl">{title ?? "Are you sure?"}</h1>
      <p className="text-sm mb-5">{message ?? "You want to delete this file?"}</p>
      <div className="w-full gap-4 grid grid-cols-2">
        <button
          onClick={onClose}
          className="text-secondary bg-blackish font-medium text-[12px] border border-secondary py-2 px-6 hover:bg-secondary hover:text-white transition-all duration-500"
        >
          No
        </button>
        <button
          className="text-red-500 bg-blackish font-medium text-[12px] border border-red-700 py-2 px-6 hover:border-red-700 hover:bg-red-700 hover:text-white transition-all duration-500"
          onClick={onSuccess}
        >
          {deleteButtonText ?? "Yes, Delete it!"}
        </button>
      </div>
    </div>
  );
};

export default ConfirmToast;
