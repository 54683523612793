import React from 'react'

const TextField = ({ label, value }) => {
    return (
        <div className='flex flex-col w-full gap-4'>
            <p className='text-xs text-black text-opacity-40 capitalize'>
                {label}
            </p>
            <p className='text-sm pb-2 border-b border-black font-semibold border-opacity-25 w-full'>
                {value}
            </p>
        </div>
    )
}

export default TextField