import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import wrong from "../../../assets/images/wrong.png";
import Line4 from "../../../assets/images/Line4.png";
import TextInputField from "../../../components/Common/InputFields/TextInputField";
import Button from "../../../components/Common/Buttons/Button";
import { customContentStyles, customStyles } from "../../../constants/Constant";
import TextInput from "../../../components/Common/InputFields/TextInput";
import InputDropdown from "../../../components/Common/InputFields/InputDropdown";
import { uploadAssestsAction } from "../../../store/LoginAndRegister/LoginAndRegisterAction";
import { useDispatch } from "react-redux";
import FileUploadComponent from "../../../components/Common/InputFields/FileUploadComponent";
import {
  deleteAddonVehicleAction,
  readAddonAction,
  readVehicleAction,
  saveAddonVehicleAction,
  saveVehicleAction,
  updateVehicleAction,
} from "../../../store/Service/ServiceAction";
import Toastify from "../../../components/Universal/Toastify";

const ServiceAddVehicle = (props) => {
  const dispatch = useDispatch();

  const initialVehicleValues = {
    vehicleType: "",
    serviceType: "",
    vehicleImage: "",
    equipmentArray: [],
  };
  const initialErrorFields = {
    vehicleType: false,
    serviceType: false,
    vehicleImage: false,
    equipmentArray: false,
  };

  const [vehicleValues, setVehicleValues] = useState(initialVehicleValues);
  const [selectedOption, setSelectedOption] = useState("");
  const [errorFields, setErrorFields] = useState(initialErrorFields);

  const handleChange = (event) => {
    const { name, value } = event.target;

    setVehicleValues((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErrorFields({ ...errorFields, [name]: false });
  };

  const handleResetInputs = async () => {
    setVehicleValues(initialVehicleValues);
    setErrorFields(initialErrorFields);
    setSelectedOption("");
  };

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
    setErrorFields({ ...errorFields, equipmentArray: false });
  };

  const onUpload = (uploadedFiles) => {
    const file = uploadedFiles[0];

    const formData = new FormData();
    formData.append("file", file);

    dispatch(
      uploadAssestsAction({
        apiPayloadRequest: formData,
        callback: (res) => {
          setVehicleValues((prevInputs) => ({
            ...prevInputs,
            vehicleImage: res?.url,
          }));
          setErrorFields({ ...errorFields, vehicleImage: false });
        },
      })
    );
  };

  console.log("selectedOption", selectedOption);
  console.log("props", props);

  const onAdd = () => {
    if (!selectedOption || selectedOption === "Select") {
      setErrorFields({ ...errorFields, equipmentArray: true });
      return false;
    }
    console.log("onAdd selectedOption", selectedOption);
    console.log("onAdd _id", props);
    if (props?.id !== "") {
      dispatch(
        saveAddonVehicleAction({
          apiPayloadRequest: {
            _id: props?.id,
            addonObjId: selectedOption,
          },
          callback: (res) => {
            if (res?.type === 1) {
              setVehicleValues((prev) => ({
                ...prev,
                equipmentArray: [
                  ...prev?.equipmentArray,
                  {
                    _id: selectedOption,
                    addon: addonOptions.filter((item) => item?.value === selectedOption)[0].label,
                  },
                ],
              }));
              setSelectedOption("Select");
            }
          },
        })
      );
    } else {
      setVehicleValues((prev) => ({
        ...prev,
        equipmentArray: [
          ...prev?.equipmentArray,
          {
            _id: selectedOption,
            addon: addonOptions.filter((item) => item?.value === selectedOption)[0].label,
          },
        ],
      }));
      setSelectedOption("Select");
    }
    setErrorFields({ ...errorFields, equipmentArray: false });
  };

  const handleDeleteEquipment = (index) => {
    if (props?.id !== "") {
      dispatch(
        deleteAddonVehicleAction({
          apiPayloadRequest: {
            _id: props?.id,
            addonObjId: vehicleValues?.equipmentArray[index]?._id,
          },
          callback: (res) => {
            if (res?.type === 1) {
              const updatedData = vehicleValues?.equipmentArray.filter((_, idx) => idx !== index);
              setVehicleValues((prev) => ({
                ...prev,
                equipmentArray: updatedData,
              }));

              Toastify("Addon Deleted Successfully!", 1);
            }
          },
        })
      );
    } else {
      const updatedData = vehicleValues?.equipmentArray.filter((_, idx) => idx !== index);
      setVehicleValues((prev) => ({
        ...prev,
        equipmentArray: updatedData,
      }));
    }
  };

  const handleSubmit = () => {
    if (!vehicleValues?.vehicleType) {
      setErrorFields({ ...errorFields, vehicleType: true });
      return false;
    } else if (!vehicleValues?.serviceType) {
      setErrorFields({ ...errorFields, serviceType: true });
      return false;
    } else if (!vehicleValues?.vehicleImage) {
      setErrorFields({ ...errorFields, vehicleImage: true });
      return false;
    } else if (vehicleValues?.equipmentArray?.length === 0) {
      setErrorFields({ ...errorFields, equipmentArray: true });
      return false;
    }
    if (props?.id !== "") {
      dispatch(
        updateVehicleAction({
          apiPayloadRequest: {
            _id: props?.id,
            vehicle_type_name: vehicleValues?.vehicleType,
            vehicle_service: vehicleValues?.serviceType,
            vehicle_icon_url: vehicleValues?.vehicleImage,
          },
          callback: (res) => {
            if (res?.type === 1) {
              // vehicleValues?.equipmentArray?.map(async (item) => {
              //   dispatch(
              //     saveAddonVehicleAction({
              //       apiPayloadRequest: {
              //         _id: props?.id,
              //         addonObjId: item?._id,
              //       },
              //       callback: (res) => {
              //         if (res?.type === 1) {
              //           //updated equipmentArray
              //         }
              //       },
              //     })
              //   );
              // });
              Toastify("Vehicle Type Updated Successfully!", 1);
              props?.setVehicleUpdated(true);
              handleResetInputs();
              props.closeModal();
            }
          },
        })
      );
    } else {
      dispatch(
        saveVehicleAction({
          apiPayloadRequest: {
            vehicle_type_name: vehicleValues?.vehicleType,
            vehicle_service: vehicleValues?.serviceType,
            vehicle_icon_url: vehicleValues?.vehicleImage,
          },
          callback: (res) => {
            if (res?.type === 1) {
              vehicleValues?.equipmentArray?.map(async (item) => {
                dispatch(
                  saveAddonVehicleAction({
                    apiPayloadRequest: {
                      _id: res?.data[0]?._id,
                      addonObjId: item?._id,
                    },
                    callback: (res) => {
                      if (res?.type === 1) {
                        //saved equipmentArray
                      }
                    },
                  })
                );
              });
              Toastify("Vehicle Type Saved Successfully!", 1);
              props?.setVehicleUpdated(true);
              handleResetInputs();
              props.closeModal();
            }
          },
        })
      );
    }
  };

  const addonOptions = props?.allAddon.map((item) => ({
    value: item?._id,
    label: item?.addon,
  }));

  useEffect(() => {
    if (props?.id !== "") {
      dispatch(
        readVehicleAction({
          apiPayloadRequest: {
            _id: props?.id,
          },
          callback: (res) => {
            if (res?.type === 1) {
              const data = res?.data[0];
              const features = data?.features;

              setVehicleValues({
                vehicleType: data?.vehicle_type_name || "",
                serviceType: data?.vehicle_service || "",
                vehicleImage: data?.vehicle_icon_url || "",
                equipmentArray: checkFeaturesAvailable(res?.data)
                  ? features.map((item) => ({
                      _id: item?.addonObjId?.[0]?._id || "",
                      addon: item?.addonObjId?.[0]?.addon || "",
                    }))
                  : [],
              });
            }
          },
        })
      );
    } else {
      setVehicleValues(initialVehicleValues);
    }
  }, [props?.id]);

  // Check if there is data in the features' addonObjId arrays
  const checkFeaturesAvailable = (dataArray) => {
    return dataArray.some(
      (item) =>
        item.features &&
        item.features.some((feature) => feature.addonObjId && feature.addonObjId.length > 0)
    );
  };

  const cancelPopup = () => {
    handleResetInputs();
    props?.closeModal();
  };

  return (
    <div>
      <ReactModal
        isOpen={props?.isOpen}
        style={{
          ...customStyles,
          ...customContentStyles,
        }}
        onRequestClose={cancelPopup}
      >
        <div className="w-[40dvw] max-h-[95vh] small-bar items-center overflow-y-auto bg-white border rounded-xl">
          <div>
            <div className=" p-5 px-4  items-center flex justify-between">
              <div>
                <h1 className="text-lg font-bold">
                  {props?.id === "" ? "Add New Vehicle" : "Update Vehicle"}
                </h1>
              </div>
              <div className="text-lg px-3 cursor-pointer" onClick={cancelPopup}>
                <img src={wrong} />
              </div>
            </div>
            <img className="w-full h-[1px]" src={Line4} />
            <div className="m-5 w-[37vw] flex flex-col gap-6 ">
              <div className="w-full flex flex-row gap-3 items-center">
                <div className="w-full">
                  <TextInputField
                    name="vehicleType"
                    handleChange={handleChange}
                    value={vehicleValues.vehicleType}
                    label="Vehicle Type *"
                    placeholder="Enter Vehicle"
                    isError={errorFields?.vehicleType}
                  />
                </div>
                <div className="w-full">
                  <TextInputField
                    name="serviceType"
                    handleChange={handleChange}
                    value={vehicleValues.serviceType}
                    label="Service Type *"
                    placeholder="Enter Service Type"
                    isError={errorFields?.serviceType}
                  />
                </div>
              </div>
              <div className="p-4 border rounded-lg">
                <p className="text-[11px] font-medium">Upload Vehicle Image *</p>
                <FileUploadComponent onUpload={onUpload} />
                <div className="w-full">
                  <TextInputField
                    name="vehicleImage"
                    handleChange={handleChange}
                    value={vehicleValues.vehicleImage}
                    placeholder="Enter URL Here"
                    isError={errorFields?.vehicleImage}
                  />
                </div>
              </div>
              <div className="w-full">
                <img className="w-full" src={Line4} />
              </div>
              <div className="w-full flex">
                <InputDropdown
                  label="Select Equipments from Below drop down *"
                  styles="border-2"
                  options={addonOptions?.filter(
                    (v) => !vehicleValues?.equipmentArray?.map((v) => v?._id).includes(v?.value)
                  )}
                  value={selectedOption}
                  onChange={handleSelectChange}
                  isError={errorFields?.equipmentArray}
                  errMsg={"Min One Equipment"}
                />
                <Button
                  label="+Add"
                  background={"bg-white h-10 mt-7 border-[1px] text-orange-500 border-orange-500"}
                  onClick={onAdd}
                />
              </div>
              <div>
                <div className=" w-full p-4 border-2 border-[#979AA480] flex flex-row flex-wrap gap-2  rounded-lg pb-12">
                  {vehicleValues?.equipmentArray?.map((item, i) => (
                    <div
                      key={item._id}
                      className="bg-[#979AA480] rounded-md flex flex-row items-center p-1 px-3 gap-3"
                    >
                      <div>
                        {" "}
                        <p className="text-[12px] text-blue-900 font-semibold">{item.addon}</p>{" "}
                      </div>
                      <img src={wrong} onClick={() => handleDeleteEquipment(i)} className="h-2" />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="px-3 pt-3 pb-2 mb-5 shadow-2xl flex mx-5 justify-end">
            <div className="flex items-center">
              <Button
                onClick={cancelPopup}
                label="Cancel"
                background={"bg-slate-300 px-6"}
                textcolor={"text-slate-600"}
              />
              <Button
                onClick={handleSubmit}
                label={props?.id === "" ? "Add" : "Update"}
                background={"bg-[#F15A2A] px-8"}
                textcolor={"text-white"}
              />
            </div>
          </div>
        </div>
      </ReactModal>
    </div>
  );
};

export default ServiceAddVehicle;
