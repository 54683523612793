import React from "react";
import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";

const DateTimeInput = ({
  placeholder,
  name,
  value,
  label,
  inputstyle = "",
  handleChange,
  isError = false,
  maxDate = null,
  minDate = null,
}) => {
  return (
    <div className="flec flex-col w-full h-full">
      <div className="text-black/60 font-medium text-[10px] mb-2">{label}</div>
      <DateTimePicker
        className={"w-full"}
        onChange={handleChange}
        value={value}
        {...(maxDate ? { maxDate: maxDate } : {})}
        {...(minDate ? { minDate: minDate } : {})}
      />
      {isError && (
        <p className="text-red-500 font-normal relative bottom-[-4px] text-[10px] mb-[-5px]">
          {label ? label?.replaceAll(/[^a-zA-Z ]+/g, "") : name} Is Required Field
        </p>
      )}
    </div>
  );
};

export default DateTimeInput;
