import React, { useEffect, useState } from "react";
import UserHeader from "../components/Universal/UserHeader";
import { FaRoad } from "react-icons/fa6";
import UserMapComponent from "../components/Universal/UserMapComponent";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { readTripAction, readVehicleAction } from "../store/Service/ServiceAction";
import moment from "moment";
import { customStyles } from "../constants/Constant";
import { FaCircle, FaPrint, FaReceipt, FaSquareFull } from "react-icons/fa";
import { ImPriceTag } from "react-icons/im";
import { RiCheckLine, RiTimerLine } from "react-icons/ri";
import { HiOutlineXMark } from "react-icons/hi2";
import { GiCloudDownload } from "react-icons/gi";
import TripInvoiceModal from "./TripInvoiceModal";
import { useRef } from "react";
import ReactDOMServer from "react-dom/server";
import { useReactToPrint } from "react-to-print";
import jsPDF from "jspdf";

import { MdCurrencyRupee } from "react-icons/md";
import ambulanceFront from "../assets/images/ambulanceFront.png";
import receiptHeader from "../assets/images/receipt_header_short_bg.png";
import LoaderSpinner from "../components/Universal/LoaderSpinner";

const TripDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const receiptToPrint = useRef(null);
  const { userDetails } = useSelector((state) => state.userProfile);

  const [currentTrip, setCurrentTrip] = useState();
  const [tripDetailsLoading, setTripDetailsLoading] = useState(true);
  const [allVehicleData, setAllVehicleData] = useState([]);
  const [addOnDetailsList, setAddOnDetailsList] = useState([]);
  const [isInvoiceModalOpen, setIsInvoiceModalOpen] = useState(false);

  const checkFeaturesAvailable = (item) => {
    return (
      item.features &&
      item.features.some((feature) => feature.addonObjId && feature.addonObjId.length > 0)
    );
  };

  console.log("currentTrip", currentTrip);
  console.log("addOnDetailsList", addOnDetailsList);

  useEffect(() => {
    dispatch(
      readVehicleAction({
        callback: (res) => {
          if (res?.type === 1) {
            setAllVehicleData(res?.data);
          }
        },
      })
    );
  }, []);

  useEffect(() => {
    if (location?.state?._id) {
      setTripDetailsLoading(true);
      dispatch(
        readTripAction({
          apiPayloadRequest: {
            _id: location?.state?._id,
          },
          callback: (res) => {
            if (res.type === 1) {
              const data = res?.data[0];
              setCurrentTrip(data);
              setTripDetailsLoading(false);
              let currentVehicleData = allVehicleData?.find(
                (v) => v?._id === data?.vehicleObjId?.[0]?._id
              );
              if (currentVehicleData) {
                let featuresList = checkFeaturesAvailable(currentVehicleData)
                  ? currentVehicleData?.features?.map((v) => v?.addonObjId[0])
                  : [];
                if (featuresList?.length > 0) {
                  if (data?.addons?.length > 0) {
                    featuresList = featuresList?.map((d) => {
                      const availableAddon = data?.addons?.find((v) => v?._id === d?._id);
                      if (availableAddon) {
                        return { ...d, quantity: Number(availableAddon?.count ?? 0) };
                      }
                      return d;
                    });
                    setAddOnDetailsList(featuresList);
                  }
                }
              }
            }
          },
        })
      );
    }
  }, [location?.state?._id]);

  const getReceiptTemplate = () => {
    return (
      <div className="flex flex-1 overflow-auto flex-col" ref={receiptToPrint}>
        <div className="bg-[#FDE6DF] pt-32 grid grid-cols-12 h-fit pl-5">
          <div className="col-span-6 pb-12">
            <p className="text-sm">
              {moment(currentTrip?.dateandTimeofTrip)?.format("MMMM DD, YYYY")}
            </p>
            <h3 className="text-[1.7rem] font-medium">
              Here's your receipt for your ride, {userDetails?.firstName}
            </h3>
          </div>
          <div className="col-span-6 relative pl-4">
            <img src={receiptHeader} className="absolute bottom-0 w-full right-0 z-[9]" />
            <img src={ambulanceFront} className="z-10 w-[125px] absolute bottom-6 right-6" />
          </div>
        </div>
        <div className="px-5 py-3">
          <div className="border-b border-primary py-6 flex items-center justify-between font-medium text-[1.7rem]">
            <span className="">Total</span>
            <span className="whitespace-nowrap flex items-center">
              <MdCurrencyRupee /> {parseFloat(currentTrip?.price)?.toFixed(2)}
            </span>
          </div>
          <div className="border-b border-gray-400 py-7 flex items-center justify-between text-gray-700">
            <span className="">Trip Charge</span>
            <span className="whitespace-nowrap flex items-center">
              <MdCurrencyRupee /> {parseFloat(currentTrip?.price)?.toFixed(2)}
            </span>
          </div>
          <div className="py-7 text-gray-700">
            <p className="flex items-center justify-between font-medium text-black mb-3">
              <span className="">Sub Total</span>
              <span className="whitespace-nowrap flex items-center">
                <MdCurrencyRupee /> {parseFloat(currentTrip?.price)?.toFixed(2)}
              </span>
            </p>
            <p className="flex items-center justify-between">
              <span className="">Booking Fee</span>
              <span className="whitespace-nowrap flex items-center">
                <MdCurrencyRupee /> 0.00
              </span>
            </p>
          </div>
        </div>
      </div>
    );
  };

  const handlePrintReceipt = useReactToPrint({
    documentTitle: `${currentTrip?._id}_receipt`,
    removeAfterPrint: true,
  });

  const handleReceiptDownload = () => {
    var doc = new jsPDF();
    doc.html(ReactDOMServer.renderToStaticMarkup(getReceiptTemplate()), {
      callback: function (doc) {
        doc.save(`${currentTrip?._id}_receipt`);
      },
      margin: [12, 20, 10, 20],
      width: 160,
      windowWidth: 800,
    });
  };

  return (
    <div className="flex flex-col h-full relative overflow-y-auto">
      <UserHeader activeTab={"myTrips"} />
      <div className="flex gap-[2.5%] w-full h-full md:pt-[80px] md:pb-6 pb-[80px] py-6 px-[5%]">
        <div className=" w-2/5 md:flex hidden justify-end">
          <button
            onClick={() => navigate("/myTrips")}
            className="flex items-center h-fit gap-2 text-base font-semibold p-4 border w-fit rounded-md transition-all duration-200 border-none hover:bg-gray-100"
          >
            <FaArrowLeftLong />
            Back to trips
          </button>
        </div>
        {tripDetailsLoading ? (
          <div className="flex flex-col w-full h-full gap-6 p-4 border rounded-md ">
            {" "}
            <div className="h-[80vh] flex flex-col items-center justify-center">
              <LoaderSpinner className="!text-black" />
              <p>Loading trips details..</p>
            </div>
          </div>
        ) : (
          <div className="flex flex-col w-full h-full gap-6 p-4 border rounded-md ">
            <button
              onClick={() => navigate("/myTrips")}
              className="md:hidden flex items-center h-fit gap-2 text-xs font-semibold "
            >
              <FaArrowLeftLong />
              Back
            </button>
            {new Date(currentTrip?.dateandTimeofTrip) > new Date() ? (
              <div className="bg-[#FEF2E7] flex flex-col items-center justify-center rounded-md p-6 gap-2">
                <span
                  className="text-white rounded-full w-[30px] h-[30px] flex items-center justify-center"
                  style={{ background: "linear-gradient(45deg, #F9963B, #F95D3B)" }}
                >
                  <RiTimerLine />
                </span>
                <p className="font-semibold">Upcoming Trip</p>
              </div>
            ) : currentTrip?.status === "7" ? (
              <div className="bg-[#E4F6F0] flex flex-col items-center justify-center rounded-md p-6 gap-2">
                <span
                  className="text-white rounded-full w-[30px] h-[30px] flex items-center justify-center"
                  style={{ background: "linear-gradient(45deg, #3EBD93, #319488)" }}
                >
                  <RiCheckLine />
                </span>
                <p className="font-semibold">Trip completed Successfully</p>
              </div>
            ) : (
              <div className="bg-[#FCE9EF] flex flex-col items-center justify-center rounded-md p-6 gap-2">
                <span
                  className="text-white rounded-full w-[30px] h-[30px] flex items-center justify-center"
                  style={{ background: "linear-gradient(45deg, #EF4E4E, #E249AE)" }}
                >
                  <HiOutlineXMark />
                </span>
                <p className="font-semibold">Trip has been canceled</p>
              </div>
            )}
            <div className="flex justify-between items-center">
              <p className="text-3xl font-semibold flex gap-4">Your trip</p>
              <p className="font-semibold flex gap-4">Trip ID: {currentTrip?.tripId}</p>
            </div>
            <p className="text-sm font-semibold">
              {moment(currentTrip?.dateandTimeofTrip)?.format("hh:mm A, dddd MMM DD, YYYY")} with{" "}
              {currentTrip?.drivers?.[0]?.firstName}
            </p>
            <div className="flex flex-shrink-0 md:w-4/5 w-full h-[60vh] bg-black/10 rounded-md overflow-hidden border">
              <UserMapComponent
                customStyles={customStyles}
                containerStyle={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "10px",
                }}
                currentTrip={currentTrip}
              />
            </div>
            <p className="font-semibold text-xl">Route</p>
            <div className="flex">
              <div className="w-fit flex flex-col justify-around items-center text-[0.6rem] pr-2 gap-1 py-1">
                <FaCircle />
                <div className="w-[3px] bg-black flex flex-1 h-auto"></div>
                <FaSquareFull />
              </div>
              <div className="flex flex-col gap-5">
                <p className="text-sm">{currentTrip?.fromAddress}</p>
                <p className="text-sm">{currentTrip?.toAddress}</p>
              </div>
            </div>
            <hr className="w-full" />
            <p className="font-semibold text-xl">Trip Details</p>
            <p className="text-sm flex gap-2">
              <FaRoad />
              {currentTrip?.distance} kilometers
            </p>
            <p className="text-sm flex gap-2">
              <ImPriceTag />
              <span>₹{parseFloat(currentTrip?.price)?.toFixed(2)}</span>
            </p>

            <div className="mt-3 flex text-white gap-3 justify-center">
              <button
                className="flex gap-3 items-center border-none bg-primary rounded-md px-7 py-2 text-sm"
                onClick={() => setIsInvoiceModalOpen(true)}
              >
                <FaReceipt />
                View Receipt
              </button>
              <button
                className="flex gap-3 items-center border-none bg-primary rounded-md px-7 py-2 text-sm"
                onClick={handleReceiptDownload}
              >
                <FaPrint />
                Download Receipt
              </button>
              {/* <button className="flex gap-3 items-center border-none bg-primary rounded-md px-7 py-2 text-sm">
              <GiCloudDownload />
              Download Invoice
            </button> */}
            </div>

            <hr className="w-full" />
            <p className="font-semibold text-xl">Driver Details</p>
            <p className="text-sm flex gap-2">
              Driver Name: {currentTrip?.drivers?.[0]?.firstName}{" "}
              {currentTrip?.drivers?.[0]?.lastName}
            </p>

            <hr className="w-full" />
            <p className="font-semibold text-xl">Vehicle Details</p>
            <p className="text-sm flex gap-2">
              Vehicle Type: {currentTrip?.vehicleObjId?.[0]?.vehicle_type_name}
            </p>
            <p className="text-sm flex gap-2">
              Vehicle Service Type: {currentTrip?.vehicleObjId?.[0]?.vehicle_service}
            </p>
            {addOnDetailsList?.addons?.map((addon) => {
              return (
                <p className="text-sm flex gap-2" key={addon?._id}>
                  {addon?.addon}: {addon?.quantity}
                </p>
              );
            })}
          </div>
        )}

        <TripInvoiceModal
          isOpen={isInvoiceModalOpen}
          closeModal={() => setIsInvoiceModalOpen(false)}
          getReceiptTemplate={getReceiptTemplate}
          handlePrintReceipt={handlePrintReceipt}
          handleReceiptDownload={handleReceiptDownload}
          receiptToPrint={receiptToPrint}
        />
      </div>
    </div>
  );
};

export default TripDetails;
