import React, { useState, useEffect } from "react";
import NavBar from "../../components/Universal/NavBar";
import Header from "../../components/Universal/Header";
import ActiveAndInActiveComp from "../../components/Common/ActiveAndInActiveComp";
import CardSamplePic from "../../assets/images/CardSamplePic.png";
import DynamicTable from "../../components/Universal/DynamicTable";
import AddAgentModal from "./Modals/AgentModal/AddAgentModal";
import { deactivateAgentAction, readAgentAction } from "../../store/Service/ServiceAction";
import { useDispatch } from "react-redux";
import Toastify from "../../components/Universal/Toastify";

const Agents = ({ role, shortBar, setShortBar }) => {
  const [activeTab, setActiveTab] = useState(1);
  const [allAgentData, setAllAgentData] = useState([]);
  const [currentAgentId, setCurrentAgent] = useState("");

  const [isAgent, setIsAgent] = useState(false);
  const dispatch = useDispatch();

  const getAgentsList = () => {
    dispatch(
      readAgentAction({
        callback: (res) => {
          if (res?.type === 1) {
            const data = res?.data?.filter((v) => v.email);
            setAllAgentData(data);
          }
        },
      })
    );
  };

  useEffect(() => {
    getAgentsList();
  }, []);

  const handleButtonClick = (data) => {
    setIsAgent(!isAgent);
  };

  const closeModal = () => {
    setIsAgent(false);
    setCurrentAgent("");
  };

  const handleTabChange = (data) => {
    setActiveTab(data);
  };

  const handleAgentPencilClick = (row) => {
    setIsAgent(!isAgent);
    setCurrentAgent(row?._id);
  };

  const handleToggleClick = (row, isActive) => {
    dispatch(
      deactivateAgentAction({
        apiPayloadRequest: {
          _id: row?._id,
          active: isActive ? 1 : 0,
        },
        callback: (res) => {
          if (res?.type === 1) {
            if (isActive) {
              Toastify("Activation Successfully!", 1);
            } else {
              Toastify("Deactivation Successfully!", 1);
            }
          }
        },
      })
    );
  };

  const headers = [
    {
      headerName: "User Name",
      headerId: "firstName",
    },
    {
      headerName: "Email",
      headerId: "email",
    },
    {
      headerName: "Mobile",
      headerId: "mobile",
    },
    {
      headerName: "Region",
      headerId: "regionObjId",
    },
    {
      headerName: "Status",
      headerId: "switch",
    },
    {
      headerName: "Actions",
      headerId: "actions",
    },
  ];

  return (
    <div className="flex bg-zinc-100 h-[100vh]">
      <NavBar active={"Agents"} role={role} />
      <div className="flex w-full h-full">
        <div className={`flex flex-col w-${shortBar ? "full" : "[70vw]"} h-full`}>
          <Header title={"Welcome"} />
          <div className="flex h-[88vh]  w-full pl-3 pr-4">
            <div className="rounded-xl mb-1 w-full bg-white p-4">
              <DynamicTable
                data={allAgentData}
                headers={headers}
                title={"Agents"}
                search={true}
                option1="All"
                buttondropdown={true}
                handleButtonClick={handleButtonClick}
                buttonstyle="py-2"
                label="Add Agent"
                width={"w-42"}
                heightDesktop={"h-[50%]"}
                nthChildWidth={
                  "[&>*:nth-child(1)]:w-48 [&>*:nth-child(2)]:w-36 [&>*:nth-child(3)]:w-36 [&>*:nth-child(4)]:w-40 [&>*:nth-child(5)]:w-32"
                }
                nthChildStyle={""}
                // handleToggleClick={(row, isActive) => {
                //     console.log(isActive, row);
                // }}
                // handleEyeClick={(row) => {
                //     console.log(row);
                // }}
                handleEditClick={(row) => {
                  console.log(row);
                }}
                showDeleteIcon={false}
                handleToggleClick={handleToggleClick}
                handlePencilClick={(row) => handleAgentPencilClick(row)}
                handleEyeClick={(row) => handleAgentPencilClick(row)}
              />
            </div>
          </div>
        </div>
        <ActiveAndInActiveComp
          shortBar={shortBar}
          setShortBar={setShortBar}
          active={activeTab}
          setActiveTab={setActiveTab}
          handleTabChange={handleTabChange}
        />
      </div>

      <AddAgentModal
        closeModal={closeModal}
        setIsOpen={setIsAgent}
        id={currentAgentId}
        isOpen={isAgent}
        getAgentsList={getAgentsList}
      />
    </div>
  );
};

export default Agents;
