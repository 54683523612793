import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import { format } from "date-fns";
import { useEffect, useRef, useState } from "react";

const DateFilter = ({ range, setRange, direction = "vertical" }) => {
  const [active, setActive] = useState(false);
  const containerRef = useRef();

  function handleRange(item) {
    setRange([item.selection]);
    setActive(false);
  }

  useEffect(() => {
    const eventHandler = (e) => {
      if (containerRef?.current && !containerRef?.current?.contains(e.target)) {
        setActive(false);
      }
    };
    document.addEventListener("mouseup", eventHandler);

    return () => document.removeEventListener("mouseup", eventHandler);
  }, []);

  return (
    <div
      className="date-range-container"
      ref={containerRef}
      onClick={() => {
        setActive(true);
      }}
    >
      <div className="date-range-btn" role="button">
        <button>{format(range?.[0]?.startDate, "LLL dd, yyyy")}</button>
        &nbsp;-&nbsp;
        <button>{format(range?.[0]?.endDate, "LLL dd, yyyy")}</button>
      </div>
      {/* <DateRangePicker className="date-range-picker d-none" onChange={(item) => handleRange(item)} showSelectionPreview={true} moveRangeOnFirstSelection={false} months={2} ranges={range} direction="vertical" /> */}

      <DateRangePicker
        className={`date-range-picker endDate ${active ? "" : "!hidden"}`}
        onChange={handleRange}
        showSelectionPreview={true}
        moveRangeOnFirstSelection={false}
        months={2}
        ranges={range}
        date={range}
        direction={direction}
      />
    </div>
  );
};

export default DateFilter;
