import { useEffect, useState } from "react";
import moment from "moment";

import DateFilter from "./DateFilter";
import DropDown from "../Common/InputFields/DropDown";
import OutlineButton from "../Common/Buttons/OutlineButton";
import Button from "../Common/Buttons/Button";
import ToggleButton from "./ToggleButton";
import { formattedDate, getTripStatus } from "../../utils/utilsHelperFunctions";

import { BsSearch } from "react-icons/bs";
import { IoMdArrowBack, IoMdArrowForward } from "react-icons/io";
import { FaLocationArrow } from "react-icons/fa";
import usercircle from "../../assets/images/usercircle.png";
import ambulanceorange from "../../assets/images/ambulanceorange.png";
import attachment from "../../assets/images/attachment.png";
import Eyes from "../../assets/images/Eyes.png";
import fullpencil from "../../assets/images/fullpencil.png";
import Delete from "../../assets/icons/Delete.svg";
import DownArrow from "../../assets/icons/DownArrow.svg";
import Excel from "../../assets/icons/excel.svg";
import { downloadExcel } from "../Common/DownloadExcel";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

const DynamicTable = ({
  title,
  headers,
  data,
  width,
  search,
  heightDesktop,
  heightMobile,
  reverseTable,
  nthChildWidth,
  nthChildColor,
  nthChildStyle,
  handleToggleClick,
  handleEditClick,
  openGivingHandler,
  testimonialTable,
  notificationTable,
  abondontripsactive,
  Dropdown,
  dropDownChangeHandler = () => {},
  button,
  option1,
  label,
  buttondropdown,
  buttonstyle,
  titleSearch,
  image,
  showEyeIcon = true,
  handleEyeClick,
  handleButtonClick = () => {},
  showEditIcon = true,
  handlePencilClick = () => {},
  showDeleteIcon = true,
  handleDeleteClick = () => {},
  showTrackIcon = false,
  handleTrackClick = () => {},
  size = 5,
  noDelete = false,
  handleFromClick,
  handleToClick,
  showDateFilter = false,
  dateFilter = null,
  handleDateFilter = () => {},
}) => {
  const PAGE_SIZE = size;
  // const navigate = useNavigate();
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedImage, setSelectedImage] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [totalRows, setTotalRows] = useState(0);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState("");
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);

  useEffect(() => {
    setTotalRows(data?.length);
  }, [data]);

  const handleSort = (header) => {
    if (!header?.headerSort) return;

    if (sortColumn === header?.headerId) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(header?.headerId);
      setSortDirection("asc");
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handlePageSelect = (event) => {
    setCurrentPage(Number(event.target.value));
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1); // Reset the current page when searching
  };

  const sortedData = sortColumn
    ? [...data]?.sort((a, b) => {
        const valueA = String(a[sortColumn]).toLowerCase();
        const valueB = String(b[sortColumn]).toLowerCase();

        if (sortDirection === "asc") {
          return valueA.localeCompare(valueB);
        } else {
          return valueB.localeCompare(valueA);
        }
      })
    : data;

  console.log("sortedData", sortedData);

  const filteredData = sortedData
    ? sortedData?.filter((row) => {
        console.log("Object.values", Object.values(row));
        return Object.values(row).some((value) => {
          return String(value).toLowerCase().includes(searchTerm.toLowerCase());
        });
      })
    : [];

  const startIndex = (currentPage - 1) * PAGE_SIZE;
  const endIndex = startIndex + PAGE_SIZE;
  const dataOnPage = filteredData?.slice(startIndex, endIndex);
  const pageCount = Math.ceil(filteredData?.length / PAGE_SIZE);

  const pageOptions = Array.from({ length: pageCount }, (_, index) => index + 1);
  return (
    <div className="h-full flex flex-col justify-between">
      <div className="flex-1 flex flex-col h-[80%]">
        <div className="flex justify-between">
          <div className={"flex md:flex-row flex-col gap-4 items-center"}>
            {title && <p className="text-[#333]  font-bold text-lg">{title}</p>}
            {search && (
              <div className="relative">
                <input
                  className="border border-opacity-50  border[#d6d6d6] px-4 py-2 rounded-lg placeholder:text-[14px]"
                  type="text"
                  name=""
                  id=""
                  placeholder={"Search..."}
                  value={searchTerm}
                  onChange={handleSearch}
                />
                <BsSearch className="absolute right-4 top-3 text-md text-[#979797]" />
              </div>
            )}
          </div>

          <div className="flex gap-3 items-center">
            <img
              src={Excel}
              className="w-8 cursor-pointer"
              onClick={() => downloadExcel(filteredData)}
              data-tooltip-id="export-button"
              data-tooltip-content="Export Data"
            />
            <Tooltip id="export-button" place="top" className="z-[1000]" />

            {Dropdown && (
              <div>
                <DropDown
                  optionsList={["All", "Emergency", "Non-Emergency"]}
                  changeHandler={dropDownChangeHandler}
                />
              </div>
            )}
            {showDateFilter && <DateFilter range={dateFilter} setRange={handleDateFilter} />}
          </div>

          {button && (
            <Button
              onClick={handleButtonClick}
              label={label}
              background="bg-orange-500 text-white w-fit px-5 py-1.5"
            />
          )}

          {buttondropdown && (
            <div className="flex items-center gap-2">
              <div>
                {" "}
                <DropDown
                  Option1={option1}
                  Option2={"Vizag"}
                  Option3={"Mumbai"}
                  Option4={"Vijaywada"}
                  Option5={"April"}
                />
              </div>
              <Button
                onClick={handleButtonClick}
                label={label}
                background={`${buttonstyle} bg-orange-500 text-white w-fit px-5 py-1`}
              />
            </div>
          )}
        </div>
        <div className="TABLE flex-1 flex flex-col mt-5 overflow-auto relative w-full remove-scrollbar h-[85%]">
          {/* Header */}
          <thead
            className={` HEADER py pl-3 z-40 bg-slate-100 gap-x-4  ${nthChildWidth} flex items-center text-[12px] font-medium justify-between w-fit min-w-full sticky top-0 rounded-lg`}
          >
            <tr
              //  className="flex justify-around w-full"
              className={`ROW gap-x-2  ${nthChildWidth} ${nthChildColor} ${nthChildStyle} p-3 rounded-xl mt-1  flex items-center  text-sm font-medium py-1.5 justify-around w-fit min-w-full`}
            >
              {headers?.map((header) => {
                return (
                  <th
                    style={{
                      justifyContent: `${
                        header?.headerAlignment === "center"
                          ? "center"
                          : header.headerAlignment === "right"
                          ? "flex-end"
                          : header.headerAlignment === "left"
                          ? "flex-start"
                          : ""
                      }`,
                    }}
                    className={`${width} py-3  font-bold flex gap-2 `}
                    key={header?.headerId}
                  >
                    {header.headerName}{" "}
                    {header.headerName === "Actions" ? "" : <img src={DownArrow} />}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody
            className={`ROWS  ${heightDesktop ? heightDesktop : "min-h-[42%]"} ${
              heightMobile ? heightMobile : ""
            } `}
          >
            {dataOnPage.length > 0 && (
              <>
                {dataOnPage?.map((row, index) => {
                  return (
                    <tr
                      key={index}
                      className={`ROW gap-x-2  ${nthChildWidth} ${nthChildColor} ${nthChildStyle} p-3 border border-black border-opacity-20 rounded-xl mt-1  flex items-center  text-sm font-medium py-1.5 justify-around w-fit min-w-full`}
                    >
                      {headers?.map((header, ind) => {
                        switch (header?.headerId) {
                          case "actions":
                            return (
                              <td
                                style={{
                                  justifyContent: `${
                                    header?.rowAlignment === "center"
                                      ? "center"
                                      : header.rowAlignment === "right"
                                      ? "flex-end"
                                      : header.rowAlignment === "left"
                                      ? "flex-start"
                                      : ""
                                  }`,
                                }}
                                className="flex justify-center items-center"
                                key={ind}
                              >
                                {!abondontripsactive ? (
                                  <div className="flex gap-2  items-center">
                                    {row[header?.headerId] === "Scheduled"
                                      ? showEyeIcon &&
                                        handleEyeClick && (
                                          <img
                                            className="cursor-pointer "
                                            src={attachment}
                                            alt="eye"
                                            onClick={() => {
                                              handleEyeClick(row, index);
                                            }}
                                          />
                                        )
                                      : (showEditIcon ||
                                          row?.status === "1" ||
                                          row?.status === "0") && (
                                          <img
                                            className="cursor-pointer"
                                            src={fullpencil}
                                            alt="eye"
                                            onClick={() => {
                                              handlePencilClick(row, index);
                                            }}
                                          />
                                        )}
                                    {showEyeIcon && row?.status !== "1" && handleEyeClick && (
                                      <img
                                        className="cursor-pointer"
                                        src={Eyes}
                                        alt="eye"
                                        onClick={() => {
                                          handleEyeClick(row, index);
                                        }}
                                      />
                                    )}
                                    {showTrackIcon &&
                                      !(row?.status === "1" || row?.status === "0") && (
                                        <FaLocationArrow
                                          className="cursor-pointer"
                                          color="#5feb34"
                                          onClick={() => handleTrackClick(row)}
                                        />
                                      )}

                                    {!noDelete && showDeleteIcon && (
                                      <img
                                        className="cursor-pointer"
                                        src={Delete}
                                        alt="eye"
                                        onClick={() => {
                                          handleDeleteClick(row);
                                        }}
                                      />
                                    )}
                                  </div>
                                ) : (
                                  <OutlineButton label="Book Ambulance" />
                                )}
                              </td>
                            );

                          case "pincodes":
                            return (
                              <td
                                style={{
                                  justifyContent: `${
                                    header?.rowAlignment === "center"
                                      ? "center"
                                      : header.rowAlignment === "right"
                                      ? "flex-end"
                                      : header.rowAlignment === "left"
                                      ? "flex-start"
                                      : ""
                                  }`,
                                }}
                                className="flex flex-wrap"
                              >
                                {row[header?.headerId]?.map((item, index) => (
                                  <p className="flex-auto" key={index}>
                                    {item?.pincode}
                                    {row[header?.headerId]?.length > index + 1 ? ", " : ""}
                                  </p>
                                ))}
                              </td>
                            );

                          case "call":
                            return (
                              <td
                                style={{
                                  justifyContent: `${
                                    header?.rowAlignment === "center"
                                      ? "center"
                                      : header.rowAlignment === "right"
                                      ? "flex-end"
                                      : header.rowAlignment === "left"
                                      ? "flex-start"
                                      : ""
                                  }`,
                                }}
                                className="flex gap-2   items-center  "
                              >
                                {row[header?.headerId] === "In-Progress" ? (
                                  <div className="flex items-center gap-2">
                                    <img
                                      className="cursor-pointer w-6"
                                      src={ambulanceorange}
                                      alt="eye"
                                      // onClick={() => {
                                      //     handleEyeClick(row);
                                      // }}
                                    />
                                    <img
                                      className="cursor-pointer w-6"
                                      src={usercircle}
                                      alt="eye"
                                      // onClick={() => {
                                      //     handleEyeClick(row);
                                      // }}
                                    />{" "}
                                  </div>
                                ) : row[header?.headerId] === "Scheduled" ? (
                                  <div>
                                    {" "}
                                    <img
                                      className="cursor-pointer w-6"
                                      src={usercircle}
                                      alt="eye"
                                      // onClick={() => {
                                      //     handleEyeClick(row);
                                      // }}
                                    />{" "}
                                  </div>
                                ) : (
                                  <div>
                                    <p>--</p>
                                  </div>
                                )}
                              </td>
                            );

                          case "typeoftrip":
                            return (
                              <td
                                style={{
                                  justifyContent: `${
                                    header?.rowAlignment === "center"
                                      ? "center"
                                      : header.rowAlignment === "right"
                                      ? "flex-end"
                                      : header.rowAlignment === "left"
                                      ? "flex-start"
                                      : ""
                                  }`,
                                }}
                                className={`${width} p-2 whitespace-break-spaces  text-[#2B2B2B]  flex gap-2 items-center`}
                                key={header?.headerId}
                              >
                                {row[header?.headerId]}
                              </td>
                            );

                          case "tripId":
                            return (
                              <td
                                style={{
                                  justifyContent: `${
                                    header?.rowAlignment === "center"
                                      ? "center"
                                      : header.rowAlignment === "right"
                                      ? "flex-end"
                                      : header.rowAlignment === "left"
                                      ? "flex-start"
                                      : ""
                                  }`,
                                }}
                                className={`${width} p-2 whitespace-break-spaces  text-[#2B2B2B]  flex gap-2 items-center w-[10%]`}
                                key={header?.headerId}
                              >
                                {row[header?.headerId]}
                              </td>
                            );

                          case "callerName":
                            return (
                              <td
                                style={{
                                  justifyContent: `${
                                    header?.rowAlignment === "center"
                                      ? "center"
                                      : header.rowAlignment === "right"
                                      ? "flex-end"
                                      : header.rowAlignment === "left"
                                      ? "flex-start"
                                      : ""
                                  }`,
                                }}
                                className={`${width} p-2 whitespace-break-spaces  text-[#2B2B2B]  flex gap-2 items-center `}
                                key={header?.headerId}
                              >
                                {row[header?.headerId]}
                              </td>
                            );

                          case "addon":
                            return (
                              <td
                                style={{
                                  justifyContent: `${
                                    header?.rowAlignment === "center"
                                      ? "center"
                                      : header.rowAlignment === "right"
                                      ? "flex-end"
                                      : header.rowAlignment === "left"
                                      ? "flex-start"
                                      : ""
                                  }`,
                                }}
                                className={`${width} p-1 whitespace-break-spaces  text-[#2B2B2B]  flex gap-2 items-center`}
                                key={header?.headerId}
                              >
                                <img className="w-8" src={row?.iconUrl} />
                                {row[header?.headerId]}
                              </td>
                            );

                          case "vehicleDetails":
                            return (
                              <td
                                style={{
                                  justifyContent: `${
                                    header?.rowAlignment === "center"
                                      ? "center"
                                      : header.rowAlignment === "right"
                                      ? "flex-end"
                                      : header.rowAlignment === "left"
                                      ? "flex-start"
                                      : ""
                                  }`,
                                }}
                                className={`${width} p-2 flex text-[#2B2B2B]  whitespace-break-spaces`}
                                key={header?.headerId}
                              >
                                {row[header?.headerId] &&
                                  row[header?.headerId][0][header?.subHeaderId]}
                              </td>
                            );

                          case "subHeaderId":
                            return (
                              <td
                                style={{
                                  justifyContent: `${
                                    header?.rowAlignment === "center"
                                      ? "center"
                                      : header.rowAlignment === "right"
                                      ? "flex-end"
                                      : header.rowAlignment === "left"
                                      ? "flex-start"
                                      : ""
                                  }`,
                                }}
                                className={`${width} p-2 flex text-[#2B2B2B]  whitespace-break-spaces`}
                                key={header?.headerId}
                              >
                                {header?.subHeaderId?.length > 0 &&
                                  header?.subHeaderId?.map((subrow, index2) => (
                                    <span key={index2}>{row[subrow]} </span>
                                  ))}
                              </td>
                            );

                          case "regionObjId":
                            return (
                              <td
                                style={{
                                  justifyContent: `${
                                    header?.rowAlignment === "center"
                                      ? "center"
                                      : header.rowAlignment === "right"
                                      ? "flex-end"
                                      : header.rowAlignment === "left"
                                      ? "flex-start"
                                      : ""
                                  }`,
                                }}
                                className={`${width} p-2 flex text-[#2B2B2B]  whitespace-break-spaces`}
                                key={header?.headerId}
                              >
                                {row[header?.headerId]?.length > 0 &&
                                  row[header?.headerId][0]?.region_name}
                              </td>
                            );

                          case "createdDatess":
                            return (
                              <td
                                style={{
                                  justifyContent: `${
                                    header?.rowAlignment === "center"
                                      ? "center"
                                      : header.rowAlignment === "right"
                                      ? "flex-end"
                                      : header.rowAlignment === "left"
                                      ? "flex-start"
                                      : ""
                                  }`,
                                }}
                                className={`${width} p-2 flex text-[#2B2B2B] text-ellipsis whitespace-break-spaces`}
                                key={header?.headerId}
                              >
                                {/* <img className="h-8" src={row["image"]} /> */}
                                {row[header?.headerId]}
                              </td>
                            );

                          case "dateandTimeofTrip":
                            return (
                              <td
                                style={{
                                  justifyContent: `${
                                    header?.rowAlignment === "center"
                                      ? "center"
                                      : header.rowAlignment === "right"
                                      ? "flex-end"
                                      : header.rowAlignment === "left"
                                      ? "flex-start"
                                      : ""
                                  }`,
                                }}
                                className={`${width} p-2 flex text-[#2B2B2B] text-ellipsis whitespace-break-spaces`}
                                key={header?.headerId}
                              >
                                {/* <img className="h-8" src={row["image"]} /> */}
                                {row[header?.headerId]
                                  ? moment(row[header?.headerId])?.format("DD/MM/YYYY")
                                  : ""}
                              </td>
                            );

                          case "timeOfBookingRequest":
                            return (
                              <td
                                style={{
                                  justifyContent: `${
                                    header?.rowAlignment === "center"
                                      ? "center"
                                      : header.rowAlignment === "right"
                                      ? "flex-end"
                                      : header.rowAlignment === "left"
                                      ? "flex-start"
                                      : ""
                                  }`,
                                }}
                                className={`${width} p-2 flex text-[#2B2B2B] text-ellipsis whitespace-break-spaces`}
                                key={header?.headerId}
                              >
                                {/* <img className="h-8" src={row["image"]} /> */}
                                {row[header?.headerId]
                                  ? moment(row[header?.headerId])?.format("DD/MM/YYYY")
                                  : ""}
                              </td>
                            );

                          case "discount_type":
                            return (
                              <td
                                style={{
                                  justifyContent: `${
                                    header?.rowAlignment === "center"
                                      ? "center"
                                      : header.rowAlignment === "right"
                                      ? "flex-end"
                                      : header.rowAlignment === "left"
                                      ? "flex-start"
                                      : ""
                                  }`,
                                }}
                                className={`${width} p-2 flex text-[#2B2B2B] text-ellipsis whitespace-break-spaces`}
                                key={header?.headerId}
                              >
                                {row[header?.headerId] === "0" ? "Percentage" : "Fixed Value"}
                              </td>
                            );

                          case "status":
                            const statusResult = getTripStatus(row[header?.headerId]);
                            return (
                              <td
                                style={{
                                  justifyContent: `${
                                    header?.rowAlignment === "center"
                                      ? "center"
                                      : header.rowAlignment === "right"
                                      ? "flex-end"
                                      : header.rowAlignment === "left"
                                      ? "flex-start"
                                      : ""
                                  }`,
                                }}
                                className={`${width} p-2 whitespace-break-spaces font-medium flex gap-2 items-center`}
                                key={header?.headerId}
                              >
                                {/* <img src={row["image"]} /> */}
                                <button
                                  className="border-none px-4 py-1.5 rounded-md text-xs"
                                  style={{
                                    color: `rgb(${statusResult?.bgColor})`,
                                    background: `rgba(${statusResult?.bgColor}, 0.2)`,
                                  }}
                                >
                                  {statusResult?.text}
                                </button>
                              </td>
                            );

                          case "amount":
                            return (
                              <td
                                style={{
                                  justifyContent: `${
                                    header?.rowAlignment === "center"
                                      ? "center"
                                      : header.rowAlignment === "right"
                                      ? "flex-end"
                                      : header.rowAlignment === "left"
                                      ? "flex-start"
                                      : ""
                                  }`,
                                }}
                                className={`${width} p-2 whitespace-break-spaces  font-medium text-[#F04743]                                                            
                                                                  flex gap-2 items-center`}
                                key={header?.headerId}
                              >
                                {/* <img src={row["image"]} /> */}
                                {row[header?.headerId]}
                              </td>
                            );

                          case "delete":
                            return (
                              <td
                                style={{
                                  justifyContent: `${
                                    header?.rowAlignment === "center"
                                      ? "center"
                                      : header.rowAlignment === "right"
                                      ? "flex-end"
                                      : header.rowAlignment === "left"
                                      ? "flex-start"
                                      : ""
                                  }`,
                                }}
                                className="flex px-8"
                              >
                                {showDeleteIcon && (
                                  <img
                                    className="cursor-pointer"
                                    src={Delete}
                                    alt="eye"
                                    onClick={() => {
                                      handleDeleteClick(row);
                                    }}
                                  />
                                )}
                              </td>
                            );

                          case "switch":
                            return (
                              <td
                                style={{
                                  justifyContent: `${
                                    header?.rowAlignment === "center"
                                      ? "center"
                                      : header.rowAlignment === "right"
                                      ? "flex-end"
                                      : header.rowAlignment === "left"
                                      ? "flex-start"
                                      : ""
                                  }`,
                                }}
                                className="flex px-4"
                              >
                                <ToggleButton
                                  handleToggleClick={(isActive) => handleToggleClick(row, isActive)}
                                  status={row?.status || row?.active ? 1 : 0}
                                />
                              </td>
                            );

                          case "from":
                            return (
                              <td
                                style={{
                                  justifyContent: `${
                                    header?.rowAlignment === "center"
                                      ? "center"
                                      : header.rowAlignment === "right"
                                      ? "flex-end"
                                      : header.rowAlignment === "left"
                                      ? "flex-start"
                                      : ""
                                  }`,
                                }}
                                className="flex px-4"
                              >
                                <button
                                  className="text-blue-500 hover:underline"
                                  onClick={() =>
                                    handleFromClick(row?.fromLatitude, row?.fromLongitude)
                                  }
                                >
                                  From
                                </button>
                              </td>
                            );

                          case "to":
                            return (
                              <td
                                style={{
                                  justifyContent: `${
                                    header?.rowAlignment === "center"
                                      ? "center"
                                      : header.rowAlignment === "right"
                                      ? "flex-end"
                                      : header.rowAlignment === "left"
                                      ? "flex-start"
                                      : ""
                                  }`,
                                }}
                                className="flex px-4"
                              >
                                <button
                                  className="text-blue-500 hover:underline"
                                  onClick={() => handleFromClick(row?.toLatitude, row?.toLongitude)}
                                >
                                  to
                                </button>
                              </td>
                            );

                          case "activationDate":
                            return (
                              <td
                                style={{
                                  justifyContent: `${
                                    header?.rowAlignment === "center"
                                      ? "center"
                                      : header.rowAlignment === "right"
                                      ? "flex-end"
                                      : header.rowAlignment === "left"
                                      ? "flex-start"
                                      : ""
                                  }`,
                                }}
                                className="flex px-4"
                              >
                                {formattedDate(row[header?.headerId])}
                              </td>
                            );

                          default:
                            return (
                              <td
                                style={{
                                  justifyContent: `${
                                    header?.rowAlignment === "center"
                                      ? "center"
                                      : header.rowAlignment === "right"
                                      ? "flex-end"
                                      : header.rowAlignment === "left"
                                      ? "flex-start"
                                      : ""
                                  }`,
                                }}
                                className={`${width} p-2 flex flex-wrap text-[#2B2B2B] break-all`}
                                key={header?.headerId}
                              >
                                <p className="flex-auto">{row[header?.headerId]}</p>
                              </td>
                            );
                        }
                      })}
                    </tr>
                  );
                })}
              </>
            )}

            {data?.length === 0 && (
              <div className="flex justify-center items-center h-full w-full">
                <p className="text-sm text-greyish ">No Data Found</p>
              </div>
            )}
            {/* ends here  */}
          </tbody>
        </div>
      </div>
      <div className="PAGINATION w-full border rounded-lg md:p-4 p-2">
        <div className="flex items-center justify-between">
          <div className="text-[13px] leading-4 text-[#333] mr-4 ">
            Showing {currentPage}-{pageCount}
          </div>
          <div className="text-[13px] leading-4 text-[#333] flex items-center">
            <span className="mr-4">Total Rows: {totalRows}</span>
            <div className=" h-8 w-[1px] bg-[#d6d6d6] "></div>
            <span className="ml-4">The page you’re on</span>
            <span className="p-2 bg-white border border-[#d6d6d6] rounded-lg mx-2">
              <select value={currentPage} onChange={handlePageSelect}>
                {pageOptions.map((page) => (
                  <option key={page} value={page}>
                    {page}
                  </option>
                ))}
              </select>
            </span>
            <div className=" h-8 w-[1px] bg-[#d6d6d6] "></div>
            <button
              className="bg-white p-2 rounded-lg border border-[#d6d6d6] mx-2"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <IoMdArrowBack className="text-[15px] " />
            </button>
            <button
              className="bg-white p-2 rounded-lg border border-[#d6d6d6]"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === pageCount}
            >
              <IoMdArrowForward className="text-[15px] " />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DynamicTable;
