import React, { useEffect, useState } from "react";
import { libraries } from "../../constants/Constant";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import Button from "../Common/Buttons/Button";
import OutlineButton from "../Common/Buttons/OutlineButton";
import Toastify from "./Toastify";

const mapContainerStyle = {
  height: "100%",
  width: "100%",
};
const center = { lat: 28.6139391, lng: 77.20902120000001 }; // Default center of India
// const center = { lat: 13.0743539, lng: 80.2565183 }; // Default center of chennai

function MapComponent(props) {
  const [map, setMap] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [pincodeText, setPincodeText] = useState("");
  const [currentLatLongList, setCurrentLatLongList] = useState([]);
  // const [searchLatLong, setSearchLatLong] = useState({ lat: "", lng: "", placeIndex: null, coordsIndex: null, matchFound: false });

  useEffect(() => {
    async function loadEditData() {
      if (props?.id && props?.pincodeList?.length > 0 && map) {
        const pinString = props?.pincodeList?.toString();
        if (!pincodeText) {
          loadPincodes();
        }
        if (pinString !== pincodeText) setPincodeText(pinString);
        // const getPincodes = currentLatLongList?.map(v => v.pincode !== props?.handleDeletePincode);
        // const getPincodes =currentLatLongList?.map(v => v.pincode)?.filter(v => v !== props?.handleDeletePincode)
        // setMap(null);
        // const getPlaceID = currentLatLongList?.find(v => v.pincode === props?.handleDeletePincode)?.placeID
        // const newMarker =markers.filter(v => v.placeId !== getPlaceID)
        // const newLatLongList = currentLatLongList?.filter(v => v.pincode !== props?.handleDeletePincode);
        // await styleBoundaries(newMarker);
        // setMarkers(newMarker)
        // setCurrentLatLongList(newLatLongList)
      }
    }
    setTimeout(() => {
      loadEditData();
    }, 500);
  }, [map, props?.pincodeList]);

  const loadPincodes = async () => {
    const pincodeString = props?.pincodeList?.toString();
    if (pincodeString) {
      const searchBox = document.getElementById("search-box-pincode");
      if (searchBox) searchBox.value = pincodeString;
      onPincodeSubmitted(pincodeString);
    }
  };

  const onMapLoad = async (mapData) => {
    setMap(mapData);
  };

  useEffect(() => {
    async function deletePincode() {
      if (props?.handleDeletePincode) {
        //delete particular pincod
        // const getPincodes = currentLatLongList?.map(v => v.pincode !== props?.handleDeletePincode);
        const getPincodes = currentLatLongList
          ?.map((v) => v.pincode)
          ?.filter((v) => v !== props?.handleDeletePincode);
        // setMap(null);
        const pincodeString = getPincodes?.toString();
        if (pincodeString)
          document.getElementById("search-box-pincode").value = pincodeString;
        onPincodeSubmitted(pincodeString);
      }
    }
    deletePincode();
  }, [props?.handleDeletePincode]);

  const onPincodeSubmitted = async (input) => {
    try {
      if (!input || !map) return false;
      let postalCodes = input.split(",").map((code) => code.trim());
      postalCodes = [...new Set(postalCodes)];
      const searchBox = document.getElementById("search-box-pincode");
      if (searchBox) searchBox.value = postalCodes?.toString();
      const newMarkers = [];
      let tempCoordsList = [];
      setCurrentLatLongList([]);
      for (const postalCode of postalCodes) {
        const places = await searchPlacesByPostalCode(postalCode);
        if (places.length > 0) {
          const place = places[0];
          const geometry = place.geometry;
          const position = geometry.location;
          const placeId = place.place_id;
          const placeName = place.name;
          newMarkers.push({ position, placeId, placeName, geometry });

          const placeViewPort = place.geometry.viewport;
          let ne = placeViewPort.getNorthEast(); // Coords of the northeast corner
          let sw = placeViewPort.getSouthWest(); // Coords of the southwest corner
          let nw = new window.google.maps.LatLng(ne.lat(), sw.lng()); // Coords of the NW corner
          let se = new window.google.maps.LatLng(sw.lat(), ne.lng()); // Coords of the SE corner
          const coordinates = [
            { ne: { lat: ne.lat(), lng: ne.lng() } },
            { nw: { lat: nw.lat(), lng: nw.lng() } },
            { sw: { lat: sw.lat(), lng: sw.lng() } },
            { se: { lat: se.lat(), lng: se.lng() } },
          ];
          tempCoordsList.push({
            placeID: placeId,
            pincode: postalCode,
            placeName,
            coords: coordinates,
            center: position.toJSON(),
          });
        }
      }
      setCurrentLatLongList(tempCoordsList);
      if (newMarkers.length > 0) {
        const bounds = new window.google.maps.LatLngBounds();
        newMarkers.forEach(({ position }) => bounds.extend(position));
        map.fitBounds(newMarkers[0]?.geometry?.viewport, 170);

        const isDataSTylingEnabled =
          map?.getMapCapabilities()?.isDataDrivenStylingAvailable;
        if (isDataSTylingEnabled) await styleBoundaries(newMarkers);

        if (props?.handlePincodes) props.handlePincodes(tempCoordsList);
        setMarkers(newMarkers);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const styleBoundaries = async (markers) => {
    const boundaryPromises = markers.map(({ placeId }) =>
      styleBoundary(placeId, markers)
    );
    await Promise.all(boundaryPromises);
  };

  const handleSubmit = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (!props?.region) {
        Toastify("Please Enter Region Name To Proceed...!", 2);
        return false;
      }
      const value = e.target.value;
      setPincodeText(value);
      onPincodeSubmitted(value);
    }
  };

  const searchPlacesByPostalCode = (postalCode) => {
    const request = {
      query: postalCode,
      // fields: ['ALL'],
      fields: ["name", "place_id", "geometry"],
    };

    return new Promise((resolve) => {
      const service = new window.google.maps.places.PlacesService(map);
      service.findPlaceFromQuery(request, (results, status) => {
        if (
          window.google.maps &&
          status === window.google.maps.places.PlacesServiceStatus.OK
        ) {
          // console.log(results, "results on search");
          resolve(results);
        } else {
          if (
            window.google.maps &&
            (status ===
              window.google.maps.places.PlacesServiceStatus.NOT_FOUND ||
              status ===
                window.google.maps.places.PlacesServiceStatus.ZERO_RESULTS)
          ) {
            Toastify(`No Results Found! for ${postalCode}`);
          }
          resolve([]);
        }
      });
    });
  };

  const styleBoundary = async (placeId, allMarkers) => {
    try {
      const featureLayer = map.getFeatureLayer("POSTAL_CODE");
      if (featureLayer) featureLayer.style = null;
      const styleFill = {
        strokeColor: "#FF0000",
        strokeOpacity: 1.0,
        strokeWeight: 3.0,
        fillOpacity: 0,
      };
      featureLayer.style = (params) => {
        const matchingMarker = allMarkers.find(
          (marker) => marker.placeId === params.feature.placeId
        );
        if (matchingMarker) {
          return styleFill;
        }
      };
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <LoadScript
        googleMapsApiKey={process.env.REACT_APP_MAP_API_KEY}
        libraries={libraries}
        mapIds={[process.env.REACT_APP_MAP_ID]}
      >
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          center={center}
          zoom={4}
          onLoad={onMapLoad}
          // onLoad={map => setMap(map)}
          // onUnmount={() => setMap(null)}
          options={{ mapId: process.env.REACT_APP_MAP_ID }}
        >
          <input
            id="search-box-pincode"
            type="text"
            placeholder="Type Pincode & Hit Enter"
            onKeyDown={handleSubmit}
            style={{
              boxSizing: `border-box`,
              border: `1px solid transparent`,
              width: `50%`,
              height: `32px`,
              padding: `0 12px`,
              borderRadius: `3px`,
              boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
              fontSize: `14px`,
              outline: `none`,
              textOverflow: `ellipses`,
              position: "absolute",
              textAlign: "center",
              left: "30%",
            }}
          />
          {markers.map((marker, index) => (
            <Marker key={index} position={marker.position} />
          ))}
        </GoogleMap>
      </LoadScript>

      {/* {currentLatLongList && currentLatLongList?.map((place, index) => (
        <div key={index} className='flex flex-col items-left text-center m-4 gap-6 py-10'>
          <div className='flex items-center justify-between'>
          <div className='flex items-baseline gap-[20px]'>
            <span className='text-2xl font-bold'>{place.placeName}: </span>{place.placeID}
          </div>
            {index === 0 && <div className='flex flex-wrap items-end gap-5'>
            <div className='grid text-left gap-2'>
              <label htmlFor="lat" className='ml-2 relative text-md gap-2 font-bold'>Enter Latitude</label>
              <input
                id="lat"
                name="lat"
                className="border border-opacity-50  border[#d6d6d6] px-4 py-2 rounded-lg placeholder:text-[14px]"
                placeholder='Enter Latitude'
                type="number"
                value={searchLatLong.lat}
                onChange={handleChangeSearch}
              />
            </div>
            <div  className='grid text-left gap-2'>
              <label htmlFor="lng" className='ml-2 relative text-md gap-2 font-bold'>Enter Longtitude</label>
               <input
                id="lng"
                name="lng"
                className="border border-opacity-50  border[#d6d6d6] px-4 py-2 rounded-lg placeholder:text-[14px]"
                placeholder='Enter Longtitude'
                type="number"
                value={searchLatLong.lng}
                onChange={handleChangeSearch}
              />
            </div>
            {searchLatLong.matchFound ?
            <OutlineButton onClick={handleCancelSearchViewPort} label='X' /> :
            <Button onClick={handleSearchViewPort} label={"Verify if location is in Viewport"} background={"p-0 bg-orange-500 text-white w-fit"} /> }

            </div> }
            </div>
          <div className='flex items-baseline gap-[20px]'>
            <span className='text-2xl font-bold'>Center Lat: </span>{place.center.lat}
            <span className='text-2xl font-bold'>Center Long: </span>{place.center.lng}
          </div>

            <table className="table-auto border-collapse w-1/2 text-center">
              <thead>
                <tr>
                  <th className="border border-slate-400"><div className='text-2xl font-bold'>Latitude</div></th>
                  <th className="border border-slate-400"><div className='text-2xl font-bold'>Longtitude</div></th>
                </tr>
              </thead>
              <tbody>
              {place.coords && place.coords?.map((coords, index2) => (
                <tr key={index2}>
                  <td className={`border border-slate-300 p-4 ${(searchLatLong.placeIndex === index && searchLatLong.coordsIndex === index2 ) ? 'bg-green-300 font-bold' : ''}`}>{coords.lat}</td>
                  <td className={`border border-slate-300 p-4 ${(searchLatLong.placeIndex === index && searchLatLong.coordsIndex === index2 ) ? 'bg-green-300 font-bold' : ''}`}>{coords.lng}</td>
                </tr>
              ))}

              </tbody>
            </table>
        </div> 
      ))}*/}
    </>
  );
}

export default MapComponent;
