import { useCallback, useEffect, useState } from "react";
import { libraries } from "../../constants/Constant";
import Ambulance from "../../assets/images/ambulanceMarker.png";
import { GoogleMap, DirectionsRenderer, LoadScript, Marker } from "@react-google-maps/api";
import Omni from "../../assets/images/Omni.png";

const UserMapComponent = ({ containerStyle, availableDriversDetail, currentTrip }) => {
  const [center, setCenter] = useState({});
  const [directions, setDirections] = useState(null);
  const [currentDriver, setCurrentDriver] = useState({});
  const [placeNames, setPlaceNames] = useState({ from: "", to: "" });
  const [map, setMap] = useState(null);
  const [pickupDistance, setPickupDistance] = useState("0 KM");

  const markers = [
    {
      lat: parseFloat(currentTrip?.fromLatitude),
      lng: parseFloat(currentTrip?.fromLongitude),
    },
    {
      lat: parseFloat(currentTrip?.toLatitude),
      lng: parseFloat(currentTrip?.toLongitude),
    },
  ];

  useEffect(() => {
    if (availableDriversDetail?.length > 0 && currentTrip?._id) {
      console.log("currentTrip?.drivers?.[0]?.userAuthId", currentTrip?.drivers?.[0]?.userAuthId);
      const found = availableDriversDetail?.find((d) => {
        return d?.user?.userAuthId === currentTrip?.drivers?.[0]?.userAuthId;
        // return d?.userAuthId === "google-oauth2|116759178528672705534";
      });
      if (found) {
        setCurrentDriver(found);
      }
    }
  }, [availableDriversDetail, currentTrip]);

  const getPickupDistance = async () => {
    let pickupDistancpickupDistancee = "0 KM";
    console.log("", pickupDistance);
    return new Promise((resolve, reject) => {
      console.log("window.google.maps", window?.google?.maps);
      if (window?.google && window?.google?.maps && map && currentTrip) {
        const origins = [
          {
            lat: parseFloat(currentTrip?.fromLatitude),
            lng: parseFloat(currentTrip?.fromLongitude),
          },
        ];
        const destinations = [
          {
            lat: currentDriver?.coords?.latitude,
            lng: currentDriver?.coords?.longitude,
          },
        ];

        const directionsService = new window.google.maps.DistanceMatrixService();
        directionsService.getDistanceMatrix(
          {
            origins,
            destinations,
            travelMode: window.google.maps ? window.google.maps.TravelMode.DRIVING : "DRIVING",
          },
          (result, status) => {
            if (window.google.maps && status === window.google.maps.DirectionsStatus.OK) {
              const distanceText = result.rows[0].elements[0]?.distance?.text;
              console.log("distanceText", distanceText);
              resolve(distanceText);
            } else {
              console.error(`Distance service failed due to ${status}`);
              resolve(pickupDistance);
            }
          }
        );
      }
    });
  };

  useEffect(() => {
    if (window.google && window.google.maps && map && currentTrip) {
      console.log("Whatttttt");
      const bounds = new window.google.maps.LatLngBounds();
      markers.forEach((marker) => bounds.extend(marker));
      map.fitBounds(bounds);

      const directionsService = new window.google.maps.DirectionsService();
      directionsService.route(
        {
          origin: markers[0],
          destination: markers[1],
          travelMode: window.google.maps ? window.google.maps.TravelMode.DRIVING : "DRIVING",
        },
        (result, status) => {
          if (window.google.maps && status === window.google.maps.DirectionsStatus.OK) {
            setDirections(result);
          } else {
            console.error(`Directions service failed due to ${status}`);
          }
        }
      );

      const placesService = new window.google.maps.places.PlacesService(map);

      const getPlaceDetails = (placeId) => {
        return new Promise((resolve, reject) => {
          placesService.getDetails(
            {
              placeId,
            },
            (place, status) => {
              if (
                window.google.maps &&
                status === window.google.maps.places.PlacesServiceStatus.OK &&
                place
              ) {
                resolve(place?.formatted_address);
              } else {
                reject(status);
              }
            }
          );
        });
      };

      async function getFromToPlaces() {
        if (currentTrip?.fromPlaceId || currentTrip?.toPlaceId) {
          const getFromPlaceName = await getPlaceDetails(currentTrip?.fromPlaceId);
          const getToPlaceName = await getPlaceDetails(currentTrip?.toPlaceId);
          setPlaceNames({
            from: getFromPlaceName || `${currentTrip?.fromLatitude} ${currentTrip?.fromLongitude}`,
            to: getToPlaceName || `${currentTrip?.toLatitude} ${currentTrip?.toLongitude}`,
          });
        }
      }

      getFromToPlaces();

      async function getDriverToPickup() {
        if (currentTrip?.driverDetails) {
          const distance = await getPickupDistance();
          setPickupDistance(distance);
        }
      }

      getDriverToPickup();
    }
  }, [map, currentTrip]);

  const onLoad = useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback() {
    setMap(map);
  }, []);

  return (
    <LoadScript
      googleMapsApiKey={process.env.REACT_APP_MAP_API_KEY}
      libraries={libraries}
      mapIds={[process.env.REACT_APP_MAP_ID]}
    >
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={10}
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={{
          panControl: false,
          mapTypeControl: false,
          scrollwheel: false,
          streetViewControl: false,
          draggableCursor: false,
          isFractionalZoomEnabled: false,
          disableDefaultUI: true,
          disableDoubleClickZoom: true,
          zoomControl: false,
        }}
      >
        {/* {currentTrip?.driverCurrentPosition ?
              <Marker
                position={{ lat: parseFloat(currentTrip?.driverCurrentPosition?.latitude), lng: parseFloat(currentTrip?.driverCurrentPosition?.longitude) }}
                icon={{ url: Omni }}
                // label={{ text: "Current location", color: "#000" }}
              /> : */}
        {currentDriver?.coords?.latitude ? (
          <Marker
            position={{
              lat: parseFloat(currentDriver?.coords?.latitude),
              lng: parseFloat(currentDriver?.coords?.longitude),
            }}
            icon={{ url: Omni }}
            // label={{ text: "Current location", color: "#000" }}
          />
        ) : currentTrip?.acceptedLatitude ? (
          <Marker
            position={{
              lat: currentTrip?.acceptedLatitude,
              lng: currentTrip?.acceptedLogitude,
            }}
            icon={{ url: Omni }}
          />
        ) : (
          ""
        )}
        {directions && (
          <DirectionsRenderer
            directions={directions}
            options={{
              polylineOptions: {
                strokeColor: "#FF0000",
                strokeOpacity: 0.8,
                strokeWeight: 4,
              },
            }}
          />
        )}
      </GoogleMap>
    </LoadScript>
  );
};

export default UserMapComponent;
