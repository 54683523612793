import React from "react";
// import CardSamplePic from "../../assets/images/CardSamplePic.png"
import ProfileCircle from "../../assets/icons/profilecircle.svg";
import HoverPin from "../../assets/icons/HoverPin.svg";
import Pin from "../../assets/icons/Pin.svg";
import { dateDiff } from "../../utils/utilsHelperFunctions";
import ProfileLogo from "../../assets/icons/profileLogo.svg"


const CardOfViewAll = ({ id, handleChange, selected, data = [] }) => {
  return (
    <div
      onClick={() => handleChange(data._id)}
      className={
        id === selected
          ? "cursor-pointer p-4 flex rounded-lg gap-2 bg-[#2D4493]"
          : "group cursor-pointer p-4 flex bg-slate-100 rounded-lg gap-2 hover:bg-blue-50"
      }
    >
      <div className="flex flex-col gap-4 items-center">
        
        <img className="h-12 w-15 rounded-full" src={data?.profilePicUrl ? data?.profilePicUrl : ProfileLogo} />
        <img src={HoverPin} />
      </div>
      <div className="flex flex-col gap-2">
        <div className="text-[14px]">
          <div className="flex justify-between">
            <p
              className={
                id === selected
                  ? "text-white text-sm font-bold"
                  : " text-sm text-[#2D4493]"
              }
            >
              {data?.firstName ? data?.firstName : ""}
            </p>
            <p
              className={
                id === selected
                  ? "text-white font-medium text-xs text-opacity-40"
                  : " font-medium text-xs text-black text-opacity-40"
              }
            >
              {dateDiff(data?.activationDate)} days ago
            </p>
          </div>
          <p
            className={
              id === selected ? "font-medium text-white" : "font-medium"
            }
          >
            {data?.vehicleDetails && data?.vehicleDetails?.length > 0
              ? `${data?.vehicleDetails[0]?.vehicleMake} ${data?.vehicleDetails[0]?.vehicleType}`
              : "No Vehicle Data Found"}
          </p>
        </div>
        <p
          className={
            id === selected
              ? "text-[13px] text-white text-opacity-50"
              : "text-[13px]  text-black text-opacity-50"
          }
        >
          {data?.email}
        </p>
      </div>
    </div>
  );
};

export default CardOfViewAll;
