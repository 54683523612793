import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
// import { useSelector } from 'react-redux';
import { useAuth0 } from "@auth0/auth0-react";
import Home from "./Screens/User/Home/Home";
import Home1 from "./Screens/User/Home/Home1";
import Home2 from "./Screens/User/Home/Home2";
import Home3 from "./Screens/User/Home/Home3";
import Home4 from "./Screens/User/Home/Home4";
import Trips from "./Screens/User/Trips/Trips";
import AbondonTrips from "./Screens/User/AbondonTrips/AbondonTrips";
import AnalyticsPage from "./Screens/User/Analytics/AnalyticsPage";
import Drivers from "./Screens/Admin/Drivers";
import RegisteredUsers from "./Screens/Admin/RegisteredUsers";
import Agents from "./Screens/Admin/Agents";
import Services from "./Screens/Admin/Services";
import ViewAllCards from "./Screens/Admin/ViewAllCards";
import LoginPage from "./Screens/Login/LoginPage";
import Unauthorized from "./assets/icons/Unauthorized.png";
import Loader from "./components/Universal/Loader";
import { useSocket } from "./components/Common/SocketContext";
import { useDispatch, useSelector } from "react-redux";
import { updateTokenAction, updateUserDetailsAction } from "./store/UserProfile/UserProfileAction";
import SessionExpired from "./components/Common/SessionExpired";
import LandingPage from "./Screens/LandingPage";
import Profile from "./Screens/Profile";
import Trip from "./Screens/Trip";
import MyTrips from "./Screens/MyTrips";
import TripDetails from "./Screens/TripDetails";
import { readProfileAction } from "./store/LoginAndRegister/LoginAndRegisterAction";

function App() {
  const role = "Admin";
  const navigate = useNavigate();
  const [shortBar, setShortBar] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const socket = useSocket();
  const dispatch = useDispatch();

  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const { userDetails } = useSelector((state) => state.userProfile);

  console.log("userDetails", userDetails);
  console.log("isAuthenticated", isAuthenticated);

  const updateToken = async () => {
    const accessToken = await getAccessTokenSilently();
    localStorage.setItem("token", accessToken);
    dispatch(updateTokenAction(accessToken));
    updateUserDetails();
  };

  const updateUserDetails = () => {
    const userDetails = JSON.parse(localStorage.getItem("user"));
    if (!userDetails?.roleCode) {
      dispatch(
        readProfileAction({
          callback: async (res) => {
            if (res?.type) {
              // const userEmail = res?.data?.[0]?.email;
              // const isUserAuthenticated = localStorage.getItem("auth");
              // if(!isUserAuthenticated) {
              //     const msg = await registerWithEmail(userEmail, userEmail);
              //     console.log(msg, "home msg");
              // }else {
              //     // checkMessages();
              // }
              // const userFirstName = res?.data?.[0]?.firstName;
              const roleCode = res?.data?.[0]?.roleCode;
              const user = res?.data?.[0];
              if (roleCode) {
                localStorage.setItem("role", roleCode);
                localStorage.setItem(
                  "roleName",
                  roleCode === "84bts2"
                    ? "Admin"
                    : roleCode === "96nhe6"
                    ? "Executive"
                    : roleCode === "63udl9"
                    ? "User"
                    : roleCode === "43ser5"
                    ? "Driver"
                    : "Unauthorized"
                );
              }
              if (user) {
                localStorage.setItem("user", JSON.stringify(user));
                dispatch(updateUserDetailsAction(user));
              }
              setTimeout(()=> setShowLoader(false), 500)
              if (roleCode === "96nhe6" || roleCode === "84bts2") navigate("/dashboard");
              else if (roleCode === "63udl9" || roleCode === "43ser5") navigate("/");
              else ShowUnauthorized();
            }
          },
        })
      );
    } else {
      dispatch(updateUserDetailsAction(userDetails));
      setTimeout(()=> setShowLoader(false), 500)
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      const token = localStorage.getItem("token");
      if (!token) {
        updateToken();
      } else {
        dispatch(updateTokenAction(token));
        updateUserDetails();
      }
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (socket) {
      socket.on("connect", () => {
        console.log("Connection established!");
      });

      socket.on("welcome", (data) => {
        console.log("welcome :", data);
      });
    }
  }, [socket]);

  const ShowUnauthorized = () => {
    // setTimeout(() => {
    //   setShowLoader(false);
    // }, 1500);

    return (
      <div className="flex flex-col justify-center items-center bg-zinc-100 h-[100vh] gap-5">
        {showLoader ? (
          <Loader />
        ) : (
          <>
            <img src={Unauthorized} className="h-[20%]" alt="Unauthorized" />
            <p className="text-5xl">Unauthorized User</p>
            <button onClick={() => (window.location.href = "/")}>Go to Login</button>
          </>
        )}
      </div>
    );
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      <Routes>
        {/* logout on session or token expire */}
        <Route exact path="/logout" element={<SessionExpired />} />
        <Route path="/" element={<LandingPage />} />
        <Route path="/login" element={<LoginPage />} />

        {/* User/Driver Routes */}
        {["63udl9", "43ser5"].includes(userDetails?.roleCode) && (
          <>
            <Route path="/trip" element={<Trip />} />
            <Route path="/myTrips" element={<MyTrips />} />
            <Route path="/tripDetails" element={<TripDetails />} />
            <Route path="/Profile" element={<Profile />} />
          </>
        )}

        {/* Admin Routes */}
        {["84bts2"].includes(userDetails?.roleCode) && (
          <>
            <Route path="/Profile" element={<Profile />} />
            <Route
              path="/dashboard"
              element={<Home role={role} shortBar={shortBar} setShortBar={setShortBar} />}
            />
            <Route
              path="/dashboard/:id"
              element={<Home role={role} shortBar={shortBar} setShortBar={setShortBar} />}
            />
            <Route
              path="/trips"
              element={<Trips role={role} shortBar={shortBar} setShortBar={setShortBar} />}
            />
            <Route
              path="/abondontrips"
              element={<AbondonTrips role={role} shortBar={shortBar} setShortBar={setShortBar} />}
            />
            <Route
              path="/services"
              element={<Services role={role} shortBar={shortBar} setShortBar={setShortBar} />}
            />
            <Route
              path="/agents"
              element={<Agents role={role} shortBar={shortBar} setShortBar={setShortBar} />}
            />
            <Route
              path="/drivers"
              element={<Drivers role={role} shortBar={shortBar} setShortBar={setShortBar} />}
            />
            <Route
              path="/registered-users"
              element={
                <RegisteredUsers role={role} shortBar={shortBar} setShortBar={setShortBar} />
              }
            />
            <Route
              path="/view-all-cards"
              element={<ViewAllCards role={role} shortBar={shortBar} setShortBar={setShortBar} />}
            />
          </>
        )}

        {/* Customer Support/Executive Routes */}
        {["96nhe6"].includes(userDetails?.roleCode) && (
          <>
            <Route path="/Profile" element={<Profile />} />
            <Route
              path="/dashboard"
              element={<Home role={role} shortBar={shortBar} setShortBar={setShortBar} />}
            />
            <Route
              path="/dashboard/:id"
              element={<Home role={role} shortBar={shortBar} setShortBar={setShortBar} />}
            />
            <Route
              path="/trips"
              element={<Trips role={role} shortBar={shortBar} setShortBar={setShortBar} />}
            />
            <Route
              path="/abondontrips"
              element={<AbondonTrips role={role} shortBar={shortBar} setShortBar={setShortBar} />}
            />
            <Route
              path="/analyticspage"
              element={<AnalyticsPage role={role} shortBar={shortBar} setShortBar={setShortBar} />}
            />
            <Route
              path="/drivers"
              element={<Drivers role={role} shortBar={shortBar} setShortBar={setShortBar} />}
            />
            <Route
              path="/registered-users"
              element={
                <RegisteredUsers role={role} shortBar={shortBar} setShortBar={setShortBar} />
              }
            />
            <Route
              path="/view-all-cards"
              element={<ViewAllCards role={role} shortBar={shortBar} setShortBar={setShortBar} />}
            />
          </>
        )}

        {/* Fallback UI/Unauthorized screen */}
        <Route path="*" element={<ShowUnauthorized />} />
      </Routes>
    </div>
  );
}

export default App;

// {/* <Analytics/> */ }
// {/* <OptionList/> */ }
// {/* <BarChart /> */ }
// {/* <DropDown/> */ }
// {/* <DataTable/> */ }
