import React, { useEffect, useState } from "react";
import Star from "../../../../assets/images/Star.png";
import driverimage from "../../../../assets/images/driverimage.png";

export default function DriverContainer({
  name,
  driverImageUrl,
  pickupLocation,
  driverLastLocation,
  setTimeToArrive,
  coords,
  mobile = "",
}) {
  const [distance, setDistance] = useState("-");

  const updateDriverDistance = (origin, destination) => {
    console.log("origin", origin);
    console.log("destination", destination);
    console.log("window.google", window?.google?.maps);
    if (window?.google?.maps) {
      const directionsService = new window.google.maps.DirectionsService();
      console.log("directionsService", directionsService);
      if (directionsService) {
        directionsService.route(
          {
            origin: origin,
            destination: destination,
            travelMode: window?.google?.maps?.TravelMode?.DRIVING,
          },
          (result, status) => {
            if (status === window?.google?.maps?.DirectionsStatus?.OK) {
              if (result?.routes?.length > 0) {
                console.log("result123", result);
                setDistance(result?.routes?.[0]?.legs?.[0]?.distance?.text);
                setTimeToArrive(result?.routes?.[0]?.legs?.[0]?.duration?.text);
              }
            } else {
              // Toastify("Route Not Found", 2);
              console.error(`Error fetching directions: ${status}`);
            }
          }
        );
      }
    }
  };

  useEffect(() => {
    const driverCoordinates = coords ?? driverLastLocation?.coords;

    if (pickupLocation?.lat && driverCoordinates?.latitude) {
      updateDriverDistance(pickupLocation, {
        lat: driverCoordinates?.latitude,
        lng: driverCoordinates?.longitude,
      });
    }
  }, [pickupLocation, driverLastLocation, coords, window?.google]);

  const getLastSeen = (time) => {
    const currDate = new Date();
    const lastSeen = new Date(time);
    const diff = Math.abs(currDate - lastSeen);
    const minutes = Math.floor((diff / (1000 * 60)) % 60);
    const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
    return hours > 0 ? `${hours}h ${minutes}m` : `${minutes}mins`;
  };

  return (
    <div className="w-full p-3 bg-slate-200 rounded-lg flex flex-row">
      <div className="h-20 w-20 rounded-lg overflow-hidden aspect-square">
        <img src={driverImageUrl ? driverImageUrl : driverimage} />{" "}
      </div>
      <div className="pl-2 flex flex-col justify-between">
        <div>
          {" "}
          <p className="font-bold text-[14px] pb-1">{name}</p>
          <p className="font-bold text-[14px] pb-1 text-[#2B2B2B]">{mobile}</p>
          <p className="text-[12px] font-semibold text-[#2B2B2B] opacity-50">
            {distance} away from pickup location
            {/* {getLastSeen(driverLastLocation?.lastUpdatedDate)} ago */}
          </p>{" "}
        </div>
        {/* <div className="flex flex-row items-center p-1 ">
          <div>
            <img src={Star} />
          </div>{" "}
          <div>
            {" "}
            <p className="text-[12px] font-semibold text-[#2B2B2B] pl-1 opacity-50 ">
              {rating}
            </p>{" "}
          </div>
        </div> */}
      </div>
    </div>
  );
}
